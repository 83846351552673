import React from 'react';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function ParkingRightProviderField({ formik, editEnabled, formLabel = '' }) {
  const selectParkingRightProvider = provider => {
    const newSelectedParkingRightProviders = [
      ...formik.values.selectedParkingRightProviders,
      provider
    ];
    const newAvailableParkingRightProviders = formik.values.availableParkingRightProviders.filter(
      p => p.id !== provider.id
    );

    formik.setValues({
      ...formik.values,
      selectedParkingRightProviders: newSelectedParkingRightProviders.sort(idSort),
      availableParkingRightProviders: newAvailableParkingRightProviders
    });
  };

  const deselectParkingRightProvider = provider => {
    const newSelectedParkingRightProviders = formik.values.selectedParkingRightProviders.filter(
      p => p.id !== provider.id
    );
    const newAvailableParkingRightProviders = [
      ...formik.values.availableParkingRightProviders,
      provider
    ];

    formik.setValues({
      ...formik.values,
      selectedParkingRightProviders: newSelectedParkingRightProviders,
      availableParkingRightProviders: newAvailableParkingRightProviders.sort(idSort)
    });
  };

  const selectAllParkingRightProviders = () => {
    const newSelectedParkingRightProviders = [
      ...formik.values.selectedParkingRightProviders,
      ...formik.values.availableParkingRightProviders
    ];

    formik.setValues({
      ...formik.values,
      selectedParkingRightProviders: newSelectedParkingRightProviders.sort(idSort),
      availableParkingRightProviders: []
    });
  };

  const deselectAllParkingRightProviders = () => {
    const newAvailableParkingRightProviders = [
      ...formik.values.availableParkingRightProviders,
      ...formik.values.selectedParkingRightProviders
    ];

    formik.setValues({
      ...formik.values,
      selectedParkingRightProviders: [],
      availableParkingRightProviders: newAvailableParkingRightProviders.sort(idSort)
    });
  };

  return (
    <>
      <FormFieldBlock
        label={formLabel}
        className="providers"
        field={
          <SideBySideSelector
            availableObjects={formik?.values?.availableParkingRightProviders}
            selectedObjects={formik?.values?.selectedParkingRightProviders}
            selectOne={selectParkingRightProvider}
            deselectOne={deselectParkingRightProvider}
            selectAll={selectAllParkingRightProviders}
            deselectAll={deselectAllParkingRightProviders}
            disabled={!editEnabled}
          />
        }
        error={formik.errors.selectedParkingRightProviders}
      />
    </>
  );
}

export default ParkingRightProviderField;
