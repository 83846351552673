import * as Yup from 'yup';

const IPV4_REGEX = /(^(\d{1,3}\.){3}(\d{1,3})$)/;
const MAC_ADDRESS_REGEX = /^([0-9a-fA-F]{2}[.:-]{0,1}){6}$/;

function ipv4(message = 'Invalid IP address') {
  return this.matches(IPV4_REGEX, {
    message,
    excludeEmptyString: true
  }).test('ip', message, value => {
    return value === undefined || value === null || value.trim() === ''
      ? true
      : value.split('.').find(i => parseInt(i, 10) > 255) === undefined;
  });
}

Yup.addMethod(Yup.string, 'ipv4', ipv4);

const mobileCameraSchema = Yup.object({
  protocol: Yup.string()
    .oneOf(['scancar_xml', 'bop'])
    .required(I18n.t('settings.field_protocol_required')),
  name: Yup.string().required(I18n.t('settings.field_name_required')),
  status: Yup.string()
    .oneOf(['enabled', 'disabled'])
    .required(I18n.t('settings.field_status_required')),
  tiny_system_states_message_active: Yup.boolean().nullable(),
  feeds_whitelist: Yup.boolean().nullable(),

  ip_addr: Yup.string().when('protocol', {
    is: protocol => {
      return protocol === 'scancar_xml';
    },
    then: Yup.string()
      .required(I18n.t('settings.field_ip_addr_required'))
      .ipv4(I18n.t('settings.field_ip_format')),
    otherwise: Yup.string().nullable()
  }),
  port: Yup.number().when('protocol', {
    is: protocol => {
      return protocol === 'scancar_xml';
    },
    then: Yup.number()
      .min(1)
      .max(65535)
      .required(I18n.t('settings.field_port_required'))
      .typeError(I18n.t('settings.field_port_number')),
    otherwise: Yup.number().nullable()
  }),
  mac_address: Yup.string().when('protocol', {
    is: protocol => {
      return protocol === 'bop';
    },
    then: Yup.string()
      .required(I18n.t('settings.field_mac_address_required'))
      .matches(MAC_ADDRESS_REGEX, I18n.t('settings.field_mac_format')),
    otherwise: Yup.string().nullable()
  }),
  nearest_zone_threshold_cm: Yup.number().when('find_nearest_zone', {
    is: true,
    then: Yup.number()
      .required(I18n.t('settings.field_nearest_zone_threshold_cm_required'))
      .typeError(I18n.t('settings.field_nearest_zone_threshold_cm_number_type'))
      .min(1, I18n.t('settings.field_nearest_zone_threshold_cm_min'))
      .max(200, I18n.t('settings.field_nearest_zone_threshold_cm_max'))
      .nullable(),
    otherwise: Yup.number().nullable()
  })
});

export default mobileCameraSchema;
