import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const Input = ({
  name,
  type,
  onChange,
  placeholder,
  disabled,
  error,
  value,
  controlled,
  className,
  checked = false
}) => {
  const [localValue, setLocalValue] = useState(value);
  const controlProps = controlled
    ? {
        value: localValue
      }
    : {
        defaultValue: localValue
      };

  useEffect(() => {
    if (controlled && value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <input
      name={name}
      type={type}
      onChange={e => {
        setLocalValue(e.target.value);
        onChange(e);
      }}
      placeholder={placeholder}
      disabled={disabled}
      className={classNames(className, { 'is-invalid': error, 'is-dirty': value })}
      checked={checked}
      {...controlProps}
    />
  );
};

export default Input;
