import React from 'react';
import { Modal, Button, Form as BYForm } from 'brickyard-ui';
import { useIdleTimerContext } from 'react-idle-timer';

const ActivityCheckerModal = ({ show, onHide, onLogout }) => {
  const idleTimer = useIdleTimerContext();

  const logoutAction = () => {
    idleTimer.pause();
    onLogout();
  };
  const activeAction = () => {
    idleTimer.activate();
    onHide();
  };

  return (
    <Modal show={show} onHide={activeAction} centered>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('activity_checker.modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BYForm.Group>
          <BYForm.Label>{I18n.t('activity_checker.modal_content_line1')}</BYForm.Label>
          <BYForm.Label>{I18n.t('activity_checker.modal_content_line2')}</BYForm.Label>
        </BYForm.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="by-dark" onClick={logoutAction}>
          {I18n.t('messages.general.close')}
        </Button>
        <Button variant="by-primary" onClick={activeAction}>
          {I18n.t('messages.general.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActivityCheckerModal;
