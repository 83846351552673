import React from 'react';
import { Marker } from '@react-google-maps/api';
import styled from 'styled-components';
import Map from '../../../../../../shared/maps/Map';
import CameraActive from '@/assets/img/camera_active.svg';
import CameraInactive from '@/assets/img/camera_inactive.svg';
import CameraWarning from '@/assets/img/camera_warning.svg';

const StyledCameraFormMap = styled.div`
  display: flex;

  #google-map {
    min-width: 0px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    margin: 10px 10px 0px 10px;
    width: calc(100% - 20px);
    padding-top: calc(100% - 20px);
  }
`;

function CameraFormMap({ camera, onMarkerDragEnd, draggable }) {
  const cameraHasLatLng = camera.latitude && camera.longitude;
  const cameraLatLng = {
    lat: parseFloat(camera.latitude),
    lng: parseFloat(camera.longitude)
  };

  const getMapCenter = () => {
    if (cameraHasLatLng) {
      return cameraLatLng;
    }

    // Netherlands
    return { lat: 52.1611973294726, lng: 5.621868260475858 };
  };

  const getIcon = () => {
    if (draggable) return null;

    switch (camera.status) {
      case 'enabled':
        return CameraActive;
      case 'disabled':
        return CameraWarning;
      default:
        return CameraInactive;
    }
  };

  return (
    <StyledCameraFormMap>
      <Map center={getMapCenter()} clickableIcons={false} zoomLevel={cameraHasLatLng ? 16 : 8}>
        {camera && (
          <Marker
            position={getMapCenter()}
            draggable={draggable}
            onDragEnd={onMarkerDragEnd}
            clickable={draggable}
            icon={getIcon()}
          />
        )}
      </Map>
    </StyledCameraFormMap>
  );
}

export default CameraFormMap;
