import React from 'react';
import styled from 'styled-components';
import StreetSearch from './../../../TicketsTable/search/StreetSearch';
import Label from './Label';
import AutoCompleteWrapper from './AutoCompleteWrapper';

const Wrapper = styled.div`
  position: relative;

  .error-message {
    width: max-content;
    font-size: 10px;
    position: absolute;
    bottom: -10px;
    left: 0;
    color: #dc3545;
  }
`;

const StreetSearchInput = ({ fieldProps, city, disabled, readOnly, error }) => {
  return (
    <Wrapper>
      <AutoCompleteWrapper>
        <Label>
          {I18n.t('activerecord.attributes.ticket.offense_location_street')}
          {readOnly && <span className="readonly-value">{fieldProps.value}</span>}
        </Label>
        {!readOnly && (
          <>
            <StreetSearch disabled={disabled} isInvalid={error} city={city} {...fieldProps} />
            {error && <span className="error-message">{error}</span>}
          </>
        )}
      </AutoCompleteWrapper>
    </Wrapper>
  );
};

export default StreetSearchInput;
