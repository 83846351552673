import gql from 'graphql-tag';

export const RESET_USE_CASE_RESULTS = gql`
  mutation CopResetUseCaseResults($ids: [ID!]!, $validatedFilter: Boolean) {
    copResetUseCaseResults(ids: $ids, validatedFilter: $validatedFilter) {
      id
      status
    }
  }
`;

export const PERSIST_OBSERVATION_IMAGE = gql`
  mutation CopPersistObservationImage($id: ID!, $useCaseResultId: ID!, $data: String!) {
    observationFilePersist(id: $id, useCaseResultId: $useCaseResultId, data: $data) {
      id
      url
      kind
    }
  }
`;

export const DELETE_OBSERVATION_IMAGE = gql`
  mutation CopDeleteObservationImage($id: ID!) {
    observationFileDelete(id: $id)
  }
`;
