import React from 'react';

import {
  Card,
  Dropdown,
  FaMapMarkerAlt,
  InfoLabel,
  FaGlobeEurope,
  Button,
  FaCircle
} from 'brickyard-ui';

import CCTV from '@/assets/img/cctv.svg';
import 'styles/scenes/observations.scss';

const ObservationsMapControl = ({
  selectedArea,
  selectedCam,
  onAreaChange,
  onCamChange,
  areas,
  toggleMap,
  showMap,
  user,
  type = 'c01'
}) => {
  return (
    <Card className="observations-map-control">
      <div className="left">
        {type === 'c01' && (
          <Dropdown>
            <Dropdown.Toggle as="span">
              <FaMapMarkerAlt color="#FF6F00" size={20} />
              <InfoLabel
                title={I18n.t('observations.areas.index.name')}
                text={
                  selectedArea ? selectedArea.name : I18n.t('observations.areas.index.select_area')
                }
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="maximum-sized-dropdown">
              {areas.map(a => (
                <Dropdown.Item key={a.id} onClick={() => onAreaChange(a)}>
                  {a.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Dropdown>
          <Dropdown.Toggle as="span">
            <img alt="CCTV Camera" src={CCTV} />
            {type === 'scancar' ? (
              <InfoLabel
                title={I18n.t('scancar.observations.index.vehicle')}
                text={
                  selectedCam ? selectedCam.name : I18n.t('observations.areas.index.select_vehicle')
                }
              />
            ) : (
              <InfoLabel
                title={I18n.t('observations.areas.index.camera_name')}
                text={
                  selectedCam ? selectedCam.name : I18n.t('observations.areas.index.select_camera')
                }
              />
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className="maximum-sized-dropdown">
            {areas.map(a => (
              <React.Fragment key={a.id}>
                <span className="camera-name">{a.name}</span>
                <Dropdown.Divider />
                {a.cameras.map(cam => (
                  <Dropdown.Item key={cam.id} onClick={() => onCamChange(cam)}>
                    <FaCircle color={cam.active ? '#28A745' : '#979797'} size={16} /> {cam.name}
                  </Dropdown.Item>
                ))}
              </React.Fragment>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="right">
        {!!selectedArea && user.whitelist_button_visible && (
          <a
            className="whitelist"
            // TODO: remove new/old ui related stuff later
            href={`/observations/administration/observations_whitelists?temp_ui=old&area_id=${selectedArea.id}&from_new_ui=true`}
          >
            <Button type="button" variant="outline-by-primary" size="sm">
              {I18n.t('observations.areas.index.manage_whitelist')}
            </Button>
          </a>
        )}
        <FaGlobeEurope color={showMap ? '#FF5F00' : '#D6D7D8'} onClick={toggleMap} />
      </div>
    </Card>
  );
};

export default ObservationsMapControl;
