import React, { useState } from 'react';
import { Modal, Container, Loading, Alert } from 'brickyard-ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useCasesActions } from '../slice';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { AVAILABLE_PROCESSOR_CLASSES } from '../../../../../../../utils/consts';
import { getProcessorName, getProcessorType } from '../../../../../../../utils/PipelineProcessors';
import { useHistory, useRouteMatch } from 'react-router-dom';

const UseCasePipelineProcessorModal = ({ showModal, setShowModal }) => {
  const availablePipelineProcessors = AVAILABLE_PROCESSOR_CLASSES;
  const [submitError, setSubmitError] = useState(false);

  const state = useSelector(selectUseCasesState);
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const onClose = () => {
    setSubmitError(false);
    setShowModal(false);
  };

  const savePipelineProcessor = async availableProcessor => {
    await history.push(`${match.url}/block/new`);
    await dispatch(useCasesActions.storeProcessor(availableProcessor));
    onClose();
  };

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => onClose()}
        className="exemption-type-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('settings.pipelines.processors.modal.title.add')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {state.loading ? (
            <div className="loading-box">
              <Loading />
            </div>
          ) : (
            <Container>
              {submitError &&
                submitError.map((error, index) => {
                  return (
                    <Alert variant="danger" key={index}>
                      {error}
                    </Alert>
                  );
                })}

              <div className={'available-processors-container'} key="pp-modal">
                {availablePipelineProcessors.map((availableProcessor, index) => (
                  <div
                    key={`pp-block-${index}`}
                    className={'processor-container'}
                    onClick={() => savePipelineProcessor(availableProcessor)}
                  >
                    <div className="processor-type">
                      {getProcessorType(availableProcessor.className)}
                    </div>
                    <div className="processor-class-name">
                      {getProcessorName(availableProcessor.className)}
                    </div>
                    <i>
                      <AiOutlineInfoCircle
                        className="info-pipprocessor"
                        size={20}
                        title={I18n.t(
                          // eslint-disable-next-line no-undef
                          `settings.pipelines.processors.info_messages.${_.snakeCase(
                            availableProcessor.className
                          )}`
                        )}
                      />
                    </i>
                  </div>
                ))}
              </div>
            </Container>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UseCasePipelineProcessorModal;
