import React from 'react';

import { Alert, Col, Row } from 'brickyard-ui';
import formatDate from '@/utils/formatDate';

import ResetObservation from './ResetObservation';
import TestBoundary from '@/utils/TestBoundary';

const ObservationProcessItem = ({ observation, onReset, action }) => {
  const actionVariants = {
    delete: 'by-dark',
    warning: 'warning',
    ticket: 'danger'
  };

  return (
    <Alert variant={actionVariants[action]}>
      <Row>
        <Col xs="3">
          <span>{formatDate(observation.observedAt)}</span>
        </Col>

        <Col xs="3">
          <span>{observation.camera.name}</span>
        </Col>

        <Col xs="3">
          <span>{observation.vehicleLicensePlateNumber}</span>
        </Col>

        <Col xs="1">
          <span>{observation.countryCode}</span>
        </Col>

        <Col xs="1">
          <span>{(observation.accuracy * 100).toFixed(0)} %</span>
        </Col>

        <Col xs="1">
          <TestBoundary>
            <ResetObservation observationId={observation.id} onReset={onReset} />
          </TestBoundary>
        </Col>
      </Row>
    </Alert>
  );
};

export default ObservationProcessItem;
