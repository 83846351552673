import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import { Modal, Container } from 'brickyard-ui';

import useIsMount from '@/hooks/useIsMount';
import Button from '../Ticket/components/Button';
import Input from '../Ticket/Cards/components/LabelInput';
import DatePicker from '../Ticket/Cards/components/LabelDatePicker';

const ActionsWrapper = styled.div`
  width: 100%;
  padding: 0 15px;

  button {
    margin: 0 16px;
  }

  .reset {
    color: #f36c21;
    border: none;
    font-weight: 400;
  }

  .submit {
    background: #007bff;
    border-color: #007bff;
    color: #fff;
  }
`;

const BodyWrapper = styled.div`
  > div {
    width: 50%;
    text-align: center;

    .input-wrapper {
      width: 95%;
      padding: 5px;
      height: calc(100% - 26px);
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      text-align: left;
      opacity: 0.5;
      transition: border 200ms ease-in, opacity 200ms ease-in;
    }

    .radio-label {
      width: auto;

      input[type='radio'] {
        width: 18px;
        height: 18px;

        &:active,
        &:focus {
          box-shadow: none;
        }
      }
    }

    &.active {
      .input-wrapper {
        border: 1px solid #ff9d5a;
        opacity: 1;
      }
    }
  }
`;

export const initialValues = {
  pvNumberEq: '',
  vehicleLicensePlateNumberInsensitiveEqInsensitive: '',
  writtenAt: '',
  searchMode: '0'
};

const requiredMsg = I18n.t('forms.validation.required');

const validationSchema = Yup.object().shape({
  pvNumberEq: Yup.string()
    .ensure()
    .when('searchMode', {
      is: '0',
      then: Yup.string()
        .ensure()
        .required(requiredMsg)
    }),
  vehicleLicensePlateNumberInsensitiveEqInsensitive: Yup.string()
    .ensure()
    .when('searchMode', {
      is: '1',
      then: Yup.string()
        .ensure()
        .required(requiredMsg)
    }),
  writtenAt: Yup.string()
    .ensure()
    .when('searchMode', {
      is: '1',
      then: Yup.string()
        .ensure()
        .required(requiredMsg)
    })
});

const HistorySearch = ({ show, onHide, onSubmit }) => {
  const isMount = useIsMount();
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  useEffect(() => {
    if (!isMount) {
      formik.setValues(initialValues);
    }
  }, [show]);

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {I18n.t('observations.observations.search.history_search')}
      </Modal.Header>

      <Modal.Body>
        <Container fluid>
          <BodyWrapper className="flex-between">
            <div className={classNames({ active: formik.values.searchMode === '0' })}>
              <Input
                className="radio-label"
                type="radio"
                name="searchMode"
                value="0"
                checked={formik.values.searchMode === '0'}
                onChange={({ target }) => {
                  formik.setFieldValue('searchMode', target.value);
                }}
              />
              <div className="input-wrapper">
                <Input
                  name="pvNumberEq"
                  label={I18n.t('activerecord.attributes.ticket.pv_number')}
                  controlled
                  disabled={formik.values.searchMode !== '0'}
                  {...formik.getFieldProps('pvNumberEq')}
                  error={formik.errors.pvNumberEq}
                />
              </div>
            </div>
            <div className={classNames({ active: formik.values.searchMode === '1' })}>
              <Input
                className="radio-label"
                type="radio"
                name="searchMode"
                value="1"
                checked={formik.values.searchMode === '1'}
                onChange={({ target }) => {
                  formik.setFieldValue('searchMode', target.value);
                }}
              />
              <div className="input-wrapper">
                <Input
                  name="vehicleLicensePlateNumberInsensitiveEqInsensitive"
                  label={I18n.t('activerecord.attributes.ticket.vehicle_license_plate_number')}
                  controlled
                  disabled={formik.values.searchMode !== '1'}
                  {...formik.getFieldProps('vehicleLicensePlateNumberInsensitiveEqInsensitive')}
                  error={formik.errors.vehicleLicensePlateNumberInsensitiveEqInsensitive}
                />
                <DatePicker
                  label={I18n.t('tickets.search.written_at')}
                  disabled={formik.values.searchMode !== '1'}
                  {...formik.getFieldProps('writtenAt')}
                  onChange={date => {
                    const adjustedDate =
                      date &&
                      new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
                    formik.setFieldValue('writtenAt', adjustedDate);
                  }}
                  error={formik.errors.writtenAt}
                />
              </div>
            </div>
          </BodyWrapper>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <ActionsWrapper className="flex-center">
          <Button onClick={onHide}>{I18n.t('actions.cancel')}</Button>

          <Button className="submit" type="submit" onClick={formik.handleSubmit}>
            {I18n.t('actions.search')}
          </Button>
        </ActionsWrapper>
      </Modal.Footer>
    </Modal>
  );
};

export default HistorySearch;
