import { useMutation, gql } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Form as BYForm, Modal, MessagePopup } from 'brickyard-ui';

import usePermission from '@/hooks/usePermission';

import Button from '../../components/Button';

export const UNEXPORT_TICKET = gql`
  mutation UnexportTicket($id: ID!, $exportConfigurationId: ID!) {
    ticketUnexport(id: $id, exportConfigurationId: $exportConfigurationId) {
      id
      exportState
    }
  }
`;

const Unexport = ({ ticket }) => {
  const { id, exportConfigurations } = ticket;
  const [exportConfigurationId, setExportCofigurationId] = useState(exportConfigurations[0]?.id);
  const [showCfg, setShowCfg] = useState(false);
  const canUnexport = usePermission('unexport');

  const [unexport, { loading: unexportLoading, error: unexportErr }] = useMutation(
    UNEXPORT_TICKET,
    {
      variables: { id, exportConfigurationId },
      onCompleted: () => {
        setShowCfg(false);
      }
    }
  );
  const [err, setErr] = useState('');

  useEffect(() => {
    unexportErr && setErr(unexportErr.message);
  }, [unexportErr]);

  if (!canUnexport || !exportConfigurations.length > 0) {
    return null;
  }

  return (
    <div>
      <Button onClick={() => setShowCfg(true)} disabled={unexportLoading}>
        {I18n.t('information.actions.unexport')}
      </Button>

      <MessagePopup
        type="error"
        show={!!err}
        onHide={() => setErr('')}
        okBtn={{ label: I18n.t('actions.close'), onClick: () => setErr('') }}
        message={err}
      />

      <Modal show={showCfg} onHide={() => setShowCfg(false)}>
        <Modal.Header>
          <Modal.Title>{I18n.t('tickets.dialogs.unexport.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <BYForm.Group>
            <BYForm.Label>{I18n.t('tickets.dialogs.unexport.description')}</BYForm.Label>
            <BYForm.Control
              name="receiptNumber"
              value={exportConfigurationId}
              onChange={evt => setExportCofigurationId(evt.target.value)}
              as="select"
            >
              <option value="" />
              {exportConfigurations.map(c => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </BYForm.Control>
          </BYForm.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="by-dark"
            className="float-right ml-2"
            onClick={() => setShowCfg(false)}
            disabled={unexportLoading}
          >
            {I18n.t('actions.cancel')}
          </Button>

          <Button
            variant="by-primary"
            className="float-right"
            onClick={unexport}
            disabled={!exportConfigurationId || unexportLoading}
          >
            {I18n.t('actions.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Unexport;
