import React, { useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormFieldSmall from '../../../../components/FormFieldSmall';
import LabelToggle from '../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldBlock from '../../../../components/FormFieldBlock';
import FormField from '../../../../components/FormField';

function MessagePusherNotifier({ outerRef, persistPipelineProcessor, editEnabled }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();
  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      exempt_on_error: !!selectedProcessor?.config?.exempt_on_error,
      url: selectedProcessor?.config?.url,
      username: selectedProcessor?.config?.username,
      password: selectedProcessor?.config?.password
    },
    enableReinitialize: true,
    onSubmit: async values => {
      await persistPipelineProcessor({
        variables: {
          id: values.id,
          name: values.name,
          config: {
            exempt_on_error: values.exempt_on_error,
            url: values.url,
            username: values.username,
            password: values.password
          }
        }
      });
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        I18n.t(
          'settings.pipelines.processors.form.message_pusher_notifier.validations.name_required'
        )
      ),
      url: Yup.string().required(
        I18n.t(
          'settings.pipelines.processors.form.message_pusher_notifier.validations.url_required'
        )
      ),
      username: Yup.string(),
      password: Yup.string().when('username', (username, schema) => {
        return username
          ? schema.required(
              I18n.t(
                'settings.pipelines.processors.form.message_pusher_notifier.validations.password_required'
              )
            )
          : schema;
      })
    })
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  return (
    <>
      <FormFieldBlock
        field={
          <>
            <FormFieldSmall
              field={
                <LabelToggle
                  id="exempt_on_error"
                  name="exempt_on_error"
                  isOn={formik.values.exempt_on_error}
                  onChange={formik.handleChange}
                  disabled={!editEnabled}
                />
              }
              error={formik.errors.exempt_on_error}
              label={
                formik.values.exempt_on_error ? (
                  <div className="alert alert-success alert-switch" role="alert">
                    {I18n.t('settings.pipelines.processors.messages.on_error_exempt')}
                  </div>
                ) : (
                  <div className="alert alert-primary alert-switch" role="alert">
                    {I18n.t('settings.pipelines.processors.messages.on_error_continue')}
                  </div>
                )
              }
            />

            <FormField
              label={I18n.t('settings.pipelines.processors.form.message_pusher_notifier.url')}
              field={
                <input
                  id="url"
                  name="url"
                  disabled={!editEnabled}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.url}
                />
              }
              error={formik.errors.url}
            />

            <FormField
              label={I18n.t('settings.pipelines.processors.form.message_pusher_notifier.username')}
              field={
                <input
                  id="username"
                  name="username"
                  disabled={!editEnabled}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.username}
                />
              }
              error={formik.errors.username}
            />

            <FormField
              label={I18n.t('settings.pipelines.processors.form.message_pusher_notifier.password')}
              field={
                <input
                  id="password"
                  name="password"
                  disabled={!editEnabled}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.password}
                />
              }
              error={formik.errors.password}
            />
          </>
        }
      />
    </>
  );
}

export default MessagePusherNotifier;
