import React, { useMemo } from 'react';
import { Row, Col } from 'brickyard-ui';
import { BARRIER_STATUS } from '@/utils/consts';

const TabbedPanelRailwaySettings = ({ useCaseResult, type }) => {
  const readOnlyLabels = useMemo(() => {
    return [
      {
        i18nTitle: I18n.t('use_case.result.details.railway_violation_data.time_since_red_light'),
        attr: 'time_since_red_light_in_secs',
        position: 0
      },
      {
        i18nTitle: I18n.t('use_case.result.details.railway_speed_limit'),
        attr: 'railwaySpeedLimit',
        position: 1
      },
      {
        i18nTitle: I18n.t('use_case.result.details.railway_pardon_time'),
        attr: 'railwayPardonTime',
        position: 2
      },
      {
        i18nTitle: I18n.t('use_case.result.details.railway_violation_data.barrier_position'),
        attr: 'barrier_position',
        position: 3
      },
      {
        i18nTitle: I18n.t('use_case.result.details.railway_violation_data.barrier_close_count'),
        attr: 'barrier_close_count',
        position: 4
      },
      {
        i18nTitle: I18n.t('use_case.result.details.railway_violation_data.time_since_prev_closure'),
        attr: 'time_since_prev_closure',
        position: 5
      }
    ];
  }, [useCaseResult, type]);

  const parsedObs = {
    ...useCaseResult,
    barrier_position: BARRIER_STATUS.includes(useCaseResult.railwayViolationData.barrier_position)
      ? I18n.t(
          `use_case.result.details.railway_violation_data.barrier_status.${useCaseResult.railwayViolationData.barrier_position}`
        )
      : I18n.t('use_case.result.details.railway_violation_data.no_detail'),
    railwayPardonTime: useCaseResult?.railwayPardonTime?.toString(),
    barrier_close_count: useCaseResult?.railwayViolationData?.barrier_close_count,
    time_since_prev_closure: useCaseResult?.railwayViolationData?.time_since_prev_closure,
    time_since_red_light_in_secs: useCaseResult?.railwayViolationData?.time_since_red_light_in_secs
  };

  const renderField = field => {
    // show no detail text if field is null or undefined
    if (parsedObs[field.attr] == null) {
      return I18n.t('use_case.result.details.railway_violation_data.no_detail');
    }

    return parsedObs[field.attr];
  };

  return (
    <>
      {readOnlyLabels.map(field => (
        <Row key={field.attr}>
          <Col xs="4">{field.i18nTitle}</Col>
          <Col xs="8" className="font-weight-bold">
            {renderField(field)}
          </Col>
        </Row>
      ))}
    </>
  );
};

export default TabbedPanelRailwaySettings;
