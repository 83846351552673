import React, { useState } from 'react';
import styled from 'styled-components';

import Button from '../../components/Button';
import Card from '../components/Card';
import LabelInput from '../components/LabelInput';
import Textarea from '../components/LabelTextarea';
import Toggle from '../components/LabelToggle';
import Tabs from '../components/Tabs';
import CountrySearchInput from '../components/CountrySearchInput';
import OfficerStatementSearch from '@/components/scenes/TicketsTable/search/OfficerStatementSearch';
import Label from '../components/Label';
import CautieRadio from './CautieRadio';
import SuspectStatementSearch from '@/components/scenes/TicketsTable/search/SuspectStatementSearch';

const shouldBeHidden = (type, personalInformationValidation) => {
  switch (type) {
    case 'fiscal':
      return true;
    case 'taxi':
    case 'juridical':
      return personalInformationValidation === 'notallowed';
    case 'recording':
      return false;
  }
};

const StyledButton = styled(Button)`
  margin: 18px 0;
`;

const StatementCard = ({ formik }) => {
  const [officerStatementModalVisible, setOfficerStatementVisibility] = useState(false);
  const [suspectStatementModalVisible, setSuspectStatementVisibility] = useState(false);
  const {
    type,
    handcuffsUsed,
    offenseCode,
    explainSuspectOffence,
    explainLegalAid,
    explainRightTranslator,
    suspectWaivedInterrogation,
    personalInformationValidation
  } = formik.values;

  const suspectHidden = shouldBeHidden(type, personalInformationValidation);

  return (
    <Card
      title={I18n.t('tickets.form.title.statements')}
      colorScheme={{ primaryColor: '#3A945F', secondaryColor: '#F5F8F5' }}
    >
      <Tabs>
        {!suspectHidden && (
          <div label={I18n.t('tickets.form.title.suspect')}>
            <Label>{I18n.t('tickets.form.text.suspect_explaining')}</Label>
            <Toggle
              disabled={formik.status.readOnly}
              label={I18n.t('tickets.titles.explain_suspect_offence')}
              name="explainSuspectOffence"
              isOn={explainSuspectOffence}
              onChange={() => formik.setFieldValue('explainSuspectOffence', !explainSuspectOffence)}
            />
            <Toggle
              disabled={formik.status.readOnly}
              label={I18n.t('tickets.titles.explain_legal_aid')}
              name="explainLegalAid"
              isOn={explainLegalAid}
              onChange={() => formik.setFieldValue('explainLegalAid', !explainLegalAid)}
            />
            <Toggle
              disabled={formik.status.readOnly}
              label={I18n.t('tickets.titles.explain_right_translator')}
              name="explainRightTranslator"
              isOn={explainRightTranslator}
              onChange={() =>
                formik.setFieldValue('explainRightTranslator', !explainRightTranslator)
              }
            />
            <Toggle
              disabled={formik.status.readOnly}
              label={I18n.t('tickets.titles.suspect_waived_interrogation')}
              name="suspectWaivedInterrogation"
              isOn={suspectWaivedInterrogation}
              onChange={() =>
                formik.setFieldValue('suspectWaivedInterrogation', !suspectWaivedInterrogation)
              }
            />
            <CountrySearchInput
              label={I18n.t('tickets.titles.language_of_interrogation')}
              readOnly={formik.status.readOnly}
              attr="name"
              fieldProps={formik.getFieldProps('languageOfInterrogation')}
            />
            <LabelInput
              label={I18n.t('tickets.titles.interpreter_number')}
              readOnly={formik.status.readOnly}
              {...formik.getFieldProps('interpreterNumber')}
            />
            <CautieRadio formik={formik} />
            <Toggle
              label={I18n.t('tickets.full_ticket.labels.handcuffs_used')}
              name="handcuffsUsed"
              disabled={formik.status.readOnly}
              isOn={handcuffsUsed}
              onChange={formik.handleChange}
            />
            <Textarea
              label={I18n.t('activerecord.attributes.ticket.statement_suspect')}
              readOnly={formik.status.readOnly}
              error={formik.errors.statementSuspect}
              {...formik.getFieldProps('statementSuspect')}
            />
            {!formik.status.readOnly && (
              <StyledButton onClick={() => setSuspectStatementVisibility(true)}>
                {I18n.t('tickets.full_ticket.actions.suggestions')}
              </StyledButton>
            )}
            <SuspectStatementSearch
              show={suspectStatementModalVisible}
              onHide={() => setSuspectStatementVisibility(false)}
              onSelect={os => formik.setFieldValue('statementSuspect', os)}
            />
          </div>
        )}
        <div label={I18n.t('tickets.form.title.officer')}>
          <Textarea
            label={I18n.t('activerecord.attributes.ticket.statement_officer')}
            readOnly={formik.status.readOnly}
            error={formik.errors.statementOfficer}
            {...formik.getFieldProps('statementOfficer')}
          />
          {!formik.status.readOnly && (
            <Button onClick={() => setOfficerStatementVisibility(true)}>
              {I18n.t('tickets.full_ticket.actions.suggestions')}
            </Button>
          )}
          <OfficerStatementSearch
            offenseCode={offenseCode}
            show={officerStatementModalVisible}
            onHide={() => setOfficerStatementVisibility(false)}
            onSelect={os => formik.setFieldValue('statementOfficer', os)}
          />
        </div>
        <div label={I18n.t('tickets.form.title.remark_officer')}>
          <Textarea
            className="ticket_statement_remark_officer"
            label={I18n.t('activerecord.attributes.ticket.remark_officer')}
            readOnly={formik.status.readOnly}
            {...formik.getFieldProps('remarkOfficer')}
          />
        </div>
      </Tabs>
    </Card>
  );
};

export default StatementCard;
