import React from 'react';
import styled from 'styled-components';
import Label from './Label';

const Wrapper = styled(Label)`
  margin: 0;
  position: relative;

  textarea {
    width: 100%;
    font-size: 12px;
    height: 155px;
    resize: none;
    border: 1px solid #cccccc;

    &:active,
    &:focus {
      box-shadow: 0px 0px 4px #007bff;
      border: none;
      outline: none;
    }

    &.is-invalid {
      border-color: #dc3545;

      &:focus {
        box-shadow: 0px 0px 4px #dc3545;
      }
    }
  }

  .error-message {
    width: max-content;
    font-size: 10px;
    position: absolute;
    bottom: -16px;
    left: 0;
    color: #dc3545;
  }
`;

const LabelTextarea = ({ label, name, onChange, value, error, className = '', readOnly }) => {
  return (
    <Wrapper className={className}>
      {label}
      {readOnly ? (
        <span className="readonly-value">{value}</span>
      ) : (
        <>
          <textarea
            name={name}
            onChange={onChange}
            defaultValue={value}
            className={error ? 'is-invalid' : ''}
          />
          {error && <span className="error-message">{error}</span>}
        </>
      )}
    </Wrapper>
  );
};

export default LabelTextarea;
