import React, { useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { ObjectToQuery, URLToObject } from '@/utils/URLQueryParser';

/**
 * This hook works pretty similar to the useState hook, but the changes made by the set function
 * will be reflect on the window URL.
 * WARNING: This hook should be used only once per "module", keeping one single source of truth.
 * If you need to read the url on secondary components, check the URLQueryParser util file.
 *
 * @param {Object} initialValue This value will be set locally and also returned aftwards, as on useState
 * @param {boolean} loadFromURL Inidicates if it should load values from the URL on intialization
 * @return {Array} Returns array of a value and a setter function, as on useState
 */
const useURLBind = (initialValue, loadFromURL = true) => {
  const [value, setValue] = useState(initialValue || {});

  useEffect(() => {
    if (loadFromURL) {
      setValue({ ...value, ...URLToObject(window.location) });
    }
  }, []);

  useEffect(() => {
    window.history.pushState({}, null, ObjectToQuery(value));
  }, [value]);

  return [value, setValue];
};

export default useURLBind;
