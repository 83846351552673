import React from 'react';

import { Badge } from 'brickyard-ui';

import 'styles/shared/recently-used-filters.scss';

const RecentlyUsedFilters = ({ filters, onSelectFilter }) => {
  return (
    <div className="recent-used-filters">
      <p>{I18n.t('observations.observations.search.recent_filters')}: </p>
      {filters
        .filter(f => typeof f.value !== 'boolean')
        .map(filter => (
          <span key={filter.key}>
            <Badge variant="outline-by-secondary" onClick={() => onSelectFilter(filter)}>
              {`${filter.labelI18n}: ${filter.valueLabel || filter.value}`}
            </Badge>
          </span>
        ))}
    </div>
  );
};

export default RecentlyUsedFilters;
