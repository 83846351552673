import React from 'react';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import { idSort } from './utils';

function FixedCameraField({
  formik,
  editEnabled,
  isSwitchable = false,
  formLabel = '',
  switchLabel = ''
}) {
  const selectCamera = camera => {
    const newSelectedCameras = [...formik.values.selectedFixedCameras, camera];
    const newAvailableCameras = formik.values.availableCameras.filter(c => c.id !== camera.id);

    formik.setValues({
      ...formik.values,
      selectedFixedCameras: newSelectedCameras,
      availableCameras: newAvailableCameras
    });
  };
  const deselectCamera = camera => {
    const newSelectedCameras = formik.values.selectedFixedCameras.filter(c => c.id !== camera.id);
    const newAvailableCameras = [...formik.values.availableCameras, camera];

    formik.setValues({
      ...formik.values,
      selectedFixedCameras: newSelectedCameras,
      availableCameras: newAvailableCameras
    });
  };

  const selectAllFixedCameras = () => {
    const newSelectedCameras = [
      ...formik.values.selectedFixedCameras,
      ...formik.values.availableCameras.filter(camera => camera.type === 'Cop::FixedCamera')
    ];
    const newAvailableCameras = [
      ...formik.values.availableCameras.filter(camera => camera.type === 'Cop::MobileCamera')
    ];

    formik.setValues({
      ...formik.values,
      selectedFixedCameras: newSelectedCameras,
      availableCameras: newAvailableCameras
    });
  };
  const deselectAllFixedCameras = () => {
    const newSelectedCameras = [
      ...formik.values.selectedFixedCameras.filter(camera => camera.type === 'Cop::MobileCamera')
    ];
    const newAvailableCameras = [
      ...formik.values.availableCameras,
      ...formik.values.selectedFixedCameras.filter(camera => camera.type === 'Cop::FixedCamera')
    ];

    formik.setValues({
      ...formik.values,
      selectedFixedCameras: newSelectedCameras,
      availableCameras: newAvailableCameras
    });
  };

  const getAvailableFixedCameras = () => {
    return formik.values.availableCameras
      .filter(camera => camera.type === 'Cop::FixedCamera')
      .sort(idSort);
  };
  const getSelectedFixedCameras = () => {
    return formik.values.selectedFixedCameras
      .filter(camera => camera.type === 'Cop::FixedCamera')
      .sort(idSort);
  };

  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledFixedCameras"
              name="enabledFixedCameras"
              isOn={formik.values.enabledFixedCameras}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledFixedCameras}
        />
      )}

      {((formik.values.enabledFixedCameras && isSwitchable) || !isSwitchable) && (
        <FormFieldBlock
          label={formLabel}
          className="fixedCamers"
          field={
            <SideBySideSelector
              availableObjects={getAvailableFixedCameras()}
              selectedObjects={getSelectedFixedCameras()}
              selectOne={selectCamera}
              deselectOne={deselectCamera}
              selectAll={selectAllFixedCameras}
              deselectAll={deselectAllFixedCameras}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.selectedFixedCameras}
        />
      )}
    </>
  );
}

export default FixedCameraField;
