import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import withStoreProvider from '@/utils/withStoreProvider';
import Routes from '../../Routes';

const App = props => {
  return (
    <Router>
      <div style={{ height: '100%' }} {...props}>
        <Routes />
      </div>
    </Router>
  );
};

export default withStoreProvider(App);
