import React, { useEffect, useState } from 'react';
import { Alert } from 'brickyard-ui';
import { useFormik } from 'formik';
import { useCaseSchema } from '../validationSchema';
import styled from 'styled-components';
import LabelToggle from '../../../../../Ticket/Cards/components/LabelToggle';
import FormControls from '../../../components/FormControls';
import FormField from '../../../components/FormField';
import UseCaseDetailsMap from './UseCaseDetailsMap';
import { useSelector, useDispatch } from 'react-redux';
import OffenseSelector from '../../../../../../shared/offenses/OffenseSelector';
import withApolloProvider from '../../../../../../../utils/withApolloProvider';
import { useUseCasesSlice } from '../slice';
import UseCaseImageField from './UseCaseImageField';

const StyledUseCaseDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 90px);
    max-height: 720px;

    .details-left-panel {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: calc(95% - 30px);
      margin: 15px 0px 0px 15px;
      overflow-x: auto;

      .dropdown {
        input.offenseSelector {
          width: 95%;
          font-size: inherit;

          &:focus {
            box-shadow: none;
            outline: 1px auto rgb(16, 16, 16);
            outline-offset: 0px;
          }
        }
      }
    }

    .details-right-panel {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
    }
  }

  .footer {
    display: flex;
    margin: auto;
    width: 100%;
  }
`;

function UseCaseDetails({ useCase, onSave, submitError, setSubmitError, onDelete, onClose }) {
  const [editEnabled, setEditEnabled] = useState(!useCase?.id);

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';

  const { actions } = useUseCasesSlice();
  const dispatch = useDispatch();
  var activeShapeFiles = null;

  const formik = useFormik({
    initialValues: useCase?.id
      ? useCase
      : {
          name: '',
          description: '',
          uuid: '',
          ttlInHours: 1,
          ttlForExemptInHours: 1,
          ttlForUndoInHours: null,
          allowValidationWithMissingVehicleType: false,
          dismissalReasonRequired: false,
          defaultOffense: false,
          offenseCode: '',
          awayStatement: '',
          towardStatement: '',
          defaultStatement: '',
          warningEnabled: true,
          exemptionEnabled: true,
          image: null,
          imageUrl: null,
          copShapeFileId: null
        },
    enableReinitialize: true,
    onSubmit: values => {
      values['ttlForUndoInHours'] = values['ttlForUndoInHours']
        ? values['ttlForUndoInHours']
        : null;

      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, [
          'id',
          'name',
          'description',
          'ttlInHours',
          'ttlForExemptInHours',
          'ttlForUndoInHours',
          'allowValidationWithMissingVehicleType',
          'dismissalReasonRequired',
          'defaultOffense',
          'warningEnabled',
          'exemptionEnabled',
          'image',
          'imageUrl',
          'copShapeFileId',
          values['defaultOffense'] ? 'offenseCode' : null,
          values['defaultOffense'] ? 'awayStatement' : null,
          values['defaultOffense'] ? 'towardStatement' : null,
          values['defaultOffense'] ? 'defaultStatement' : null
        ])
      );
    },
    validationSchema: useCaseSchema
  });

  const handleCancel = () => {
    if (useCase?.id) {
      setEditEnabled(false);
    } else {
      onClose();
    }
    setSubmitError(false);
    formik.resetForm();
  };
  const handleDelete = () => {
    onDelete();
  };

  useEffect(() => {
    return () => {
      setSubmitError(false);
    };
  }, []);

  useEffect(() => {
    dispatch(actions.fetchSelectedUseCaseZone(useCase.id));
  }, [useCase.id]);

  const getActiveShapeFiles = () => {
    activeShapeFiles ??= (useCase?.shapeFiles ?? []).filter(
      shapeFile => shapeFile.status === 'finished'
    );

    return activeShapeFiles;
  };

  return (
    <StyledUseCaseDetails>
      <div className="body">
        <div className="details-left-panel">
          {submitError &&
            submitError.map((error, index) => {
              return (
                <Alert variant="danger" key={index}>
                  {error}
                </Alert>
              );
            })}
          <FormField
            label={I18n.t('settings.field_name_label')}
            field={
              <input
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name ?? ''}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.name}
          />
          <FormField
            label={I18n.t('settings.field_uuid_label')}
            field={
              <input
                id="uuid"
                name="uuid"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.uuid ?? ''}
                disabled
              />
            }
            error={formik.errors.uuid}
          />
          <FormField
            label={I18n.t('settings.field_description_label')}
            field={
              <textarea
                id="description"
                name="description"
                rows={3}
                onChange={formik.handleChange}
                value={formik.values.description ?? ''}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.description}
          />
          <FormField
            label={I18n.t('settings.field_ttl_in_hours_label')}
            field={
              <input
                id="ttlInHours"
                name="ttlInHours"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.ttlInHours ?? ''}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.ttlInHours}
          />
          <FormField
            label={I18n.t('settings.field_ttl_for_exempt_in_hours_label')}
            field={
              <input
                id="ttlForExemptInHours"
                name="ttlForExemptInHours"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.ttlForExemptInHours ?? ''}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.ttlForExemptInHours}
          />
          <FormField
            label={I18n.t('settings.field_ttl_for_undo_in_hours_label')}
            field={
              <input
                id="ttlForUndoInHours"
                name="ttlForUndoInHours"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.ttlForUndoInHours ?? ''}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.ttlForUndoInHours}
          />
          {getActiveShapeFiles().length > 0 && (
            <FormField
              label={I18n.t('settings.shape_files.shape_file_version')}
              field={
                <select
                  id="copShapeFileId"
                  name="copShapeFileId"
                  onChange={event => {
                    formik.setFieldValue('copShapeFileId', parseInt(event.target.value, 10));
                  }}
                  value={formik.values.copShapeFileId ?? ''}
                  disabled={!editEnabled}
                >
                  <option />
                  {getActiveShapeFiles().map(shapeFile => (
                    <option key={shapeFile.id} value={shapeFile.id}>
                      {shapeFile.versionName}
                    </option>
                  ))}
                </select>
              }
              error={formik.errors.allowValidationWithMissingVehicleType}
            />
          )}
          <FormField
            label={I18n.t('settings.field_allow_validation_with_missing_vehicle_type')}
            field={
              <LabelToggle
                name="allowValidationWithMissingVehicleType"
                isOn={formik.values.allowValidationWithMissingVehicleType ?? false}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.allowValidationWithMissingVehicleType}
          />
          <FormField
            label={I18n.t('settings.field_warning_enabled')}
            field={
              <LabelToggle
                name="warningEnabled"
                isOn={formik.values.warningEnabled ?? false}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.warningEnabled}
          />
          <FormField
            label={I18n.t('settings.field_exemption_enabled')}
            field={
              <LabelToggle
                name="exemptionEnabled"
                isOn={formik.values.exemptionEnabled ?? false}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.exemptionEnabled}
          />
          <FormField
            label={I18n.t('settings.field_dismissal_reason_required_label')}
            field={
              <LabelToggle
                name="dismissalReasonRequired"
                isOn={formik.values.dismissalReasonRequired ?? false}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.dismissalReasonRequired}
          />
          <FormField
            label={I18n.t('settings.field_default_offense_label')}
            field={
              <LabelToggle
                name="defaultOffense"
                isOn={formik.values.defaultOffense ?? false}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
            }
            error={formik.errors.defaultOffense}
          />
          {formik.values.defaultOffense && (
            <>
              <FormField
                label={I18n.t('settings.field_offense_code_label')}
                field={
                  <OffenseSelector
                    size="sm"
                    initialOffense={formik.values.offenseCode ?? null}
                    disabled={!editEnabled}
                    showLabel={false}
                    {...formik.getFieldProps('offenseCode')}
                  />
                }
                error={formik.errors.offenseCode}
              />

              <FormField
                label={I18n.t('settings.field_default_statement_label')}
                field={
                  <textarea
                    id="defaultStatement"
                    name="defaultStatement"
                    rows={3}
                    onChange={formik.handleChange}
                    value={formik.values.defaultStatement ?? ''}
                    disabled={!editEnabled}
                  />
                }
                error={formik.errors.defaultStatement}
              />

              <FormField
                label={I18n.t('settings.field_toward_statement_label')}
                field={
                  <textarea
                    id="towardStatement"
                    name="towardStatement"
                    rows={3}
                    onChange={formik.handleChange}
                    value={formik.values.towardStatement ?? ''}
                    disabled={!editEnabled}
                  />
                }
                error={formik.errors.towardStatement}
              />

              <FormField
                label={I18n.t('settings.field_away_statement_label')}
                field={
                  <textarea
                    id="awayStatement"
                    name="awayStatement"
                    rows={3}
                    onChange={formik.handleChange}
                    value={formik.values.awayStatement ?? ''}
                    disabled={!editEnabled}
                  />
                }
                error={formik.errors.awayStatement}
              />
            </>
          )}
        </div>
        <div className="details-right-panel">
          {useCase?.zones && <UseCaseDetailsMap useCase={useCase} />}
          {useCase?.id && <UseCaseImageField formik={formik} editEnabled={editEnabled} />}
        </div>
      </div>
      <div className="footer">
        {hasWritePermission && (
          <FormControls
            editEnabled={editEnabled}
            handleEdit={() => setEditEnabled(true)}
            handleSave={formik.submitForm}
            handleCancel={handleCancel}
            handleDelete={handleDelete}
            isDeletable={!!useCase?.id}
          />
        )}
      </div>
    </StyledUseCaseDetails>
  );
}

export default withApolloProvider(UseCaseDetails);
