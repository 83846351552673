import React from 'react';

function ImageCanvas({ image, lines, showLines, imageFilter, outsideRef, ...props }) {
  const canvasRef = React.useRef(null);
  const imageCanvasRef = outsideRef || React.useRef(null);
  const lineCanvasRef = React.useRef(null);
  const footerCanvasRef = React.useRef(null);

  const drawLine = (ctx, line) => {
    ctx.beginPath();
    ctx.moveTo(line.startX, line.startY);
    ctx.lineTo(line.endX, line.endY);
    ctx.lineWidth = 7;

    ctx.stroke();
  };

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const imgCanvas = imageCanvasRef.current;
    const lineCanvas = lineCanvasRef.current;
    const footerCanvas = footerCanvasRef.current;

    const ctx = canvas.getContext('2d');
    const imageCtx = imgCanvas.getContext('2d');
    const lineCtx = lineCanvas.getContext('2d');
    const footerCtx = footerCanvas.getContext('2d');

    const footer = image?.images?.find(img => img.kind === 'footer');

    const img = new Image();
    img.src = image.url;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      imgCanvas.width = img.width;
      imgCanvas.height = img.height;

      lineCanvas.width = img.width;
      lineCanvas.height = img.height;

      // image is drawn in the image canvas
      imageCtx.filter = imageFilter || '';
      imageCtx.drawImage(img, 0, 0, img.width, img.height);

      // lines are drawn in the line canvas
      if (lines && showLines) {
        if (lines.entry) {
          lineCtx.strokeStyle = 'yellow';
          drawLine(lineCtx, {
            startX: lines.entry.start_x,
            startY: lines.entry.start_y,
            endX: lines.entry.end_x,
            endY: lines.entry.end_y
          });
        }

        if (lines.exit) {
          lineCtx.strokeStyle = 'blue';
          drawLine(lineCtx, {
            startX: lines.exit.start_x,
            startY: lines.exit.start_y,
            endX: lines.exit.end_x,
            endY: lines.exit.end_y
          });
        }

        if (lines.violation) {
          lineCtx.strokeStyle = 'red';
          drawLine(lineCtx, {
            startX: lines.violation.start_x,
            startY: lines.violation.start_y,
            endX: lines.violation.end_x,
            endY: lines.violation.end_y
          });
        }
      }

      // footer is drawn in the footer canvas
      if (footer) {
        const footerImg = new Image();
        footerImg.src = footer.url;
        footerImg.onload = () => {
          footerCanvas.width = footerImg.width;
          footerCanvas.height = footerImg.height;

          canvas.height = img.height + footerImg.height;

          footerCtx.drawImage(footerImg, 0, 0);

          if (imageCanvasRef.current) ctx.drawImage(imageCanvasRef.current, 0, 0);
          if (lineCanvasRef.current) ctx.drawImage(lineCanvasRef.current, 0, 0);
          if (footerCanvasRef.current) ctx.drawImage(footerCanvasRef.current, 0, img.height);
        };
      } else {
        if (imageCanvasRef.current) ctx.drawImage(imageCanvasRef.current, 0, 0);
        if (lineCanvasRef.current) ctx.drawImage(lineCanvasRef.current, 0, 0);
      }
    };
  }, [image, lines, showLines, imageFilter]);

  return (
    <>
      <canvas
        ref={canvasRef}
        {...props}
        style={{ margin: 'auto', cursor: 'pointer', maxWidth: '100%', maxHeight: '100%' }}
      />
      <div style={{ display: 'none' }}>
        <canvas ref={imageCanvasRef} />
        <canvas ref={lineCanvasRef} />
        <canvas ref={footerCanvasRef} />
      </div>
    </>
  );
}

export default ImageCanvas;
