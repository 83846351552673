import React from 'react';
import styled from 'styled-components';

const StyledSideBySideSelector = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .box-col {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    p {
      margin: auto auto 5px 10px;
    }
    p.selected {
      color: #00821d;
      font-weight: 600;
    }

    .object-box {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */

      display: flex;
      flex-direction: column;
      width: 300px;
      height: 200px;
      max-height: 250px;
      margin: 0px 20px 0px 0px;
      border: 1px solid #bebebe;
      background-color: #e5e5e5;
      border-radius: 5px;
      overflow: scroll;
      overflow-x: hidden;

      .object-box-row {
        display: flex;
        width: calc(100% - 4px);
        min-height: 30px;
        height: 30px;
        margin: 2px;
        border-bottom: 1px solid #bebebe;
        font-size: 14px;
      }
    }

    .footer-button {
      display: flex;
      margin-top: 5px;

      p {
        margin: auto 25px auto auto;
        color: #f36c21;
        cursor: pointer;
      }

      p:hover {
        color: #ff8e22;
      }
    }
  }

  .box-col-enabled {
    .object-box {
      background-color: #f7f8fb;

      .object-box-row {
        cursor: pointer;
      }
      .object-box-row:hover {
        color: #3382e9;
      }
    }
  }
`;

function SideBySideSelector({
  availableObjects,
  selectedObjects,
  selectOne = () => {},
  deselectOne = () => {},
  selectAll = () => {},
  deselectAll = () => {},
  disabled = false
}) {
  return (
    <StyledSideBySideSelector>
      <div className={`box-col`.concat(disabled ? '' : ' box-col-enabled')}>
        <p>{I18n.t('settings.field_available_label')}</p>
        <div className="object-box">
          {availableObjects.map((object, index) => (
            <div
              key={`avail-${object.id}-${index}`}
              className="object-box-row"
              onClick={disabled ? undefined : () => selectOne(object)}
            >
              <p>{object.name}</p>
            </div>
          ))}
        </div>
        {!disabled && (
          <div className="footer-button">
            <p onClick={() => selectAll(availableObjects)}>
              {I18n.t('settings.form_add_all_button')}
            </p>
          </div>
        )}
      </div>
      <div className={`box-col`.concat(disabled ? '' : ' box-col-enabled')}>
        <p className="selected">{I18n.t('settings.field_selected_label')}</p>
        <div className="object-box">
          {selectedObjects.map((object, index) => (
            <div
              key={`select-${object.id}-${index}`}
              className="object-box-row"
              onClick={disabled ? undefined : () => deselectOne(object)}
            >
              <p>{object.name}</p>
            </div>
          ))}
        </div>
        {!disabled && (
          <div className="footer-button">
            <p onClick={() => deselectAll(selectedObjects)}>
              {I18n.t('settings.form_remove_all_button')}
            </p>
          </div>
        )}
      </div>
    </StyledSideBySideSelector>
  );
}

export default SideBySideSelector;
