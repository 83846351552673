import React from 'react';
import { Badge } from 'brickyard-ui';

import '@/styles/shared/use_cases.scss';

const ExemptedBadge = () => {
  return (
    <>
      <Badge variant="by-secondary" className="clickable">
        {I18n.t('observations.observations.details.exempted_badge')}
      </Badge>
    </>
  );
};

export default ExemptedBadge;
