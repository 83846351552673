import { complement, both, isNil, isEmpty, compose, filter } from 'ramda';

const isNotNil = complement(isNil);
const isNotEmpty = complement(isEmpty);

export const getCleanFilters = compose(
  Object.fromEntries,
  filter(([_, value]) => both(isNotNil, isNotEmpty)(value)),
  Object.entries
);

export function replaceVehicleTypes(arrayToReplace) {
  return arrayToReplace.replaceAll(' ', '_').replaceAll('-', '');
}
