import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrashAlt } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import InputConfirmationModal from '../../../components/InputConfirmationModal';
import { selectUseCasesState, useUseCasesSlice } from '../slice';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getProcessorName, getProcessorType } from '@/utils/PipelineProcessors';

const UseCasePipelineProcessorCard = pipelineProcessor => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { actions } = useUseCasesSlice();
  const state = useSelector(selectUseCasesState);
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';

  const useCase = state.selectedUseCase || null;
  const processor = pipelineProcessor?.pipelineProcessor || null;

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handlePipelineProcessorDelete = () => {
    dispatch(
      actions.deletePipelineProcessor({
        useCaseId: useCase?.id,
        pipelineProcessorId: parseInt(processor?.id)
      })
    );
    const blockId = location.pathname.includes(`/block/`)
      ? location.pathname.split('/block/')[1]
      : null;
    if (processor?.id === blockId) {
      history.push(`${match.url.replace(/\block\/$/, '')}`);
    }
    dispatch(actions.storeProcessor(null));
    setShowDeleteConfirmation(false);
  };

  const onSelectProcessor = () => {
    {
      if (!showDeleteConfirmation) {
        if (location.pathname.includes(`/block/${processor?.id}`)) {
          history.push(`${match.url.replace(/\block\/$/, '')}`);
        } else {
          history.push(`${match.url.replace(/\block\/$/, '')}/block/${processor?.id}`);
        }
      }
    }
  };

  return (
    <>
      <div className={'processor-container'} onClick={() => onSelectProcessor()}>
        <div className="processor-type">
          {getProcessorName(processor?.className) + ' ' + getProcessorType(processor?.className)}
        </div>
        <div className="processor-class-name">{processor?.name || ''}</div>
        <div className="actions-cell">
          <i>
            <AiOutlineInfoCircle
              className="info-pipprocessor"
              size={20}
              title={I18n.t(
                // eslint-disable-next-line no-undef
                `settings.pipelines.processors.info_messages.${_.snakeCase(processor?.className)}`
              )}
            />
          </i>
          {hasWritePermission && (
            <i>
              <FaTrashAlt
                className={'fa-trash-alt'}
                size={16}
                onClick={e => {
                  e.stopPropagation();
                  setShowDeleteConfirmation(true);
                }}
              />
            </i>
          )}
        </div>

        {showDeleteConfirmation && (
          <InputConfirmationModal
            show={showDeleteConfirmation}
            onHide={() => handleCancelDelete()}
            message={I18n.t('settings.form_exemption_type_delete_confirm', { word: 'DELETE' })}
            confirmMessage={'DELETE'}
            cancelBtn={{
              label: I18n.t('settings.form_cancel_button'),
              onClick: () => handleCancelDelete()
            }}
            okBtn={{
              label: I18n.t('settings.form_ok_button'),
              onClick: handlePipelineProcessorDelete
            }}
          />
        )}
      </div>
    </>
  );
};

export default UseCasePipelineProcessorCard;
