import { useMutation, gql } from '@apollo/client';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, SortTh, FaExclamationTriangle, Badge } from 'brickyard-ui';
import classNames from 'classnames';

import TestBoundary from '@/utils/TestBoundary';
import { setCurrentObservation } from '../../actions/observationActions';

import 'styles/scenes/observations.scss';
import formatDate from '@/utils/formatDate';
import { LongDateFormat } from '@/utils/DateFormats';
import ResetUseCaseResult from './ResetUseCaseResult';

const getStatusLabel = action => {
  switch (`${action}`) {
    case 'delete':
      return I18n.t('observations.observations.details.actions.delete');
    case 'ticket':
      return I18n.t('observations.observations.details.to_ticket');
    case 'warning':
      return I18n.t('observations.observations.details.to_warning');
    case 'exemption':
      return I18n.t('use_case.result.list.exemption');
  }
  return I18n.t('observations.observations.details.unassigned');
};

const format = (field, key) => {
  if (key === 'observedAt') {
    return formatDate(field, LongDateFormat);
  }
  if (key === 'accuracy') {
    return `${parseInt(field, 10).toFixed(0)} %`;
  }
  return field;
};

export const RECORD_VIEW = gql`
  mutation RecordUseCaseResultView($id: ID!) {
    recordUseCaseResultView(id: $id) {
      id
    }
  }
`;

const UseCaseResultTable = ({ observations, onReset, sorting, onSort }) => {
  const dispatch = useDispatch();
  const currentObservationId = useSelector(state => state.observations.current);
  const filters = useSelector(state => state.observations.filters);

  const [recordView] = useMutation(RECORD_VIEW);
  const user = useSelector(state => state.user);
  const header = [
    { label: I18n.t('observations.observations.list.datetime'), key: 'observedAt', width: '10%' },
    {
      label: I18n.t('observations.observations.list.use_case'),
      key: 'useCase',
      width: '10%',
      disableSort: true
    },
    {
      label: I18n.t(`observations.observations.list.source`),
      key: 'camera',
      width: '16%'
    },
    {
      label: I18n.t('observations.observations.list.location'),
      key: 'location',
      width: '22%'
    },
    {
      label: I18n.t('observations.observations.list.offense_code'),
      key: 'offenseCode',
      width: '8%'
    },
    {
      label: I18n.t('observations.observations.list.license_plate'),
      key: 'vehicleLicensePlateNumber',
      width: '9%'
    },
    { label: I18n.t('observations.observations.list.country'), key: 'countryCode', width: '5%' },
    {
      label: I18n.t('observations.observations.list.accuracy_label'),
      key: 'accuracy',
      width: '5%'
    }
  ];

  useEffect(() => {
    const openedIdx = observations.findIndex(obs => obs.id === currentObservationId);
    if (openedIdx === -1) {
      dispatch(setCurrentObservation(''));
    } else {
      const next = observations
        .slice(openedIdx)
        .find(obs => (!filters.validatedFilter ? !obs.action && !obs.validatedBy : true));
      dispatch(setCurrentObservation(next ? next.id : ''));
    }
  }, [observations]);

  const validateAssigned = observation => {
    const validateObj = observation.validatedBy ?? null;
    if (!validateObj) return false;
    return !(parseInt(validateObj.id, 10) === user.id);
  };

  const isUseCaseResultResetable = (obs, filters) => {
    return (
      ((obs.action || obs.validatedBy) && !filters.validatedFilter) ||
      (obs.useCaseResultPermission == 'USE_CASE_RESULT_PERMISSION_WRITE' &&
        obs.needsFollowUp &&
        !obs.assignedEnforcerName)
    );
  };

  const statusValue = obs => {
    if (obs.camera == 'ByMobileCamera' && obs.assignedEnforcerName) {
      return `${I18n.t('suspect_list.status.assigned_to')} ${obs.assignedEnforcerName}`;
    } else if (obs.needsFollowUp) {
      if (obs.assignedEnforcerName) {
        return `${I18n.t('suspect_list.status.assigned_to')} ${obs.assignedEnforcerName}`;
      } else {
        return I18n.t('suspect_list.status.marked_for_follow_up');
      }
    } else {
      return validateAssigned(obs)
        ? getStatusLabel(obs.validatedType.toLowerCase())
        : getStatusLabel(obs.action);
    }
  };

  const getSort = key => {
    if (sorting.key === key) {
      return sorting.order === 'asc' ? 'desc' : 'asc';
    }
    return 'asc';
  };

  return (
    <Table size="sm" className="observation-table">
      <thead>
        <tr>
          <th width="2%" />
          <th width="9%" />
          {header.map(h =>
            h.disableSort ? (
              <th key={h.key}>{h.label}</th>
            ) : (
              <SortTh
                key={h.key}
                onSort={sort => onSort(h.key, sort)}
                sortState={sorting.key === h.key && sorting.order}
                width={h.width || 'auto'}
              >
                <span className="clickable" onClick={() => onSort(h.key, getSort(h.key))}>
                  {h.label}
                </span>
              </SortTh>
            )
          )}
          <th width="12%">Status</th>
          <th width="2%"></th>
        </tr>
      </thead>

      <tbody>
        {observations.map(obs => (
          <React.Fragment key={obs.id}>
            <tr
              className={classNames({
                [`obs-row-${obs.action}`]: obs.action,
                'obs-row-assigned': validateAssigned(obs),
                'obs-row-needs-follow-up': obs.needsFollowUp,
                'obs-row-selected': currentObservationId === obs.id
              })}
              onClick={() => {
                recordView({ variables: { id: obs.id } });
                dispatch(setCurrentObservation(obs.id));
              }}
            >
              <td>
                {validateAssigned(obs) && (
                  <i className="assigned-icon">
                    <FaExclamationTriangle size={16} />
                  </i>
                )}
              </td>
              <td>
                {obs.exempt && (
                  <Badge variant="by-secondary">
                    {I18n.t('observations.observations.details.exempted_badge')}
                  </Badge>
                )}
                {obs.delayed && (
                  <Badge variant="warning">
                    {I18n.t('observations.observations.details.processing_badge')}
                  </Badge>
                )}
              </td>

              {header.map(({ key }) => (
                <td key={key} className={key == 'useCase' ? 'use-case-img-row' : ''}>
                  {key === 'useCase' ? (
                    obs['useCase']['imageUrl'] != null ? (
                      <div className="use-case-img-container">
                        <img src={obs['useCase']['imageUrl']} className="use-case-img" />
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    format(obs[key], key)
                  )}
                </td>
              ))}
              <td className={`obs-status-${obs.action}`}>{statusValue(obs)}</td>
              <td>
                <TestBoundary>
                  {isUseCaseResultResetable(obs, filters) && (
                    <ResetUseCaseResult useCaseResultId={obs.id} onReset={onReset} />
                  )}
                </TestBoundary>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default UseCaseResultTable;
