import React, { useMemo } from 'react';
import { Row, Col } from 'brickyard-ui';

import ObservationForm from './ObservationForm';
import ExemptedBadge from './ExemptedBadge';
import PrpBadge from './PrpBadge';
import formatDate from '@/utils/formatDate';
import { LongDateFormat } from '@/utils/DateFormats';

const OffenseCard = ({ observation, mainObservationId, type }) => {
  const readOnlyLabels = useMemo(() => {
    const commonLabels = [
      {
        i18nTitle: observation.delayed ? (
          I18n.t('observations.observations.details.delayed_until')
        ) : (
          <>
            {I18n.t('observations.observations.details.rechecked_at')}{' '}
            <PrpBadge observationId={observation.id} areaId={observation.area.id} />
          </>
        ),
        attr: observation.delayed ? 'delayedRecheckScheduledTime' : 'recheckedAt',
        position: 0
      },
      {
        i18nTitle: I18n.t('observations.observations.details.rdw_admittance'),
        attr: 'rdwAdmittance',
        position: 2
      },
      {
        i18nTitle: I18n.t('observations.observations.details.emission_code'),
        attr: 'environmentalClass',
        position: 3
      }
    ];
    const c01Labels = [
      {
        i18nTitle: I18n.t('observations.observations.details.vehicle_heading'),
        attr: 'vehicleHeading',
        position: 1
      },
      {
        i18nTitle: I18n.t('observations.observations.details.speed'),
        attr: 'speed',
        position: 4
      }
    ];
    const scancarLabels = [
      {
        i18nTitle: I18n.t('observations.observations.details.parking_area_code'),
        attr: 'scancarParkingAreaCode',
        position: 5
      }
    ];

    let result = commonLabels;

    if (type === 'c01') {
      result = [...commonLabels, ...c01Labels].sort((l1, l2) => l1.position - l2.position);
    }

    if (type === 'scancar') {
      result = [...commonLabels, ...scancarLabels].sort((l1, l2) => l1.position - l2.position);
    }

    return result;
  }, [observation, type]);

  const vehicleHeading = {
    '-': I18n.t('observations.observations.details.vehicle_heading_toward'),
    '+': I18n.t('observations.observations.details.vehicle_heading_away')
  };

  const parsedObs = {
    ...observation,
    vehicleHeading:
      vehicleHeading[observation.vehicleHeading] ||
      I18n.t('observations.observations.details.vehicle_heading_not_given'),
    parkingAreaCode: observation.area.parkingAreaCode
  };

  const renderWithDateFormat = field => {
    if (field.attr === 'delayedRecheckScheduledTime' || field.attr === 'recheckedAt')
      return (
        formatDate(parsedObs[field.attr], LongDateFormat) ||
        I18n.t('observations.observations.details.no_offense_detail')
      );
    return parsedObs[field.attr] || I18n.t('observations.observations.details.no_offense_detail');
  };

  return (
    <div className="offense-card">
      <Row>
        <ObservationForm
          observation={observation}
          canEdit={observation.status === 'observed' && observation.id === mainObservationId}
          mainObservationId={mainObservationId}
          type={type}
        />
      </Row>
      <Row className="offense-read-only-fields">
        {readOnlyLabels.map(field => (
          <Col xs="8" key={field.attr}>
            <span>{field.i18nTitle}</span>
            <span>{renderWithDateFormat(field)}</span>
          </Col>
        ))}
        {observation.exempt && (
          <Col xs="4" className="offense-card-exemption-container">
            <ExemptedBadge observationId={observation.id} areaId={observation.area.id} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default OffenseCard;
