import React, { useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormFieldBlock from '../../../../components/FormFieldBlock';
import FormField from '../../../../components/FormField';

function FooterDecorator({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();
  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      language: selectedProcessor?.config?.language ?? 'nl'
    },
    enableReinitialize: true,
    onSubmit: async values => {
      await persistPipelineProcessor({
        variables: { id: values.id, name: values.name, config: { language: values.language } }
      });
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        I18n.t('settings.pipelines.processors.form.validations.name_required')
      )
    })
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  return (
    <div className={selectedProcessor?.className ?? ''}>
      <div className="content">
        <FormFieldBlock
          field={
            <>
              <div className="alert alert-success mb-1" role="alert">
                {I18n.t('settings.pipelines.processors.messages.on_error_exempt')}
              </div>
            </>
          }
        />
        <FormField
          label={I18n.t('settings.pipelines.processors.form.footer_decorator.language.name')}
          className="language"
          field={
            <select
              id="language"
              name="language"
              disabled={!editEnabled}
              onChange={formik.handleChange}
              value={formik.values.language}
            >
              <option value="en">
                {I18n.t('settings.pipelines.processors.form.footer_decorator.language.english')}
              </option>
              <option value="nl">
                {I18n.t('settings.pipelines.processors.form.footer_decorator.language.dutch')}
              </option>
            </select>
          }
          error={formik.errors.language}
        />
      </div>
    </div>
  );
}

export default FooterDecorator;
