import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../slice';
import DynamicComponent from './PipelineProcessorForms/DynamicComponent';

function UseCasePipelineBlock({ useCase }) {
  const { actions } = useUseCasesSlice();

  const state = useSelector(selectUseCasesState);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.id !== 'new') {
      dispatch(
        actions.fetchProcessor({
          useCaseId: useCase?.id,
          pipelineId: state.selectedPipeline.id,
          processorId: params?.id
        })
      );
    }
  }, [params?.id]);

  return (
    <div className="details-block-panel">
      {state.selectedProcessor && (
        <DynamicComponent
          key={state.selectedProcessor.id}
          className={state.selectedProcessor.className}
          useCase={useCase}
        />
      )}
    </div>
  );
}

export default UseCasePipelineBlock;
