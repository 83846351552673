import React, { useMemo } from 'react';
import { Row, Col } from 'brickyard-ui';

const TabbedPanelInfo = ({ useCaseResult, type }) => {
  const readOnlyLabels = useMemo(() => {
    return [
      {
        i18nTitle: I18n.t('use_case.result.details.direction'),
        attr: 'vehicleHeading',
        position: 0
      },
      {
        i18nTitle: I18n.t('use_case.result.details.speed_indication'),
        attr: 'speed',
        position: 1
      },
      {
        i18nTitle: I18n.t('use_case.result.details.accuracy'),
        attr: 'accuracy',
        position: 2
      },
      {
        i18nTitle: I18n.t('use_case.result.details.city'),
        attr: 'offenseLocationCity',
        position: 3
      },
      {
        i18nTitle: I18n.t('use_case.result.details.street'),
        attr: 'offenseLocationStreet',
        position: 4
      },
      {
        i18nTitle: I18n.t('use_case.result.details.municipality'),
        attr: 'cameraMunicipality',
        position: 5
      }
    ];
  }, [useCaseResult, type]);

  const vehicleHeading = {
    towards: I18n.t('observations.observations.details.vehicle_heading_toward'),
    away: I18n.t('observations.observations.details.vehicle_heading_away')
  };

  const parsedObs = {
    ...useCaseResult,
    vehicleHeading:
      vehicleHeading[useCaseResult.vehicleHeading] ||
      I18n.t('observations.observations.details.vehicle_heading_not_given'),
    accuracy: parseInt(useCaseResult.accuracy * 100, 10).toFixed(0)
  };

  const renderField = field => {
    return parsedObs[field.attr] || I18n.t('observations.observations.details.no_offense_detail');
  };

  return (
    <>
      {readOnlyLabels.map(field => (
        <Row key={field.attr}>
          <Col xs="4">{field.i18nTitle}</Col>
          <Col xs="8" className="font-weight-bold">
            {renderField(field)}
          </Col>
        </Row>
      ))}
    </>
  );
};

export default TabbedPanelInfo;
