import React from 'react';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function VehicleTypeField({
  formik,
  editEnabled,
  isSwitchable = false,
  formLabel = '',
  switchLabel = ''
}) {
  const selectVehicleType = type => {
    const newSelectedVehicleTypes = [...formik.values.selectedVehicleTypes, type];
    const newAvailableVehicleTypes = formik.values.availableVehicleTypes.filter(
      d => d.id !== type.id
    );

    formik.setValues({
      ...formik.values,
      selectedVehicleTypes: newSelectedVehicleTypes.sort(idSort),
      availableVehicleTypes: newAvailableVehicleTypes
    });
  };

  const deselectVehicleType = type => {
    const newSelectedVehicleTypes = formik.values.selectedVehicleTypes.filter(
      d => d.id !== type.id
    );
    const newAvailableVehicleTypes = [...formik.values.availableVehicleTypes, type];

    formik.setValues({
      ...formik.values,
      selectedVehicleTypes: newSelectedVehicleTypes,
      availableVehicleTypes: newAvailableVehicleTypes.sort(idSort)
    });
  };

  const selectAllVehicleTypes = () => {
    const newSelectedVehicleTypes = [
      ...formik.values.selectedVehicleTypes,
      ...formik.values.availableVehicleTypes
    ];

    formik.setValues({
      ...formik.values,
      selectedVehicleTypes: newSelectedVehicleTypes.sort(idSort),
      availableVehicleTypes: []
    });
  };

  const deselectAllVehicleTypes = () => {
    const newAvailableVehicleTypes = [
      ...formik.values.availableVehicleTypes,
      ...formik.values.selectedVehicleTypes
    ];

    formik.setValues({
      ...formik.values,
      selectedVehicleTypes: [],
      availableVehicleTypes: newAvailableVehicleTypes.sort(idSort)
    });
  };

  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledVehicleTypes"
              name="enabledVehicleTypes"
              isOn={formik.values.enabledVehicleTypes}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledVehicleTypes}
        />
      )}

      {((formik.values.enabledVehicleTypes && isSwitchable) || !isSwitchable) && (
        <FormFieldBlock
          label={formLabel}
          className="vehicleTypes"
          field={
            <SideBySideSelector
              availableObjects={formik?.values?.availableVehicleTypes}
              selectedObjects={formik?.values?.selectedVehicleTypes}
              selectOne={selectVehicleType}
              deselectOne={deselectVehicleType}
              selectAll={selectAllVehicleTypes}
              deselectAll={deselectAllVehicleTypes}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.selectedVehicleTypes}
        />
      )}
    </>
  );
}

export default VehicleTypeField;
