import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ExemptionDetailSchema from './validationSchema';
import FormField from '../../../../components/FormField';
import FormControls from '../../../../components/FormControls';

import withApolloProvider from '../../../../../../../../utils/withApolloProvider';
import { Alert, Loading } from 'brickyard-ui';
import { useFormik } from 'formik';
import { parseGraphQLErrors } from '../../../../../../../../utils/errors';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectExemptionDetailsState, useExemptionDetailsSlice } from './slice';
import { CREATE_EXEMPTION_DETAIL_MUTATION, UPDATE_EXEMPTION_DETAIL_MUTATION } from './mutations';

const StyledExemptionDetailsPanel = styled.div`
  .details-left-panel {
    width: 50%;
  }
`;

function ExemptionDetails({ user }) {
  const state = useSelector(selectExemptionDetailsState);
  const dispatch = useDispatch();
  const { actions } = useExemptionDetailsSlice();
  const [editEnabled, setEditEnabled] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchDetails());

    return () => {
      dispatch(actions.clearData());
    };
  }, [dispatch, actions]);

  const hasWritePermission = user.cop_settings_permission == 'copSettingsWrite';

  const formik = useFormik({
    initialValues: state.details?.id
      ? state.details
      : {
          id: null,
          notificationEmail: ''
        },
    enableReinitialize: true,
    onSubmit: values => {
      // eslint-disable-next-line no-undef
      onSave(_.pick(values, ['id', 'notificationEmail']));
    },
    validationSchema: ExemptionDetailSchema
  });

  const [createExemptionDetail] = useMutation(CREATE_EXEMPTION_DETAIL_MUTATION, {
    onError: errorCreate => {
      setSubmitError(
        formik.dirty && errorCreate.message ? parseGraphQLErrors(errorCreate.message) : false
      );
      dispatch(actions.setLoading(false));
    },
    onCompleted: () => {
      setSubmitError(false);
      setEditEnabled(false);
      dispatch(actions.setLoading(false));
    }
  });

  const [updateExemptionDetail] = useMutation(UPDATE_EXEMPTION_DETAIL_MUTATION, {
    onError: errorUpdate => {
      setSubmitError(
        formik.dirty && errorUpdate.message ? errorUpdate.message.toString().split(':') : ''
      );
      dispatch(actions.setLoading(false));
    },
    onCompleted: () => {
      setSubmitError(false);
      setEditEnabled(false);
      dispatch(actions.setLoading(false));
    }
  });

  const onSave = async values => {
    dispatch(actions.setLoading(true));
    if (state.details?.id) {
      await updateExemptionDetail({ variables: values });
    } else {
      await createExemptionDetail({ variables: values });
    }
  };

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    setEditEnabled(false);
    setSubmitError(false);
  };

  const handleEdit = () => {
    setEditEnabled(true);
    setSubmitError(false);
  };

  return (
    <StyledExemptionDetailsPanel>
      <div className="body">
        <div className="details-left-panel">
          {state.loading ? (
            <div className="loading-box">
              <Loading />
            </div>
          ) : (
            <>
              <FormField
                className="name"
                label={I18n.t('settings.exemption_details_form_notification_email')}
                field={
                  <input
                    id="notificationEmail"
                    name="notificationEmail"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.notificationEmail ?? ''}
                    disabled={!editEnabled}
                  />
                }
                error={formik.errors.notificationEmail}
              />
              {submitError &&
                submitError.map((error, index) => {
                  return (
                    <Alert variant="danger" key={index}>
                      {error}
                    </Alert>
                  );
                })}

              {hasWritePermission && (
                <FormControls
                  handleEdit={handleEdit}
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                  editEnabled={editEnabled}
                />
              )}
            </>
          )}
        </div>
      </div>
    </StyledExemptionDetailsPanel>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

export default withApolloProvider(connect(mapStateToProps)(ExemptionDetails));
