import { useMutation } from '@apollo/client';
import React from 'react';
import { Button, InfoIcon, FaTimes, Loading } from 'brickyard-ui';

import { RESET_OBSERVATIONS } from '@/components/mutations/observations';

const ResetObservation = ({ observationId, onReset, isButton }) => {
  const [resetObservation, { loading }] = useMutation(RESET_OBSERVATIONS, {
    onCompleted: () => {
      onReset(observationId);
    },
    variables: { ids: [observationId] }
  });
  const label = I18n.t('observations.observations.list.reset_action');

  return isButton ? (
    <Button onClick={resetObservation} variant="by-secondary">
      {label}
    </Button>
  ) : (
    <i className="reset-observation-icon" onClick={resetObservation}>
      <InfoIcon Icon={FaTimes} size="md" placement="top" variant="by-dark" text={label} />
      {loading && <Loading size="sm" variant="by-dark" />}
    </i>
  );
};

ResetObservation.defaultProps = {
  isButton: false
};

export default ResetObservation;
