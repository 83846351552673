import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-weight: 400;
  font-size: 12px;

  label,
  span,
  strong {
    width: 50%;
  }

  label {
    margin: 0;
  }

  span {
    font-weight: 300;
  }

  strong {
    font-weight: 600;
  }
`;

const InfoText = ({ label, text, highlighted, className = '' }) => {
  return (
    <Wrapper className={className}>
      <label>{label}</label>
      {highlighted ? <strong>{text}</strong> : <span>{text}</span>}
    </Wrapper>
  );
};

export default InfoText;
