import React from 'react';
import { Modal } from 'brickyard-ui';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
  ImageWithZoom,
  Dot
} from 'pure-react-carousel';

const ZoomCarouselModal = ({ images, selected, show, onHide }) => {
  return (
    <Modal show={show} size="full" className="zoom-modal">
      <Modal.Body>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={images.length}
          currentSlide={selected}
        >
          <Slider>
            {images.map((image, idx) => (
              <Slide index={idx} key={idx}>
                <a href={image} target="_blank" rel="noopener noreferrer">
                  <ImageWithZoom src={image} />
                </a>
              </Slide>
            ))}
          </Slider>
          <div className="zoom-modal-controls">
            <ButtonBack>&lsaquo;</ButtonBack>
            <div className="carousel__dots">
              {images.map((image, idx) => (
                <Dot slide={idx} key={idx}>
                  <Image src={image} />
                </Dot>
              ))}
            </div>
            <ButtonNext>&rsaquo;</ButtonNext>
          </div>
        </CarouselProvider>
        <div className="zoom-modal-close" onClick={onHide}>
          ✕
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ZoomCarouselModal;
