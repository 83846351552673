import React from 'react';
import { Button } from 'brickyard-ui';
import Cookies from 'js-cookie/src/js.cookie';
import formatDate from '@/utils/formatDate';
import { ShortDateFormatNoTime } from '@/utils/DateFormats';

const RepeatOffenderButton = ({
  type,
  times,
  lastDatetime,
  licensePlate,
  offenseCode,
  observationId
}) => {
  const labelByType = {
    ticket: I18n.t('observations.observations.details.repeat_ticket'),
    warning: I18n.t('observations.observations.details.repeat_warning')
  };

  const queryParams =
    Cookies.get('ui_tickets') === 'old'
      ? [
          `observation_id=${observationId}`,
          `q[is_warning_eq]=${type === 'warning'}`,
          `q[offense_code_cont]=${offenseCode}`,
          `q[vehicle_license_plate_number_insensitive_eq_insensitive]=${licensePlate}`
        ]
      : [
          `observationIdEq=${observationId}`,
          `isWarningEq=${type === 'warning'}`,
          `offenseCodeCont=${offenseCode}`,
          `vehicleLicensePlateNumberInsensitiveEqInsensitive=${licensePlate}`,
          `useCaseResultIdEq`,
          `temporaryFilters=true`
        ];

  return (
    <a
      className="repeat-offender-button"
      target="_blank"
      rel="noopener noreferrer"
      href={`/tickets?${queryParams.join('&')}`}
    >
      <Button variant={type === 'ticket' ? 'danger' : 'warning'} size="md-text-xs">
        {labelByType[type]} x {times}
        <br />
        <b>{formatDate(lastDatetime, ShortDateFormatNoTime)}</b>
      </Button>
    </a>
  );
};

RepeatOffenderButton.defaultProps = {
  type: 'ticket',
  times: 0,
  lastDatetime: null
};

export default RepeatOffenderButton;
