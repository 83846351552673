import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Form as BYForm, Badge, Button, Modal, AutoTable } from 'brickyard-ui';

import { GET_EXEMPTIONS_RULES } from '@/components/queries/observations';

const VVRRuleDialog = ({ observationId, areaId, onSelect }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const { loading, data, error } = useQuery(GET_EXEMPTIONS_RULES, {
    variables: { id: [observationId], areaId },
    fetchPolicy: 'network-only'
  });

  const rulesHeaders = [
    { key: 'name', label: I18n.t('observations.administration.exemption_rules.results.name') },
    {
      key: 'cameras',
      label: I18n.t('observations.administration.exemption_rules.results.cameras')
    },
    {
      key: 'vehicleTypes',
      label: I18n.t('observations.administration.exemption_rules.results.vehicle_types')
    },
    {
      key: 'vehicleDirections',
      label: I18n.t('observations.administration.exemption_rules.results.vehicle_direction')
    },
    {
      key: 'offenseCode',
      label: I18n.t('observations.administration.exemption_rules.results.offense_code')
    },
    {
      key: 'typesOfFuel',
      label: I18n.t('observations.administration.exemption_rules.results.types_of_fuel')
    },
    {
      key: 'emissionCodes',
      label: I18n.t('observations.administration.exemption_rules.results.emission_codes')
    },
    {
      key: 'europeanCategories',
      label: I18n.t('observations.administration.exemption_rules.results.european_categories')
    },
    {
      key: 'countries',
      label: I18n.t('observations.administration.exemption_rules.results.countries')
    },
    {
      key: 'vehicleBuildYear',
      label: I18n.t('observations.administration.exemption_rules.results.vehicle_build_year')
    },
    {
      key: 'vehicleMaxWeight',
      label: I18n.t('observations.administration.exemption_rules.results.vehicle_max_weight')
    },
    {
      key: 'vehicleMaxWidth',
      label: I18n.t('observations.administration.exemption_rules.results.vehicle_max_width')
    }
  ];

  if (loading) return null;
  if (error) return <Badge variant="danger">{error.toString()}</Badge>;
  if (!data.observations.nodes.length || !data.observations.nodes[0].exemptionRulesMatches.length)
    return null;

  const { exemptionRulesMatches } = data.observations.nodes[0];

  return (
    <>
      <Badge className="clickable" variant="by-primary" onClick={() => setShowModal(true)}>
        {exemptionRulesMatches.length}
      </Badge>

      <Modal centered size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('observations.observations.violation_matches_dialog.title')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AutoTable
            header={rulesHeaders}
            objArr={exemptionRulesMatches.map(rule => ({
              ...rule.appliedData,
              name: (
                <BYForm.Group>
                  <BYForm.Check
                    id={rule.appliedData.id}
                    type="radio"
                    custom
                    checked={!!selectedRule && rule.id === selectedRule.id}
                    onChange={() => {}}
                    label={rule.appliedData.name}
                  />
                </BYForm.Group>
              ),
              europeanCategories: (rule.appliedData.europeanCategories || []).join(', '),
              countries: (rule.appliedData.countries || []).join(', '),
              onClick: () => setSelectedRule(rule)
            }))}
            size="xs"
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="by-dark" onClick={() => setShowModal(false)}>
            {I18n.t('actions.cancel')}
          </Button>

          <Button
            variant="by-primary"
            onClick={() => {
              onSelect(selectedRule);
              setShowModal(false);
            }}
          >
            {I18n.t('actions.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VVRRuleDialog;
