import { ApolloProvider } from '@apollo/client';
import React from 'react';

import Client from './Client';

const withApolloProvider = Component => props => (
  <ApolloProvider client={Client}>
    <Component {...props} />
  </ApolloProvider>
);

export default withApolloProvider;
