import React, { useEffect } from 'react';
import { selectEventsState, useEventsSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory, useLocation, Route, Switch } from 'react-router-dom';
import { Button, Loading } from 'brickyard-ui';
import Table from '../../../Settings/components/Table';
import styled from 'styled-components';
import EventsForm from './components/EventsForm';

const EventsStyledPage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .left-panel {
    display: flex;
    flex-direction: column;
    width: calc(100% * 1 / 3);
    height: 100%;

    .left-panel-header {
      display: flex;
      max-height: 100px;
      flex-direction: row;

      h3 {
        margin: 15px;
        color: #777777;
        font-weight: 600;
      }

      button {
        margin: 15px;
        margin-left: auto;
      }
    }

    .left-panel-body {
      display: flex;
      margin: 30px;

      width: calc(100% - 60px);
      height: calc(100% - 20px);

      .loading-box {
        margin: auto;
      }
    }
  }

  .status-cell {
    display: flex;

    .status-label {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      display: inline-block;
      padding: 0px 4px;
      border: 1px solid #ffffff;
      border-radius: 2px;
    }

    .ok-label {
      background: rgba(0, 199, 44, 0.26);
      color: rgb(0, 199, 44);
    }

    .blue-label {
      background: rgba(0, 0, 255, 0.26);
      color: rgb(0, 0, 255);
    }

    .grey-label {
      background: rgba(128, 128, 128, 0.26);
      color: rgb(128, 128, 128);
    }
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    width: calc(100% * 2 / 3 - 60px);
  }
`;

const Events = () => {
  const { actions } = useEventsSlice();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.fetchEvents());
    dispatch(actions.fetchUseCaseCameraOptions());

    return () => {
      dispatch(actions.clearData());
    };
  }, []);

  const state = useSelector(selectEventsState);

  const onRowClick = id => {
    history.push({ pathname: `${match.url}/${id}`, search: location.search });
  };

  const onNewButtonClick = () => {
    history.push({ pathname: `${match.url}/new`, search: location.search });
  };

  const onFormClose = () => {
    history.push({ pathname: match.url, search: location.search });

    dispatch(actions.fetchEvents());
    dispatch(actions.storeEvent(null));
    dispatch(actions.setError(null));
  };

  const getStatusLabel = row => {
    let spanText = '';
    let spanKlass = 'status-label ';

    if (new Date(row.end_date) < new Date()) {
      spanText = I18n.t('settings.expired_status_label');
      spanKlass += 'blue-label';
    } else {
      spanText = row.active
        ? I18n.t('settings.active_status_label')
        : I18n.t('settings.inactive_status_label');
      spanKlass += row.active ? 'ok-label' : 'grey-label';
    }

    return <span className={spanKlass}>{spanText}</span>;
  };

  const columns = [
    {
      key: 'name',
      header: I18n.t('settings.table_name_header'),
      sortable: true
    },
    {
      key: 'active',
      header: I18n.t('settings.table_status_header'),
      sortable: true,
      cell: row => <div className="status-cell">{getStatusLabel(row)}</div>
    }
  ];

  return (
    <EventsStyledPage>
      <div className="left-panel">
        <div className="left-panel-header">
          <h3>{I18n.t('settings.events_label')}</h3>
          <Button
            variant="outline-by-secondary"
            onClick={onNewButtonClick}
            className="btn btn-primary"
          >
            {I18n.t('settings.new_event_label')}
          </Button>
        </div>

        <div className="left-panel-body">
          {state.loading && !state?.events?.length ? (
            <div className="spinner-container">
              <Loading animation="border" variant="primary" />
            </div>
          ) : (
            <Table
              columns={columns}
              items={state.events}
              onRowClick={onRowClick}
              selectedId={state.selectedEvent?.id}
            />
          )}
        </div>
      </div>

      <Switch>
        <Route path={`${match.path}/:id`}>
          <EventsForm event={state.selectedEvent} onClose={onFormClose} isLoading={state.loading} />
        </Route>
      </Switch>
    </EventsStyledPage>
  );
};

export default Events;
