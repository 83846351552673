import { useFormik } from 'formik';
import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import styled from 'styled-components';

const StyledConfirmationInput = styled.div`
  display: flex;

  input {
    margin: auto;
    width: 70%;
    height: 40px;
  }
`;

const InputConfirmationModal = ({
  size,
  show,
  onHide,
  cancelBtn,
  okBtn,
  title,
  message,
  confirmMessage = 'DELETE'
}) => {
  const formik = useFormik({
    initialValues: {
      inputValue: ''
    }
  });

  const validInput = () => {
    return formik.values.inputValue === confirmMessage;
  };

  return (
    <Modal show={show} onHide={onHide} centered size={size} className="by-message-popup">
      {!!title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>
        <div className={`message-icon-container message-popup-confirmation`}>
          <i>
            <FaQuestionCircle />
          </i>

          <p>{message}</p>
        </div>
        <StyledConfirmationInput>
          <input
            name="inputValue"
            type="text"
            value={formik.values.inputValue ?? ''}
            onChange={formik.handleChange}
          />
        </StyledConfirmationInput>
      </Modal.Body>

      <Modal.Footer>
        {!!cancelBtn && (
          <Button variant="by-dark" onClick={cancelBtn.onClick}>
            {cancelBtn.label}
          </Button>
        )}
        {okBtn && (
          <Button variant="by-primary" onClick={okBtn.onClick} disabled={!validInput()}>
            {okBtn.label}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default InputConfirmationModal;
