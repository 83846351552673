import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useUseCaseListsSlice } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { COP_PERM_WRITE } from '../../../../../../../utils/consts';
import { useFormik } from 'formik';
import InputConfirmationModal from '../../../components/InputConfirmationModal';
import { Alert, Loading } from 'brickyard-ui';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import OutsideClickHandler from '../../../../../../OutsideClickHandler';
import FormControls from '../../../components/FormControls';
import Close from '@/assets/img/close_icon.svg';
import FormField from '../../../components/FormField';
import UseCaseSelector from './UseCaseSelector';
import { useCaseListsSchema } from '../validationSchema';

const StyledUseCaseListForm = styled.div`
  display: flex;
  width: calc(100% * 2 / 3 - 60px);
  height: calc(100% - 60px);
  background-color: #ffffff;
  margin: 30px;
  border: 1px solid #e5e5e5;

  .loading-box {
    margin: auto;
  }

  .click-handler-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .form-header {
      display: flex;
      margin: 10px;
      height: 50px;

      h3 {
        display: flex;
        color: #4a4a49;
        font-weight: 600;
        margin: auto auto auto 10px;

        .static-title {
          font-weight: 300;
          margin-right: 7px;
        }
      }

      .close-button {
        margin: auto 10px auto auto;
        cursor: pointer;
      }
    }

    .form-body {
      display: flex;
      flex-direction: column;
      width: calc(100% - 40px);
      height: calc(100% - 260px);
      margin: 10px;
      margin-top: 0px;
      overflow-y: auto;

      .row {
        .label {
          width: 10%;
          margin: auto 40px auto 10px;
        }

        .field {
          input {
            width: 85%;
          }

          .box-col > p {
            margin-left: 0;
          }
        }
      }

      .box-col:first-child {
        margin-left: 10px;
      }
    }
  }
`;

const UseCaseListForm = ({ useCaseList, onClose, isLoading, backendValidationError }) => {
  const { actions } = useUseCaseListsSlice();
  const dispatch = useDispatch();
  const params = useParams();

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === COP_PERM_WRITE;

  const [editEnabled, setEditEnabled] = useState(false);
  const editEnabledRef = useRef();
  editEnabledRef.current = editEnabled;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [outsideClickEnabled, setOutsideClickEnabled] = useState(false);

  useEffect(() => {
    setOutsideClickEnabled(!showDeleteConfirmation);
  }, [showDeleteConfirmation]);

  const formik = useFormik({
    initialValues: useCaseList ?? {
      name: '',
      use_cases: []
    },
    enableReinitialize: true,
    onSubmit: values => {
      if (params.id === 'new') {
        dispatch(
          actions.createUseCaseList({
            use_case_list: values,
            onSuccess: () => {
              onClose();
            }
          })
        );
      } else {
        dispatch(
          actions.updateUseCaseList({
            id: useCaseList.id,
            use_case_list: values,
            onSuccess: () => {
              onClose();
            }
          })
        );
      }
    },
    validationSchema: useCaseListsSchema
  });

  useEffect(() => {
    dispatch(actions.fetchAvailableUseCases());
    if (params.id === 'new') {
      dispatch(actions.storeUseCaseList(null));
      setEditEnabled(true);
    } else {
      dispatch(actions.fetchUseCaseList(parseInt(params.id)));
      setEditEnabled(false);
    }
  }, [params.id]);

  const handleEdit = () => {
    setEditEnabled(true);
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  const handleCancel = () => {
    formik.resetForm();
    params.id === 'new' ? onClose() : setEditEnabled(false);
  };

  const handleDelete = () => {
    dispatch(actions.deleteUseCaseList(parseInt(params.id)));
    onClose();
  };

  const handleOutsideClick = event => {
    if (editEnabledRef.current) {
      setEditEnabled(false);
      if (confirm(I18n.t('settings.form_close_confirm'))) {
        onClose();
      } else {
        setEditEnabled(true);
        event.stopPropagation();
      }
    } else {
      onClose();
    }
  };

  const availableUseCases = useSelector(state => state.useCaseLists.availableUseCases).filter(
    uc => !formik.values.use_cases.some(uc2 => parseInt(uc2.id) === parseInt(uc.id))
  );

  return (
    <>
      <StyledUseCaseListForm>
        {isLoading ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <OutsideClickHandler
            className="click-handler-wrapper"
            onOutsideClick={handleOutsideClick}
            disabled={!outsideClickEnabled}
          >
            <div className="form-header">
              <div className="close-button" onClick={onClose}>
                <img src={Close} alt="close" />
              </div>
            </div>

            <div className="form-body">
              <FormField
                label={I18n.t('settings.field_name_label')}
                field={
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name ?? ''}
                    disabled={!editEnabled}
                  />
                }
                error={formik.touched.name && formik.errors.name}
              />

              <UseCaseSelector
                availableUseCases={availableUseCases}
                selectedUseCases={formik.values.use_cases}
                selectOne={useCase =>
                  formik.setFieldValue('use_cases', [...formik.values.use_cases, useCase])
                }
                deselectOne={useCase =>
                  formik.setFieldValue(
                    'use_cases',
                    formik.values.use_cases.filter(uc => uc.id !== useCase.id)
                  )
                }
                editEnabled={editEnabled}
                error={formik.errors.use_cases}
              />
            </div>

            {hasWritePermission && (
              <FormControls
                editEnabled={editEnabled}
                handleEdit={handleEdit}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleDelete={() => setShowDeleteConfirmation(true)}
                isDeletable={params.id !== 'new'}
              />
            )}
          </OutsideClickHandler>
        )}
      </StyledUseCaseListForm>

      {showDeleteConfirmation && (
        <InputConfirmationModal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          message={I18n.t('settings.form_suspect_list_delete_confirm', { word: 'DELETE' })}
          confirmMessage={'DELETE'}
          cancelBtn={{
            label: I18n.t('settings.form_cancel_button'),
            onClick: () => setShowDeleteConfirmation(false)
          }}
          okBtn={{
            label: I18n.t('settings.form_ok_button'),
            onClick: handleDelete
          }}
        />
      )}

      {backendValidationError && (
        <Alert
          variant="danger"
          style={{
            position: 'fixed',
            left: '50%',
            transform: 'translate(-50%, 0)',
            width: '80%',
            marginTop: '20px',
            textAlign: 'center'
          }}
        >
          {backendValidationError}
        </Alert>
      )}
    </>
  );
};

export default UseCaseListForm;
