import React, { useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import FormField from '../../../../components/FormField';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import styled from 'styled-components';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import FormFieldBlock from '../../../../components/FormFieldBlock';

const ColoredBoxLabel = styled.div`
  height: 30px;
  width: 550px;
  display: flex;
  border: 2px solid #d4666b;
  background-color: #f2cfd0;
  margin-bottom: 10px;

  p {
    margin: auto;
  }
`;

function P1ExternalBlacklistChecker({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      url: selectedProcessor?.config?.url,
      municipalityId: selectedProcessor?.config?.municipality_id,
      name: selectedProcessor?.name
    },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, ['id', 'url', 'name', 'municipalityId'])
      );
    },
    validationSchema: Yup.object().shape({
      url: Yup.string()
        .required(
          I18n.t('settings.pipelines.processors.form.p1_external_checker.validations.url_required')
        )
        .min(
          8,
          I18n.t('settings.pipelines.processors.form.p1_external_checker.validations.url_length')
        ),
      municipalityId: Yup.number().required(
        I18n.t(
          'settings.pipelines.processors.form.p1_external_checker.validations.municipality_id_required'
        )
      ),
      name: Yup.string().required(
        I18n.t('settings.pipelines.processors.form.validations.name_required')
      )
    })
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    const params = {
      id: values.id,
      name: values.name,
      config: {
        url: values.url,
        municipality_id: values.municipalityId
      }
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <>
      <FormFieldBlock
        field={
          <>
            <div className="alert alert-success mb-1" role="alert">
              {I18n.t('settings.pipelines.processors.messages.on_error_exempt')}
            </div>
          </>
        }
      />

      <ColoredBoxLabel className="colored-box-label">
        <p>{I18n.t('settings.pipelines.processors.form.p1_external_checker.suspect_if_label')}</p>
      </ColoredBoxLabel>

      <FormField
        label={I18n.t('settings.field_url_label')}
        field={
          <input
            id="url"
            name="url"
            type="text"
            value={formik.values.url ?? ''}
            disabled={!editEnabled}
            onChange={formik.handleChange}
          />
        }
        error={formik.errors.url}
      />

      <FormField
        label={I18n.t('settings.field_municipality_id_label')}
        field={
          <input
            id="municipalityId"
            name="municipalityId"
            type="number"
            value={formik.values.municipalityId ?? ''}
            disabled={!editEnabled}
            onChange={formik.handleChange}
          />
        }
        error={formik.errors.municipalityId}
      />
    </>
  );
}

export default P1ExternalBlacklistChecker;
