import React from 'react';
import { compose, omit } from 'ramda';
import { Badge } from 'brickyard-ui';

import { getCleanFilters } from './utils';

const FilterTags = ({ filters, removeTag, getLabel, i18nAllSearchFields, isHistorySearch }) => {
  if (!filters) {
    return null;
  }

  const cleanFilterEntries = compose(
    Object.entries,
    omit(['s', 'temporaryFilters']),
    getCleanFilters
  )(filters);

  return (
    <section className="current-filters">
      {cleanFilterEntries.map(([key, value]) => (
        <span className="mr-1" key={key}>
          {isHistorySearch ? (
            <Badge variant="by-secondary" className="history-tag">
              {`${i18nAllSearchFields[key]}: ${getLabel(key, value)}`}
            </Badge>
          ) : (
            <Badge variant="by-secondary" onClose={() => removeTag(key)} dismissable>
              {`${i18nAllSearchFields[key]}: ${getLabel(key, value)}`}
            </Badge>
          )}
        </span>
      ))}
    </section>
  );
};

export default FilterTags;
