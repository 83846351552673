import React from 'react';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function ObjectTypeField({ formik, editEnabled, formLabel = '' }) {
  const selectObjectType = object => {
    const newSelectedObjectTypes = [...formik.values.selectedObjectTypes, object];
    const newAvailableObjectTypes = formik.values.availableObjectTypes.filter(
      f => f.id !== object.id
    );

    formik.setValues({
      ...formik.values,
      selectedObjectTypes: newSelectedObjectTypes.sort(idSort),
      availableObjectTypes: newAvailableObjectTypes
    });
  };

  const deselectObjectType = object => {
    const newSelectedObjectTypes = formik.values.selectedObjectTypes.filter(
      c => c.id !== object.id
    );
    const newAvailableObjectTypes = [...formik.values.availableObjectTypes, object];

    formik.setValues({
      ...formik.values,
      selectedObjectTypes: newSelectedObjectTypes,
      availableObjectTypes: newAvailableObjectTypes.sort(idSort)
    });
  };

  const selectAllObjectTypes = () => {
    const newSelectedObjectTypes = [
      ...formik.values.selectedObjectTypes,
      ...formik.values.availableObjectTypes
    ];

    formik.setValues({
      ...formik.values,
      selectedObjectTypes: newSelectedObjectTypes.sort(idSort),
      availableObjectTypes: []
    });
  };

  const deselectAllObjectTypes = () => {
    const newAvailableObjectTypes = [
      ...formik.values.availableObjectTypes,
      ...formik.values.selectedObjectTypes
    ];

    formik.setValues({
      ...formik.values,
      selectedObjectTypes: [],
      availableObjectTypes: newAvailableObjectTypes.sort(idSort)
    });
  };

  return (
    <>
      <FormFieldBlock
        label={formLabel}
        className="objectTypes"
        field={
          <SideBySideSelector
            availableObjects={formik?.values?.availableObjectTypes}
            selectedObjects={formik?.values?.selectedObjectTypes}
            selectOne={selectObjectType}
            deselectOne={deselectObjectType}
            selectAll={selectAllObjectTypes}
            deselectAll={deselectAllObjectTypes}
            disabled={!editEnabled}
          />
        }
        error={formik.errors.selectedObjectTypes}
      />
    </>
  );
}

export default ObjectTypeField;
