import React from 'react';
import { Modal, Container, Loading } from 'brickyard-ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectExemptionTypesState, useExemptionTypesSlice } from './slice';
import { useFormik } from 'formik';
import exemptionTypeSchema from './validationSchema';
import FormField from '../../../../components/FormField';
import LabelToggle from '../../../../../../Ticket/Cards/components/LabelToggle';
import FormControls from '../../../../components/FormControls';

const ExemptionTypeModal = ({ showModal, setShowModal }) => {
  const { actions } = useExemptionTypesSlice();
  const state = useSelector(selectExemptionTypesState);
  const dispatch = useDispatch();

  const onSave = values => {
    if (state.selectedExemptionType?.id) {
      dispatch(actions.updateExemptionType(values));
    } else {
      dispatch(actions.createExemptionType(values));
    }

    formik.resetForm();
    onClose();
  };

  const onClose = () => {
    setShowModal(false);
    dispatch(actions.storeExemptionType(null));
  };

  const formik = useFormik({
    initialValues: state.selectedExemptionType?.id
      ? state.selectedExemptionType
      : {
          name: '',
          global: false
        },
    enableReinitialize: true,
    onSubmit: values => {
      // eslint-disable-next-line no-undef
      onSave(_.pick(values, ['id', 'name', 'global']));
    },
    validationSchema: exemptionTypeSchema
  });

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => onClose()}
        className="exemption-type-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {state.selectedExemptionType?.id
              ? I18n.t('settings.edit_exemption_type_modal_title')
              : I18n.t('settings.add_exemption_type_modal_title')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {state.loading ? (
            <div className="loading-box">
              <Loading />
            </div>
          ) : (
            <Container>
              <FormField
                className="name"
                label={
                  state.selectedExemptionType?.id
                    ? I18n.t('settings.modal_field_edit_name_label')
                    : I18n.t('settings.modal_field_add_name_label')
                }
                field={
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name ?? ''}
                  />
                }
                error={formik.errors.name}
              />

              <FormField
                className="global"
                label={I18n.t('settings.field_global_label')}
                field={
                  <LabelToggle
                    id="global"
                    name="global"
                    isOn={formik.values.global}
                    onChange={formik.handleChange}
                  />
                }
                error={formik.errors.global}
              />

              <FormControls
                editEnabled={true}
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            </Container>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExemptionTypeModal;
