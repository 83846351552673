import { useMutation, gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useUseCasesSlice } from '../slice';
import { parseGraphQLErrors } from '../../../../../../../utils/errors';
import { useFormik } from 'formik';
import { shapeFileSchema } from '../validationSchema';
import FormField from '../../../components/FormField';
import { Container, Alert, Button } from 'brickyard-ui';
import { useDispatch, useSelector } from 'react-redux';
import withApolloProvider from '../../../../../../../utils/withApolloProvider';
import FormControls from '../../../components/FormControls';
import InputConfirmationModal from '../../../components/InputConfirmationModal';

const CREATE_SHAPE_FILE = gql`
  mutation CreateShapeFile($useCaseId: ID!, $versionName: String!, $file: Upload!) {
    shapeFileCreate(useCaseId: $useCaseId, versionName: $versionName, file: $file) {
      id
      versionName
      status
    }
  }
`;

const DELETE_SHAPEFILE = gql`
  mutation DeleteShapefile($id: ID!) {
    shapeFileDelete(id: $id) {
      id
      versionName
      status
    }
  }
`;

const statusBadgeMap = {
  pending: 'secondary',
  finished: 'success',
  error: 'danger',
  processing: 'info'
};

function UseCaseShapeFiles({ useCase }) {
  const { actions } = useUseCasesSlice();
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const [editEnabled, setEditEnabled] = useState(!useCase?.id);
  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [outsideClickEnabled, setOutsideClickEnabled] = useState(false);
  const [selectedShapeFile, setSelectedShapeFile] = useState(null);

  useEffect(() => {
    setOutsideClickEnabled(!showDeleteConfirmation);
  }, [showDeleteConfirmation]);

  const [deleteShapefile] = useMutation(DELETE_SHAPEFILE, {
    onError: error => {
      setErrors(error.message ? parseGraphQLErrors(error.message) : []);
    },
    onCompleted: () => {
      dispatch(actions.fetchUseCase(useCase.id));
    }
  });
  const [createShapefile] = useMutation(CREATE_SHAPE_FILE, {
    onError: errorCreate => {
      setErrors(
        formik.dirty && errorCreate.message ? parseGraphQLErrors(errorCreate.message) : false
      );
    },
    onCompleted: () => {
      setErrors([]);
      dispatch(actions.fetchUseCase(useCase.id));
    }
  });

  const handleCancel = () => {
    setEditEnabled(false);
    setErrors([]);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      versionName: '',
      file: null
    },
    enableReinitialize: true,
    onSubmit: values => {
      handleCreate(
        // eslint-disable-next-line no-undef
        _.pick(values, ['versionName', 'file'])
      );
    },
    validationSchema: shapeFileSchema
  });

  const showDelete = id => {
    setSelectedShapeFile(id);
    setShowDeleteConfirmation(true);
  };

  const handleDelete = id => {
    deleteShapefile({ variables: { id: selectedShapeFile } }).then(r => {
      setShowDeleteConfirmation(false);
      setSelectedShapeFile(null);
    });
  };

  const handleCreate = async values => {
    const params = { ...values, useCaseId: parseInt(useCase.id) };
    await createShapefile({
      variables: params
    });
  };

  return (
    <>
      <div className="container-fluid cop-shape-files-container">
        {(!useCase.shapeFiles || useCase.shapeFiles.length === 0) && (
          <Alert variant="danger">
            <div>{I18n.t('settings.shape_files.no_shape_file_warning')}</div>
          </Alert>
        )}

        {errors && errors.length > 0 && (
          <Alert variant="danger">
            {errors.map((error, index) => {
              return <div key={index}>{error}</div>;
            })}
          </Alert>
        )}

        <div className="row">
          <div className="col-12 col-xl-6">
            <FormField
              className="name"
              label={I18n.t('settings.shape_files.version_name')}
              field={
                <input
                  id="versionName"
                  name="versionName"
                  type="text"
                  disabled={!editEnabled}
                  onChange={formik.handleChange}
                  value={formik.values.versionName ?? ''}
                />
              }
              error={formik.errors.versionName}
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-auto">
            <FormField
              field={
                <input
                  type="file"
                  name="file"
                  id="file"
                  placeholder={I18n.t('settings.shape_files.no_file_selected')}
                  disabled={!editEnabled}
                  onChange={event => {
                    formik.setFieldValue('file', event.currentTarget.files[0]);
                  }}
                />
              }
              error={formik.errors.file}
            />
          </div>

          <div className="col-auto">
            <Button onClick={() => formik.submitForm()} disabled={!editEnabled}>
              {I18n.t('settings.shape_files.upload_shape_file')}
            </Button>
          </div>
        </div>
        <ul className="list-group">
          {useCase.shapeFiles.map(shapefile => (
            <li className="list-group-item" key={shapefile.id}>
              <div className="row align-items-center">
                <div className="col-lg-4">{shapefile.versionName}</div>
                <div className="col-lg-4 text-center">
                  <span className={'badge badge-pill badge-' + statusBadgeMap[shapefile.status]}>
                    {shapefile.status}
                  </span>
                </div>
                <div className="col-lg-4 text-center">
                  {useCase.copShapeFileId !== shapefile.id && (
                    <button
                      className="delete-button btn btn-light-danger"
                      disabled={!editEnabled}
                      onClick={() => showDelete(shapefile.id)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
              {shapefile.processLog && (
                <Alert
                  variant={shapefile.status === 'error' ? 'danger' : 'success'}
                  className="my-2"
                >
                  {shapefile.processLog.map((log, index) => {
                    return <div key={index}>{log}</div>;
                  })}
                </Alert>
              )}
            </li>
          ))}
        </ul>
        <div className="footer">
          {hasWritePermission && (
            <FormControls
              editEnabled={editEnabled}
              handleEdit={() => setEditEnabled(true)}
              handleCancel={handleCancel}
              isDeletable={false}
            />
          )}
        </div>
      </div>

      {showDeleteConfirmation && (
        <InputConfirmationModal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          message={I18n.t('settings.shape_files.delete_confirm', { word: 'DELETE' })}
          confirmMessage={'DELETE'}
          cancelBtn={{
            label: I18n.t('settings.form_cancel_button'),
            onClick: () => setShowDeleteConfirmation(false)
          }}
          okBtn={{
            label: I18n.t('settings.form_ok_button'),
            onClick: handleDelete
          }}
        />
      )}
    </>
  );
}

export default withApolloProvider(UseCaseShapeFiles);
