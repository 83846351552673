import { string } from 'yup';
import { either, equals, isNil } from 'ramda';

import Api from '@/utils/Api';
import { requiredMsg, invalidMsg } from './CommonTicketSchema';

const isRequired = equals('required');

const shouldRequire = (type, vehicleInformationValidation) => {
  switch (type) {
    case 'fiscal':
      return either(isRequired, isNil)(vehicleInformationValidation);
    case 'juridical':
      return isRequired(vehicleInformationValidation);
    case 'taxi':
      return true;
    case 'recording': {
      // template action.vehicle_fields affects this
      return true;
    }
    default:
      return false;
  }
};

const customIsRequired = string().when(['type', 'vehicleInformationValidation'], {
  is: shouldRequire,
  then: string()
    .ensure()
    .required(requiredMsg),
  otherwise: string().ensure()
});

const licensePlateValidator = async function(value) {
  const countryValue = this.parent.vehicleCountry;

  if (!value || !countryValue) {
    return true;
  }

  const { data: isValid } = await Api['get']('/validations/license_plate', {
    params: { plate: value, country: countryValue }
  });
  return isValid;
};

const vehicleValidations = {
  vehicleCountry: customIsRequired,
  vehicleLicensePlateNumber: customIsRequired.test(
    'is-valid-license-plate',
    invalidMsg,
    licensePlateValidator
  ),
  vehicleType: customIsRequired,
  vehicleMake: customIsRequired,
  vehicleColor: customIsRequired
};

export default vehicleValidations;
