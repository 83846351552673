import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Tab, Row, Col } from 'brickyard-ui';
import OffenseCard from './OffenseCard';
import TabbedPanel from './TabbedPanel';
import UseCaseResultImages from './UseCaseResultImages';

import { UPDATE_USE_CASE_RESULT } from '../queries/SharedQueries';
import { useSelector } from 'react-redux';

const UseCaseResultTab = ({
  useCaseResult,
  useCaseName,
  mainObservation,
  canUseCaseResultBeEdited,
  type
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const filters = useSelector(state => state.observations.filters);
  const [updateUseCaseResult, { loading, error }] = useMutation(UPDATE_USE_CASE_RESULT);

  useEffect(() => {
    formik.setValues({ ...getFormValues(useCaseResult), updateAll: formik.values.updateAll });
  }, [useCaseResult]);

  const getFormValues = () => {
    const fieldArr = [
      'id',
      'vehicleLicensePlateNumber',
      'countryCode',
      'vehicleType',
      'offenseCode',
      'offenseLocationStreet',
      'vehicleMake',
      'vehicleColor',
      'duplicateCode'
    ];

    return Object.keys(useCaseResult).reduce((formValues, key) => {
      if (fieldArr.includes(key)) {
        return { ...formValues, [key]: useCaseResult[key] || '' };
      }
      return formValues;
    }, {});
  };

  const formik = useFormik({
    initialValues: {
      ...getFormValues(useCaseResult),
      updateAll: true
    },
    enableReinitialize: true,
    onSubmit: async values => {
      await updateUseCaseResult({
        variables: {
          ...values,
          duplicateCode: values.duplicateCode || null
        }
      });
      setIsEditable(false);
    },
    validationSchema: Yup.object().shape({
      vehicleLicensePlateNumber: Yup.string().required(),
      countryCode: Yup.string(),
      vehicleType: Yup.string(),
      vehicleMake: Yup.string(),
      vehicleColor: Yup.string(),
      offenseCode: Yup.string(),
      offenseLocationStreet: Yup.string(),
      duplicateCode: Yup.number()
        .min(0)
        .max(99)
        .nullable()
    })
  });

  return (
    <Tab.Pane key={useCaseResult.id} eventKey={useCaseResult.id} className="full-height">
      <Row noGutters className="full-height observation-form-container">
        <Col className="full-height image-section">
          {!!useCaseResult.files && (
            <UseCaseResultImages
              useCaseResult={useCaseResult}
              canEdit={
                canUseCaseResultBeEdited &&
                useCaseResult.id === mainObservation.id &&
                !filters.validatedFilter
              }
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              formik={formik}
              loading={loading}
            />
          )}
        </Col>

        <Col className="right-container details-section mr-0">
          <OffenseCard
            useCaseResult={useCaseResult}
            mainUseCaseResultId={mainObservation.id}
            key="offenseCard"
            isEditable={isEditable}
            formik={formik}
            loading={loading}
            error={error}
          />
          <TabbedPanel
            useCaseResult={useCaseResult}
            useCaseName={useCaseName}
            type={type}
            key="tabbedPanel"
          />
        </Col>
      </Row>
    </Tab.Pane>
  );
};

export default UseCaseResultTab;
