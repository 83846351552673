import React from 'react';
import Table from '../../../components/Table';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { Button, Loading } from 'brickyard-ui';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { selectLocationsState, useLocationsSlice } from '../locationsSlice';
import LocationForm from './LocationForm';

const FixedCamerasLocations = () => {
  const { actions } = useLocationsSlice();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.fetchLocations());

    return () => {
      dispatch(actions.clearData());
    };
  }, [dispatch]);

  const state = useSelector(selectLocationsState);

  const onRowClick = id => {
    history.push({ pathname: `${match.url}/${id}`, search: location.search });
  };

  const onNewButtonClick = () => {
    history.push({ pathname: `${match.url}/new`, search: location.search });
  };

  const onFormClose = () => {
    history.push({ pathname: match.url, search: location.search });

    dispatch(actions.fetchLocations());
    dispatch(actions.storeLocation(null));
    dispatch(actions.setBackendValidationError(null));
  };

  const columns = [
    {
      key: 'name',
      header: I18n.t('settings.table_name_header'),
      sortable: true
    }
  ];

  return (
    <>
      <div className="right-panel">
        <div className="right-panel-header">
          <h5>{I18n.t('settings.fixed_cameras_location_label')}</h5>
          {state.permission === 'copSettingsWrite' && (
            <Button
              variant="outline-by-secondary"
              onClick={onNewButtonClick}
              className="new-type-btn"
            >
              {I18n.t('settings.new_fixed_camera_location_button')}
            </Button>
          )}
        </div>

        <div className="right-panel-body">
          {state.loading && !state.locations?.length ? (
            <div className="loading-box">
              <Loading />
            </div>
          ) : (
            <Table
              columns={columns}
              items={state.locations}
              onRowClick={onRowClick}
              selectedId={state.selectedLocation?.id}
            />
          )}
        </div>
      </div>

      <Switch>
        <Route path={`${match.path}/:id`}>
          <LocationForm
            location={state.selectedLocation}
            onClose={onFormClose}
            isLoading={state.loading}
            backendValidationError={state.backendValidationError}
            permission={state.permission}
          />
        </Route>
      </Switch>
    </>
  );
};

export default FixedCamerasLocations;
