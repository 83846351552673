import React from 'react';
import styled from 'styled-components';
import { Button } from 'brickyard-ui';

const StyledFormControls = styled.div`
  display: flex;
  width: calc(100% - 80px);
  height: 50px;
  margin: 40px;
  margin-top: 0px;

  .button-group {
    display: flex;
    margin: auto auto auto 10px;
    width: 100%;

    button {
      width: 100px;
      margin-right: 10px;
    }

    .delete-button {
      margin: auto 10px auto auto;
    }
  }
`;

function FormControls({
  editEnabled,
  saveEnabled = true,
  handleEdit,
  handleSave,
  handleCancel,
  handleDelete,
  isDeletable
}) {
  return (
    <StyledFormControls className="controls">
      {editEnabled ? (
        <div className="button-group">
          <Button onClick={handleSave} disabled={!saveEnabled}>
            {I18n.t('settings.form_save_button')}
          </Button>
          <Button variant="outline-by-dark" onClick={handleCancel}>
            {I18n.t('settings.form_cancel_button')}
          </Button>

          {isDeletable && (
            <Button variant="light-danger" className="delete-button" onClick={handleDelete}>
              {I18n.t('settings.form_delete_button')}
            </Button>
          )}
        </div>
      ) : (
        <div className="button-group">
          <Button onClick={handleEdit}>{I18n.t('settings.form_edit_button')}</Button>
        </div>
      )}
    </StyledFormControls>
  );
}

export default FormControls;
