import React from 'react';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function OffenseTypeField({
  formik,
  editEnabled,
  isSwitchable = false,
  formLabel = '',
  switchLabel = ''
}) {
  const selectOffenseType = oc => {
    const newSelectedOffenseTypes = [...formik.values.selectedOffenseTypes, oc];
    const newAvailableOffenseTypes = formik.values.availableOffenseTypes.filter(
      c => c.id !== oc.id
    );

    formik.setValues({
      ...formik.values,
      selectedOffenseTypes: newSelectedOffenseTypes.sort(idSort),
      availableOffenseTypes: newAvailableOffenseTypes
    });
  };

  const deselectOffenseType = oc => {
    const newSelectedOffenseTypes = formik.values.selectedOffenseTypes.filter(c => c.id !== oc.id);
    const newAvailableOffenseTypes = [...formik.values.availableOffenseTypes, oc];

    formik.setValues({
      ...formik.values,
      selectedOffenseTypes: newSelectedOffenseTypes,
      availableOffenseTypes: newAvailableOffenseTypes.sort(idSort)
    });
  };

  const selectAllOffenseTypes = () => {
    const newSelectedOffenseTypes = [
      ...formik.values.selectedOffenseTypes,
      ...formik.values.availableOffenseTypes
    ];

    formik.setValues({
      ...formik.values,
      selectedOffenseTypes: newSelectedOffenseTypes.sort(idSort),
      availableOffenseTypes: []
    });
  };

  const deselectAllOffenseTypes = () => {
    const newAvailableOffenseTypes = [
      ...formik.values.availableOffenseTypes,
      ...formik.values.selectedOffenseTypes
    ];

    formik.setValues({
      ...formik.values,
      selectedOffenseTypes: [],
      availableOffenseTypes: newAvailableOffenseTypes.sort(idSort)
    });
  };

  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledOffenseTypes"
              name="enabledOffenseTypes"
              isOn={formik.values.enabledOffenseTypes}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledOffenseTypes}
        />
      )}

      {((formik.values.enabledOffenseTypes && isSwitchable) || !isSwitchable) && (
        <FormFieldBlock
          label={formLabel}
          className="OffenseTypes"
          field={
            <SideBySideSelector
              availableObjects={formik?.values?.availableOffenseTypes}
              selectedObjects={formik?.values?.selectedOffenseTypes}
              selectOne={selectOffenseType}
              deselectOne={deselectOffenseType}
              selectAll={selectAllOffenseTypes}
              deselectAll={deselectAllOffenseTypes}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.selectedOffenseTypes}
        />
      )}
    </>
  );
}

export default OffenseTypeField;
