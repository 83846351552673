import React, { useEffect, useRef, useState } from 'react';
import {
  TbReload,
  TbChevronDown,
  TbChevronUp,
  TbChevronLeft,
  TbChevronRight
} from 'react-icons/tb';
import { getImageToAdjust, getLicenseImage } from './utils';
import _ from 'lodash';

const ImageComparison = ({ observation, offset, setOffset }) => {
  const [rect1, setRect1] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [rect2, setRect2] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [originalBox, setOriginalBox] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const originalBoxRef = useRef(originalBox);
  const [adjustedBox, setAdjustedBox] = useState(null);
  const adjustedBoxRef = useRef(adjustedBox);
  const [dimensions1, setDimensions1] = useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0
  });
  const dimensions1Ref = useRef(dimensions1);
  const [dimensions2, setDimensions2] = useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0
  });
  const dimensions2Ref = useRef(dimensions2);
  const img1Ref = useRef(null);
  const img2Ref = useRef(null);

  useEffect(() => {
    adjustedBoxRef.current = adjustedBox;
  }, [adjustedBox]);

  useEffect(() => {
    originalBoxRef.current = originalBox;
  }, [originalBox]);

  const handleKeyDown = event => {
    switch (event.key) {
      case 'ArrowUp':
        moveRect(0, -10);
        break;
      case 'ArrowDown':
        moveRect(0, 10);
        break;
      case 'ArrowLeft':
        moveRect(-10, 0);
        break;
      case 'ArrowRight':
        moveRect(10, 0);
        break;
      case '+':
        resizeRect(5);
        break;
      case '-':
        resizeRect(-5);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const updateDimensions1 = () => {
    if (img1Ref.current) {
      setDimensions1(prevDimensions => ({
        ...prevDimensions,
        width: img1Ref.current.clientWidth,
        height: img1Ref.current.clientHeight,
        naturalWidth: img1Ref.current.naturalWidth,
        naturalHeight: img1Ref.current.naturalHeight,
        displayRatio: img1Ref.current.clientWidth / img1Ref.current.naturalWidth
      }));
    }
  };

  const updateDimensions2 = () => {
    if (img2Ref.current) {
      setDimensions2(prevDimensions => ({
        ...prevDimensions,
        width: img2Ref.current.clientWidth,
        height: img2Ref.current.clientHeight,
        naturalWidth: img2Ref.current.naturalWidth,
        naturalHeight: img2Ref.current.naturalHeight,
        displayRatio: img2Ref.current.clientWidth / img2Ref.current.naturalWidth
      }));
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions1);
    window.addEventListener('resize', updateDimensions2);

    return () => {
      window.removeEventListener('resize', updateDimensions1);
      window.removeEventListener('resize', updateDimensions2);
    };
  }, []);

  useEffect(() => {
    if (observation && observation.files) {
      setOriginalBox({
        x: getLicenseImage(observation).licensePlateLocation.top_left_x,
        y: getLicenseImage(observation).licensePlateLocation.top_left_y,
        width: getLicenseImage(observation).licensePlateLocation.width,
        height: getLicenseImage(observation).licensePlateLocation.height
      });
      if (_.size(offset) > 0) {
        if (getLicenseImage(observation).kind === 'overview') {
          // reverse offset
          setAdjustedBox({
            x: getLicenseImage(observation).licensePlateLocation.top_left_x - offset.dx,
            y: getLicenseImage(observation).licensePlateLocation.top_left_y - offset.dy,
            width: getLicenseImage(observation).licensePlateLocation.width / offset.sizeAdjust,
            height: getLicenseImage(observation).licensePlateLocation.height / offset.sizeAdjust,
            sizeAdjust: 100 / offset.sizeAdjust
          });
        } else {
          setAdjustedBox({
            x: getLicenseImage(observation).licensePlateLocation.top_left_x + offset.dx,
            y: getLicenseImage(observation).licensePlateLocation.top_left_y + offset.dy,
            width: getLicenseImage(observation).licensePlateLocation.width * offset.sizeAdjust,
            height: getLicenseImage(observation).licensePlateLocation.height * offset.sizeAdjust,
            sizeAdjust: 100 * offset.sizeAdjust
          });
        }
      } else {
        setAdjustedBox({
          x: getLicenseImage(observation).licensePlateLocation.top_left_x,
          y: getLicenseImage(observation).licensePlateLocation.top_left_y,
          width: getLicenseImage(observation).licensePlateLocation.width,
          height: getLicenseImage(observation).licensePlateLocation.height,
          sizeAdjust: 100
        });
      }
    }
  }, [observation]);

  const moveRect = (dx, dy) => {
    if (adjustedBoxRef.current) {
      if (
        (adjustedBoxRef.current.x + dx + getAdjustedWidth() < img2Ref.current.naturalWidth ||
          dx <= 0) &&
        adjustedBoxRef.current.x + dx >= 0 &&
        (adjustedBoxRef.current.y + dy + getAdjustedHeight() < img2Ref.current.naturalHeight ||
          dy <= 0) &&
        adjustedBoxRef.current.y + dy >= 0
      ) {
        setAdjustedBox(prevRect => ({
          ...prevRect,
          x: prevRect.x + dx,
          y: prevRect.y + dy
        }));
      }
    }
  };

  const resizeRect = ds => {
    if (adjustedBoxRef.current) {
      if (adjustedBoxRef.current.sizeAdjust + ds > 0) {
        setAdjustedBox(prevRect => ({
          ...prevRect,
          sizeAdjust: prevRect.sizeAdjust + ds,
          width: (originalBoxRef.current.width * (prevRect.sizeAdjust + ds)) / 100,
          height: (originalBoxRef.current.height * (prevRect.sizeAdjust + ds)) / 100
        }));
      }
    }
  };

  const resetRect = () => {
    setAdjustedBox({
      ...originalBoxRef.current,
      sizeAdjust: 100
    });
  };

  const getAdjustedWidth = () => {
    return (adjustedBoxRef.current.width * adjustedBoxRef.current.sizeAdjust) / 100;
  };

  const getAdjustedHeight = () => {
    return (adjustedBoxRef.current.height * adjustedBoxRef.current.sizeAdjust) / 100;
  };

  const updateRects = () => {
    if (dimensions1Ref.current.displayRatio) {
      setRect1({
        x: originalBoxRef.current.x * dimensions1Ref.current.displayRatio,
        y: originalBoxRef.current.y * dimensions1Ref.current.displayRatio,
        width: originalBoxRef.current.width * dimensions1Ref.current.displayRatio,
        height: originalBoxRef.current.height * dimensions1Ref.current.displayRatio
      });
    }
    if (dimensions2Ref.current.displayRatio) {
      setRect2({
        x: adjustedBox.x * dimensions2Ref.current.displayRatio,
        y: adjustedBox.y * dimensions2Ref.current.displayRatio,
        width: getAdjustedWidth() * dimensions2Ref.current.displayRatio,
        height: getAdjustedHeight() * dimensions2Ref.current.displayRatio
      });
    }
  };

  useEffect(() => {
    updateRects();
  }, [originalBox]);

  useEffect(() => {
    updateRects();
    updateOffset();
  }, [adjustedBox]);

  useEffect(() => {
    dimensions1Ref.current = dimensions1;
    updateRects();
  }, [dimensions1]);

  useEffect(() => {
    dimensions2Ref.current = dimensions2;
    updateRects();
  }, [dimensions2]);

  const updateOffset = () => {
    let source, destination;
    if (getLicenseImage(observation).kind === 'general') {
      source = adjustedBoxRef.current;
      destination = originalBoxRef.current;
    } else {
      source = originalBoxRef.current;
      destination = adjustedBoxRef.current;
    }

    if (!!source && !!destination) {
      setOffset({
        dx: source.x - destination.x,
        dy: source.y - destination.y,
        sizeAdjust: source.width / destination.width
      });
    }
  };

  return (
    <>
      {observation && (
        <div className="comparison-container">
          <div className="left-side">
            <div className="d-flex justify-content-center">
              <div className="image-container">
                <img
                  src={getLicenseImage(observation)?.url}
                  alt="Image 1"
                  ref={img1Ref}
                  onLoad={updateDimensions1}
                  className="comparison-image"
                />
                <div
                  style={{
                    position: 'absolute',
                    top: rect1.y,
                    left: rect1.x,
                    width: rect1.width,
                    height: rect1.height,
                    border: '2px solid green',
                    boxSizing: 'border-box'
                  }}
                />
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="d-flex justify-content-center">
              <div className="image-container">
                <img
                  src={getImageToAdjust(observation)?.url}
                  alt="Image 2"
                  onLoad={updateDimensions2}
                  ref={img2Ref}
                  className="comparison-image"
                />
                <div
                  style={{
                    position: 'absolute',
                    top: rect2.y,
                    left: rect2.x,
                    width: rect2.width,
                    height: rect2.height,
                    border: '2px solid red',
                    boxSizing: 'border-box'
                  }}
                />
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-center">
                    <div className="position-grid">
                      <div className="grid-cell"></div>
                      <div className="grid-cell">
                        <button
                          className="btn btn-outline-by-secondary"
                          onClick={() => moveRect(0, -10)}
                        >
                          <TbChevronUp />
                        </button>
                      </div>
                      <div className="grid-cell"></div>
                      <div className="grid-cell">
                        <button
                          className="btn btn-outline-by-secondary"
                          onClick={() => moveRect(-10, 0)}
                        >
                          <TbChevronLeft />
                        </button>
                      </div>
                      <div className="grid-cell">
                        <button
                          className="btn btn-outline-by-secondary"
                          onClick={() => resetRect()}
                        >
                          <TbReload />
                        </button>
                      </div>
                      <div className="grid-cell">
                        <button
                          className="btn btn-outline-by-secondary"
                          onClick={() => moveRect(10, 0)}
                        >
                          <TbChevronRight />
                        </button>
                      </div>
                      <div className="grid-cell"></div>
                      <div className="grid-cell">
                        <button
                          className="btn btn-outline-by-secondary"
                          onClick={() => moveRect(0, 10)}
                        >
                          <TbChevronDown />
                        </button>
                      </div>
                      <div className="grid-cell"></div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex justify-content-center h-100 align-items-center">
                    <div className="size-grid">
                      <div className="grid-cell">
                        <button
                          className="btn btn-outline-by-secondary"
                          onClick={() => resizeRect(5, 0)}
                        >
                          +
                        </button>
                      </div>
                      <div className="grid-cell">
                        <button
                          className="btn btn-outline-by-secondary"
                          onClick={() => resizeRect(-5, 0)}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="text-secondary d-flex justify-content-center">
                    {I18n.t('settings.blurring_offset.position')}
                  </div>
                </div>
                <div className="col">
                  <div className="text-secondary d-flex justify-content-center">
                    {I18n.t('settings.blurring_offset.size')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageComparison;
