import { useQuery } from '@apollo/client/react';
import { GET_SOURCE_SYSTEMS } from '../tickets';

const useSourceSystemOptions = () => {
  const { data, loading } = useQuery(GET_SOURCE_SYSTEMS);

  const options = data
    ? data.licensedSourceSystems.map(({ value, name }) => ({ id: value, name }))
    : [];

  return [options, loading];
};

export default useSourceSystemOptions;
