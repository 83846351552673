import React from 'react';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function DirectionField({
  formik,
  editEnabled,
  isSwitchable = false,
  formLabel = '',
  switchLabel = ''
}) {
  const selectDirection = direction => {
    const newSelectedDirections = [...formik.values.selectedDirections, direction];
    const newAvailableDirections = formik.values.availableDirections.filter(
      d => d.id !== direction.id
    );

    formik.setValues({
      ...formik.values,
      selectedDirections: newSelectedDirections.sort(idSort),
      availableDirections: newAvailableDirections
    });
  };

  const deselectDirection = direction => {
    const newSelectedDirections = formik.values.selectedDirections.filter(
      d => d.id !== direction.id
    );
    const newAvailableDirections = [...formik.values.availableDirections, direction];

    formik.setValues({
      ...formik.values,
      selectedDirections: newSelectedDirections,
      availableDirections: newAvailableDirections.sort(idSort)
    });
  };

  const selectAllDirections = () => {
    const newSelectedDirections = [
      ...formik.values.selectedDirections,
      ...formik.values.availableDirections
    ];

    formik.setValues({
      ...formik.values,
      selectedDirections: newSelectedDirections.sort(idSort),
      availableDirections: []
    });
  };

  const deselectAllDirections = () => {
    const newAvailableDirections = [
      ...formik.values.availableDirections,
      ...formik.values.selectedDirections
    ];

    formik.setValues({
      ...formik.values,
      selectedDirections: [],
      availableDirections: newAvailableDirections.sort(idSort)
    });
  };

  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledDirections"
              name="enabledDirections"
              isOn={formik.values.enabledDirections}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledDirections}
        />
      )}

      {((formik.values.enabledDirections && isSwitchable) || !isSwitchable) && (
        <FormFieldBlock
          label={formLabel}
          className="directions"
          field={
            <SideBySideSelector
              availableObjects={formik?.values?.availableDirections}
              selectedObjects={formik?.values?.selectedDirections}
              selectOne={selectDirection}
              deselectOne={deselectDirection}
              selectAll={selectAllDirections}
              deselectAll={deselectAllDirections}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.selectedDirections}
        />
      )}
    </>
  );
}

export default DirectionField;
