import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  Button,
  Col,
  DatePicker,
  Form as BYForm,
  Row,
  TimePicker,
  ToggleButton,
  ToggleButtonGroup
} from 'brickyard-ui';

import Api from '@/utils/Api';
import parseTime from '@/utils/parseTime';

dayjs.extend(customParseFormat);

const ScheduledOverrideForm = ({ inline, locations, scheduledOverride, onSave, onCancel }) => {
  const weekdaysArr = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];

  const onSubmit = async values => {
    try {
      const schedule = { ...values };
      delete schedule.weekdays;
      ['id', 'created_at', 'updated_at'].forEach(key => delete schedule[key]);
      weekdaysArr.forEach(day => (schedule[day] = values.weekdays.includes(day)));
      const { data } = await Api[scheduledOverride ? 'patch' : 'post'](
        `/administration/counting/scheduled_overrides/${scheduledOverride?.id || ''}`,
        {
          scheduled_override: schedule
        }
      );
      onSave(data);
    } catch (err) {
      console.log(err);
    }
  };

  const parsedSchedule = () => {
    if (!scheduledOverride) return {};

    const weekdays = weekdaysArr.filter(day => scheduledOverride[day]);
    const schedule = { ...scheduledOverride };
    weekdaysArr.forEach(day => delete schedule[day]);
    if (scheduledOverride.override_from_time) {
      schedule.override_from_time = parseTime(scheduledOverride.override_from_time);
    }
    if (scheduledOverride.override_till_time) {
      schedule.override_till_time = parseTime(scheduledOverride.override_till_time);
    }

    return { ...schedule, weekdays };
  };

  const formik = useFormik({
    initialValues: {
      counting_reporter_id: '',
      description: '',
      override_from: new Date(),
      override_till: new Date(),
      is_weekly: false,
      override_from_time: '6:00',
      override_till_time: '12:00',
      is_part_of_day: false,
      weekdays: [],
      ...parsedSchedule()
    },
    validationSchema: Yup.object().shape({
      counting_reporter_id: Yup.string().required(),
      description: Yup.string(),
      override_from: Yup.date(),
      override_till: Yup.date().min(
        Yup.ref('override_from'),
        'must be greater than From date value'
      ),
      is_weekly: Yup.boolean(),
      override_from_time: Yup.string(),
      override_till_time: Yup.string().test({
        name: 'is-greater',
        test: function(endTime) {
          const { override_from_time } = this.parent;
          return dayjs(override_from_time, 'HH:mm').isBefore(dayjs(endTime, 'HH:mm'))
            ? true
            : this.createError({
                message: 'Must be greater than ${override_from_time}',
                params: { override_from_time: override_from_time }
              });
        }
      }),
      is_part_of_day: Yup.boolean(),
      weekdays: Yup.array()
        .of(Yup.string())
        .when('is_weekly', {
          is: true,
          then: Yup.array().required()
        })
    }),
    onSubmit
  });

  return (
    <BYForm onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs="12" lg={inline ? '6' : '12'}>
          <BYForm.Group>
            <BYForm.Label>{I18n.t('scheduled_override.form.location')}</BYForm.Label>
            <BYForm.Control
              size={inline ? 'sm' : 'md'}
              {...formik.getFieldProps('counting_reporter_id')}
              as="select"
              isInvalid={formik.touched.counting_reporter_id && formik.errors.counting_reporter_id}
            >
              <option value="" />
              {locations.map(l => (
                <option value={l.reporter.id} key={l.reporter.id}>
                  {l.name}
                </option>
              ))}
            </BYForm.Control>
            <BYForm.Control.Feedback type="invalid">
              {formik.errors.counting_reporter_id}
            </BYForm.Control.Feedback>
          </BYForm.Group>
        </Col>

        <Col xs="12" lg={inline ? '6' : '12'}>
          <BYForm.Group>
            <BYForm.Label>{I18n.t('scheduled_override.form.description')}</BYForm.Label>
            <BYForm.Control size={inline ? 'sm' : 'md'} {...formik.getFieldProps('description')} />
          </BYForm.Group>
        </Col>
      </Row>

      <Row>
        <Col xs="12" lg="6">
          <BYForm.Group>
            <BYForm.Label>{I18n.t('scheduled_override.form.from')}</BYForm.Label>
            <DatePicker
              value={formik.values.override_from}
              onChange={date => formik.setFieldValue('override_from', date)}
              locale={I18n.locale}
            />
          </BYForm.Group>
        </Col>
        <Col xs="12" lg="6">
          <BYForm.Group>
            <BYForm.Label>{I18n.t('scheduled_override.form.till')}</BYForm.Label>
            <DatePicker
              value={formik.values.override_till}
              onChange={date => formik.setFieldValue('override_till', date)}
              isInvalid={formik.errors.override_till}
              feedbackInvalid={formik.errors.override_till}
              locale={I18n.locale}
            />
          </BYForm.Group>
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <BYForm.Label>{I18n.t('scheduled_override.form.repeats.weekly')}</BYForm.Label>
        </Col>
        <Col xs="1">
          <BYForm.Group>
            <BYForm.Switch
              id="isWeekly"
              label=""
              checked={formik.values.is_weekly}
              size={inline ? 'md' : 'lg'}
              onClick={() => !formik.values.is_weekly && formik.setFieldValue('weekdays', [])}
              {...formik.getFieldProps('is_weekly')}
            />
          </BYForm.Group>
        </Col>
        {formik.values.is_weekly && (
          <Col xs="11">
            <BYForm.Group>
              <ToggleButtonGroup
                type="checkbox"
                onChange={weekdays => formik.setFieldValue('weekdays', weekdays)}
                value={formik.values.weekdays}
              >
                {weekdaysArr.map((day, idx) => (
                  <ToggleButton
                    key={day}
                    checked={formik.values.weekdays.includes(day)}
                    variant="outline-secondary"
                    size="sm"
                    value={day}
                  >
                    {I18n.t('date.day_names')[idx]}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <BYForm.Control.Feedback
                type="invalid"
                className={
                  formik.errors.weekdays?.length
                    ? 'weekdays-error-hint is-invalid'
                    : 'weekdays-error-hint'
                }
              >
                {formik.errors.weekdays}
              </BYForm.Control.Feedback>
            </BYForm.Group>
          </Col>
        )}
      </Row>

      <Row className="part-of-day-container">
        <Col xs="12">
          <BYForm.Label>{I18n.t('scheduled_override.form.is_part_of_day')}</BYForm.Label>
        </Col>
        <Col xs="1">
          <BYForm.Group>
            <BYForm.Switch
              id="isPartOfDay"
              label=""
              checked={formik.values.is_part_of_day}
              size={inline ? 'sm' : 'md'}
              {...formik.getFieldProps('is_part_of_day')}
            />
          </BYForm.Group>
        </Col>
        {formik.values.is_part_of_day && (
          <>
            <Col xs="5">
              <BYForm.Group as={Row}>
                <BYForm.Label column xs={2}>
                  {I18n.t('scheduled_override.form.from')}
                </BYForm.Label>
                <Col>
                  <TimePicker
                    value={formik.values.override_from_time}
                    onChange={time => formik.setFieldValue('override_from_time', time)}
                  />
                </Col>
              </BYForm.Group>
            </Col>
            <Col xs="6">
              <BYForm.Group as={Row}>
                <BYForm.Label column xs={2}>
                  {I18n.t('scheduled_override.form.till')}
                </BYForm.Label>
                <Col>
                  <TimePicker
                    value={formik.values.override_till_time}
                    onChange={time => formik.setFieldValue('override_till_time', time)}
                    isInvalid={formik.errors.override_till_time}
                    feedbackInvalid={formik.errors.override_till_time}
                  />
                </Col>
              </BYForm.Group>
            </Col>
          </>
        )}
      </Row>

      <section className="schedule-form-btn-holder">
        <Button size={inline ? 'sm' : 'md'} variant="by-dark" onClick={onCancel}>
          {I18n.t('actions.cancel')}
        </Button>

        <Button size={inline ? 'sm' : 'md'} variant="by-primary" type="submit">
          {I18n.t('actions.save')}
        </Button>
      </section>
    </BYForm>
  );
};

export default ScheduledOverrideForm;
