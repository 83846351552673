import React from 'react';
import FormField from '../../../components/FormField';
import LabelToggle from '../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldBlock from '../../../components/FormFieldBlock';

function UseCaseCameraZone({ formik }) {
  return (
    <div className="details-block-panel">
      <FormField
        label={I18n.t('settings.field_name_label')}
        className="header"
        field={
          <input
            id="name"
            name="name"
            type="text"
            value={formik.values.name ?? ''}
            readOnly={true}
            disabled={true}
          />
        }
      />

      <FormField
        label={I18n.t('settings.field_active')}
        className="use_case_camera_enabled"
        field={
          <LabelToggle
            id="use_case_camera_enabled"
            name="use_case_camera_enabled"
            isOn={formik.values.use_case_camera_enabled}
            onChange={formik.handleChange}
          />
        }
      />

      {!formik.values.camera_is_active && (
        <FormFieldBlock
          field={
            <div className="alert alert-warning" role="alert">
              {I18n.t('settings.cameras.label.disabled_camera')}
            </div>
          }
        />
      )}

      <FormField
        label={I18n.t('settings.cameras.label.roof_zone_id')}
        className="roof_zone_id"
        field={
          <input
            type="text"
            id="roof_zone_id"
            name="roof_zone_id"
            value={formik.values.roof_zone_id ?? ''}
            onChange={formik.handleChange}
          />
        }
        error={formik.errors.roof_zone_id}
      />

      <FormField
        label={I18n.t('settings.cameras.label.zone_group_id')}
        className="zone_group_id"
        field={
          <input
            type="text"
            id="zone_group_id"
            name="zone_group_id"
            value={formik.values.zone_group_id ?? ''}
            onChange={formik.handleChange}
          />
        }
        error={formik.errors.zone_group_id}
      />

      <FormField
        label={I18n.t('settings.cameras.label.parking_code')}
        className="parking_code"
        field={
          <input
            type="text"
            id="parking_code"
            name="parking_code"
            value={formik.values.parking_code ?? ''}
            onChange={formik.handleChange}
          />
        }
        error={formik.errors.parking_code}
      />
    </div>
  );
}

export default UseCaseCameraZone;
