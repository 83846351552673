import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import MenuItem from '../../../components/MenuItem';

function FixedCamerasSubMenu() {
  const match = useRouteMatch();

  const menuItems = [
    { link: `${match.url}/cameras`, label: I18n.t('settings.fixed_cameras_list_label') },
    { link: `${match.url}/locations`, label: I18n.t('settings.fixed_cameras_location_label') }
  ];

  const isItemSelected = item => {
    return location.pathname.match(`${item.link}`);
  };

  return (
    <div className="submenu">
      {menuItems.map(item => (
        <MenuItem
          key={item.label}
          link={item.link}
          label={item.label}
          isSelected={isItemSelected(item)}
        />
      ))}
    </div>
  );
}

export default FixedCamerasSubMenu;
