import React, { useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import LabelToggle from '../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../components/FormFieldSmall';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import * as Yup from 'yup';
import FormFieldBlock from '../../../../components/FormFieldBlock';

function ImageDecorator({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      fetch_panorama_images: selectedProcessor?.config?.fetch_panorama_images ?? false
    },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, ['id', 'name', 'fetch_panorama_images'])
      );
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        I18n.t('settings.pipelines.processors.form.validations.name_required')
      )
    })
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    const params = {
      id: values.id,
      name: values.name,
      config: {
        fetch_panorama_images: values.fetch_panorama_images
      }
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <>
      <FormFieldBlock
        field={
          <>
            <div className="alert alert-success" role="alert">
              {I18n.t('settings.pipelines.processors.messages.on_error_exempt')}
            </div>
          </>
        }
      />

      <FormFieldSmall
        label={I18n.t('settings.pipelines.processors.form.image_decorator.fetch_panorama_images')}
        field={
          <LabelToggle
            id="fetch_panorama_images"
            name="fetch_panorama_images"
            isOn={formik.values.fetch_panorama_images ?? false}
            onChange={formik.handleChange}
            disabled={!editEnabled}
          />
        }
        error={formik.errors.fetch_panorama_images}
      />
    </>
  );
}

export default ImageDecorator;
