import React from 'react';
import { Modal, Button, Form as BYForm } from 'brickyard-ui';

const LoggedOutModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('activity_checker.modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BYForm.Group>
          <BYForm.Label>{I18n.t('activity_checker.modal_logged_out_content')}</BYForm.Label>
        </BYForm.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="by-primary" onClick={onHide}>
          {I18n.t('actions.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoggedOutModal;
