import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Alert, Loading } from 'brickyard-ui';

import formatDate from '@/utils/formatDate';
import Button from '../../components/Button';
import Remark from './Remark';
import NewRemarkModal from './NewRemarkModal';
import { ShortDateFormat } from '@/utils/DateFormats';

const GET_TICKET_COMMENTS = gql`
  query GetTicketComments($ticketId: ID!) {
    ticketsComments(search: { ticketId: $ticketId }) {
      id
      body
      createdAt
      userName
    }
  }
`;

const StyledButton = styled(Button)`
  font-size: 13px;
  margin: 8px 0;
`;

const Remarks = ({ formik, ticketId }) => {
  const [modalIsVisible, setModalVisibility] = useState(false);
  const { data, loading, error, refetch } = useQuery(GET_TICKET_COMMENTS, {
    variables: { ticketId },
    skip: !ticketId
  });

  const openRemarkDialog = () => setModalVisibility(true);

  if (error) return <Alert variant="danger">{error?.message || error?.toString()}</Alert>;

  const remapRemark = ({ id, createdAt, userName, body }) => ({
    id,
    date: formatDate(createdAt, ShortDateFormat),
    author: userName,
    text: body
  });

  const remarks = data ? data.ticketsComments.map(remapRemark) : [];

  return (
    <div>
      {!formik.status.readOnly && (
        <StyledButton onClick={openRemarkDialog}>
          {I18n.t('tickets.full_ticket.actions.add_comment')}
        </StyledButton>
      )}
      <div>
        {loading ? (
          <Loading />
        ) : (
          remarks.map(({ id, date, author, text }) => (
            <Remark
              key={id}
              id={id}
              date={date}
              author={author}
              text={text}
              afterDelete={refetch}
              formik={formik}
            />
          ))
        )}
        <NewRemarkModal
          ticketId={ticketId}
          show={modalIsVisible}
          onHide={() => setModalVisibility(false)}
          afterCreate={refetch}
        />
      </div>
    </div>
  );
};

export default Remarks;
