import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectFixedCamerasState, useFixedCamerasSlice } from './slice';
import { Alert, Button, Loading } from 'brickyard-ui';
import Table from '../../components/Table';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import CameraForm from './components/CameraForm';
import CameraListMap from './components/CameraListMap';

const FixedCameras = () => {
  const { actions } = useFixedCamerasSlice();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [paginatedCameras, setPaginatedCameras] = useState([]);

  useEffect(() => {
    dispatch(actions.fetchCameras());
    dispatch(actions.fetchRoadTypes());

    return () => {
      dispatch(actions.clearData());
    };
  }, [dispatch]);

  const state = useSelector(selectFixedCamerasState);

  const onCloseAlert = () => {
    dispatch(actions.setBackendValidationError(null));
  };

  const onRowClick = id => {
    history.push({ pathname: `${match.url}/${id}`, search: location.search });
  };

  const onNewButtonClick = () => {
    history.push({ pathname: `${match.url}/new`, search: location.search });
  };

  const onFormClose = () => {
    history.push({ pathname: match.url, search: location.search });

    dispatch(actions.fetchCameras());
    dispatch(actions.storeCamera(null));
    dispatch(actions.storeLocations(null));
    dispatch(actions.setBackendValidationError(null));
  };

  const getStatusLabelClass = status => {
    let klass = 'status-label ';

    return (klass += status === 'enabled' ? 'ok-label' : 'warn-label');
  };

  const columns = [
    {
      key: 'name',
      header: I18n.t('settings.table_name_header'),
      sortable: true
    },
    {
      key: 'status',
      header: I18n.t('settings.table_status_header'),
      sortable: true,
      cell: row => (
        <div className="status-cell">
          <span className={getStatusLabelClass(row.status)}>{row.status.toUpperCase()}</span>
        </div>
      )
    }
  ];

  return (
    <>
      <div className="right-panel">
        <div className="right-panel-header">
          <h5>{I18n.t('settings.fixed_cameras_list_label')}</h5>
          {state.permission === 'copSettingsWrite' && (
            <Button
              variant="outline-by-secondary"
              onClick={onNewButtonClick}
              className="new-type-btn"
            >
              {I18n.t('settings.new_fixed_camera_button')}
            </Button>
          )}
        </div>

        <div className="right-panel-body">
          {state.loading && !state.cameras.length ? (
            <div className="loading-box">
              <Loading />
            </div>
          ) : (
            <Table
              columns={columns}
              items={state.cameras}
              onRowClick={onRowClick}
              selectedId={state.selectedCamera?.id}
              onPaginationChange={setPaginatedCameras}
            />
          )}
        </div>
      </div>

      <div className="details-panel">
        <Switch>
          <Route path={`${match.path}/:id`}>
            <CameraForm
              camera={state.selectedCamera}
              onClose={onFormClose}
              isLoading={state.loading}
              backendValidationError={state.backendValidationError}
              permission={state.permission}
            />
          </Route>
          <Route path={`${match.path}`}>
            <CameraListMap cameras={paginatedCameras} onMarkerClick={onRowClick} />
          </Route>
        </Switch>

        {state.backendValidationError && (
          <Alert
            onClose={() => {
              onCloseAlert();
            }}
            variant="danger"
            dismissible
          >
            {state.backendValidationError}
          </Alert>
        )}
      </div>
    </>
  );
};

export default FixedCameras;
