import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Close from '@/assets/img/close_icon.svg';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { selectUseCasesState, useUseCasesSlice } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from 'brickyard-ui';
import { parseGraphQLErrors } from '../../../../../../../utils/errors';
import { CREATE_USE_CASE_MUTATION, UPDATE_USE_CASE_MUTATION } from '../mutations';
import UseCaseSubMenu from './UseCaseSubMenu';
import UseCaseDetails from './UseCaseDetails';
import UseCaseCameras from './UseCaseCameras';
import InputConfirmationModal from '../../../components/InputConfirmationModal';
import UseCasePipelines from './UseCasePipelines';
import withApolloProvider from '../../../../../../../utils/withApolloProvider';
import UseCaseMobile from './UseCaseMobile';
import UseCaseShapeFiles from './UseCaseShapeFiles';

const StyledUseCaseDashboard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .dashboard-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70px;

    h3 {
      display: flex;
      color: #777777;
      font-weight: 600;
      margin: 15px;

      .static-title {
        font-weight: 300;
        margin-right: 7px;
      }
    }

    .close-button {
      margin: auto 20px auto auto;
      cursor: pointer;
    }
  }

  .dashboard-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .dashbord-body-content {
      display: flex;
      flex-direction: column;
      width: calc(100% - 255px);
      height: 100%;
      margin-right: 5px;
      border: 1px solid #e5e5e5;
      background-color: #ffffff;

      :has(div.cop-uc-pipelines),
      :has(div.cop-uc-sources) {
        border: none;
        background-color: #f7f8fb;
        margin-right: 10px;
      }

      .body-component {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
  }

  .loading-box {
    display: flex;
    margin: auto;
  }
`;

function UseCaseDashboard({ onClose }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const [createUseCase] = useMutation(CREATE_USE_CASE_MUTATION, {
    onError: errorUpdate => {
      setSubmitError(errorUpdate.message ? parseGraphQLErrors(errorUpdate.message) : false);
    },
    onCompleted: () => {
      setSubmitError(false);
    }
  });

  const [updateUseCase] = useMutation(UPDATE_USE_CASE_MUTATION, {
    onError: errorUpdate => {
      setSubmitError(errorUpdate.message ? parseGraphQLErrors(errorUpdate.message) : false);
    },
    onCompleted: () => {
      setSubmitError(false);
    }
  });

  const { actions } = useUseCasesSlice();
  const params = useParams();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const state = useSelector(selectUseCasesState);

  useEffect(() => {
    if (params.id === 'new') {
      dispatch(actions.storeUseCase({}));
    } else {
      dispatch(actions.fetchUseCase(params.id));
      dispatch(actions.fetchCameras(params.id));
      dispatch(actions.fetchCountries());
      dispatch(actions.fetchOffenseCodes());
      dispatch(actions.fetchParkingRightProviders());
    }

    return () => {
      dispatch(actions.storeUseCase(null));
    };
  }, [params.id]);

  const handleFormSave = async values => {
    if (params.id !== 'new') {
      await updateUseCase({ variables: values }).then(response => {
        if (!response.errors) {
          dispatch(actions.storeUseCase(response.data.useCaseUpdate));
          dispatch(actions.fetchUseCases());
        }
      });

      if (values['imageUrl'] == null) {
        dispatch(actions.deleteUseCaseImage({ id: params.id }));
      } else {
        dispatch(actions.uploadUseCaseImage({ id: params.id, file: values.image }));
      }
    } else {
      await createUseCase({ variables: values }).then(() => {
        onClose();
        dispatch(actions.fetchUseCases());
      });
    }
  };

  const handleCamerasSave = values => {
    dispatch(
      actions.updateUseCase({
        id: state.selectedUseCase.id,
        cameras: values.map(camera => parseInt(camera.id))
      })
    );
  };

  const handleFormDelete = () => {
    dispatch(actions.deleteUseCase(params.id));
    onClose();
  };

  return (
    <StyledUseCaseDashboard>
      {state.selectedUseCase && !state.loading ? (
        <>
          <div className="dashboard-header">
            <h3>
              <div className="static-title">{I18n.t('settings.use_case_title')}</div>
              {' - '.concat(
                params.id === 'new' ? I18n.t('settings.new_title') : state.selectedUseCase.name
              )}
            </h3>
            <div className="close-button" onClick={onClose}>
              <img src={Close} alt="close" />
            </div>
          </div>
          <div className="dashboard-body">
            <UseCaseSubMenu isEdit={params.id !== 'new'} />
            <div className="dashbord-body-content">
              <div className="body-component">
                <Switch>
                  <Route path={`${match.path}/details`}>
                    <UseCaseDetails
                      useCase={state.selectedUseCase}
                      onSave={handleFormSave}
                      submitError={submitError}
                      setSubmitError={setSubmitError}
                      onDelete={() => setShowDeleteConfirmation(true)}
                      onClose={onClose}
                    />
                  </Route>
                  <Route path={`${match.path}/cameras`}>
                    <UseCaseCameras
                      useCase={state.selectedUseCase}
                      cameras={state.cameras}
                      onSave={handleCamerasSave}
                      onDelete={() => setShowDeleteConfirmation(true)}
                    />
                  </Route>
                  <Route path={`${match.path}/pipelines`}>
                    <UseCasePipelines useCase={state.selectedUseCase} />
                  </Route>
                  <Route path={`${match.path}/shape_files`}>
                    <UseCaseShapeFiles useCase={state.selectedUseCase} />
                  </Route>
                  <Route path={`${match.path}/mobile`}>
                    <UseCaseMobile useCase={state.selectedUseCase} />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loading-box">
          <Loading />
        </div>
      )}
      {showDeleteConfirmation && (
        <InputConfirmationModal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          message={I18n.t('settings.form_use_case_delete_confirm', { word: 'DELETE' })}
          confirmMessage={'DELETE'}
          cancelBtn={{
            label: I18n.t('settings.form_cancel_button'),
            onClick: () => setShowDeleteConfirmation(false)
          }}
          okBtn={{
            label: I18n.t('settings.form_ok_button'),
            onClick: handleFormDelete
          }}
        />
      )}
    </StyledUseCaseDashboard>
  );
}

export default withApolloProvider(UseCaseDashboard);
