import React from 'react';
import dayjs from 'dayjs';

import usePermission from '@/hooks/usePermission';

import ToggleBlock from './ToggleBlock';
import ToggleDismiss from './ToggleDismiss';
import TogglePay from './TogglePay';
import Unexport from './Unexport';
import Button from '../../components/Button';
import Close from '@/assets/img/close_icon.svg';
import { TICKETS_FILTER_KEY } from '../../../../../utils/consts';

const Actions = ({ ticket }) => {
  const canCreateCarbonCopy = usePermission('createCarbonCopy');
  const canExportToWoo = usePermission('exportToWoo');
  const ticketsFilter = JSON.parse(localStorage.getItem(TICKETS_FILTER_KEY));
  const sortBy = ticketsFilter?.s ?? 'WRITTEN_AT_DESC';
  const writtenAt =
    ticketsFilter?.writtenAtGteq ??
    dayjs()
      .subtract(3, 'month')
      .toISOString();
  const goBack = () => {
    window.location.href = `/tickets?s=${sortBy}&writtenAtGteq=${writtenAt}`;
  };

  return (
    <div className="flex-end">
      {ticket && (
        <>
          {canExportToWoo && (
            <Button
              className="button"
              as="a"
              href={`/tickets/${ticket.id}/export_to_woo`}
              rel="noopener noreferrer"
              data-method="post"
              target="_blank"
            >
              {I18n.t('tickets.actions.export_to_woo')}
            </Button>
          )}
          <Button
            className="button"
            as="a"
            href={`/tickets/${ticket.id}/export_to_pdf`}
            rel="noopener noreferrer"
            data-method="post"
            target="_blank"
          >
            {I18n.t('tickets.actions.export_to_pdf')}
          </Button>
          {ticket.carbonCopyAllowed && canCreateCarbonCopy && (
            <Button
              className="button"
              as="a"
              href={`/tickets/${ticket.id}/create_carbon_copy`}
              data-method="put"
            >
              {I18n.t('tickets.full_ticket.actions.create_carbon_copy')}
            </Button>
          )}
          {ticket.exportState === 'exported' ? (
            <Unexport ticket={ticket} />
          ) : (
            <>
              <ToggleDismiss ticket={ticket} />
              <ToggleBlock ticket={ticket} />
              <TogglePay ticket={ticket} />
            </>
          )}
        </>
      )}
      <a onClick={goBack}>
        <img src={Close} alt="close" className="clickable close" />
      </a>
    </div>
  );
};

export default Actions;
