import React, { useEffect, useState } from 'react';
import InputConfirmationModal from '../../../components/InputConfirmationModal';
import UseCasePipelineBlock from './UseCasePipelineBlock';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../slice';
import { FaPencilAlt } from 'react-icons/fa';
import UseCasePipelineProcessors from './UseCasePipelineProcessors';
import UseCasePipelineProcessorModal from './UseCasePipelineProcessorModal';
import { Button, Col, Row } from 'brickyard-ui';

function UseCasePipelineDetails({ useCase, onEditPipeline }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showPipelineProcessorModal, setShowPipelineProcessorModal] = useState(false);
  const { actions } = useUseCasesSlice();

  const match = useRouteMatch();
  const state = useSelector(selectUseCasesState);
  const dispatch = useDispatch();
  const params = useParams();

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';

  useEffect(() => {
    dispatch(actions.fetchPipeline({ useCaseId: useCase?.id, pipelineId: params?.id }));

    return () => {
      dispatch(actions.storePipeline(null));
    };
  }, [params?.id]);

  const onDeletePipeline = () => {
    setShowDeleteConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handlePipelineDelete = () => {
    dispatch(
      actions.deletePipeline({
        useCaseId: useCase?.id,
        pipelineId: state.selectedPipeline?.id
      })
    );
    dispatch(actions.storePipeline(null));
    setShowDeleteConfirmation(false);
  };

  const onNewPipelineProcessor = () => {
    setShowPipelineProcessorModal(true);
  };

  return (
    <>
      {state.selectedPipeline?.id && (
        <>
          <div className="details-right-panel">
            <div className="cop-ucp-details">
              <Row className="header">
                <Col>
                  <h5>
                    {state.selectedPipeline?.name}
                    {hasWritePermission && (
                      <i className="cop-ucp-edit">
                        <FaPencilAlt size={15} onClick={() => onEditPipeline()} />
                      </i>
                    )}
                  </h5>
                </Col>
                <Col>
                  {hasWritePermission && (
                    <Button
                      variant="outline-by-secondary"
                      className="new-pipprocessor-btn"
                      onClick={() => onNewPipelineProcessor()}
                    >
                      {I18n.t('settings.new_pipeline_processor_button')}
                    </Button>
                  )}
                </Col>
              </Row>

              <UseCasePipelineProcessors />
              <UseCasePipelineProcessorModal
                useCase={useCase}
                showModal={showPipelineProcessorModal}
                setShowModal={setShowPipelineProcessorModal}
              />
            </div>
            <div className="cop-ucp-footer">
              {hasWritePermission && (
                <a className="cop-ucp-delete" onClick={() => onDeletePipeline()}>
                  {I18n.t('settings.form_delete_button')}
                </a>
              )}

              {showDeleteConfirmation && (
                <InputConfirmationModal
                  show={showDeleteConfirmation}
                  onHide={() => handleCancelDelete()}
                  message={I18n.t('settings.form_exemption_type_delete_confirm', {
                    word: 'DELETE'
                  })}
                  confirmMessage={'DELETE'}
                  cancelBtn={{
                    label: I18n.t('settings.form_cancel_button'),
                    onClick: () => handleCancelDelete()
                  }}
                  okBtn={{
                    label: I18n.t('settings.form_ok_button'),
                    onClick: handlePipelineDelete
                  }}
                />
              )}
            </div>
          </div>

          <Switch>
            <Route path={`${match.path}/block/:id`}>
              <UseCasePipelineBlock useCase={useCase} />
            </Route>
          </Switch>
        </>
      )}
    </>
  );
}

export default UseCasePipelineDetails;
