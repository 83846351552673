import React, { useEffect, useState } from 'react';
import FormFieldSmall from '../../../../../../components/FormFieldSmall';
import LabelToggle from '../../../../../../../../Ticket/Cards/components/LabelToggle';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import FormField from '../../../../../../components/FormField';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { JSONToICal, iCalToJSON, parseICalJSON } from '../utils';
import * as Yup from 'yup';

const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;

  .modal-row {
    display: flex;
    flex-direction: row;
    margin: 5px;
    width: calc(100% - 10px);
    flex-wrap: wrap;

    .form-row {
      display: flex;
      flex-direction: row;
      width: calc(50% - 10px);
    }

    .day-option {
      display: flex;
      width: calc(25% - 10px);
      margin: 10px 5px 10px 5px;
    }

    .error {
      color: #d4666b;
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .modal-subtitle {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #f36c21;
  }
`;

const Scheduler = ({ formik, editEnabled }) => {
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

  const DAYS_OF_WEEK = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

  useEffect(() => {
    loadScheduleValue();
  }, []);

  const loadScheduleValue = () => {
    const jsonValue = iCalToJSON(formik.values.schedule_ical);
    const formValues = parseICalJSON(jsonValue);

    modalFormik.setFieldValue('startDate', formValues.startDate);
    modalFormik.setFieldValue('startTime', formValues.startTime);

    modalFormik.setFieldValue('endDate', formValues.endDate);
    modalFormik.setFieldValue('endTime', formValues.endTime);

    modalFormik.setFieldValue('untilDate', formValues.untilDate);
    modalFormik.setFieldValue('untilTime', formValues.untilTime);

    modalFormik.setFieldValue('recurrence', formValues.recurrence);
    modalFormik.setFieldValue('frequency', formValues.frequency);
  };

  const handleSaveModal = () => {
    modalFormik.handleSubmit();
  };

  const modalFormik = useFormik({
    initialValues: {},
    onSubmit: values => {
      formik.setFieldValue('schedule_ical', JSONToICal(values));
      setScheduleModalOpen(false);
    },
    validationSchema: Yup.object().shape({
      startTime: Yup.string().required('Required'),
      endTime: Yup.string()
        .required('Required')
        .when('startTime', (startTime, schema) => {
          return schema.test({
            test: endTime => {
              return endTime > startTime;
            },
            message: 'End time must be after start time'
          });
        }),
      startDate: Yup.string().required('Required'),
      recurrence: Yup.array()
        .required('Required')
        .min(1, 'Required')
    }),
    validateOnChange: false,
    validateOnBlur: false
  });

  const hasRecurrenceOn = day => {
    const recurrence = modalFormik.values.recurrence ?? [];

    return recurrence.includes(day);
  };
  const changeRecurrenceOn = day => {
    const recurrence = modalFormik.values.recurrence ?? [];

    if (recurrence.includes(day)) {
      recurrence.splice(recurrence.indexOf(day), 1);
    } else {
      recurrence.push(day);
    }

    modalFormik.setFieldValue('recurrence', recurrence);
  };

  return (
    <>
      <FormFieldSmall
        label={I18n.t('settings.pipelines.processors.exemption_rule_checker.block_time_label')}
        field={
          <LabelToggle
            id="scheduled_at_enabled"
            name="scheduled_at_enabled"
            isOn={formik.values.scheduled_at_enabled}
            onChange={formik.handleChange}
            disabled={!editEnabled}
          />
        }
      />
      {formik.values.scheduled_at_enabled && editEnabled && (
        <div className="edit-link">
          <a onClick={() => setScheduleModalOpen(true)}>
            {I18n.t('settings.pipelines.processors.exemption_rule_checker.edit_schedule_label')}
          </a>
        </div>
      )}

      {scheduleModalOpen && (
        <Modal show={scheduleModalOpen} onHide={() => setScheduleModalOpen(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {I18n.t('settings.pipelines.processors.exemption_rule_checker.schedule_modal.title', {
                name: formik.values.name
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalFormik && (
              <StyledModalBody>
                <div className="modal-row">
                  <h5 className="modal-subtitle">
                    {I18n.t(
                      'settings.pipelines.processors.exemption_rule_checker.schedule_modal.time_subtitle'
                    )}
                  </h5>
                </div>
                <div className="modal-row">
                  <div className="form-row">
                    <FormField
                      label={I18n.t(
                        'settings.pipelines.processors.exemption_rule_checker.schedule_modal.start_time_label'
                      )}
                      field={
                        <input
                          id="startTime"
                          name="startTime"
                          type="time"
                          value={modalFormik.values.startTime}
                          onChange={modalFormik.handleChange}
                        />
                      }
                      error={modalFormik.errors.startTime}
                    />
                  </div>
                </div>
                <div className="modal-row">
                  <div className="form-row">
                    <FormField
                      label={I18n.t(
                        'settings.pipelines.processors.exemption_rule_checker.schedule_modal.end_time_label'
                      )}
                      field={
                        <input
                          id="endTime"
                          name="endTime"
                          type="time"
                          value={modalFormik.values.endTime}
                          onChange={modalFormik.handleChange}
                        />
                      }
                      error={modalFormik.errors.endTime}
                    />
                  </div>
                </div>

                <div className="modal-row">
                  <h5 className="modal-subtitle">
                    {I18n.t(
                      'settings.pipelines.processors.exemption_rule_checker.schedule_modal.range_subtitle'
                    )}
                  </h5>
                </div>
                <div className="modal-row">
                  <div className="form-row">
                    <FormField
                      label={I18n.t(
                        'settings.pipelines.processors.exemption_rule_checker.schedule_modal.start_date_label'
                      )}
                      field={
                        <input
                          id="startDate"
                          name="startDate"
                          type="date"
                          min={new Date().toISOString().split('T')[0]}
                          defaultValue={modalFormik.values.startDate}
                          onChange={modalFormik.handleChange}
                        />
                      }
                      error={modalFormik.errors.startDate}
                    />
                  </div>
                </div>
                <div className="modal-row">
                  <div className="form-row">
                    <FormField
                      label={I18n.t(
                        'settings.pipelines.processors.exemption_rule_checker.schedule_modal.end_date_label'
                      )}
                      field={
                        <input
                          id="untilDate"
                          name="untilDate"
                          type="date"
                          min={
                            modalFormik.values.startDate || new Date().toISOString().split('T')[0]
                          }
                          defaultValue={modalFormik.values.untilDate}
                          onChange={modalFormik.handleChange}
                        />
                      }
                      error={modalFormik.errors.untilDate}
                    />
                  </div>
                </div>

                <div className="modal-row">
                  <h5 className="modal-subtitle">
                    {I18n.t(
                      'settings.pipelines.processors.exemption_rule_checker.schedule_modal.recurrence_subtitle'
                    )}
                  </h5>
                </div>
                <div className="modal-row">
                  {DAYS_OF_WEEK.map((day, index) => (
                    <div key={`${index}-${day}`} className="day-option">
                      <Form.Check
                        inline
                        label={I18n.t(
                          `settings.pipelines.processors.exemption_rule_checker.schedule_modal.days.${day.toLowerCase()}`
                        )}
                        id={day}
                        name={day}
                        checked={hasRecurrenceOn(day)}
                        onChange={() => changeRecurrenceOn(day)}
                      />
                    </div>
                  ))}
                </div>
                {modalFormik.errors.recurrence && (
                  <div className="modal-row">
                    <p className="error">{modalFormik.errors.recurrence}</p>
                  </div>
                )}
              </StyledModalBody>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary" onClick={handleSaveModal}>
              {I18n.t('settings.form_save_button')}
            </button>
            <button className="btn btn-secondary" onClick={() => setScheduleModalOpen(false)}>
              {I18n.t('settings.form_cancel_button')}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Scheduler;
