import * as Yup from 'yup';
import Api from '@/utils/Api';

export const requiredMsg = I18n.t('forms.validation.required');
export const invalidMsg = I18n.t('forms.validation.invalid_short');

export const isCameraSystem = sourceSystem =>
  ['C01::Observation', 'ProRail::Observation'].includes(sourceSystem);

const licensePlateValidator = async function(value) {
  const countryValue = this.parent.vehicleCountry;

  if (!value || !countryValue) {
    return true;
  }

  const { data: isValid } = await Api['get']('/validations/license_plate', {
    params: { plate: value, country: countryValue }
  });
  return isValid;
};

const CommonTicketSchema = {
  writtenAt: Yup.date().required(requiredMsg),
  offenseCode: Yup.string()
    .ensure()
    .required(requiredMsg),
  offenseLocationCity: Yup.string()
    .ensure()
    .required(requiredMsg),
  offenseLocationStreet: Yup.string()
    .ensure()
    .required(requiredMsg),
  vehicleLicensePlateNumber: Yup.string()
    .ensure()
    .required(requiredMsg)
    .test('is-valid-license-plate', invalidMsg, licensePlateValidator),
  vehicleCountry: Yup.string()
    .ensure()
    .required(requiredMsg)
};

export default CommonTicketSchema;
