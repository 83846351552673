import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table';
import ExemptionTypeModal from './ExemptionTypeModal';
import InputConfirmationModal from '../../../../components/InputConfirmationModal';
import { Loading, Badge, Button } from 'brickyard-ui';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectExemptionTypesState, useExemptionTypesSlice } from './slice';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

function ExemptionTypes({ user }) {
  const { actions } = useExemptionTypesSlice();
  const dispatch = useDispatch();
  const state = useSelector(selectExemptionTypesState);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onNewExemptionType = () => {
    dispatch(actions.storeExemptionType(null));
    setShowModal(true);
  };

  const onEditExemptionType = exemptionTypeId => {
    dispatch(actions.fetchExemptionType(exemptionTypeId));
    setShowModal(true);
  };

  const onDeleteExemptionType = exemptionTypeId => {
    // eslint-disable-next-line no-undef
    let exemptionType = _.find(state.exemptionTypes, { id: exemptionTypeId });
    dispatch(actions.storeExemptionType(exemptionType));
    setShowDeleteConfirmation(true);
  };

  const handleExemptionTypeDelete = () => {
    dispatch(actions.deleteExemptionType(state.selectedExemptionType?.id));
    dispatch(actions.storeExemptionType(null));
    setShowDeleteConfirmation(false);
  };

  const handleCancelDelete = () => {
    dispatch(actions.storeExemptionType(null));
    setShowDeleteConfirmation(false);
  };

  const hasWritePermission = user.cop_settings_permission == 'copSettingsWrite';

  const columns = [
    {
      key: 'name',
      header: I18n.t('settings.table_name_header'),
      sortable: true
    },
    {
      key: 'global',
      header: '',
      sortable: false,
      cell: row => (
        <div className="badges-cell">
          {row.global === true ? (
            <Badge variant="by-primary">{I18n.t('settings.table_global_label')}</Badge>
          ) : (
            <Badge variant="by-secondary">{I18n.t('settings.table_usecase_label')}</Badge>
          )}
        </div>
      )
    },
    {
      key: 'id',
      header: '',
      sortable: false,
      cell: row => (
        <div className="actions-cell">
          {hasWritePermission ? (
            <i>
              <FaPencilAlt size={20} onClick={() => onEditExemptionType(row.id)} />
            </i>
          ) : (
            <i className="disabled">
              <FaPencilAlt size={20} />
            </i>
          )}
          {row.isDeletable && hasWritePermission ? (
            <i>
              <FaTrashAlt size={20} onClick={() => onDeleteExemptionType(row.id)} />
            </i>
          ) : (
            <i className="disabled">
              <FaTrashAlt size={20} />
            </i>
          )}
        </div>
      )
    }
  ];

  useEffect(() => {
    dispatch(actions.fetchExemptionTypes());

    return () => {
      dispatch(actions.clearData());
    };
  }, [dispatch, actions]);
  return (
    <>
      <div className="right-panel-header">
        <h5>{I18n.t('settings.exemptions_types_page')}</h5>

        {hasWritePermission && (
          <Button
            variant="outline-by-secondary"
            className="new-type-btn"
            onClick={() => onNewExemptionType()}
          >
            {I18n.t('settings.new_exemption_type_button')}
          </Button>
        )}
      </div>
      <div className="right-panel-body">
        {state.loading && !state.exemptionTypes.length ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <>
            <Table
              columns={columns}
              items={state.exemptionTypes}
              selectedId={state.selectedExemptionType?.id}
            />

            <ExemptionTypeModal showModal={showModal} setShowModal={setShowModal} />

            {showDeleteConfirmation && (
              <InputConfirmationModal
                show={showDeleteConfirmation}
                onHide={() => handleCancelDelete()}
                message={I18n.t('settings.form_exemption_type_delete_confirm', { word: 'DELETE' })}
                confirmMessage={'DELETE'}
                cancelBtn={{
                  label: I18n.t('settings.form_cancel_button'),
                  onClick: () => handleCancelDelete()
                }}
                okBtn={{
                  label: I18n.t('settings.form_ok_button'),
                  onClick: handleExemptionTypeDelete
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(ExemptionTypes);
