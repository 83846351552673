import React from 'react';
import styled from 'styled-components';

import DeleteIcon from '@/assets/img/close.svg';
import Label from './Label';
import ErrorWrapper from './ErrorWrapper';
import Input from '../../../../Input';

const InputWrapper = styled.div`
  align-items: center;
  width: calc(100% + ${props => (props.canClear ? 21 : 0)}px);

  button {
    margin: 0 5px;
    background: none;
    border: none;
    padding: 0;
  }
`;

const StyledLabel = styled(Label)`
  position: relative;

  input {
    width: 100%;
    height: 34px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 4px;
    transition: background 200ms ease-in;

    &:active,
    &:focus {
      box-shadow: 0px 0px 4px #007bff;
      border: none;
      outline: none;
    }

    &.is-invalid {
      border-color: #dc3545;

      &:focus {
        box-shadow: 0px 0px 4px #dc3545;
      }
    }

    &.is-dirty {
      background: #fdfce5;
    }
  }

  .error-message {
    bottom: -16px;
  }
`;

const LabelInput = ({
  label,
  name,
  type,
  onChange,
  value,
  placeHolder,
  className,
  disabled,
  readOnly,
  error,
  helper,
  controlled,
  hasClear,
  checked
}) => {
  const InputComponent = (
    <Input
      name={name}
      type={type}
      onChange={onChange}
      value={value}
      placeholder={placeHolder}
      disabled={disabled}
      className="form-control"
      controlled={controlled}
      checked={checked}
    />
  );

  if (readOnly) {
    return (
      <StyledLabel className={className}>
        {label}
        <span className="readonly-value">{value}</span>
      </StyledLabel>
    );
  }

  const canClear = hasClear && value;

  return (
    <StyledLabel className={className}>
      {label}
      <InputWrapper className="flex-between" canClear={canClear}>
        {InputComponent}
        {canClear && (
          <button
            type="button"
            onClick={e => {
              // e.stopPropagation();
              e.preventDefault();
              onChange({ target: { name, value: '' } });
            }}
          >
            <img src={DeleteIcon} />
          </button>
        )}
      </InputWrapper>

      {error && <ErrorWrapper className="error-message">{error}</ErrorWrapper>}
      {helper}
    </StyledLabel>
  );
};

export default LabelInput;
