import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from 'brickyard-ui';
import LeftMenu from '../LeftMenu';
import { PowerBIEmbed } from 'powerbi-client-react';
import styled from 'styled-components';
import { selectPowerBIPageState, usePowerBIPageSlice } from './slice';

const StyledPowerBIPage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .main-container {
    display: flex;
    width: 100%;
    height: 100%;

    .spinner-border {
      margin: auto;
    }

    .powerbi-container {
      width: 100%;
      height: 100%;

      iframe {
        border: none;
      }
    }
  }
`;

const PowerBIPage = ({ pageName, showNavigation = true }) => {
  const { actions } = usePowerBIPageSlice();
  const powerBIPageState = useSelector(selectPowerBIPageState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchReport(pageName));

    return () => dispatch(actions.clearData());
  }, [pageName]);

  const getPageContent = () => {
    if (powerBIPageState.loading) return <Loading variant="by-primary" />;
    if (powerBIPageState.error) return <p>{powerBIPageState.error.toString()}</p>;
    return (
      <PowerBIEmbed cssClassName="powerbi-container" embedConfig={powerBIPageState.embedConfig} />
    );
  };
  return (
    <StyledPowerBIPage>
      {showNavigation && <LeftMenu />}
      <div className="main-container">{getPageContent()}</div>
    </StyledPowerBIPage>
  );
};

export default PowerBIPage;
