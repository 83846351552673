import { useQuery } from '@apollo/client/react';
import { GET_VEHICLE_TYPES } from '../SharedQueries';

const useVehicleTypeOptions = (needRdwConst = false) => {
  const { data, loading } = useQuery(GET_VEHICLE_TYPES, {
    variables: { needRdwConst }
  });

  const options = data ? data.vehicleTypes : [];

  return [options, loading];
};

export default useVehicleTypeOptions;
