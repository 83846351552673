import gql from 'graphql-tag';

export const RESET_OBSERVATIONS = gql`
  mutation ResetObservations($ids: [ID!]!) {
    resetObservations(ids: $ids) {
      id
      status
    }
  }
`;
