import {
  ADD_OBSERVATION_RELATION_METADATA,
  ADD_OBSERVATION_ACTION_METADATA,
  LOAD_OBSERVATION_METADATA,
  REMOVE_OBSERVATION_RELATION_METADATA,
  REMOVE_OBSERVATION_METADATA,
  CLEAN_METADATA,
  SET_CURRENT_OBSERVATION,
  SET_PREVIOUS_BOUNDS,
  REMOVE_OBSERVATION_ALL_RELATIONS_METADATA
} from './actionTypes';

export const addObservationRelationMetadata = relation => ({
  type: ADD_OBSERVATION_RELATION_METADATA,
  relation
});

export const addObservationActionMetadata = payload => ({
  type: ADD_OBSERVATION_ACTION_METADATA,
  payload
});

export const loadObservationMetadata = metadataObj => ({
  type: LOAD_OBSERVATION_METADATA,
  metadataObj
});

export const cleanMetadata = () => ({ type: CLEAN_METADATA });

export const removeObservationRelationMetadata = relation => ({
  type: REMOVE_OBSERVATION_RELATION_METADATA,
  relation
});

export const removeObservationAllRelationMetadata = observationId => ({
  type: REMOVE_OBSERVATION_ALL_RELATIONS_METADATA,
  observationId
});

export const removeObservationMetadata = observationId => ({
  type: REMOVE_OBSERVATION_METADATA,
  observationId
});

export const setCurrentObservation = observationId => (dispatch, getState) => {
  const { current } = getState().observations;
  if (current !== observationId) {
    dispatch({
      type: SET_CURRENT_OBSERVATION,
      payload: observationId
    });
  }
};

export const setPreviousBounds = bounds => dispatch => {
  dispatch({
    type: SET_PREVIOUS_BOUNDS,
    payload: bounds
  });
};
