import React from 'react';
import { InfoIcon, FaCrown, FaLink, FaUnlink, FaTrashAlt } from 'brickyard-ui';
import formatDate from '@/utils/formatDate';
import { LongDateFormat } from '@/utils/DateFormats';

const MainIcon = ({ isMain, isDelete, onRelationChange }) => {
  if (!isMain && isDelete) {
    return null;
  }

  return isMain ? (
    <InfoIcon
      Icon={FaCrown}
      text={I18n.t('observations.observations.details.actions.main_evidence')}
      size="md"
      variant="by-primary-active"
    />
  ) : (
    <InfoIcon
      clickable
      Icon={FaCrown}
      text={I18n.t('observations.observations.details.actions.main_evidence')}
      size="md"
      variant="by-primary-inactive"
      onClick={() => onRelationChange('main')}
    />
  );
};

const LeftIcon = ({ isMain, status, onRelationChange }) => {
  if (isMain && status !== 'delete') {
    return null;
  }

  switch (status) {
    case 'delete':
      return (
        <InfoIcon
          key="deleted"
          Icon={FaTrashAlt}
          text={I18n.t('observations.info.deleted')}
          size="sm"
          variant="by-default"
        />
      );
    case 'related':
      return (
        <InfoIcon
          key="related"
          clickable
          Icon={FaLink}
          text={I18n.t('observations.info.related')}
          size="sm"
          variant="by-secondary-active"
          onClick={() => onRelationChange('unrelate')}
        />
      );
    default:
      return (
        <InfoIcon
          key="unrelated"
          clickable
          Icon={FaUnlink}
          text={I18n.t('observations.info.unrelated')}
          size="sm"
          variant="by-secondary-inactive"
          onClick={() => onRelationChange('relate')}
        />
      );
  }
};

const RightIcon = ({ isMain, status, onRelationChange, observation }) => {
  if (status === 'delete') {
    return null;
  }

  // rewrite this when you have more context into more readable format
  if (
    !isMain ||
    (isMain && (observation.status !== 'validated' || observation.validatedForDeletion))
  ) {
    return (
      <InfoIcon
        clickable
        Icon={FaTrashAlt}
        text={I18n.t('observations.info.deleted')}
        size="sm"
        variant="by-default"
        onClick={() => onRelationChange('delete')}
      />
    );
  }

  return null;
};

const RelatedObservationListitem = ({ observation, isMain, status, onClick, onRelationChange }) => {
  return (
    <div onClick={onClick} className="related-observation-listitem">
      <div className="related-observation-listitem-icon">
        {observation.exempt !== true && (
          <MainIcon
            isMain={isMain}
            isDelete={status === 'delete'}
            onRelationChange={onRelationChange}
          />
        )}
      </div>

      <div className="related-observation-listitem-icon text-centered">
        <LeftIcon isMain={isMain} status={status} onRelationChange={onRelationChange} />
      </div>

      <div className="related-observation-listitem-text-long text-centered">
        <span>{formatDate(observation.observedAt, LongDateFormat)}</span>
      </div>

      <div className="related-observation-listitem-text-short text-centered">
        <span>{`${(observation.accuracy * 100).toFixed(0)} %`}</span>
      </div>

      <div className="related-observation-listitem-icon text-right">
        <RightIcon
          isMain={isMain}
          status={status}
          onRelationChange={onRelationChange}
          observation={observation}
        />
      </div>
    </div>
  );
};

export default RelatedObservationListitem;
