import { useQuery } from '@apollo/client';
import React from 'react';
import { Loading } from 'brickyard-ui';

import { GET_ROAD_TYPES } from '@/components/queries/tickets';
import Select from '../components/LabelSelect';

const RoadTypeSelector = ({ name, onChange, value, disabled, readOnly, error }) => {
  const { data, loading } = useQuery(GET_ROAD_TYPES);

  if (loading) {
    return <Loading size="sm" />;
  }

  const roadTypeOptions = data?.roadTypes?.map(type => ({
    id: type.id,
    name: type.name
  }));

  return (
    <Select
      label={I18n.t('activerecord.attributes.ticket.offense_location_road_type')}
      name={name}
      onChange={onChange}
      value={value}
      placeholder=""
      options={roadTypeOptions}
      disabled={disabled}
      readOnly={readOnly}
      error={error}
    />
  );
};

export default RoadTypeSelector;
