import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Marker, MarkerClusterer } from '@react-google-maps/api';

import Map from '@shared/maps/Map';
import CameraOn from '@/assets/img/camera_on.svg';
import CameraInactive from '@/assets/img/camera_inactive.svg';
import CameraActive from '@/assets/img/camera_active.svg';

import { GET_OBSERVATIONS_FOR_MAP } from '@/components/queries/cop/use_case_results';

import { useSelector } from 'react-redux';
import CameraIconDirection from '../../utils/CameraIconDirection';

const UseCaseResultsMap = ({ center, onLoad, sortBy, onObsClick, useCase, selectedLocation }) => {
  const [map, setMap] = useState(null);
  const previousBounds = useSelector(state => state.observations.previousBounds ?? null);

  const filters = useSelector(state => state.observations.filters);
  const activeFilters = () => {
    return (
      !filters ||
      Object.keys(filters).reduce(
        (removed, key) => (filters[key] ? { ...removed, [key]: filters[key] } : removed),
        {}
      )
    );
  };

  const { loading, data, error } = useQuery(GET_OBSERVATIONS_FOR_MAP, {
    variables: {
      first: 1000,
      search: {
        ...activeFilters(),
        useCaseId: useCase.id,
        s: sortBy
      }
    }
  });

  if (error) {
    console.error(error);
    return null;
  }

  const onClick = edge => {
    const index = data.useCaseResults.edges.findIndex(e => e.node.id === edge.node.id);
    const previousCursor = index <= 0 ? null : data.useCaseResults.edges[index - 1].cursor;
    const clickedId = data.useCaseResults.edges[index].node.id;

    onObsClick(clickedId, previousCursor, map.getBounds(), map.zoom);
  };

  const edges = loading
    ? []
    : data.useCaseResults.edges.filter(
        edge => !!edge.node.vehicleLatitude && !!edge.node.vehicleLongitude
      );

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      edges.forEach(edge => {
        bounds.extend(
          new window.google.maps.LatLng(edge.node.vehicleLatitude, edge.node.vehicleLongitude)
        );
      });

      if (previousBounds) {
        map.fitBounds(previousBounds?.bounds);
        map.setZoom(previousBounds?.zoom);
      } else {
        !bounds.isEmpty() && map.fitBounds(bounds);
      }
    }
  }, [map, edges, previousBounds]);

  return (
    <Map
      center={center}
      onLoad={map => {
        setMap(map);
        onLoad(map);
      }}
      zoomLevel={13}
    >
      <MarkerClusterer zoomOnClick minimumClusterSize={2} maxZoom={16} clusterClass="cluster">
        {clusterer =>
          edges.map(edge => (
            <Marker
              key={edge.node.id}
              position={{ lat: edge.node.vehicleLatitude, lng: edge.node.vehicleLongitude }}
              t
              onClick={() => onClick(edge)}
              clusterer={clusterer}
              icon={CameraOn}
            />
          ))
        }
      </MarkerClusterer>
      <MarkerClusterer zoomOnClick minimumClusterSize={2} maxZoom={16} clusterClass="cluster">
        {clusterer =>
          useCase.cameras
            .filter(cam => (selectedLocation ? cam.location == selectedLocation.id : true))
            .map(
              cam =>
                cam.latitude &&
                cam.longitude && (
                  <Marker
                    key={cam.id}
                    position={{ lat: cam.latitude, lng: cam.longitude }}
                    t
                    onClick={() => onClick(cam)}
                    clusterer={clusterer}
                    icon={
                      cam.active
                        ? cam.rotation || cam.rotation === 0
                          ? CameraIconDirection(cam.rotation)
                          : CameraActive
                        : CameraInactive
                    }
                  />
                )
            )
        }
      </MarkerClusterer>
    </Map>
  );
};

export default UseCaseResultsMap;
