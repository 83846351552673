import styled from 'styled-components';

const Label = styled.label`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #373a3c;
  margin: 6px 0;

  .readonly-value {
    border-radius: 4px;
    border-top: 1px solid #ccc;
    color: #212529;
    display: block;
    font-size: 14px;
    font-weight: 300;
    min-height: 34px;
    padding: 6px 1px 5px 2px;
  }

  &.ticket_statement_remark_officer {
    .readonly-value {
      white-space: pre-wrap;
    }
  }
`;

export default Label;
