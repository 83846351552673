import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';

export const GMAP_API_KEY = 'AIzaSyANqJzf7RNvDVnorAKScfdDQwOaORrfpC8';

const Map = ({ children, center, mapContainerStyle, onLoad, zoomLevel = 8, ...props }) => {
  const [parsedCenter, setParsedCenter] = useState();

  useEffect(() => {
    if (typeof center === 'object') {
      setParsedCenter(center);
    }
    if (!center) {
      setParsedCenter({ lat: 0, lng: 0 });
    }
    if (typeof center === 'string' && !!window.google) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { address: center, componentRestrictions: { country: center } },
        (res, status) => {
          if (status === 'OK') {
            setParsedCenter(res[0].geometry.location);
          }
        }
      );
    }
  }, [center, window.google]);

  return (
    <LoadScriptNext id="script-loader" googleMapsApiKey={GMAP_API_KEY}>
      <GoogleMap
        id="google-map"
        zoom={zoomLevel}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false
        }}
        center={parsedCenter}
        mapContainerStyle={mapContainerStyle}
        onLoad={onLoad}
        {...props}
      >
        {children}
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default Map;
