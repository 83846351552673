import styled from 'styled-components';

const ErrorWrapper = styled.span`
  width: max-content;
  font-size: 10px;
  position: absolute;
  left: 0;
  color: #dc3545;
`;

export default ErrorWrapper;
