import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { Loading, Form as BYForm } from 'brickyard-ui';

export const GET_REASONS = gql`
  query GetReasons {
    cameraReasons(limit: 100) {
      id
      description
      type
      code
    }
  }
`;

const DeleteReasonsSelect = ({ onChange, value, name, isValid, size }) => {
  const { loading, data, error } = useQuery(GET_REASONS);

  if (loading) return <Loading size={size} variant="by-dark" />;
  if (error) return <span>{error.toString()}</span>;

  return (
    <BYForm.Control
      size={size}
      value={value}
      isValid={isValid}
      as="select"
      name={name}
      onChange={onChange}
    >
      <option value="" />
      {data.cameraReasons.map(reason => (
        <option value={reason.id} key={reason.id}>
          {reason.description}
        </option>
      ))}
    </BYForm.Control>
  );
};

export default DeleteReasonsSelect;
