import React from 'react';
import { InfoIcon, FaCrown, FaLink, FaUnlink, FaTrashAlt } from 'brickyard-ui';
import formatDate from '@/utils/formatDate';
import { LongDateFormat } from '@/utils/DateFormats';

const _ = require('lodash');
const MainIcon = ({ isMain, isDelete, onRelationChange, canUseCaseResultBeEdited }) => {
  if (!isMain && isDelete) {
    return null;
  }

  return isMain ? (
    <InfoIcon
      Icon={FaCrown}
      text={I18n.t('observations.observations.details.actions.main_evidence')}
      size="md"
      variant="by-primary-active"
    />
  ) : (
    <InfoIcon
      clickable={canUseCaseResultBeEdited}
      Icon={FaCrown}
      text={I18n.t('observations.observations.details.actions.main_evidence')}
      size="md"
      variant="by-primary-inactive"
      onClick={canUseCaseResultBeEdited ? () => onRelationChange('main') : () => {}}
    />
  );
};

const LeftIcon = ({ isMain, status, canUseCaseResultBeEdited, onRelationChange }) => {
  if (isMain && status !== 'delete') {
    return null;
  }

  switch (status) {
    case 'delete':
      return (
        <InfoIcon
          key="deleted"
          Icon={FaTrashAlt}
          text={I18n.t('observations.info.deleted')}
          size="sm"
          variant="by-default"
        />
      );
    case 'related':
      return (
        <InfoIcon
          key="related"
          clickable={canUseCaseResultBeEdited}
          Icon={FaLink}
          text={I18n.t('observations.info.related')}
          size="sm"
          variant="by-secondary-active"
          onClick={canUseCaseResultBeEdited ? () => onRelationChange('unrelate') : () => {}}
        />
      );
    default:
      return (
        <InfoIcon
          key="unrelated"
          clickable={canUseCaseResultBeEdited}
          Icon={FaUnlink}
          text={I18n.t('observations.info.unrelated')}
          size="sm"
          variant="by-secondary-inactive"
          onClick={canUseCaseResultBeEdited ? () => onRelationChange('relate') : () => {}}
        />
      );
  }
};

const RightIcon = ({
  isMain,
  status,
  canUseCaseResultBeEdited,
  onRelationChange,
  useCaseResult
}) => {
  if (status === 'delete' || !canUseCaseResultBeEdited) {
    return null;
  }

  // rewrite this when you have more context into more readable format
  if (
    !isMain ||
    (isMain && (useCaseResult.status !== 'validated' || useCaseResult.validatedForDeletion))
  ) {
    return (
      <InfoIcon
        clickable={canUseCaseResultBeEdited}
        Icon={FaTrashAlt}
        text={I18n.t('observations.info.deleted')}
        size="sm"
        variant="by-default"
        onClick={
          canUseCaseResultBeEdited
            ? e => {
                e.stopPropagation();
                onRelationChange('delete');
              }
            : () => {}
        }
      />
    );
  }

  return null;
};

const RelatedUseCaseResultListItem = ({
  useCaseResult,
  isMain,
  status,
  canUseCaseResultBeEdited,
  onClick,
  onRelationChange
}) => {
  let cameraName = _.truncate(useCaseResult.camera.name, { length: 20, omission: '...' });
  return (
    <div onClick={onClick} className="related-observation-listitem">
      <div className="related-observation-listitem-icon">
        {useCaseResult.exempt !== true && (
          <MainIcon
            isMain={isMain}
            isDelete={status === 'delete'}
            onRelationChange={onRelationChange}
            canUseCaseResultBeEdited={canUseCaseResultBeEdited}
          />
        )}
      </div>

      <div className="related-observation-listitem-icon text-centered">
        <LeftIcon
          isMain={isMain}
          status={status}
          canUseCaseResultBeEdited={canUseCaseResultBeEdited}
          onRelationChange={onRelationChange}
        />
      </div>

      <div className="related-observation-listitem-text-long text-centered">
        <span>{formatDate(useCaseResult.observedAt, LongDateFormat)}</span>
      </div>

      <div className="related-observation-listitem-icon">
        {useCaseResult.useCase?.imageUrl != null ? (
          <div className="use-case-img-container">
            <img src={useCaseResult.useCase?.imageUrl} className="use-case-img" />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="related-observation-listitem-text-long text-centered">
        <span>{`${cameraName}`}</span>
      </div>

      <div className="related-observation-listitem-icon text-right">
        {(useCaseResult.exempt !== true ||
          (useCaseResult.exempt == true && useCaseResult.evidence == true)) && (
          <RightIcon
            isMain={isMain}
            status={status}
            canUseCaseResultBeEdited={canUseCaseResultBeEdited}
            onRelationChange={onRelationChange}
            useCaseResult={useCaseResult}
          />
        )}
      </div>
    </div>
  );
};

export default RelatedUseCaseResultListItem;
