import React from 'react';

import {
  Card,
  Dropdown,
  FaMapMarkerAlt,
  InfoLabel,
  FaGlobeEurope,
  Button,
  FaCircle
} from 'brickyard-ui';

import CCTV from '@/assets/img/cctv.svg';
import use_case from '@/assets/img/use_case.svg';
import suspect_list from '@/assets/img/suspect_list.svg';
import 'styles/scenes/observations.scss';

const ObservationsMapControl = ({
  selectedUseCase,
  selectedCam,
  selectedLocation,
  onUseCaseChange,
  onCamChange,
  onLocationChange,
  useCases,
  toggleMap,
  showMap,
  user,
  suspectLists,
  selectedSuspectList,
  onSuspectListChange
}) => {
  const _ = require('lodash');

  return (
    <Card className="observations-map-control">
      <div className="left">
        {_.size(suspectLists) > 0 && (
          <Dropdown>
            <Dropdown.Toggle as="span">
              <img alt="Suspect list" src={suspect_list} />
              <InfoLabel
                title={I18n.t('suspect_list.list')}
                text={
                  selectedSuspectList
                    ? selectedSuspectList.name
                    : I18n.t('observations.areas.index.select_list')
                }
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="maximum-sized-dropdown">
              {selectedSuspectList && (
                <Dropdown.Item onClick={() => onSuspectListChange(null)}>
                  {I18n.t('observations.areas.index.select_list')}
                </Dropdown.Item>
              )}
              {suspectLists.map(u => (
                <Dropdown.Item key={u.id} onClick={() => onSuspectListChange(u)}>
                  {u.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Dropdown>
          <Dropdown.Toggle as="span">
            <img alt="Use Case" src={use_case} />
            <InfoLabel
              title={I18n.t('suspect_list.use_case')}
              text={
                selectedUseCase && !selectedSuspectList
                  ? selectedUseCase.name
                  : I18n.t('observations.areas.index.select_use_case')
              }
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="maximum-sized-dropdown">
            {useCases
              .filter(u =>
                selectedSuspectList
                  ? _.find(selectedSuspectList.useCases, function(s) {
                      return s.id == u.id;
                    })
                  : true
              )
              .map(u => (
                <Dropdown.Item key={u.id} onClick={() => onUseCaseChange(u)}>
                  {u.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle as="span">
            <FaMapMarkerAlt color="#FF6F00" size={20} />
            <InfoLabel
              title={I18n.t('scancar.observations.index.location')}
              text={
                selectedLocation
                  ? selectedLocation.name
                  : I18n.t('observations.areas.index.select_location')
              }
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="maximum-sized-dropdown">
            {selectedUseCase.locations.map(location => (
              <Dropdown.Item key={location.id} onClick={() => onLocationChange(location)}>
                {location.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle as="span">
            <img alt="CCTV Camera" src={CCTV} />
            <InfoLabel
              title={I18n.t('scancar.observations.index.source')}
              text={
                selectedCam ? selectedCam.name : I18n.t('observations.areas.index.select_source')
              }
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="maximum-sized-dropdown">
            {selectedUseCase.cameras
              .filter(cam => (selectedLocation ? cam.location == selectedLocation.id : true))
              .map(cam => (
                <Dropdown.Item key={cam.id} onClick={() => onCamChange(cam)}>
                  <FaCircle color={cam.active ? '#28A745' : '#979797'} size={16} /> {cam.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="right">
        {!!selectedUseCase && user.whitelist_button_visible && (
          <a
            className="whitelist"
            // TODO: remove new/old ui related stuff later
            // TODO: COP check controller accept use_case_id
            href={`/observations/administration/observations_whitelists?temp_ui=old&use_case_id=${selectedUseCase.id}&from_new_ui=true`}
          >
            <Button type="button" variant="outline-by-primary" size="sm">
              {I18n.t('observations.areas.index.manage_whitelist')}
            </Button>
          </a>
        )}
        <FaGlobeEurope color={showMap ? '#FF5F00' : '#D6D7D8'} onClick={toggleMap} />
      </div>
    </Card>
  );
};

export default ObservationsMapControl;
