import React from 'react';
import { Button } from 'brickyard-ui';
import formatDate from '@/utils/formatDate';
import { ShortDateFormatNoTime } from '@/utils/DateFormats';
import { LABEL_BY_TYPE } from '../../utils/consts';

const RepeatOffenderButton = ({
  type,
  times,
  lastDatetime,
  licensePlate,
  offenseCode,
  useCaseResultId
}) => {
  const queryParams = [
    `useCaseResultIdEq=${useCaseResultId}`,
    `isWarningEq=${type === 'warning'}`,
    `offenseCodeCont=${offenseCode}`,
    `vehicleLicensePlateNumberInsensitiveEqInsensitive=${licensePlate}`,
    `observationIdEq`,
    `temporaryFilters`
  ];

  return (
    <a
      className="repeat-offender-button"
      target="_blank"
      rel="noopener noreferrer"
      href={`/tickets?${queryParams.join('&')}`}
    >
      <Button variant={type === 'ticket' ? 'danger' : 'warning'} size="md-text-xs">
        {LABEL_BY_TYPE[type]} x {times}
        <br />
        <b>{formatDate(lastDatetime, ShortDateFormatNoTime)}</b>
      </Button>
    </a>
  );
};

RepeatOffenderButton.defaultProps = {
  type: 'ticket',
  times: 0,
  lastDatetime: null
};

export default RepeatOffenderButton;
