import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'brickyard-ui';
import { FaQuestionCircle } from 'react-icons/fa';

import '@/styles/shared/tickets.scss';

const NewTicketModal = ({ show, onHide }) => {
  const history = useHistory();
  return (
    <Modal show={show} centered onHide={onHide} className="by-message-popup" size="md">
      <Modal.Body>
        <div className="message-icon-container message-popup-confirmation">
          <i>
            <FaQuestionCircle />
          </i>

          <p>{I18n.t('tickets.dialogs.new_ticket.description')}</p>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-between">
        <div>
          <Button variant="by-dark" onClick={onHide}>
            {I18n.t('tickets.cancel')}
          </Button>
        </div>
        <div className="flex-end">
          <Button variant="by-primary" onClick={() => history.push('/tickets/new?type=fiscal')}>
            {I18n.t('tickets.fiscal')}
          </Button>
          <Button variant="by-primary" onClick={() => history.push('/tickets/new?type=juridical')}>
            {I18n.t('tickets.juridical')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NewTicketModal;
