import React, { useState } from 'react';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';

import ZoomCarouselModal from '../ZoomCarouselModal';
import ObservationPanoramaImages from './ObservationPanoramaImages';
import { GMAP_API_KEY } from '../maps/Map';

const IMG_TYPE_PLATE = 'plate';

const ObservationImages = ({ observation, type }) => {
  observation.files.forEach((img, i) => {
    if (img.kind === IMG_TYPE_PLATE) {
      observation.files.splice(i, 1);
      observation.files.unshift(img);
    }
  });

  const images = observation.files.map(file => file.url).filter(Boolean);
  const panoramaImages = observation.panoramaFiles.map(file => file.url).filter(Boolean);
  const [selected, setSelected] = useState(null);

  const orderedImages = () => {
    return [images[3], images[0], images[1], images[2]].concat(images.slice(4)).filter(Boolean);
  };

  const renderTiles = () => {
    let tiles = orderedImages()
      .slice(0, type === 'scancar' ? 3 : 4)
      .map((image, index) => (
        <div
          className="observation-image"
          style={{
            backgroundImage: `url(${image})`,
            width: type === 'scancar' ? '50%' : images.length + index < 4 ? '100%' : '50%'
          }}
          onClick={() => setSelected(index)}
          key={image}
        />
      ));
    if (type === 'scancar') {
      const position = { lat: observation.vehicleLatitude, lng: observation.vehicleLongitude };
      tiles = [
        <LoadScriptNext googleMapsApiKey={GMAP_API_KEY} key="scancar-map-tile">
          <GoogleMap
            center={position}
            zoom={18}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: true
            }}
            mapContainerStyle={{ width: '50%' }}
          >
            <Marker position={position} t />
          </GoogleMap>
        </LoadScriptNext>,
        ...tiles
      ];
    }
    return tiles;
  };

  return images.length ? (
    <>
      <div
        className="observation-images"
        style={{
          height: type === 'scancar' && panoramaImages.length ? 'calc(100% - 100px)' : '100%'
        }}
      >
        {renderTiles()}
      </div>
      {type === 'scancar' && panoramaImages.length ? (
        <ObservationPanoramaImages images={panoramaImages} />
      ) : null}
      <ZoomCarouselModal
        images={orderedImages()}
        selected={selected}
        show={selected !== null}
        onHide={() => setSelected(null)}
      />
    </>
  ) : null;
};

export default ObservationImages;
