import * as Yup from 'yup';

const useCaseSchema = Yup.object({
  name: Yup.string().required(I18n.t('settings.field_name_required')),
  uuid: Yup.string(),
  ttlInHours: Yup.number()
    .max(31 * 24, I18n.t('settings.field_ttl_max'))
    .required(I18n.t('settings.field_ttl_required')),
  ttlForExemptInHours: Yup.number()
    .min(1, I18n.t('settings.field_ttl_min'))
    .required(I18n.t('settings.field_ttl_exempt_required'))
    .when('ttlInHours', (ttlInHours, schema) => {
      return schema.test('ttlForExemptInHours', I18n.t('settings.field_ttl_exempt_min'), function(
        ttlForExemptInHours
      ) {
        return ttlForExemptInHours <= ttlInHours;
      });
    }),
  ttlForUndoInHours: Yup.number()
    .min(1, I18n.t('settings.field_ttl_undo_in_hours_min'))
    .max(31 * 24, I18n.t('settings.field_ttl_undo_in_hours_max'))
    .nullable(),
  dismissalReasonRequired: Yup.boolean().nullable(),
  defaultOffense: Yup.boolean().nullable(),
  offenseCode: Yup.string().when('defaultOffense', {
    is: true,
    then: Yup.string().required(I18n.t('settings.field_offense_code_required')),
    otherwise: Yup.string().nullable()
  }),
  defaultStatement: Yup.string().when('defaultOffense', {
    is: true,
    then: Yup.string().required(I18n.t('settings.field_default_statement_required')),
    otherwise: Yup.string().nullable()
  })
});

const pipelineSchema = Yup.object({
  name: Yup.string().required(I18n.t('settings.pipelines.modal.form.validation.name')),
  scheduleType: Yup.string().required(
    I18n.t('settings.pipelines.modal.form.validation.schedule_type')
  ),
  specifiedTime: Yup.string().when('scheduleType', {
    is: scheduleType => scheduleType === 'at_specified_time',
    then: Yup.string()
      .required(I18n.t('settings.pipelines.modal.form.validation.specified_time'))
      .typeError(I18n.t('settings.pipelines.modal.form.validation.specified_time')),
    otherwise: Yup.string().nullable()
  }),
  minutesAfterObservation: Yup.string().when('scheduleType', {
    is: scheduleType => scheduleType === 'minutes_after_observation',
    then: Yup.string()
      .required(I18n.t('settings.pipelines.modal.form.validation.minutes_after_observation'))
      .typeError(I18n.t('settings.pipelines.modal.form.validation.minutes_after_observation')),
    otherwise: Yup.string().nullable()
  })
});

const shapeFileSchema = Yup.object({
  versionName: Yup.string().required('Version name is required'),
  file: Yup.mixed().required('A file is required')
});

export { useCaseSchema, pipelineSchema, shapeFileSchema };
