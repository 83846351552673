import React from 'react';
import { Card } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';

/**
 * This component is a react bootstrap Card component that is wrapped by Draggable from react-beautiful-dnd, should be used as a child for DropContainer.
 *
 * @param {String} draggableId - Unique ID used for sorting.
 * @param {Number} index - This is handled by the DropContainer component.
 * @return {React.Component} DraggableCard - react bootstrap card that can be dragged in a DropContainer.
 */
const DraggableCard = ({ draggableId, index, children, spacing, isDragDisabled = false }) => {
  return (
    <Draggable draggableId={`${draggableId}`} index={index} isDragDisabled={isDragDisabled}>
      {provided => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            margin: spacing
          }}
        >
          {children}
        </Card>
      )}
    </Draggable>
  );
};

export default DraggableCard;
