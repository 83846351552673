import styled from 'styled-components';

export default styled.span`
  min-width: auto;
  height: 17px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  padding: 0 4px;

  background: rgba(${props => props.secondaryColor || props.color}, 0.26);
  color: rgb(${props => props.color});
  border-radius: 2px;
`;
