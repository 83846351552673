import { useMutation, gql } from '@apollo/client';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, SortTh, FaExclamationTriangle, Badge } from 'brickyard-ui';
import classNames from 'classnames';

import TestBoundary from '@/utils/TestBoundary';
import ResetObservation from '@shared/observations/ResetObservation';
import ExemptedBadge from '@shared/observations/ExemptedBadge';
import { setCurrentObservation } from '../../actions/observationActions';

import 'styles/scenes/observations.scss';
import formatDate from '@/utils/formatDate';
import { LongDateFormat } from '@/utils/DateFormats';

const getStatusLabel = action => {
  switch (action) {
    case 'delete':
      return I18n.t('observations.observations.details.actions.delete');
    case 'ticket':
      return I18n.t('observations.observations.details.to_ticket');
    case 'warning':
      return I18n.t('observations.observations.details.to_warning');
  }
  return I18n.t('observations.observations.details.unassigned');
};

const format = (field, key) => {
  if (key === 'observedAt') {
    return formatDate(field, LongDateFormat);
  }
  if (key === 'accuracy') {
    return `${parseInt(field, 10).toFixed(0)} %`;
  }
  return field;
};

export const RECORD_VIEW = gql`
  mutation RecordObservationView($id: ID!) {
    recordObservationView(id: $id) {
      id
    }
  }
`;

const ObservationsTable = ({ observations, onReset, sorting, onSort, type }) => {
  const dispatch = useDispatch();
  const currentObservationId = useSelector(state => state.observations.current);

  const [recordView] = useMutation(RECORD_VIEW);
  const user = useSelector(state => state.user);
  const header = [
    {
      label: I18n.t('observations.observations.list.datetime'),
      key: 'observedAt',
      width: '16%',
      sortable: true
    },
    {
      label: I18n.t(`observations.observations.list.${type === 'scancar' ? 'vehicle' : 'camera'}`),
      key: 'camera',
      width: '18%',
      sortable: false
    },
    {
      label: I18n.t('observations.observations.list.license_plate'),
      key: 'vehicleLicensePlateNumber',
      width: '16%',
      sortable: true
    },
    {
      label: I18n.t('observations.observations.list.country'),
      key: 'countryCode',
      width: '12%',
      sortable: true
    },
    {
      label: I18n.t('observations.observations.list.accuracy_label'),
      key: 'accuracy',
      width: '14%',
      sortable: true
    }
  ];

  useEffect(() => {
    const openedIdx = observations.findIndex(obs => obs.id === currentObservationId);
    if (openedIdx === -1) {
      dispatch(setCurrentObservation(''));
    } else {
      const next = observations.slice(openedIdx).find(obs => !obs.action && !obs.validatedBy);
      dispatch(setCurrentObservation(next ? next.id : ''));
    }
  }, [observations]);

  const validateAssigned = observation => {
    const validateObj =
      (type === 'scancar' ? observation.assignedTo : observation.validatedBy) ||
      observation.validatedForDeletionBy;
    if (!validateObj) return false;
    return !(parseInt(validateObj.id, 10) === user.id);
  };

  const assignedTo = observation => {
    if (type === 'scancar' && observation.assignedTo) return observation.assignedTo.name;
    if (type !== 'scancar' && observation.validatedBy) return observation.validatedBy.name;
    if (observation.validatedForDeletionBy) return observation.validatedForDeletionBy.name;
    return 'Unassigned';
  };

  const getSort = key => {
    if (sorting.key === key) {
      return sorting.order === 'asc' ? 'desc' : 'asc';
    }
    return 'asc';
  };

  return (
    <Table size="sm" className="observation-table">
      <thead>
        <tr>
          <th width="2%"></th>
          <th width="9%" />
          {header.map(h =>
            h.sortable ? (
              <SortTh
                key={h.key}
                onSort={sort => onSort(h.key, sort)}
                sortState={sorting.key === h.key && sorting.order}
                width={h.width || 'auto'}
              >
                <span className="clickable" onClick={() => onSort(h.key, getSort(h.key))}>
                  {h.label}
                </span>
              </SortTh>
            ) : (
              <th key={h.key} width={h.width || 'auto'}>
                {h.label}
              </th>
            )
          )}
          <th width="11%">Status</th>
          <th width="2%"></th>
        </tr>
      </thead>

      <tbody>
        {observations.map(obs => (
          <React.Fragment key={obs.id}>
            <tr
              className={classNames({
                [`obs-row-${obs.action}`]: obs.action,
                'obs-row-assigned': validateAssigned(obs),
                'obs-row-selected': currentObservationId === obs.id
              })}
              onClick={() => {
                if (!obs.validatedForDeletion) {
                  recordView({ variables: { id: obs.id } });
                  dispatch(setCurrentObservation(obs.id));
                }
              }}
            >
              <td>
                {validateAssigned(obs) && (
                  <i className="assigned-icon">
                    <FaExclamationTriangle size={16} />
                  </i>
                )}
              </td>
              <td>
                {obs.exempt && (
                  <TestBoundary>
                    <ExemptedBadge
                      componentIdentifier="exempted-badge"
                      observationId={obs.id}
                      areaId={obs.area.id}
                    />
                  </TestBoundary>
                )}
                {obs.delayed && (
                  <Badge variant="warning">
                    {I18n.t('observations.observations.details.delayed_badge')}
                  </Badge>
                )}
              </td>

              {header.map(({ key }) => (
                <td key={key}>{format(obs[key], key)}</td>
              ))}
              <td className={`obs-status-${obs.action}`}>
                {validateAssigned(obs)
                  ? `${I18n.t('observations.observations.details.assigned_to')} ${assignedTo(obs)}`
                  : getStatusLabel(obs.action)}
              </td>
              <td>
                <TestBoundary>
                  {(obs.action ||
                    (type === 'c01' && obs.validatedBy) ||
                    obs.validatedForDeletion) && (
                    <ResetObservation observationId={obs.id} onReset={onReset} />
                  )}
                </TestBoundary>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default ObservationsTable;
