import { useQuery } from '@apollo/client';
import React from 'react';
import { Loading } from 'brickyard-ui';

import { GET_PARKING_AREAS } from '@/components/queries/tickets';
import Select from '../components/LabelSelect';

const ParkingAreaSelector = ({ name, onChange, value, disabled, readOnly, error }) => {
  const { data, loading } = useQuery(GET_PARKING_AREAS);

  if (loading) {
    return <Loading />;
  }

  const parkingAreaOptions = data?.parkingAreas?.map(({ name }) => ({
    id: name,
    name
  }));

  return (
    <Select
      label={I18n.t('activerecord.models.parking_area.one')}
      name={name}
      onChange={onChange}
      value={value}
      placeholder=""
      options={parkingAreaOptions}
      disabled={disabled}
      readOnly={readOnly}
      error={error}
    />
  );
};

export default ParkingAreaSelector;
