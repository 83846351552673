import React from 'react';
import SideBySideSelector from '../../../components/SideBySideSelector';
import FormFieldBlock from '../../../components/FormFieldBlock';

const UseCaseSelector = ({
  availableUseCases,
  selectedUseCases,
  selectOne,
  deselectOne,
  editEnabled,
  error
}) => {
  return (
    <FormFieldBlock
      label={I18n.t('settings.use_cases_label')}
      className="useCases"
      field={
        <SideBySideSelector
          availableObjects={availableUseCases}
          selectedObjects={selectedUseCases}
          selectOne={selectOne}
          deselectOne={deselectOne}
          disabled={!editEnabled}
        />
      }
      error={error}
    />
  );
};

export default UseCaseSelector;
