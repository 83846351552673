import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import FormField from '../../../components/FormField';
import FormControls from '../../../components/FormControls';
import withApolloProvider from '../../../../../../../utils/withApolloProvider';
import { useFormik } from 'formik';
import { parseGraphQLErrors } from '../../../../../../../utils/errors';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Container, Loading, Alert, TimePicker } from 'brickyard-ui';
import { selectUseCasesState, useUseCasesSlice } from '../slice';
import { pipelineSchema } from '../validationSchema';
import { CREATE_PIPELINE_MUTATION, UPDATE_PIPELINE_MUTATION } from '../mutations';

const UseCasePipelineModal = ({
  useCase,
  showPipelineModal,
  setShowPipelineModal,
  selectedPipelineId
}) => {
  const { actions } = useUseCasesSlice();
  const [submitError, setSubmitError] = useState(false);

  const state = useSelector(selectUseCasesState);
  const dispatch = useDispatch();

  const SCHEDULE_TYPE_ASAP = 'asap';
  const SCHEDULE_TYPE_TIME = 'at_specified_time';
  const SCHEDULE_TYPE_DELAYED = 'minutes_after_observation';

  const scheduleTypes = [
    { id: SCHEDULE_TYPE_ASAP, name: I18n.t('settings.pipelines.modal.schedule_types.asap') },
    {
      id: SCHEDULE_TYPE_TIME,
      name: I18n.t('settings.pipelines.modal.schedule_types.at_specified_time')
    },
    {
      id: SCHEDULE_TYPE_DELAYED,
      name: I18n.t('settings.pipelines.modal.schedule_types.minutes_after_observation')
    }
  ];

  const [createPipeline] = useMutation(CREATE_PIPELINE_MUTATION, {
    onError: errorCreate => {
      setSubmitError(
        formik.dirty && errorCreate.message ? parseGraphQLErrors(errorCreate.message) : false
      );
    },
    onCompleted: () => {
      setSubmitError(false);
      dispatch(actions.fetchUseCase(useCase.id));
      dispatch(actions.storePipeline(null));
    }
  });

  const [updatePipeline] = useMutation(UPDATE_PIPELINE_MUTATION, {
    onError: errorUpdate => {
      setSubmitError(
        formik.dirty && errorUpdate.message ? errorUpdate.message.toString().split(':') : ''
      );
    },
    onCompleted: () => {
      setSubmitError(false);
      dispatch(actions.fetchUseCase(useCase.id));
      dispatch(
        actions.storePipeline(useCase.pipelines.find(p => p.id === state.selectedPipeline?.id))
      );
    }
  });

  const onSave = async values => {
    const params = { ...values, useCaseId: parseInt(useCase.id) };

    if (state.selectedPipeline?.id) {
      await updatePipeline({ variables: params });
    } else {
      await createPipeline({ variables: params });
    }
  };

  const formik = useFormik({
    initialValues: state.selectedPipeline?.id
      ? state.selectedPipeline
      : {
          name: '',
          scheduleType: '',
          specifiedTime: null,
          minutesAfterObservation: null
        },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, [
          'id',
          'name',
          'scheduleType',
          'specifiedTime',
          'minutesAfterObservation',
          'useCaseId'
        ])
      );
    },
    validationSchema: pipelineSchema
  });

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  const onClose = () => {
    if (selectedPipelineId) {
      dispatch(actions.storePipeline(useCase.pipelines.find(p => p.id === selectedPipelineId)));
    }
    setShowPipelineModal(false);
  };

  return (
    <>
      <Modal
        size="md"
        centered
        show={showPipelineModal}
        onHide={() => onClose()}
        className="exemption-type-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {state.selectedPipeline?.id
              ? I18n.t('settings.pipelines.modal.title.edit')
              : I18n.t('settings.pipelines.modal.title.add')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {state.loading ? (
            <div className="loading-box">
              <Loading />
            </div>
          ) : (
            <Container>
              {submitError &&
                submitError.map((error, index) => {
                  return (
                    <Alert variant="danger" key={index}>
                      {error}
                    </Alert>
                  );
                })}
              <FormField
                className="name"
                label={I18n.t('settings.pipelines.modal.label.name')}
                field={
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name ?? ''}
                  />
                }
                error={formik.errors.name}
              />

              {state.selectedPipeline === null && (
                <FormField
                  className="name"
                  label={I18n.t('settings.pipelines.modal.label.schedule_type')}
                  field={
                    <select
                      id="scheduleType"
                      name="scheduleType"
                      onChange={formik.handleChange}
                      value={formik.values.scheduleType}
                    >
                      <option value=""></option>
                      {scheduleTypes.map(scheduleType => (
                        <option key={scheduleType.id} value={scheduleType.id}>
                          {scheduleType.name}
                        </option>
                      ))}
                    </select>
                  }
                  error={formik.errors.scheduleType}
                />
              )}

              {formik.values.scheduleType === SCHEDULE_TYPE_TIME && (
                <FormField
                  className="name time-picker"
                  label={I18n.t('settings.pipelines.modal.label.specified_time')}
                  field={
                    <TimePicker
                      id="specifiedTime"
                      name="specifiedTime"
                      value={formik.values.specifiedTime}
                      onChange={time => formik.setFieldValue('specifiedTime', time)}
                      isInvalid={formik.errors.specifiedTime}
                      feedbackInvalid={formik.errors.specifiedTime}
                    />
                  }
                  error={formik.errors.minutesAfterObservation}
                />
              )}

              {formik.values.scheduleType === SCHEDULE_TYPE_DELAYED && (
                <FormField
                  className="name"
                  label={I18n.t('settings.pipelines.modal.label.minutes_after_observation')}
                  field={
                    <input
                      id="minutesAfterObservation"
                      name="minutesAfterObservation"
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.minutesAfterObservation ?? ''}
                    />
                  }
                  error={formik.errors.minutesAfterObservation}
                />
              )}

              <FormControls
                editEnabled={true}
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            </Container>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withApolloProvider(UseCasePipelineModal);
