import React from 'react';
import styled from 'styled-components';
import FixedCameras from '../index';
import FixedCamerasSubMenu from './FixedCamerasSubMenu';
import FixedCamerasLocations from './FixedCamerasLocations';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

const FixedCamerasDashboardStyledPage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .left-panel {
    display: flex;
    flex-direction: column;
    width: calc(100% * 1 / 6);
    height: 100%;

    .left-panel-header {
      display: flex;
      max-height: 100px;
      flex-direction: row;

      h4 {
        margin: 15px;
        color: #777777;
        font-weight: 600;
      }

      button {
        margin: 15px;
        margin-left: auto;
      }
    }

    .left-panel-body {
      display: flex;
      width: calc(100% - 20px);
      height: calc(100% - 20px);

      .loading-box {
        margin: auto;
      }

      .submenu {
        display: flex;
        flex-direction: column;
        width: 250px;
        height: 100%;

        p {
          margin-left: 15px;
        }
      }
    }
  }

  .right-panel {
    border: 1px solid #d7d7d8;
    display: flex;
    background: #ffffff;
    flex-direction: column;

    width: calc(100% * 2 / 6 - 30px);
    height: calc(100% - 20px);
    margin-top: 20px;

    .right-panel-header {
      display: flex;
      max-height: 100px;
      flex-direction: row;
      margin: 15px;

      h5 {
        color: #000000;
        font-weight: 800;
      }

      .new-type-btn {
        margin-left: auto;
      }
    }

    .right-panel-body {
      display: flex;
      height: calc(100% - 20px);
      margin: 0px 15px 15px 15px;

      .table {
        font-size: 14px;
        .col-id {
          width: 100px;
        }

        .col-global {
          width: 120px;
        }
      }
    }
  }

  .details-panel {
    border: 1px solid #d7d7d8;
    display: flex;
    background: #ffffff;
    flex-direction: column;

    width: calc(100% * 3 / 6 - 20px);
    height: calc(100% - 20px);
    margin-top: 20px;
    margin-left: 20px;

    .details-panel-header {
      display: flex;
      max-height: 100px;
      flex-direction: row;
      margin: 15px;

      h5 {
        color: #000000;
        font-weight: 800;
      }

      .new-type-btn {
        margin-left: auto;
      }
    }

    .details-panel-body {
      display: flex;
      height: calc(100% - 20px);
      margin: 0px 15px 15px 15px;

      .table {
        font-size: 14px;
        .col-id {
          width: 100px;
        }

        .col-global {
          width: 120px;
        }
      }
    }
  }

  .status-cell {
    display: flex;

    .status-label {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      display: inline-block;
      padding: 0px 4px;
      border: 1px solid #ffffff;
      border-radius: 2px;
    }

    .warn-label {
      background: rgba(247, 194, 68, 0.26);
      color: rgb(247, 194, 68);
    }

    .ok-label {
      background: rgba(0, 199, 44, 0.26);
      color: rgb(0, 199, 44);
    }
  }

  .alert {
    position: fixed;
    left: 55%;
    transform: translate(-50%, 0);
    width: 85%;
    margintop: 20px;
    textalign: center;
  }
`;

const FixedCamerasDashboard = () => {
  const match = useRouteMatch();

  return (
    <FixedCamerasDashboardStyledPage>
      <div className="left-panel">
        <div className="left-panel-header">
          <h4>{I18n.t('settings.fixed_cameras_label')}</h4>
        </div>
        <div className="left-panel-body">
          <FixedCamerasSubMenu />
        </div>
      </div>

      <Switch>
        <Route path={`${match.path}/cameras`}>
          <FixedCameras />
        </Route>

        <Route path={`${match.path}/locations`}>
          <FixedCamerasLocations />
        </Route>

        <Route path={`${match.path}`}>
          <Redirect to={`${match.path}/cameras`} />
        </Route>
      </Switch>
    </FixedCamerasDashboardStyledPage>
  );
};

export default FixedCamerasDashboard;
