import React, { useState, useEffect } from 'react';

import AreaOccupancyCardCard from './AreaOccupancyCard';

const OccupancyDashboard = ({ areas }) => {
  const [formattedAreas, setFormattedAreas] = useState([]);
  const [toggleCard, setToggleCard] = useState(null);

  useEffect(() => {
    setFormattedAreas(
      areas.map(a => ({
        id: a.id,
        name: a.name,
        maxVehicleNumber: a.max_vehicle_number,
        currentOccupancy: a.current_occupancy,
        correction: a.reporter?.correction,
        reporterId: a.reporter?.id,
        spdpv2: !!a.spdpv2_uuid,
        lastReportStatus: a.reporter?.last_report_status,
        lastReportAt: a.reporter?.last_report_at ? new Date(a.reporter.last_report_at) : null,
        lastReportErrors: a.reporter?.last_report_errors
      }))
    );
  }, [areas]);

  return (
    <section className="occupancy-dashboard">
      {formattedAreas.map(a => (
        <AreaOccupancyCardCard
          area={a}
          toggleCard={toggleCard}
          setToggleCard={setToggleCard}
          key={a.id}
        />
      ))}
    </section>
  );
};

export default OccupancyDashboard;
