import { either, equals } from 'ramda';
import { boolean, string } from 'yup';

import { requiredMsg } from './CommonTicketSchema';

const offenseValidations = {
  offenseCategory: string().when('type', {
    is: 'recording',
    otherwise: string()
      .ensure()
      .required(requiredMsg)
  }),
  offenseCode: string().when('type', {
    is: 'recording',
    otherwise: string()
      .ensure()
      .required(requiredMsg)
  }),
  offenseIsTowable: boolean().when('type', {
    is: either(equals('juridical'), equals('taxi')),
    then: boolean().required(requiredMsg)
  })
};

export default offenseValidations;
