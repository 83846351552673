import React, { useState, useEffect } from 'react';

import {
  Button,
  FaCaretDown,
  FaCaretRight,
  FaCheck,
  FaTimes,
  FaTrashAlt,
  Loading,
  MessagePopup,
  Table
} from 'brickyard-ui';

import ScheduledOverrideModal from './ScheduledOverrideModal';
import ScheduledOverrideForm from './ScheduledOverrideForm';
import Api from '@/utils/Api';
import formatDate from '@/utils/formatDate';
import parseTime from '@/utils/parseTime';
import { LongDateFormatNoTime } from '@/utils/DateFormats';

const headers = [
  { label: 'Location', field: 'location' },
  { label: 'From', field: 'formattedFrom' },
  { label: 'To', field: 'formattedUntil' },
  { label: 'Repeats', field: 'weeklyIcon' },
  { label: 'From', field: 'formattedFromTime' },
  { label: 'To', field: 'formattedUntilTime' },
  { label: 'Description', field: 'description' }
];

const ScheduledOverrideTable = ({ areas }) => {
  const [loading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [openedSchedule, setOpenedSchedule] = useState(null);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const formatSchedule = schedule => {
    return {
      ...schedule,
      location: areas.find(a => a.reporter.id === schedule.counting_reporter_id)?.name,
      formattedFrom: formatDate(schedule.override_from, LongDateFormatNoTime),
      formattedUntil: formatDate(schedule.override_till, LongDateFormatNoTime),
      formattedFromTime: schedule.is_part_of_day ? parseTime(schedule.override_from_time) : '-',
      formattedUntilTime: schedule.is_part_of_day ? parseTime(schedule.override_till_time) : '-',
      weeklyIcon: schedule.is_weekly ? (
        <FaCheck color="#28A745" size="16" />
      ) : (
        <FaTimes color="#E0E0E0" size="16" />
      )
    };
  };

  const fetchSchedules = async () => {
    try {
      const { data } = await Api.get('/administration/counting/scheduled_overrides');
      setSchedules(data);
      setOpenedSchedule(null);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = schedule => {
    const idx = schedules.findIndex(s => s.id == schedule.id);
    if (idx !== -1) {
      const newSchedules = [...schedules];
      newSchedules[idx] = schedule;
      setSchedules(newSchedules);
    } else {
      setSchedules(schedules.concat(schedule));
    }
  };

  const deleteSchedule = async () => {
    try {
      await Api.delete(`/administration/counting/scheduled_overrides/${confirmDelete}`);
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmDelete(null);
      fetchSchedules();
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="scheduled-override-table-container">
      <MessagePopup
        show={confirmDelete}
        type="confirmation"
        cancelBtn={{ label: I18n.t('actions.cancel'), onClick: () => setConfirmDelete(null) }}
        okBtn={{ label: I18n.t('actions.delete'), onClick: deleteSchedule }}
        title={I18n.t('actions.delete')}
        message={I18n.t('counting_reporters.messages.confirm_delete')}
      />
      <main>
        <Table size="sm">
          <thead>
            <tr>
              <th />
              {headers.map(h => (
                <th key={h.field}>{h.label}</th>
              ))}
              <th />
            </tr>
          </thead>

          <tbody>
            {!!schedules.length &&
              schedules.map(sch => (
                <React.Fragment key={sch.id}>
                  <tr>
                    <td width="5%">
                      <i
                        onClick={() => setOpenedSchedule(sch.id === openedSchedule ? null : sch.id)}
                      >
                        {sch.id === openedSchedule ? <FaCaretDown /> : <FaCaretRight />}
                      </i>
                    </td>
                    {headers.map(h => (
                      <td key={h.field}>{formatSchedule(sch)[h.field]}</td>
                    ))}
                    <td>
                      <i onClick={() => setConfirmDelete(sch.id)}>
                        <FaTrashAlt />
                      </i>
                    </td>
                  </tr>
                  {openedSchedule === sch.id && (
                    <tr>
                      <td colSpan={headers.length + 2}>
                        <ScheduledOverrideForm
                          scheduledOverride={sch}
                          onSave={fetchSchedules}
                          onCancel={() => setOpenedSchedule(null)}
                          locations={areas}
                          inline
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            {!schedules.length && (
              <tr>
                <td colSpan={headers.length + 2}>
                  {I18n.t('counting_reporters.messages.no_schedule_overrides')}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </main>
      <hr className="mt-4" />
      <footer className="schedule-form-btn-holder">
        <Button
          variant="by-primary"
          onClick={() => {
            setOpenedSchedule(null);
            setShowModal(true);
          }}
        >
          {I18n.t('actions.add')}
        </Button>
      </footer>

      <ScheduledOverrideModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSave={handleSave}
        locations={areas}
      />
    </div>
  );
};

export default ScheduledOverrideTable;
