import * as Yup from 'yup';
import {
  CHECKING_TIME,
  MINUTES_AFTER_OBSERVATION,
  OBSERVATION_TIME
} from '../../../../../../../../utils/consts';

const requiredParams = [
  'enabledScanCars',
  'enabledFixedCameras',
  'enabledVehicleTypes',
  'enabledEmissionCodes',
  'enabledCountries',
  'enabledFuelTypes',
  'is_taxi',
  'enabledVehicleBuildYear',
  'enabledVehicleWidth',
  'enabledVehicleWeight',
  'enabledEuropeanCategories',
  'enabledDirections'
];

const offenseDecoratorSchema = Yup.object({
  matching_offense_code: Yup.string().when(requiredParams, {
    is: (...props) => props.includes(true),
    then: Yup.string().required(
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.matching_offense_code_required'
      )
    ),
    otherwise: Yup.string().nullable()
  }),
  matching_offense_statement: Yup.string().when(requiredParams, {
    is: (...props) => props.includes(true),
    then: Yup.string().required(
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.matching_offense_statement_required'
      )
    ),
    otherwise: Yup.string().nullable()
  }),
  selectedFixedCameras: Yup.array().when('enabledFixedCameras', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.fixed_camera_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.fixed_camera_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedMobileCameras: Yup.array().when('enabledScanCars', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.mobile_camera_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.mobile_camera_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedDirections: Yup.array().when('enabledDirections', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.directions_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.directions_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedCountries: Yup.array().when('enabledCountries', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.countries_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.countries_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedVehicleTypes: Yup.array().when('enabledVehicleTypes', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_types_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_types_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedEmissionCodes: Yup.array().when('enabledEmissionCodes', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.emission_codes_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.emission_codes_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedEuropeanCategories: Yup.array().when('enabledEuropeanCategories', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.european_categories_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.european_categories_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedFuelTypes: Yup.array().when('enabledFuelTypes', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.fuel_types_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.fuel_types_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  // vehicle year
  vehicleBuildYear: Yup.boolean().when('enabledVehicleBuildYear', {
    is: true,
    then: Yup.boolean().test(
      'oneOfRequired',
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.vehicle_build_year_required'
      ),
      function() {
        return this.parent.vehicleBuildYearGt || this.parent.vehicleBuildYearLt;
      }
    ),
    otherwise: Yup.boolean().nullable()
  }),
  vehicleBuildYearGt: Yup.number().when('enabledVehicleBuildYear', {
    is: true,
    then: Yup.number()
      .min(
        1900,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_build_year_gt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleBuildYearLt: Yup.number().when('enabledVehicleBuildYear', {
    is: true,
    then: Yup.number()
      .min(
        1900,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_build_year_lt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleWidth: Yup.boolean().when('enabledVehicleWidth', {
    is: true,
    then: Yup.boolean().test(
      'oneOfRequired',
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.vehicle_width_required'
      ),
      function() {
        return this.parent.vehicleWidthGt || this.parent.vehicleWidthLt;
      }
    ),
    otherwise: Yup.boolean().nullable()
  }),
  vehicleWidthGt: Yup.number().when('enabledVehicleWidth', {
    is: true,
    then: Yup.number()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_width_gt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleWidthLt: Yup.number().when('enabledVehicleWidth', {
    is: true,
    then: Yup.number()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_width_lt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleWeight: Yup.boolean().when('enabledVehicleWeight', {
    is: true,
    then: Yup.boolean().test(
      'oneOfRequired',
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.vehicle_weight_required'
      ),
      function() {
        return this.parent.vehicleWeightGt || this.parent.vehicleWeightLt;
      }
    ),
    otherwise: Yup.boolean().nullable()
  }),
  vehicleWeightGt: Yup.number().when('enabledVehicleWeight', {
    is: true,
    then: Yup.number()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_weight_gt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleWeightLt: Yup.number().when('enabledVehicleWeight', {
    is: true,
    then: Yup.number()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_weight_lt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  name: Yup.string().required(
    I18n.t('settings.pipelines.processors.form.validations.name_required')
  )
});

const railwayCheckerSchema = Yup.object({
  pardon_time_30: Yup.number()
    .min(0)
    .max(99.9)
    .required(
      I18n.t(
        'settings.pipelines.processors.form.railway_violation_checker.validations.pardon_time_30_required'
      )
    ),
  pardon_time_50: Yup.number()
    .min(0)
    .max(99.9)
    .required(
      I18n.t(
        'settings.pipelines.processors.form.railway_violation_checker.validations.pardon_time_50_required'
      )
    ),
  pardon_time_60: Yup.number()
    .min(0)
    .max(99.9)
    .required(
      I18n.t(
        'settings.pipelines.processors.form.railway_violation_checker.validations.pardon_time_60_required'
      )
    ),
  pardon_time_70: Yup.number()
    .min(0)
    .max(99.9)
    .required(
      I18n.t(
        'settings.pipelines.processors.form.railway_violation_checker.validations.pardon_time_70_required'
      )
    ),
  pardon_time_80: Yup.number()
    .min(0)
    .max(99.9)
    .required(
      I18n.t(
        'settings.pipelines.processors.form.railway_violation_checker.validations.pardon_time_80_required'
      )
    ),
  moped_on_bike_path: Yup.number()
    .min(0)
    .max(99.9)
    .required(
      I18n.t(
        'settings.pipelines.processors.form.railway_violation_checker.validations.moped_on_bike_path_required'
      )
    ),
  moped_on_main_road: Yup.number()
    .min(0)
    .max(99.9)
    .required(
      I18n.t(
        'settings.pipelines.processors.form.railway_violation_checker.validations.moped_on_main_road_required'
      )
    ),
  name: Yup.string().required(
    I18n.t('settings.pipelines.processors.form.validations.name_required')
  )
});

const objectTypeCheckerSchema = Yup.object({
  object_type_confidence: Yup.number()
    .min(
      1,
      I18n.t(
        'settings.pipelines.processors.form.object_type_checker.validations.object_type_confidence'
      )
    )
    .max(
      1000,
      I18n.t(
        'settings.pipelines.processors.form.object_type_checker.validations.object_type_confidence'
      )
    ),
  selectedObjectTypes: Yup.array()
    .min(
      1,
      I18n.t(
        'settings.pipelines.processors.form.object_type_checker.validations.object_type_required'
      )
    )
    .required(
      I18n.t(
        'settings.pipelines.processors.form.object_type_checker.validations.object_type_required'
      )
    ),
  name: Yup.string().required(
    I18n.t('settings.pipelines.processors.form.validations.name_required')
  )
});

const blacklistCheckerSchema = Yup.object({
  blacklist_id: Yup.number()
    .required(
      I18n.t(
        'settings.pipelines.processors.form.blacklist_checker.validations.blacklist_id_required'
      )
    )
    .min(
      1,
      I18n.t(
        'settings.pipelines.processors.form.blacklist_checker.validations.blacklist_id_required'
      )
    ),
  name: Yup.string().required(
    I18n.t('settings.pipelines.processors.form.validations.name_required')
  )
});

const whitelistCheckerSchema = Yup.object({
  whitelist_id: Yup.number()
    .required(
      I18n.t(
        'settings.pipelines.processors.form.whitelist_checker.validations.whitelist_id_required'
      )
    )
    .min(
      1,
      I18n.t(
        'settings.pipelines.processors.form.whitelist_checker.validations.whitelist_id_required'
      )
    ),
  name: Yup.string().required(
    I18n.t('settings.pipelines.processors.form.validations.name_required')
  )
});

const followUpDecoratorSchema = Yup.object({
  makes_follow_up_required: Yup.string().required(
    I18n.t(
      'settings.pipelines.processors.form.follow_up_decorator.validations.makes_follow_up_required'
    )
  ),
  follow_up_rule: Yup.boolean().test(
    'oneOfRequired',
    I18n.t('settings.pipelines.processors.form.follow_up_decorator.validations.follow_up_rule'),
    function() {
      return (
        this.parent.enabledCountries ||
        this.parent.enabledOffenseCodes ||
        this.parent.enabledOffenseTypes
      );
    }
  ),
  selectedCountries: Yup.array().when('enabledCountries', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.follow_up_decorator.validations.countries_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.follow_up_decorator.validations.countries_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedOffenseTypes: Yup.array().when('enabledOffenseTypes', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.follow_up_decorator.validations.offense_types_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.follow_up_decorator.validations.offense_types_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedOffenseCodes: Yup.array().when('enabledOffenseCodes', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.follow_up_decorator.validations.offense_codes_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.follow_up_decorator.validations.offense_codes_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  name: Yup.string().required(
    I18n.t('settings.pipelines.processors.form.validations.name_required')
  ),
  follow_up_ttl: Yup.number()
    .min(
      0,
      I18n.t(
        'settings.pipelines.processors.form.follow_up_decorator.validations.field_follow_up_ttl_min'
      )
    )
    .max(
      999,
      I18n.t(
        'settings.pipelines.processors.form.follow_up_decorator.validations.field_follow_up_ttl_max'
      )
    )
    .nullable(),
  assigned_ttl: Yup.number()
    .min(
      0,
      I18n.t(
        'settings.pipelines.processors.form.follow_up_decorator.validations.field_assigned_ttl_min'
      )
    )
    .max(
      999,
      I18n.t(
        'settings.pipelines.processors.form.follow_up_decorator.validations.field_assigned_ttl_max'
      )
    )
    .nullable()
});

const emailNotifierSchema = Yup.object({
  recipients: Yup.array()
    .of(
      Yup.string()
        .email()
        .required(
          I18n.t(
            'settings.pipelines.processors.form.email_notifier.validations.email_recipient_required'
          )
        )
        .matches(
          '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
          I18n.t('settings.pipelines.processors.form.email_notifier.validations.email_invalid')
        )
    )
    .min(
      1,
      I18n.t(
        'settings.pipelines.processors.form.email_notifier.validations.email_recipient_required'
      )
    ),
  subject_template: Yup.string().required(
    I18n.t(
      'settings.pipelines.processors.form.email_notifier.validations.subject_template_required'
    )
  ),
  text_template_html: Yup.string().required(
    I18n.t(
      'settings.pipelines.processors.form.email_notifier.validations.text_template_html_required'
    )
  ),
  name: Yup.string().required(
    I18n.t('settings.pipelines.processors.form.validations.name_required')
  )
});

const externalRightCheckerSchema = Yup.object({
  selectedParkingRightProviders: Yup.array()
    .required(I18n.t('settings.field_provider_required'))
    .min(1, I18n.t('settings.field_provider_required')),
  datetime_used_in_query: Yup.string()
    .oneOf(
      [MINUTES_AFTER_OBSERVATION, CHECKING_TIME, OBSERVATION_TIME],
      I18n.t(
        'settings.pipelines.processors.form.external_right_checker.validations.field_datetime_used_in_query_required'
      )
    )
    .required(
      I18n.t(
        'settings.pipelines.processors.form.external_right_checker.validations.field_datetime_used_in_query_required'
      )
    ),
  minutes_delayed_from_observation_time: Yup.number().when('datetime_used_in_query', {
    is: MINUTES_AFTER_OBSERVATION,
    then: Yup.number()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.external_right_checker.validations.field_minutes_delayed_from_observation_time_at_least'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.external_right_checker.validations.field_minutes_delayed_from_observation_time_required'
        )
      ),
    otherwise: Yup.number().nullable()
  }),
  name: Yup.string().required(
    I18n.t('settings.pipelines.processors.form.validations.name_required')
  )
});

export {
  offenseDecoratorSchema,
  railwayCheckerSchema,
  objectTypeCheckerSchema,
  blacklistCheckerSchema,
  whitelistCheckerSchema,
  followUpDecoratorSchema,
  emailNotifierSchema,
  externalRightCheckerSchema
};
