import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectExemptionsListState, useExemptionsListSlice } from './slice';
import { Badge, Button, FaTrashAlt, Loading } from 'brickyard-ui';
import Table from '../../../../components/Table';
import InputConfirmationModal from '../../../../components/InputConfirmationModal';
import formatDate from '../../../../../../../../utils/formatDate';
import { ShortDateFormat } from '../../../../../../../../utils/DateFormats';
import ExemptionModal from '../ExemptionsList/ExemptionModal';

function ExemptionsList({ user }) {
  const { actions } = useExemptionsListSlice();
  const dispatch = useDispatch();
  const state = useSelector(selectExemptionsListState);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onAddExemption = () => {
    dispatch(actions.fetchExemptionTypes());
    dispatch(actions.fetchUseCases());
    dispatch(actions.storeExemption(null));
    setShowModal(true);
  };

  const hasWritePermission = user.cop_settings_permission == 'copSettingsWrite';

  const columns = [
    {
      key: 'exemptedOn',
      header: I18n.t('settings.table_exempted_on_header'),
      sortable: true,
      cell: row => <>{formatDate(row.exemptedOn, ShortDateFormat)}</>
    },
    {
      key: 'licensePlate',
      header: I18n.t('settings.table_license_header'),
      sortable: true
    },
    {
      key: 'scope',
      header: I18n.t('settings.table_scope_header'),
      sortable: true,
      cell: row => (
        <div className="badges-cell">
          {row.scope === true ? (
            <Badge variant="by-primary">{I18n.t('settings.table_global_label')}</Badge>
          ) : (
            <Badge variant="by-secondary">{I18n.t('settings.table_usecase_label')}</Badge>
          )}
        </div>
      )
    },
    {
      key: 'type',
      header: I18n.t('settings.table_type_header'),
      sortable: true
    },
    {
      key: 'useCaseName',
      header: I18n.t('settings.table_use_case_header'),
      sortable: true
    },
    {
      key: 'exemptedByName',
      header: I18n.t('settings.table_exempted_by_header'),
      sortable: true
    },
    {
      key: 'requestedOn',
      header: I18n.t('settings.table_requested_on_header'),
      sortable: true,
      cell: row => <>{formatDate(row.requestedOn, ShortDateFormat)}</>
    },
    {
      key: 'requestedByName',
      header: I18n.t('settings.table_requested_by_header'),
      sortable: true
    },
    {
      key: 'result',
      header: I18n.t('settings.table_result_header'),
      sortable: true,
      cell: row => (
        <div className="badges-cell">
          <Badge variant={`by-${row.result}`}>{I18n.t('settings.result.' + row.result)}</Badge>
        </div>
      )
    },
    {
      key: 'id',
      header: '',
      sortable: false,
      cell: row => (
        <div className="actions-cell">
          {hasWritePermission ? (
            <i>
              <FaTrashAlt size={20} onClick={() => onDeleteExemption(row.id)} />
            </i>
          ) : (
            <i className="disabled">
              <FaTrashAlt size={20} />
            </i>
          )}
        </div>
      )
    }
  ];

  const handleExemptionDelete = () => {
    dispatch(actions.deleteExemption(state.selectedExemption?.id));
    dispatch(actions.storeExemption(null));
    setShowDeleteConfirmation(false);
  };

  const handleCancelDelete = () => {
    dispatch(actions.storeExemption(null));
    setShowDeleteConfirmation(false);
  };

  const onDeleteExemption = exemptionId => {
    // eslint-disable-next-line no-undef
    let exemption = _.find(state.exemptionsList, { id: exemptionId });
    dispatch(actions.storeExemption(exemption));
    setShowDeleteConfirmation(true);
  };

  useEffect(() => {
    dispatch(actions.fetchExemptionsList());

    return () => {
      dispatch(actions.clearData());
    };
  }, [dispatch, actions]);

  return (
    <>
      <div className="right-panel-header">
        <h5>{I18n.t('settings.exemptions_list_page')}</h5>

        {hasWritePermission && (
          <Button
            variant="outline-by-secondary"
            className="new-type-btn"
            onClick={() => onAddExemption()}
          >
            {I18n.t('settings.new_exemption_button')}
          </Button>
        )}
      </div>

      <div className="right-panel-body">
        {state.loading && !state.exemptionsList.length ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <>
            <Table
              columns={columns}
              items={state.exemptionsList}
              selectedId={state.selectedExemption?.id}
              defaultSortBy="id"
            />

            <ExemptionModal showModal={showModal} setShowModal={setShowModal} />

            {showDeleteConfirmation && (
              <InputConfirmationModal
                show={showDeleteConfirmation}
                onHide={() => handleCancelDelete()}
                message={I18n.t('settings.form_exemptions_list_delete_confirm', { word: 'DELETE' })}
                confirmMessage={'DELETE'}
                cancelBtn={{
                  label: I18n.t('settings.form_cancel_button'),
                  onClick: () => handleCancelDelete()
                }}
                okBtn={{
                  label: I18n.t('settings.form_ok_button'),
                  onClick: handleExemptionDelete
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(ExemptionsList);
