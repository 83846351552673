import React, { useEffect, useImperativeHandle } from 'react';
import FormField from '../../../../components/FormField';
import { useFormik } from 'formik';
import MultiRecipientsField from './fields/MultiRecipientsField';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import { emailNotifierSchema } from './validationSchema';
import FormFieldBlock from '../../../../components/FormFieldBlock';

function EmailNotifier({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();

  const formik = useFormik({
    initialValues: selectedProcessor?.id
      ? {
          ...selectedProcessor.config,
          id: selectedProcessor.id,
          name: selectedProcessor.name
        }
      : {
          name: '',
          subject_template: '',
          text_template_html: '',
          recipients: ['']
        },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, ['id', 'name', 'subject_template', 'text_template_html', 'recipients'])
      );
    },
    validationSchema: emailNotifierSchema
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    const params = {
      id: values.id,
      name: values.name,
      config: {
        subject_template: values.subject_template,
        text_template_html: values.text_template_html,
        recipients: values.recipients
      }
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <>
      <FormFieldBlock
        field={
          <>
            <div className="alert alert-primary" role="alert">
              {I18n.t('settings.pipelines.processors.messages.on_error_continue')}
            </div>
          </>
        }
      />

      <FormField
        label={I18n.t('settings.pipelines.processors.form.email_notifier.subject')}
        field={
          <input
            id="subject_template"
            name="subject_template"
            type="text"
            value={formik.values.subject_template ?? ''}
            disabled={!editEnabled}
            onChange={formik.handleChange}
          />
        }
        error={formik.errors.subject_template}
      />

      <FormField
        label={I18n.t('settings.pipelines.processors.form.email_notifier.text')}
        field={
          <input
            id="text_template_html"
            name="text_template_html"
            type="text"
            value={formik.values.text_template_html ?? ''}
            disabled={!editEnabled}
            onChange={formik.handleChange}
          />
        }
        error={formik.errors.text_template_html}
      />

      <FormField
        label={I18n.t('settings.pipelines.processors.form.email_notifier.recipients')}
        field={<MultiRecipientsField disabled={!editEnabled} formik={formik} />}
        error={formik.errors.recipients}
      />
    </>
  );
}

export default EmailNotifier;
