import React, { useContext } from 'react';
import styled from 'styled-components';

export const ColorSchemeContext = React.createContext({
  primaryColor: '#000',
  secondaryColor: '#fff'
});

const ThemedLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background: ${props => props.colorScheme.primaryColor};
`;

export const LineBreak = () => {
  const colorScheme = useContext(ColorSchemeContext);
  return <ThemedLine colorScheme={colorScheme} />;
};

const StyledCard = styled.article`
  padding: 7px 12px;
  background: ${props => props.colorScheme.secondaryColor};
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  h2 {
    font-size: 18px;
    font-weight: 400;
    padding: 0 0 5px;
    border-bottom: 4px solid ${props => props.colorScheme.primaryColor};
  }
`;

const Card = ({
  title = '',
  colorScheme,
  icon = null,
  children,
  className,
  disabled,
  customHeader = null
}) => {
  return (
    <ColorSchemeContext.Provider value={colorScheme}>
      <StyledCard className={className} colorScheme={colorScheme} disabled={disabled}>
        {customHeader || (
          <h2 className="flex-between">
            {title}
            {icon}
          </h2>
        )}
        {children}
      </StyledCard>
    </ColorSchemeContext.Provider>
  );
};

export default Card;
