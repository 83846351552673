import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'brickyard-ui';

import useDebounce from '@/hooks/useDebounce';
import useIsMount from '@/hooks/useIsMount';
import Api from '@/utils/Api';

const StreetSearch = ({ onChange, value, name, city, disabled, readOnly, size, isInvalid }) => {
  const isMount = useIsMount();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const debouncedSearch = useDebounce(search, 400);

  useEffect(() => {
    if (city) {
      const fetchStreets = async () => {
        const { data } = await Api.get(
          `/streets/search.json?q=${debouncedSearch}&ticket_offense_location_city=${city}`
        );
        setList(data.map(s => s.name));
        setLoading(false);
      };

      if (debouncedSearch && !disabled) {
        setLoading(true);
        fetchStreets();
      }
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (!isMount) {
      selectStreet('');
    }
  }, [city]);

  useEffect(() => {
    selectStreet(value);
  }, [value]);

  const selectStreet = value => {
    setSearch(value);
    onChange({ target: { value, name } });
  };

  return (
    <AutoComplete
      inputProps={{
        value: search,
        autoComplete: 'off',
        onChange: evt => {
          if (!evt.target.value) {
            selectStreet('');
          } else {
            setSearch(evt.target.value);
          }
        },
        disabled,
        readOnly,
        size,
        name,
        isInvalid
      }}
      onSelect={selectStreet}
      loading={loading}
      list={list}
    />
  );
};

export default StreetSearch;
