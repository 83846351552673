import styled from 'styled-components';

export default styled.button`
  padding: 0.3em 1em;
  line-height: 1;
  background: transparent;

  border: 1px solid #494949;
  box-sizing: border-box;
  border-radius: 20px;
  user-select: none;
`;
