import { useQuery } from '@apollo/client';
import React from 'react';
import { isEmpty, isNil, either } from 'ramda';
import Radio from '../components/InlineLabelRadio';
import { GET_CREATORS, GET_PARTNERS } from '@/components/queries/tickets';

const CautieRadio = ({ disabled, formik }) => {
  const { data: creatorsData, loading: creatorsLoading } = useQuery(GET_CREATORS);
  const { data: partnersData, loading: partnersLoading } = useQuery(GET_PARTNERS);

  const { creator, partner, partnerId, cautie } = formik.values;

  const creatorOptions = creatorsData
    ? creatorsData.creators?.map(({ id, displayName }) => ({ id: id, name: displayName }))
    : [];
  const dataCreator = creator?.id ? creator : creatorOptions?.find(c => c.id === creator);
  const partnerOptions = partnersData
    ? partnersData.partners?.map(({ id, displayName }) => ({ id: id, name: displayName }))
    : [];
  const dataPartner = partner?.id ? partner : partnerOptions?.find(p => p.id === partnerId);

  return (
    <Radio
      label={I18n.t('tickets.full_ticket.labels.cautie')}
      disabled={disabled || formik.status.readOnly || creatorsLoading || partnersLoading}
      options={[
        {
          label: I18n.t('activerecord.attributes.ticket.cautie_messages.not_given'),
          value: '0'
        },
        {
          label: I18n.t('activerecord.attributes.ticket.cautie_messages.given_by_officer', {
            name: dataCreator?.name || '?'
          }),
          value: dataCreator?.id
        },
        {
          label: I18n.t('activerecord.attributes.ticket.cautie_messages.given_by_partner', {
            name: dataPartner?.name
          }),
          value: dataPartner?.id,
          isHidden: either(isNil, isEmpty)(dataPartner)
        }
      ]}
      name="cautie"
      onChange={formik.handleChange}
      error={formik.errors.cautie}
      defaultValue={`${cautie}`}
    />
  );
};

export default CautieRadio;
