import React from 'react';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function CountryField({
  formik,
  editEnabled,
  isSwitchable = false,
  formLabel = '',
  switchLabel = ''
}) {
  const selectCountry = country => {
    const newSelectedCountries = [...formik.values.selectedCountries, country];
    const newAvailableCountries = formik.values.availableCountries.filter(c => c.id !== country.id);

    formik.setValues({
      ...formik.values,
      selectedCountries: newSelectedCountries.sort(idSort),
      availableCountries: newAvailableCountries
    });
  };

  const deselectCountry = country => {
    const newSelectedCountries = formik.values.selectedCountries.filter(c => c.id !== country.id);
    const newAvailableCountries = [...formik.values.availableCountries, country];

    formik.setValues({
      ...formik.values,
      selectedCountries: newSelectedCountries,
      availableCountries: newAvailableCountries.sort(idSort)
    });
  };

  const selectAllCountries = () => {
    const newSelectedCountries = [
      ...formik.values.selectedCountries,
      ...formik.values.availableCountries
    ];

    formik.setValues({
      ...formik.values,
      selectedCountries: newSelectedCountries.sort(idSort),
      availableCountries: []
    });
  };

  const deselectAllCountries = () => {
    const newAvailableCountries = [
      ...formik.values.availableCountries,
      ...formik.values.selectedCountries
    ];

    formik.setValues({
      ...formik.values,
      selectedCountries: [],
      availableCountries: newAvailableCountries.sort(idSort)
    });
  };

  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledCountries"
              name="enabledCountries"
              isOn={formik.values.enabledCountries}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledCountries}
        />
      )}

      {((formik.values.enabledCountries && isSwitchable) || !isSwitchable) && (
        <FormFieldBlock
          label={formLabel}
          className="countries"
          field={
            <SideBySideSelector
              availableObjects={formik?.values?.availableCountries}
              selectedObjects={formik?.values?.selectedCountries}
              selectOne={selectCountry}
              deselectOne={deselectCountry}
              selectAll={selectAllCountries}
              deselectAll={deselectAllCountries}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.selectedCountries}
        />
      )}
    </>
  );
}

export default CountryField;
