import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import MenuItem from '../../../components/MenuItem';

function ExemptionsSubMenu() {
  const match = useRouteMatch();

  const menuItems = [
    { link: `${match.url}/types`, label: I18n.t('settings.exemptions_types_label') },
    { link: `${match.url}/list`, label: I18n.t('settings.exemptions_list_label') },
    { link: `${match.url}/details`, label: I18n.t('settings.exemptions_details_label') }
  ];

  const isItemSelected = item => {
    return location.pathname.match(`${item.link}`);
  };

  return (
    <div className="cop_exemptions submenu">
      {menuItems.map(item => (
        <MenuItem
          key={item.label}
          link={item.link}
          label={item.label}
          isSelected={isItemSelected(item)}
        />
      ))}
    </div>
  );
}

export default ExemptionsSubMenu;
