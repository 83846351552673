import React, { useEffect, useState } from 'react';
import { Marker, MarkerClusterer } from '@react-google-maps/api';
import styled from 'styled-components';
import Map from '../../../../../../shared/maps/Map';
import CameraActive from '@/assets/img/camera_active.svg';
import CameraInactive from '@/assets/img/camera_inactive.svg';
import CameraWarning from '@/assets/img/camera_warning.svg';

const StyledCameraListMap = styled.div`
  display: flex;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;

  #google-map {
    min-width: 0px;
    border-radius: 4px;
    margin: 10px 10px 0px 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }
`;

function CameraListMap({ cameras, onMarkerClick }) {
  const [map, setMap] = useState();

  // Position map to show every camera on the current page
  useEffect(() => {
    if (map) {
      // Netherlands
      map.setCenter({ lat: 52.1611973294726, lng: 5.621868260475858 });

      if (cameras.length) {
        const bounds = new window.google.maps.LatLngBounds();

        cameras.forEach(camera => {
          bounds.extend({ lat: parseFloat(camera.latitude), lng: parseFloat(camera.longitude) });
        });
        !bounds.isEmpty() && map.fitBounds(bounds);
      }
    }
  }, [map, cameras]);

  const mapStatusToIcon = state => {
    switch (state) {
      case 'enabled':
        return CameraActive;
      case 'disabled':
        return CameraWarning;
      default:
        return CameraInactive;
    }
  };

  return (
    <StyledCameraListMap>
      <Map onLoad={setMap} clickableIcons={false}>
        <MarkerClusterer zoomOnClick minimumClusterSize={2}>
          {clusterer =>
            cameras.map(camera => (
              <Marker
                key={camera.id}
                position={{ lat: parseFloat(camera.latitude), lng: parseFloat(camera.longitude) }}
                icon={mapStatusToIcon(camera.status)}
                onClick={() => onMarkerClick(camera.id)}
                clusterer={clusterer}
              />
            ))
          }
        </MarkerClusterer>
      </Map>
    </StyledCameraListMap>
  );
}

export default CameraListMap;
