import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'brickyard-ui';

import useDebounce from '@/hooks/useDebounce';
import Api from '@/utils/Api';

const CountrySearch = ({
  onChange,
  value,
  isInvalid,
  name,
  disabled,
  readOnly,
  size,
  attr,
  suspectBirthDate = null
}) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const debouncedSearch = useDebounce(search, 400);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  const selectCountry = value => {
    setSearch(value);
    onChange({ target: { value, name } });
  };

  useEffect(() => {
    const action = attr == 'name' ? 'search' : 'search_code';
    let queryString = `/countries/${action}.json?q=${debouncedSearch}`;
    queryString += suspectBirthDate ? `&date=${suspectBirthDate}` : '';

    const fetchCountries = async () => {
      const { data } = await Api.get(queryString);
      setList(data.map(c => c[attr]));
      setLoading(false);
    };

    if (debouncedSearch && debouncedSearch !== value && !disabled) {
      setLoading(true);
      fetchCountries();
    }
  }, [debouncedSearch]);

  return (
    <AutoComplete
      inputProps={{
        value: search,
        autoComplete: 'off',
        onChange: evt => {
          setSearch(evt.target.value);
          if (!evt.target.value) {
            selectCountry('');
          }
        },
        isInvalid,
        disabled,
        readOnly,
        size,
        name
      }}
      onSelect={selectCountry}
      loading={loading}
      list={list}
    />
  );
};

CountrySearch.defaultProps = {
  attr: 'code'
};

export default CountrySearch;
