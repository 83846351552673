import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ExemptionsSubMenu from './components/ExemptionsSubMenu';
import ExemptionTypes from './components/ExemptionTypes';
import ExemptionsList from './components/ExemptionsList';
import ExemptionDetails from './components/ExemptionDetails';

function Exemptions() {
  const match = useRouteMatch();

  return (
    <div className="cop_exemptions">
      <div className="left-panel">
        <div className="left-panel-header">
          <h4>{I18n.t('settings.exemptions_label')}</h4>
        </div>
        <div className="left-panel-body">
          <ExemptionsSubMenu />
        </div>
      </div>
      <div className="right-panel">
        <Switch>
          <Route path={`${match.path}/types`}>
            <ExemptionTypes />
          </Route>
          <Route path={`${match.path}/details`}>
            <ExemptionDetails />
          </Route>
          <Route path={`${match.path}/list`}>
            <ExemptionsList />
          </Route>
          <Route path={`${match.path}`}>
            <Redirect to={`${match.path}/types`} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Exemptions;
