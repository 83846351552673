import { useMutation, gql } from '@apollo/client';
import React, { useState, useEffect } from 'react';

import { MessagePopup } from 'brickyard-ui';

import usePermission from '@/hooks/usePermission';

import Button from '../../components/Button';

export const BLOCK_TICKET = gql`
  mutation BlockTicket($id: ID!) {
    ticketBlock(id: $id) {
      id
      blockState
    }
  }
`;

export const UNBLOCK_TICKET = gql`
  mutation UnblockTicket($id: ID!) {
    ticketUnblock(id: $id) {
      id
      blockState
    }
  }
`;

const ToggleBlockButton = ({ ticket }) => {
  const { id, blockState } = ticket;
  const canUnblock = usePermission('unblock');
  const canBlock = usePermission('block');

  const isBlocked = blockState === 'blocked';

  const [blockTicket, { loading: blockLoading, error: blockErr }] = useMutation(BLOCK_TICKET, {
    variables: { id }
  });
  const [unblockTicket, { loading: unblockLoading, error: unblockErr }] = useMutation(
    UNBLOCK_TICKET,
    {
      variables: { id }
    }
  );
  const [err, setErr] = useState('');

  useEffect(() => {
    if (blockErr || unblockErr) {
      setErr(blockErr?.message || unblockErr?.message);
    }
  }, [blockErr, unblockErr]);

  const toggle = () => (isBlocked ? unblockTicket() : blockTicket());

  if ((!canUnblock && isBlocked) || (!canBlock && !isBlocked)) {
    return null;
  }

  return (
    <div>
      <Button onClick={toggle} disabled={blockLoading || unblockLoading} block>
        {isBlocked ? I18n.t('tickets.actions.unblock') : I18n.t('tickets.actions.block')}
      </Button>

      <MessagePopup
        type="error"
        show={err}
        onHide={() => setErr('')}
        okBtn={{ label: I18n.t('actions.close'), onClick: () => setErr('') }}
        message={err}
      />
    </div>
  );
};

export default ToggleBlockButton;
