import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Badge, Button, Modal, AutoTable, Loading } from 'brickyard-ui';

import { GET_PRP_RESPONSES } from '@/components/queries/observations';
import formatDate from '@/utils/formatDate';
import { LongDateFormat } from '@/utils/DateFormats';

import 'styles/shared/observations.scss';

const PrpBadge = ({ observationId, areaId }) => {
  const { loading, error, data } = useQuery(GET_PRP_RESPONSES, {
    variables: { observationId, areaId }
  });
  const [showModal, setShowModal] = useState(false);

  if (loading) return <Loading size="sm" />;

  if (error || !data) return null;

  const responseHeaders = [
    { key: 'webService', label: I18n.t('observations.observations.prp_results_dialog.webservice') },
    {
      key: 'mobileQueryTimestamp',
      label: I18n.t('observations.observations.prp_results_dialog.mobile_query_time')
    },
    {
      key: 'portalQuerySentAt',
      label: I18n.t('observations.observations.prp_results_dialog.portal_query_sent_at')
    },
    { key: 'reply', label: I18n.t('observations.observations.prp_results_dialog.reply') },
    {
      key: 'response',
      label: I18n.t('observations.observations.prp_results_dialog.result_description')
    }
  ];

  const responses = data.observations.nodes[0]?.prpResponses || [];
  const formattedResponses = responses.map(response => ({
    ...response,
    mobileQueryTimestamp: response.mobileQueryTimestamp
      ? formatDate(response.mobileQueryTimestamp, LongDateFormat)
      : ' - ',
    portalQuerySentAt: response.portalQuerySentAt
      ? formatDate(response.portalQuerySentAt, LongDateFormat)
      : ' - '
  }));

  return (
    <>
      <Badge variant="by-primary" onClick={() => setShowModal(true)} className="prp-badge">
        {responses.length}
      </Badge>

      <Modal show={showModal} onHide={() => setShowModal(false)} className="prp-modal" size="lg">
        <Modal.Header closeButton>
          <h3>{I18n.t('observations.observations.prp_results_dialog.title')}</h3>
        </Modal.Header>
        <Modal.Body>
          <AutoTable header={responseHeaders} objArr={formattedResponses} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="by-dark" onClick={() => setShowModal(false)}>
            {I18n.t('messages.general.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrpBadge;
