import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

let count = 0;

/**
 * This component creates a list that can be sorted through dragging and dropping actions.
 *
 * @param {Array} initialSort - Initial sort that will be set locally on the component, its elements must be strings.
 * @param {Function} onSort - Function that will be called after moving cards around, use it for side effects on parent component.
 * @param {String} containerId - An ID for the container, that's used only by react-beautiful-dnd and can be automatically generated if you don't pass it.
 *
 * @returns {React.Component} DropContainer - Container that controls the sorting a list of DraggableCards.
 *
 */
const DropContainer = ({ children, initialSort, onSort, containerId }) => {
  const childrenArr = React.Children.toArray(children);
  const [list, setList] = useState(
    initialSort || childrenArr.map(child => `${child.props.draggableId}`)
  );

  useEffect(() => {
    if (list.length !== childrenArr.length) {
      setList([...childrenArr.map(child => `${child.props.draggableId}`)]);
    }
  }, [children]);

  const moveCard = useCallback(
    (destination, source, draggableId) => {
      const newList = Array.from(list);
      newList.splice(source.index, 1);
      newList.splice(destination.index, 0, draggableId);

      setList(newList);
      onSort(newList);
    },
    [list]
  );

  const onDragEnd = ({ destination, source, draggableId }) => {
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }

    moveCard(destination, source, draggableId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={containerId}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {list.map((order, index) => {
              const node = childrenArr.find(child => `${child.props.draggableId}` === order);
              if (!node) return null;

              return React.cloneElement(node, { index });
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DropContainer.defaultProps = {
  containerId: `drop-id-${count}`,
  onSort: () => {}
};

count += 1;

export default DropContainer;
