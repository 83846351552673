import React, { useEffect, useState } from 'react';
import { AutoTable, Button, Modal, Loading, InfoIcon, FaSearch } from 'brickyard-ui';

import Api from '@/utils/Api';
import formatDate from '@/utils/formatDate';

const RDWSearch = ({ onChange, onError, licensePlate, countryCode }) => {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [history, setHistory] = useState(null);

  /*
   * Check the AutoTable component on brickyard-ui, that's a standard format for its headers.
   * The label  can be empty so it doesn't print anyting on the TH, but the key is responsible for
   * the whole rendering.
   */
  const historyTableHeader = [
    { key: 'recording_number', label: '' },
    { key: 'written_at', label: I18n.t('tickets.history.date') },
    { key: 'offense_code', label: I18n.t('tickets.history.offense') }
  ];

  useEffect(() => {
    info && onChange(info);
  }, [info]);

  useEffect(() => {
    error && onError(error);
  }, [error]);

  const parseHistory = history => {
    if (!history) return null;

    const parsedHistory = history.map(h => ({
      ...h,
      recording_number: `${
        h.is_warning ? I18n.t('tickets.history.warning') : I18n.t('tickets.history.ticket')
      } ${h.recording_number}`,
      written_at: formatDate(h.written_at)
    }));

    return parsedHistory;
  };

  // This variable makes the map from the attributes returned by RDW to the attributes we use on GraphQL
  const keyMap = {
    license_plate: 'vehicleLicensePlateNumber',
    country: 'vehicleCountry',
    maker: 'vehicleMake',
    color: 'vehicleColor',
    type: 'vehicleType'
  };

  const fetchInfo = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const { data } = await Api.get(
          `/services/vehicle/${licensePlate}${countryCode ? `,${countryCode}` : ''}`
        );
        if (data?.err) {
          setError(data.msg);
          return;
        }

        if (!data.vehicle_information) {
          return;
        }

        setHistory(parseHistory(data?.history));
        /*
         * This object uses the keyMap to set parse the returned data from the API to
         * the expected format on formik and GraphQL.
         */
        const parsedInfo = Object.keys(keyMap).reduce((newObj, key) => {
          newObj[keyMap[key]] = data.vehicle_information[key] || '';
          return newObj;
        }, {});

        setInfo(parsedInfo);

        setError(null);
      } catch (err) {
        console.log(err);
        setError(err?.response?.data?.msg);
      } finally {
        setLoading(false);
      }
    }
  };

  if (!licensePlate && !countryCode) return null;

  return (
    <>
      <i className="clickable" onClick={fetchInfo}>
        {loading ? (
          <Loading size="sm" />
        ) : (
          <InfoIcon
            text={I18n.t('tickets.form.sections.search_vehicle_info')}
            size="sm"
            Icon={FaSearch}
          />
        )}
      </i>
      <Modal show={!!history?.length} onHide={() => setHistory(null)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('tickets.history.details_found')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!!history?.length && (
            <AutoTable size="sm" header={historyTableHeader} objArr={history} />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setHistory(null)} variant="by-secondary">
            {I18n.t('actions.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RDWSearch;
