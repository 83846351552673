import React, { useMemo } from 'react';
import { Row, Col } from 'brickyard-ui';
import formatDate from '@/utils/formatDate';
import { LongDateFormat } from '@/utils/DateFormats';

const TabbedPanelResponses = ({ useCaseResult, type }) => {
  const readOnlyLabels = useMemo(() => {
    return [
      {
        attr: 'rightResponses',
        position: 0
      }
    ];
  }, [useCaseResult, type]);

  const renderField = field => {
    if (!useCaseResult[field.attr] || useCaseResult[field.attr].length == 0) {
      return I18n.t('observations.observations.details.no_offense_detail');
    }

    return useCaseResult[field.attr].map(row => (
      <Row key={`ucr_response_${row.id}`} className="tabbed-panel-list-row">
        <Col xs="2" className="pr-0">
          {row.providerName}
        </Col>
        <Col xs="2" className="pl-0 pr-0">
          {formatDate(row.querySentAt, LongDateFormat)}
        </Col>
        <Col xs="2" className="pl-0 pr-0">
          {formatDate(row.datetimeUsedInQuery, LongDateFormat)}
        </Col>
        <Col xs="1" className="pl-0 pr-0">
          {row.hasRight}
        </Col>
        <Col xs="5" className="pl-0 pr-3">
          {row.description}
        </Col>
      </Row>
    ));
  };

  return (
    <>
      <Row className="tabbed-panel-list-header sticky-top">
        <Col xs="2" className="pr-0">
          {I18n.t('use_case.result.details.service')}
        </Col>
        <Col xs="2" className="pl-0 pr-0">
          {I18n.t('use_case.result.details.query_time')}
        </Col>
        <Col xs="2" className="pl-0 pr-0">
          {I18n.t('use_case.result.details.check_time')}
        </Col>
        <Col xs="1" className="pl-0 pr-0">
          {I18n.t('use_case.result.details.result')}
        </Col>
        <Col xs="5" className="pl-1 pr-0">
          {I18n.t('use_case.result.details.details')}
        </Col>
      </Row>
      {readOnlyLabels.map(field => renderField(field))}
    </>
  );
};

export default TabbedPanelResponses;
