export const getSelectedTypeScope = (exemptionTypes, formik) => {
  const selectedType =
    exemptionTypes?.find(type => type.id === formik.values.exemptionTypeId) ?? '';
  return selectedType.global;
};

export const getSelectedTypeScopeLabel = (exemptionTypes, formik) => {
  const label = getSelectedTypeScope(exemptionTypes, formik)
    ? I18n.t('settings.table_global_label')
    : I18n.t('settings.table_usecase_label');

  return label.toUpperCase();
};
