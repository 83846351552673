import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CopUseCaseResults from '../../CopUseCaseResults';
import ExemptionRequests from './containers/ExemptionRequests';

const Suspects = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/use_cases`}>
        <CopUseCaseResults type="scancar" />
      </Route>
      <Route path={`${match.path}/exemption_requests`}>
        <ExemptionRequests />
      </Route>
    </Switch>
  );
};

export default Suspects;
