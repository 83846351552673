import React, { useImperativeHandle, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { railwayCheckerSchema } from './validationSchema';
import FormField from '../../../../components/FormField';
import FormFieldBlock from '../../../../components/FormFieldBlock';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';

function RailwayViolationChecker({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      pardon_time_30: selectedProcessor?.config?.pardon_time_30 ?? 0,
      pardon_time_50: selectedProcessor?.config?.pardon_time_50 ?? 0,
      pardon_time_60: selectedProcessor?.config?.pardon_time_60 ?? 0,
      pardon_time_70: selectedProcessor?.config?.pardon_time_70 ?? 0,
      pardon_time_80: selectedProcessor?.config?.pardon_time_80 ?? 0,
      moped_on_bike_path: selectedProcessor?.config?.moped_on_bike_path ?? 0,
      moped_on_main_road: selectedProcessor?.config?.moped_on_main_road ?? 0
    },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, [
          'id',
          'name',
          'pardon_time_30',
          'pardon_time_50',
          'pardon_time_60',
          'pardon_time_70',
          'pardon_time_80',
          'moped_on_bike_path',
          'moped_on_main_road'
        ])
      );
    },
    validationSchema: railwayCheckerSchema
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    const params = {
      name: values.name,
      id: values.id,
      config: {
        pardon_time_30: values.pardon_time_30,
        pardon_time_50: values.pardon_time_50,
        pardon_time_60: values.pardon_time_60,
        pardon_time_70: values.pardon_time_70,
        pardon_time_80: values.pardon_time_80,
        moped_on_bike_path: values.moped_on_bike_path,
        moped_on_main_road: values.moped_on_main_road
      }
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <div className={selectedProcessor?.className ?? ''}>
      <div className="content">
        <FormFieldBlock
          field={
            <>
              <div className="alert alert-success" role="alert">
                {I18n.t('settings.pipelines.processors.messages.on_error_exempt')}
              </div>

              <div className="alert alert-success" role="alert">
                {I18n.t(
                  'settings.pipelines.processors.form.railway_violation_checker.exempt_rule_camera'
                )}
              </div>
              <div className="alert alert-success" role="alert">
                {I18n.t(
                  'settings.pipelines.processors.form.railway_violation_checker.exempt_rule_time'
                )}
              </div>
              <div className="alert alert-danger" role="alert">
                {I18n.t(
                  'settings.pipelines.processors.form.railway_violation_checker.exempt_rule_vehicle'
                )}
              </div>
            </>
          }
        />
        <FormFieldBlock
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.pardon_times'
          )}
          className="orangeLabel"
        />

        <FormFieldBlock
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.motorized_traffic'
          )}
          className="sectionLabel"
        />

        <FormField
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.pardon_time_30'
          )}
          className="pardonTime"
          field={
            <>
              <input
                id="pardon_time_30"
                name="pardon_time_30"
                type="number"
                min={0}
                max={99.9}
                step={0.1}
                className="small-input"
                value={formik.values.pardon_time_30 ?? ''}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
              <label className="right-label">
                {I18n.t('settings.pipelines.processors.form.railway_violation_checker.seconds')}
              </label>
            </>
          }
          error={formik.errors.pardon_time_30}
        />

        <FormField
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.pardon_time_50'
          )}
          className="pardonTime"
          field={
            <>
              <input
                id="pardon_time_50"
                name="pardon_time_50"
                type="number"
                min={0}
                max={99.9}
                step={0.1}
                className="small-input"
                value={formik.values.pardon_time_50 ?? ''}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
              <label className="right-label">
                {I18n.t('settings.pipelines.processors.form.railway_violation_checker.seconds')}
              </label>
            </>
          }
          error={formik.errors.pardon_time_50}
        />

        <FormField
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.pardon_time_60'
          )}
          className="pardonTime"
          field={
            <>
              <input
                id="pardon_time_60"
                name="pardon_time_60"
                type="number"
                min={0}
                max={99.9}
                step={0.1}
                className="small-input"
                value={formik.values.pardon_time_60 ?? ''}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
              <label className="right-label">
                {I18n.t('settings.pipelines.processors.form.railway_violation_checker.seconds')}
              </label>
            </>
          }
          error={formik.errors.pardon_time_60}
        />

        <FormField
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.pardon_time_70'
          )}
          className="pardonTime"
          field={
            <>
              <input
                id="pardon_time_70"
                name="pardon_time_70"
                type="number"
                min={0}
                max={99.9}
                step={0.1}
                className="small-input"
                value={formik.values.pardon_time_70 ?? ''}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
              <label className="right-label">
                {I18n.t('settings.pipelines.processors.form.railway_violation_checker.seconds')}
              </label>
            </>
          }
          error={formik.errors.pardon_time_70}
        />

        <FormField
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.pardon_time_80'
          )}
          className="pardonTime"
          field={
            <>
              <input
                id="pardon_time_80"
                name="pardon_time_80"
                type="number"
                min={0}
                max={99.9}
                step={0.1}
                className="small-input"
                value={formik.values.pardon_time_80 ?? ''}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
              <label className="right-label">
                {I18n.t('settings.pipelines.processors.form.railway_violation_checker.seconds')}
              </label>
            </>
          }
          error={formik.errors.pardon_time_80}
        />

        <FormFieldBlock
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.mopeds_traffic'
          )}
          className="sectionLabel"
        />

        <FormField
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.moped_on_bike_path'
          )}
          className="pardonTime"
          field={
            <>
              <input
                id="moped_on_bike_path"
                name="moped_on_bike_path"
                type="number"
                min={0}
                max={99.9}
                step={0.1}
                className="small-input"
                value={formik.values.moped_on_bike_path ?? ''}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
              <label className="right-label">
                {I18n.t('settings.pipelines.processors.form.railway_violation_checker.seconds')}
              </label>
            </>
          }
          error={formik.errors.moped_on_bike_path}
        />

        <FormField
          label={I18n.t(
            'settings.pipelines.processors.form.railway_violation_checker.moped_on_main_road'
          )}
          className="pardonTime"
          field={
            <>
              <input
                id="moped_on_main_road"
                name="moped_on_main_road"
                min={0}
                max={99.9}
                step={0.1}
                type="number"
                className="small-input"
                value={formik.values.moped_on_main_road ?? ''}
                onChange={formik.handleChange}
                disabled={!editEnabled}
              />
              <label className="right-label">
                {I18n.t('settings.pipelines.processors.form.railway_violation_checker.seconds')}
              </label>
            </>
          }
          error={formik.errors.moped_on_main_road}
        />
      </div>
    </div>
  );
}

export default RailwayViolationChecker;
