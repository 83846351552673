import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import currency from 'currency.js';

import Api from '@/utils/Api';
import InfoText from './InfoText';
import CurrencyInput from './CurrencyInput';

const currencyEuroToCents = { symbol: '€', fromCents: true, precision: 2 };

const fields = ['variableFine', 'fixedFine', 'fineSurcharge', 'totalFine'];

const i18nMap = {
  variableFine: I18n.t('tickets.offense_extra_fine_amount_in_eur'),
  fixedFine: I18n.t('tickets.offense_fine_amount_in_eur'),
  fineSurcharge: I18n.t('tickets.fine_surcharge_amount_in_eur'),
  totalFine: I18n.t('tickets.offense_total_fine_amount_in_eur')
};

const AmountInput = styled(CurrencyInput)`
  margin: 6px 0 6px 18px;
  width: 83px;
`;

const fetchParkingAreaAmounts = async ({ parkingArea, setFines, fineSurcharge, setErr }) => {
  try {
    const { data } = await Api.get(`/parking_areas/search?name=${parkingArea}`);
    setFines({
      variableFine: data.fmt_variable_fine,
      fixedFine: data.fmt_fixed_fine,
      totalFine: currency(data.fmt_total_fine, { symbol: '€' })
        .add(currency(fineSurcharge, currencyEuroToCents).format())
        .format()
    });
  } catch (err) {
    const errMsg = err.response?.data?.error || err.response?.data || err;
    setErr(errMsg.toString());
  }
};

const PopoverWrapper = styled.div`
  width: 130px;
`;

const StyledInfoText = styled(InfoText)`
  font-size: 10px;
  > span,
  > label {
    width: auto;
  }
`;

const Amount = ({
  ticketType,
  parkingArea,
  isWarning,
  offenseFine,
  offenseExtraFine,
  showFineSurcharge,
  fineSurcharge,
  readOnly,
  newRecord
}) => {
  const target = useRef(null);
  const [overlayIsVisible, setOverlayVisibility] = useState(false);
  const [fines, setFines] = useState({
    fixedFine: currency('0', currencyEuroToCents).format(),
    variableFine: currency('0', currencyEuroToCents).format(),
    totalFine: currency(fineSurcharge, currencyEuroToCents).format()
  });
  const [err, setErr] = useState('');
  const allFines = {
    ...fines,
    fineSurcharge: currency(fineSurcharge, currencyEuroToCents).format()
  };

  useEffect(() => {
    if (newRecord && ticketType === 'fiscal' && parkingArea && !isWarning) {
      fetchParkingAreaAmounts({
        parkingArea,
        setFines,
        showFineSurcharge,
        fineSurcharge,
        setErr
      });
    } else {
      setFines({
        variableFine: currency(offenseExtraFine, currencyEuroToCents).format(),
        fixedFine: currency(offenseFine || 0, currencyEuroToCents).format(),
        totalFine: currency(offenseExtraFine, currencyEuroToCents)
          .add(offenseFine)
          .add(fineSurcharge)
          .format()
      });
    }
  }, [parkingArea]);

  const fineData = fields
    .filter(key => key !== 'fineSurcharge' || fineSurcharge || showFineSurcharge)
    .map(key => ({ amount: allFines[key], label: `${i18nMap[key]}:` }));

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <PopoverWrapper>
          {fineData.map(field => (
            <StyledInfoText
              className="flex-between"
              key={field.label}
              label={field.label}
              text={field.amount}
            />
          ))}
        </PopoverWrapper>
      </Popover.Content>
    </Popover>
  );

  return (
    <div>
      {ticketType === 'fiscal' ? (
        <div
          onMouseEnter={() => {
            setOverlayVisibility(true);
          }}
          onMouseLeave={() => {
            setOverlayVisibility(false);
          }}
        >
          <OverlayTrigger
            target={target.current}
            show={overlayIsVisible}
            overlay={popover}
            placement="bottom"
          >
            <AmountInput
              label={I18n.t('tickets.offense_juridical_fine_amount_in_eur')}
              name="amount"
              value={fines.totalFine || 0}
              error={err}
              disabled={true}
              readOnly={readOnly}
            />
          </OverlayTrigger>
        </div>
      ) : (
        <AmountInput
          label={I18n.t('tickets.offense_juridical_fine_amount_in_eur')}
          name="amount"
          value={currency(isWarning ? 0 : offenseFine, currencyEuroToCents).format()}
          error={err}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

Amount.defaultProps = {
  fineSurcharge: 0,
  offenseExtraFine: 0
};

export default Amount;
