import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Close from '@/assets/img/close_icon.svg';
import { useEventsSlice } from '../slice';
import { Loading } from 'brickyard-ui';
import { useFormik } from 'formik';
import OutsideClickHandler from '../../../../../../OutsideClickHandler';
import FormField from '../../../../Settings/components/FormField';
import FormControls from '../../../../Settings/components/FormControls';
import InputConfirmationModal from '../../../../Settings/components/InputConfirmationModal';
import LabelToggle from '../../../../../Ticket/Cards/components/LabelToggle';
import formatDate from '../../../../../../../utils/formatDate';
import SideBySideSelector from '../../../../Settings/components/SideBySideSelector';
import FormFieldBlock from '../../../../Settings/components/FormFieldBlock';

const EventsFormPanel = styled.div`
  display: flex;
  width: calc(100% * 2 / 3 - 60px);
  height: calc(100% - 60px);
  background-color: #ffffff;
  margin: 30px;
  border: 1px solid #e5e5e5;

  .loading-box {
    margin: auto;
  }

  .click-handler-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .form-header {
      display: flex;
      flex-direction: row;
      width: (90% - 80px);
      margin: 40px 40px 20px 40px;
      height: 50px;

      .header-content {
        display: flex;
        flex-direction: column;

        h3 {
          display: flex;
          color: #4a4a49;
          font-weight: 600;
          margin: auto auto auto 10px;

          .static-title {
            font-weight: 300;
            margin-right: 7px;
          }
        }

        p {
          display: flex;
          color: #4a4a49;
          font-weight: 300;
          margin: 5px 5px 10px 10px;
        }
      }

      .close-button {
        margin: auto 10px auto auto;
        cursor: pointer;
      }
    }

    .form-body {
      display: flex;
      flex-direction: column;
      width: calc(100% - 40px);
      height: calc(100% - 220px);
      margin: 0px 40px 0px 40px;
      overflow-y: auto;

      .object-box-row {
        height: auto;
        min-height: fit-content;
      }

      .right-shifted-field {
        margin-left: 9px;

        .box-col {
          margin-left: 0px;
          margin-right: 20px;

          p {
            margin-left: 0px;
          }
        }
      }

      .normal-fields {
        .label {
          width: calc(25% - 10px);
        }

        .field {
          width: calc(70% - 10px);
        }
      }
    }
  }
`;

const EventsForm = ({ event, onClose, isLoading }) => {
  const { actions } = useEventsSlice();
  const dispatch = useDispatch();
  const params = useParams();

  const [editEnabled, setEditEnabled] = useState(false);
  const editEnabledRef = useRef();
  editEnabledRef.current = editEnabled;

  const formik = useFormik({
    initialValues: event
      ? {
          ...event,
          start_date: event?.start_date
            ? formatDate(new Date(event?.start_date), 'YYYY-MM-DDTHH:mm')
            : null,
          end_date: event?.end_date
            ? formatDate(new Date(event?.end_date), 'YYYY-MM-DDTHH:mm')
            : null
        }
      : {
          active: false
        },
    enableReinitialize: true,
    onSubmit: values => {
      values = {
        ...values,
        start_date: values.start_date ? new Date(values.start_date).toISOString() : null,
        end_date: values.end_date ? new Date(values.end_date).toISOString() : null
      };

      const propsToRemove = ['id'];
      const filteredValues = Object.keys(values)
        .filter(key => !propsToRemove.includes(key))
        .reduce((obj, key) => {
          return { ...obj, [key]: values[key] };
        }, {});

      if (params.id === 'new') {
        dispatch(
          actions.createEvent({
            props: filteredValues,
            onSuccess: () => {
              onClose();
            }
          })
        );
      } else {
        dispatch(
          actions.updateEvent({
            id: parseInt(params.id),
            props: filteredValues,
            onSuccess: () => {
              onClose();
            }
          })
        );
      }
    }
  });

  const useCaseCameraOptions = useSelector(state => state.events.useCaseCameraOptions).map(opt => {
    return {
      id: `${opt?.use_case_id}_${opt?.camera_id}`,
      name: `${opt?.use_case_name} - ${opt?.camera_name}`
    };
  });

  const availableUCCameras = useCaseCameraOptions.filter(
    opt => !(formik.values?.fixed_cameras ?? []).some(selected => selected.id === opt.id)
  );

  const onSelectOneUCCamera = selected => {
    let newValues = [...(formik.values?.fixed_cameras ?? [])];
    newValues.push(selected);
    formik.setFieldValue('fixed_cameras', newValues);
  };

  const onDeselectOneUCCamera = deselected => {
    formik.setFieldValue(
      'fixed_cameras',
      (formik.values?.fixed_cameras ?? []).filter(opt => opt.id !== deselected.id)
    );
  };

  const onSelectAllUCCameras = selected => {
    formik.setFieldValue('fixed_cameras', selected);
  };

  const onDeselectAllUCCameras = () => {
    formik.setFieldValue('fixed_cameras', []);
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [outsideClickEnabled, setOutsideClickEnabled] = useState(false);
  useEffect(() => {
    setOutsideClickEnabled(!showDeleteConfirmation);
  }, [showDeleteConfirmation]);

  useEffect(() => {
    if (params.id === 'new') {
      dispatch(actions.storeEvent(null));
      setEditEnabled(true);
    } else {
      dispatch(actions.fetchEvent(parseInt(params.id)));
      setEditEnabled(false);
    }
  }, [params.id]);

  const handleEdit = () => {
    setEditEnabled(true);
  };

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    params.id === 'new' ? onClose() : setEditEnabled(false);
  };

  const handleDelete = () => {
    dispatch(actions.deleteEvent(parseInt(params.id)));
    onClose();
  };

  const handleOutsideClick = event => {
    if (editEnabledRef.current) {
      setEditEnabled(false);
      if (confirm(I18n.t('settings.form_close_confirm'))) {
        onClose();
      } else {
        setEditEnabled(true);
        event.stopPropagation();
      }
    } else {
      onClose();
    }
  };

  return (
    <>
      <EventsFormPanel>
        {isLoading ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <OutsideClickHandler
            className="click-handler-wrapper"
            onOutsideClick={handleOutsideClick}
            disabled={!outsideClickEnabled}
          >
            <div className="form-header">
              <div className="header-content">
                <h3>
                  <div className="static-title">{I18n.t('settings.events_label')}</div>
                  {' - '.concat(params.id === 'new' ? I18n.t('settings.new_title') : event?.name)}
                </h3>
                <p>{I18n.t('settings.events_subtitle')}</p>
              </div>
              <div className="close-button" onClick={onClose}>
                <img src={Close} alt="close" />
              </div>
            </div>

            <div className="form-body">
              <div className="normal-fields">
                <FormField
                  label={I18n.t('settings.active_label')}
                  field={
                    <LabelToggle
                      name="active"
                      isOn={formik.values.active ?? false}
                      disabled={!editEnabled}
                      onChange={formik.handleChange}
                    />
                  }
                  error={formik.errors.active}
                />
                <FormField
                  label={I18n.t('settings.field_name_label')}
                  field={
                    <input
                      id="name"
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name ?? ''}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.touched.name && formik.errors.name}
                />
                <FormField
                  label={I18n.t('settings.field_description_label')}
                  field={
                    <input
                      id="description"
                      name="description"
                      type="textarea"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description ?? ''}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.touched.description && formik.errors.description}
                />

                <FormField
                  label={I18n.t('settings.start_date_label')}
                  field={
                    <input
                      id="start_date"
                      name="start_date"
                      type="datetime-local"
                      value={formik.values.start_date ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.errors.start_date}
                />
                <FormField
                  label={I18n.t('settings.end_date_label')}
                  field={
                    <input
                      id="end_date"
                      name="end_date"
                      type="datetime-local"
                      value={formik.values.end_date ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.errors.end_date}
                />
              </div>

              <div className="right-shifted-field">
                <FormFieldBlock
                  label={I18n.t('settings.event_use_case_cameras_label')}
                  field={
                    <SideBySideSelector
                      availableObjects={availableUCCameras}
                      selectedObjects={formik.values?.fixed_cameras ?? []}
                      selectOne={onSelectOneUCCamera}
                      deselectOne={onDeselectOneUCCamera}
                      selectAll={onSelectAllUCCameras}
                      deselectAll={onDeselectAllUCCameras}
                      disabled={!editEnabled}
                    />
                  }
                />
              </div>
            </div>

            <FormControls
              editEnabled={editEnabled}
              handleEdit={handleEdit}
              handleSave={handleSave}
              handleCancel={handleCancel}
              handleDelete={() => setShowDeleteConfirmation(true)}
              isDeletable={params.id !== 'new'}
            />
          </OutsideClickHandler>
        )}
      </EventsFormPanel>

      {showDeleteConfirmation && (
        <InputConfirmationModal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          message={I18n.t('settings.form_event_delete_confirm', { word: 'DELETE' })}
          confirmMessage={'DELETE'}
          cancelBtn={{
            label: I18n.t('settings.form_cancel_button'),
            onClick: () => setShowDeleteConfirmation(false)
          }}
          okBtn={{
            label: I18n.t('settings.form_ok_button'),
            onClick: handleDelete
          }}
        />
      )}
    </>
  );
};

export default EventsForm;
