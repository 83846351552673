import React from 'react';
import styled from 'styled-components';

const StyledFormFieldSmall = styled.div`
  .row {
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    min-height: 25px;
    margin: 0px auto 0px auto;
    color: #373a3c;
    font-weight: 400;

    .toggle-switch-div {
      margin: 0;
    }

    .label {
      display: flex;
      width: calc(95% - 10px);
      margin: auto auto auto 10px;
      font-weight: 500;
    }

    .field {
      display: flex;
      flex-direction: column;
      width: calc(5% - 10px);
      margin: auto 10px auto auto;

      .toggle-switch-disabled {
        border-color: #ff6f00;
        opacity: 55%;
        .toggle-switch-inner {
          &:before {
            background-color: #ff6f00;
            opacity: 55%;
          }
        }
      }

      input,
      select,
      textarea {
        width: 90%;
        min-height: 50px;
        max-height: 200px;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        padding: 10px;
        background-color: #ffffff;

        &:disabled {
          background-color: #e9e9ed;
        }
      }

      .error-label {
        font-size: 12px;
        color: #dc3545;
        margin-left: 5px;
      }
    }

    .invalid {
      input,
      select {
        border: 1px solid #dc3545;
        height: 35px;
      }

      input:focus,
      select:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
      }
    }
  }
`;

const FormFieldSmall = ({ label, field, error, className = '' }) => {
  return (
    <StyledFormFieldSmall>
      <div className={`row ${className}`}>
        {field && (
          <div className={'field'.concat(error ? ' invalid' : '')}>
            {field}
            <div className="error-label">{error}</div>
          </div>
        )}
        {label && <div className="label">{label}</div>}
      </div>
    </StyledFormFieldSmall>
  );
};

export default FormFieldSmall;
