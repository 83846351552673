import React, { useEffect, useMemo, useState } from 'react';
import { Table as ByTable, SortTh, BYPagination } from 'brickyard-ui';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import hash from 'object-hash';

const StyledTable = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .main {
    background-color: #ffffff;

    tbody {
      tr:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }

      .selected-row {
        border-left: 4px solid #ff8c00;
      }
    }
  }

  .footer {
    display: flex;
    margin: auto auto 10px auto;
  }
`;

const PAGE_SIZE = 11;

const Table = ({
  columns,
  items = [],
  onRowClick = () => {},
  selectedId = null,
  onPaginationChange = () => {},
  defaultSortBy = 'name',
  defaultSortOrder = 'asc'
}) => {
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const page = params.get('page');
  const sortBy = params.get('sortBy');
  const sortOrder = params.get('sortOrder');

  const [currentPage, setCurrentPage] = useState(page ? parseInt(page - 1) : 0);
  const [sortState, setSortState] = useState({
    field: sortBy ?? defaultSortBy,
    order: sortOrder ?? defaultSortOrder
  });

  useEffect(() => {
    let params = new URLSearchParams();
    params.set('page', currentPage + 1);
    params.set('sortBy', sortState.field);
    params.set('sortOrder', sortState.order);

    history.replace({ search: params.toString() });
  }, [currentPage, sortState]);

  const paginatedItems = useMemo(() => {
    const start = currentPage * PAGE_SIZE;
    const end = start + PAGE_SIZE;

    return [...items]
      .sort((a, b) => {
        let order = String(a[sortState.field]).localeCompare(String(b[sortState.field]));
        return sortState.order === 'asc' ? order : -order;
      })
      .slice(start, end);
  }, [items, currentPage, sortState]);

  useEffect(() => {
    onPaginationChange(paginatedItems);
  }, [hash(items), currentPage, sortState]);

  const getReverseSort = itemKey => {
    if (sortState.field === itemKey) {
      return sortState.order === 'asc' ? 'desc' : 'asc';
    }

    return 'asc';
  };

  return (
    <StyledTable>
      <div className="main">
        <ByTable>
          <thead>
            <tr>
              {columns.map(col => {
                return col.sortable ? (
                  <SortTh
                    key={col.key}
                    onSort={order => setSortState({ field: col.key, order })}
                    sortState={sortState.field === col.key && sortState.order}
                  >
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        setSortState({ field: col.key, order: getReverseSort(col.key) })
                      }
                    >
                      {col.header}
                    </span>
                  </SortTh>
                ) : (
                  <th key={col.key} className={`col-` + col.key}>
                    {col.header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {paginatedItems.map((item, index) => (
              <tr
                key={index}
                onClick={() => onRowClick(item.id)}
                className={selectedId === item.id ? 'selected-row' : undefined}
              >
                {columns.map(col => (
                  <td key={col.key}>{col.cell ? col.cell(item) : item[col.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </ByTable>
      </div>

      <div className="footer">
        <BYPagination
          count={Math.ceil(items.length / PAGE_SIZE)}
          curPage={currentPage}
          onChange={setCurrentPage}
          pageInterval={1}
        />
      </div>
    </StyledTable>
  );
};

export default Table;
