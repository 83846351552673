import React from 'react';
import styled from 'styled-components';
import { compose, trim, toLower, defaultTo } from 'ramda';
import Card from './components/Card';
import Input from './components/LabelInput';
import Select from './components/LabelSelect';
import LabelToggle from './components/LabelToggle';
import DatePicker from './components/LabelDatePicker';
import Tabs from './components/Tabs';
import CountrySearchInput from './components/CountrySearchInput';
import CitySearchInput from './components/CitySearchInput';
import StreetSearchInput from './components/StreetSearchInput';
import SSNSearch from './components/SSNSearch';
import { useMunicipalitiesCities } from '../../Ticket';

const NameContainer = styled.div`
  label:first-of-type {
    min-width: 129px;
    margin-right: 12px;
  }
`;

const SsnContainer = styled.div`
  position: relative;
  input {
    padding-right: 2.25em;
  }
  i {
    position: absolute;
    right: 0.5em;
    top: 1.15em;
    > div {
      position: absolute;
      top: 1.4em;
      right: 0.5em;
    }
  }
`;

const nlList = ['nl', 'netherlands', 'nederland'];

const cleanUp = compose(trim, toLower, defaultTo(''));

const shouldBeDisabled = (type, personalInformationValidation) => {
  switch (type) {
    case 'fiscal':
      return true;
    case 'taxi':
    case 'juridical':
      return personalInformationValidation === 'notallowed';
    case 'recording':
      return false;
  }
};

const SuspectCard = ({ formik }) => {
  const {
    suspectIsResident,
    suspectResidenceCountry,
    suspectResidenceCity,
    suspectZvwovp,
    suspectBirthCountry,
    type,
    personalInformationValidation,
    suspectSsn
  } = formik.values;

  const disabled = shouldBeDisabled(type, personalInformationValidation);

  const identificationTypeOptions = Object.entries(
    I18n.t('activerecord.attributes.ticket.suspect_identification_types')
  ).map(([key, value]) => ({
    id: key.toUpperCase(),
    name: value
  }));

  const suspectGenderOptions = [
    { id: 'MALE', name: I18n.t('male') },
    { id: 'FEMALE', name: I18n.t('female') }
  ];

  const onSuspectInfoChange = async info => {
    await formik.setValues({ ...formik.values, ...info });
    formik.validateForm();
  };

  const onSuspectInfoError = error => {
    formik.setFieldError('suspectSsn', error);
  };

  return (
    <Card
      title={I18n.t('tickets.form.title.suspect')}
      colorScheme={{ primaryColor: '#E18247', secondaryColor: '#FDF7F4' }}
      disabled={disabled}
    >
      <div>
        <Tabs disabled={disabled}>
          <div label={I18n.t('tickets.form.title.identity')}>
            <Select
              label={I18n.t('activerecord.attributes.ticket.suspect_identification_type')}
              options={identificationTypeOptions}
              disabled={disabled}
              readOnly={formik.status.readOnly}
              placeholder=""
              error={formik.errors.suspectIdentificationType}
              {...formik.getFieldProps('suspectIdentificationType')}
            />
            <Input
              label={I18n.t('activerecord.attributes.ticket.suspect_identification_code')}
              disabled={disabled}
              readOnly={formik.status.readOnly}
              error={formik.errors.suspectIdentificationCode}
              {...formik.getFieldProps('suspectIdentificationCode')}
            />
            <SsnContainer>
              <Input
                label={I18n.t('activerecord.attributes.ticket.suspect_ssn')}
                disabled={disabled}
                readOnly={formik.status.readOnly}
                error={formik.errors.suspectSsn}
                {...formik.getFieldProps('suspectSsn')}
                controlled
              />
              <SSNSearch
                ssn={suspectSsn}
                onChange={onSuspectInfoChange}
                onError={onSuspectInfoError}
              />
            </SsnContainer>
            <NameContainer className="flex-between">
              <Input
                label={I18n.t('activerecord.attributes.ticket.suspect_firstnames')}
                disabled={disabled}
                readOnly={formik.status.readOnly}
                error={formik.errors.suspectFirstnames}
                {...formik.getFieldProps('suspectFirstnames')}
                controlled
              />
              <Input
                label={I18n.t('activerecord.attributes.ticket.suspect_middlename')}
                disabled={disabled}
                readOnly={formik.status.readOnly}
                {...formik.getFieldProps('suspectMiddlename')}
                controlled
              />
            </NameContainer>
            <Input
              label={I18n.t('activerecord.attributes.ticket.suspect_lastname')}
              disabled={disabled}
              readOnly={formik.status.readOnly}
              error={formik.errors.suspectLastname}
              {...formik.getFieldProps('suspectLastname')}
              controlled
            />
            <Select
              label={I18n.t('activerecord.attributes.ticket.suspect_gender')}
              options={suspectGenderOptions}
              placeholder=""
              disabled={disabled}
              readOnly={formik.status.readOnly}
              error={formik.errors.suspectGender}
              {...formik.getFieldProps('suspectGender')}
            />
          </div>
          <div label={I18n.t('tickets.form.title.birth')}>
            <DatePicker
              label={I18n.t('activerecord.attributes.ticket.suspect_birth_date')}
              disabled={disabled}
              readOnly={formik.status.readOnly}
              error={formik.errors.suspectBirthDate}
              {...formik.getFieldProps('suspectBirthDate')}
              onChange={date => formik.setFieldValue('suspectBirthDate', date.toISOString())}
            />
            <CountrySearchInput
              label={I18n.t('activerecord.attributes.ticket.suspect_birth_country')}
              disabled={disabled}
              readOnly={formik.status.readOnly}
              error={formik.errors.suspectBirthCountry}
              suspectBirthDate={formik.getFieldProps('suspectBirthDate')}
              fieldProps={formik.getFieldProps('suspectBirthCountry')}
              attr="name"
            />
            {nlList.includes(cleanUp(suspectBirthCountry)) ? (
              <CitySearchInput
                label={I18n.t('activerecord.attributes.ticket.suspect_birth_city')}
                disabled={disabled}
                readOnly={formik.status.readOnly}
                error={formik.errors.suspectBirthCity}
                fieldProps={formik.getFieldProps('suspectBirthCity')}
                cityType={useMunicipalitiesCities}
              />
            ) : (
              <Input
                label={I18n.t('activerecord.attributes.ticket.suspect_birth_city')}
                disabled={disabled}
                readOnly={formik.status.readOnly}
                error={formik.errors.suspectBirthCity}
                {...formik.getFieldProps('suspectBirthCity')}
                controlled
              />
            )}
            <LabelToggle
              label={I18n.t('activerecord.attributes.ticket.suspect_is_resident')}
              name="suspectIsResident"
              disabled={disabled || formik.status.readOnly}
              isOn={suspectIsResident}
              onChange={() => formik.setFieldValue('suspectIsResident', !suspectIsResident)}
            />
          </div>
          <div label={I18n.t('tickets.form.title.residence')}>
            {(type === 'juridical' || type === 'recording') && (
              <LabelToggle
                label={I18n.t('activerecord.attributes.ticket.suspect_zvwovp')}
                name="suspectZvwovp"
                isOn={suspectZvwovp}
                disabled={disabled || formik.status.readOnly}
                onChange={() => formik.setFieldValue('suspectZvwovp', !suspectZvwovp)}
              />
            )}
            <CountrySearchInput
              disabled={disabled}
              readOnly={formik.status.readOnly}
              label={I18n.t('activerecord.attributes.ticket.suspect_residence_country')}
              value={suspectResidenceCountry}
              attr="name"
              error={formik.errors.suspectResidenceCountry}
              fieldProps={formik.getFieldProps('suspectResidenceCountry')}
            />
            <Input
              label={I18n.t('activerecord.attributes.ticket.suspect_residence_zipcode')}
              disabled={disabled || suspectZvwovp}
              readOnly={formik.status.readOnly}
              error={formik.errors.suspectResidenceZipcode}
              {...formik.getFieldProps('suspectResidenceZipcode')}
              controlled
            />
            {nlList.includes(cleanUp(suspectResidenceCountry)) ? (
              <>
                <CitySearchInput
                  label={I18n.t('activerecord.attributes.ticket.suspect_residence_city')}
                  disable={disabled || suspectZvwovp}
                  readOnly={formik.status.readOnly}
                  error={formik.errors.suspectResidenceCity}
                  fieldProps={formik.getFieldProps('suspectResidenceCity')}
                />
                <StreetSearchInput
                  label={I18n.t('activerecord.attributes.ticket.suspect_residence_street')}
                  disabled={disabled || suspectZvwovp || !suspectResidenceCity}
                  readOnly={formik.status.readOnly}
                  city={suspectResidenceCity}
                  error={formik.errors.suspectResidenceStreet}
                  fieldProps={formik.getFieldProps('suspectResidenceStreet')}
                />
              </>
            ) : (
              <>
                <Input
                  label={I18n.t('activerecord.attributes.ticket.suspect_birth_city')}
                  disabled={disabled}
                  readOnly={formik.status.readOnly}
                  error={formik.errors.suspectResidenceCity}
                  {...formik.getFieldProps('suspectResidenceCity')}
                  controlled
                />
                <Input
                  label={I18n.t('activerecord.attributes.ticket.suspect_residence_street')}
                  disabled={disabled}
                  readOnly={formik.status.readOnly}
                  error={formik.errors.suspectResidenceStreet}
                  {...formik.getFieldProps('suspectResidenceStreet')}
                  controlled
                />
              </>
            )}
            <Input
              label={I18n.t('activerecord.attributes.ticket.suspect_residence_housenumber')}
              disabled={disabled || suspectZvwovp}
              readOnly={formik.status.readOnly}
              error={formik.errors.suspectResidenceHousenumber}
              {...formik.getFieldProps('suspectResidenceHousenumber')}
              controlled
            />
            <Input
              label={I18n.t(
                'activerecord.attributes.ticket.suspect_residence_housenumber_addition'
              )}
              disabled={disabled || suspectZvwovp}
              readOnly={formik.status.readOnly}
              {...formik.getFieldProps('suspectResidenceHousenumberAddition')}
              controlled
            />
          </div>
        </Tabs>
      </div>
    </Card>
  );
};

export default SuspectCard;
