import { useQuery } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import React from 'react';
import Close from '@/assets/img/close_icon.svg';
import TestBoundary from '@/utils/TestBoundary';
import OffenseSelector from '../shared/offenses/OffenseSelector';

import RepeatOffenderButton from './RepeatOffenderButton';
import { useDispatch } from 'react-redux';
import { setCurrentObservation } from '../../actions/observationActions';
import { Col, Container, Form as BYForm, MessagePopup, Row } from 'brickyard-ui';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import { GMAP_API_KEY } from '../shared/maps/Map';
import {
  GET_VEHICLE_TYPES,
  GET_VEHICLE_MAKES,
  GET_VEHICLE_COLORS
} from '@/components/queries/SharedQueries';

const UseCaseResultForm = ({ canEdit, useCaseResult, isEditable, formik, error, type }) => {
  const dispatch = useDispatch();

  const { data: vehicleMakesData, loading: makesLoading } = useQuery(GET_VEHICLE_MAKES);
  const { data: vehicleColorsData, loading: colorsLoading } = useQuery(GET_VEHICLE_COLORS);
  const { data: vehicleTypeData, loading: typesLoading } = useQuery(GET_VEHICLE_TYPES);

  const dependsOnLicensePlateAndCountry = () => {
    return (
      !isEditable ||
      formik.initialValues.vehicleLicensePlateNumber !== formik.values.vehicleLicensePlateNumber ||
      formik.initialValues.countryCode !== formik.values.countryCode
    );
  };

  const isFixedCamera = () => {
    return type.includes('Fixed');
  };

  const shouldShowUpdateAll = () => {
    return !!(
      useCaseResult.relatedUseCaseResults &&
      useCaseResult.relatedUseCaseResults.length &&
      canEdit &&
      isEditable
    );
  };

  if (makesLoading || colorsLoading || typesLoading) {
    return null;
  }

  const vehiclePosition = {
    lat: useCaseResult.vehicleLatitude,
    lng: useCaseResult.vehicleLongitude
  };

  const gMapContainerStyle = {
    width: '100%',
    height: '100%'
  };

  return (
    <Container fluid className="ucr-form">
      <form>
        <Row className="ucr-close mr-0">
          <Col xs="1">
            <img
              src={Close}
              alt="close"
              className="clickable"
              onClick={() => {
                dispatch(setCurrentObservation(''));
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <Row>
              <Col>
                <BYForm.Group>
                  <BYForm.Label>{I18n.t('observations.observations.details.make')}</BYForm.Label>
                  <BYForm.Control
                    disabled={dependsOnLicensePlateAndCountry()}
                    readOnly={dependsOnLicensePlateAndCountry()}
                    size="sm"
                    as="select"
                    {...formik.getFieldProps('vehicleMake')}
                  >
                    <option />
                    {!!(
                      useCaseResult.vehicleMake &&
                      !vehicleMakesData.vehicleMakes.includes(useCaseResult.vehicleMake)
                    ) && (
                      <option value={useCaseResult.vehicleMake}>{useCaseResult.vehicleMake}</option>
                    )}
                    {vehicleMakesData.vehicleMakes.map(vm => (
                      <option key={vm.id} value={vm.name}>
                        {vm.name}
                      </option>
                    ))}
                  </BYForm.Control>
                </BYForm.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <BYForm.Group>
                  <BYForm.Label>{I18n.t('observations.observations.details.color')}</BYForm.Label>
                  <BYForm.Control
                    disabled={dependsOnLicensePlateAndCountry()}
                    readOnly={dependsOnLicensePlateAndCountry()}
                    size="sm"
                    as="select"
                    {...formik.getFieldProps('vehicleColor')}
                  >
                    <option />
                    {!!(
                      useCaseResult.vehicleColor &&
                      !vehicleColorsData.vehicleColors.includes(useCaseResult.vehicleColor)
                    ) && (
                      <option value={useCaseResult.vehicleColor}>
                        {useCaseResult.vehicleColor}
                      </option>
                    )}
                    {vehicleColorsData.vehicleColors.map(vc => (
                      <option key={vc.id} value={vc.name}>
                        {vc.name}
                      </option>
                    ))}
                  </BYForm.Control>
                </BYForm.Group>
              </Col>
            </Row>
          </Col>
          <Col xs="4">
            <Row>
              <Col>
                <BYForm.Group>
                  <BYForm.Label>
                    {I18n.t('observations.observations.details.vehicle_type')}
                  </BYForm.Label>
                  <BYForm.Control
                    disabled={dependsOnLicensePlateAndCountry()}
                    readOnly={dependsOnLicensePlateAndCountry()}
                    size="sm"
                    as="select"
                    {...formik.getFieldProps('vehicleType')}
                  >
                    <option />
                    {!!(
                      useCaseResult.vehicleType &&
                      !vehicleTypeData.vehicleTypes
                        .map(vt => {
                          vt.name;
                        })
                        .includes(useCaseResult.vehicleType)
                    ) && (
                      <option value={useCaseResult.vehicleType}>{useCaseResult.vehicleType}</option>
                    )}
                    {vehicleTypeData.vehicleTypes.map(vt => (
                      <option key={vt.name} value={vt.name}>
                        {vt.name}
                      </option>
                    ))}
                  </BYForm.Control>
                </BYForm.Group>
              </Col>
            </Row>
            <Row className="col-with-info">
              <Col>
                <TestBoundary>
                  <OffenseSelector
                    disabled={dependsOnLicensePlateAndCountry()}
                    readOnly={dependsOnLicensePlateAndCountry()}
                    size="sm"
                    offenseCategory={useCaseResult.offenseCategory}
                    initialOffense={useCaseResult.offenseCode}
                    {...formik.getFieldProps('offenseCode')}
                  />
                </TestBoundary>
              </Col>
            </Row>
          </Col>
          <Col xs="4">
            <Row>
              <Col>
                <BYForm.Group>
                  <BYForm.Label>{I18n.t('use_case.result.details.offense_location')}</BYForm.Label>
                  <BYForm.Control
                    disabled={dependsOnLicensePlateAndCountry() || isFixedCamera()}
                    readOnly={dependsOnLicensePlateAndCountry()}
                    size="sm"
                    {...formik.getFieldProps('offenseLocationStreet')}
                  />
                </BYForm.Group>
              </Col>
            </Row>
            {useCaseResult.vehicleLatitude && useCaseResult.vehicleLongitude && (
              <Row className="h-100">
                <Col>
                  <LoadScriptNext googleMapsApiKey={GMAP_API_KEY} key="scancar-map-tile">
                    <GoogleMap
                      mapContainerStyle={gMapContainerStyle}
                      center={vehiclePosition}
                      zoom={16}
                      options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false
                      }}
                    >
                      <Marker position={vehiclePosition} />
                    </GoogleMap>
                  </LoadScriptNext>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {canEdit && (
          <>
            {isEditable && shouldShowUpdateAll() && (
              <Row>
                <Col xs="7">
                  <BYForm.Switch
                    className={useCaseResult.blueZone === true ? 'd-none' : ''}
                    id="updateAll"
                    disabled={!isEditable}
                    {...formik.getFieldProps('updateAll')}
                    checked={formik.values.updateAll}
                    variant="by-secondary"
                    label={I18n.t('observations.observations.dialogs.update.update_all')}
                  />
                </Col>
              </Row>
            )}
          </>
        )}

        {!isEditable && (
          <Row className="pt-2">
            {!!useCaseResult.previousTickets.count && (
              <Col xs="4" className="ucr-form-offenses">
                <RepeatOffenderButton
                  times={useCaseResult.previousTickets.count}
                  offenseCode={useCaseResult.offenseCode}
                  lastDatetime={useCaseResult.previousTickets.lastDatetime}
                  licensePlate={useCaseResult.vehicleLicensePlateNumber}
                  useCaseResultId={useCaseResult.id}
                />
              </Col>
            )}

            {!!useCaseResult.previousWarnings.count && (
              <Col xs="4" className="ucr-form-offenses">
                <RepeatOffenderButton
                  type="warning"
                  times={useCaseResult.previousWarnings.count}
                  offenseCode={useCaseResult.offenseCode}
                  lastDatetime={useCaseResult.previousWarnings.lastDatetime}
                  licensePlate={useCaseResult.vehicleLicensePlateNumber}
                  useCaseResultId={useCaseResult.id}
                />
              </Col>
            )}
          </Row>
        )}
      </form>
      {error && <MessagePopup type="error" size="sm" message={error} />}
    </Container>
  );
};

UseCaseResultForm.defaultProps = {
  useCaseResult: {
    vehicleLicensePlateNumber: '',
    countryCode: '',
    vehicleType: '',
    vehicleMake: '',
    vehicleColor: '',
    offenseCode: '',
    offenseLocationStreet: ''
  },
  canEdit: true
};

export default withApollo(UseCaseResultForm);
