import React, { useEffect } from 'react';
import { AutoComplete, Form as BYForm, InfoIcon } from 'brickyard-ui';
import Label from '../components/Label';
import AutoCompleteWrapper from '../components/AutoCompleteWrapper';

const OffenseInput = ({
  name,
  value,
  search,
  setSearch,
  disabled = false,
  readOnly = false,
  showDescription,
  offense,
  offenseList,
  setOffense,
  error
}) => {
  useEffect(() => {
    setSearch(value || '');
  }, [value, disabled]);

  return (
    <div className="d-flex">
      <BYForm.Group className={`flex-fill offense-code offense-code-alert`}>
        <AutoCompleteWrapper title={showDescription ? offense?.text : ''}>
          <Label>
            {I18n.t('observations.observations.details.offense_code')}
            &nbsp;
            <InfoIcon
              text={offense?.text || I18n.t('observations.observations.details.no_offense_detail')}
              variant="by-dark"
              placement="top"
            />
            {readOnly && <span className="readonly-value">{value}</span>}
          </Label>
          {!readOnly && (
            <AutoComplete
              inputProps={{
                value: search,
                autoComplete: 'off',
                onChange: evt => setSearch(evt.target.value),
                disabled: disabled,
                readOnly,
                name,
                isInvalid: error
              }}
              onSelect={setOffense}
              list={offenseList}
            />
          )}
        </AutoCompleteWrapper>
      </BYForm.Group>
    </div>
  );
};

export default OffenseInput;
