import React, { useEffect, useImperativeHandle } from 'react';
import FormField from '../../../../components/FormField';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import * as Yup from 'yup';
import FormFieldBlock from '../../../../components/FormFieldBlock';

function PermitChecker({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      default_zone_group_id: selectedProcessor?.config?.default_zone_group_id ?? ''
    },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, ['id', 'name', 'default_zone_group_id'])
      );
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        I18n.t('settings.pipelines.processors.form.validations.name_required')
      )
    })
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    const params = {
      id: values.id,
      name: values.name,
      config: values.default_zone_group_id
        ? {
            default_zone_group_id: values.default_zone_group_id
          }
        : {}
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <>
      <FormFieldBlock
        field={
          <>
            <div className="alert alert-success mb-1" role="alert">
              {I18n.t('settings.pipelines.processors.messages.on_error_exempt')}
            </div>
          </>
        }
      />
      <FormField
        label={I18n.t('settings.pipelines.processors.form.permit_checker.default_zone_group')}
        field={
          <input
            id="default_zone_group_id"
            name="default_zone_group_id"
            disabled={!editEnabled}
            onChange={formik.handleChange}
            type="text"
            value={formik.values.default_zone_group_id}
          />
        }
        error={formik.errors.default_zone_group_id}
      />
    </>
  );
}

export default PermitChecker;
