import React, { useContext } from 'react';
import styled from 'styled-components';
import { ColorSchemeContext } from './Card';

const Wrapper = styled.div`
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${props => props.colorScheme.primaryColor};
  width: 16px;
  height: 16px;
`;

export const Checkmark = ({ colorScheme }) => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1898 1.79027C11.1898 1.96884 11.1273 2.12062 11.0023 2.24562L6.15405 7.09383L5.24334 8.00455C5.11834 8.12955 4.96655 8.19205 4.78798 8.19205C4.60941 8.19205 4.45762 8.12955 4.33262 8.00455L3.42191 7.09383L0.997803 4.66973C0.872803 4.54473 0.810303 4.39294 0.810303 4.21437C0.810303 4.0358 0.872803 3.88401 0.997803 3.75901L1.90852 2.8483C2.03352 2.7233 2.1853 2.6608 2.36387 2.6608C2.54245 2.6608 2.69423 2.7233 2.81923 2.8483L4.78798 4.82375L9.18084 0.424194C9.30584 0.299194 9.45762 0.236694 9.6362 0.236694C9.81477 0.236694 9.96655 0.299194 10.0916 0.424194L11.0023 1.33491C11.1273 1.45991 11.1898 1.61169 11.1898 1.79027Z"
      fill={colorScheme.primaryColor}
    />
  </svg>
);

export const ExclamationMark = ({ colorScheme }) => (
  <svg width="4" height="12" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.99019 0.25C2.7549 0.25 3.40196 0.697801 3.40196 1.49151C3.40196 3.91344 3.12745 3.89384 3.12745 6.31577C3.12745 6.94675 2.46078 7.21126 1.99019 7.21126C1.36282 7.21126 0.833367 6.94668 0.833367 6.31577C0.833367 3.89384 0.55892 3.91344 0.55892 1.49151C0.55892 0.697801 1.18629 0.25 1.99019 0.25ZM2.00981 11.75C1.14711 11.75 0.5 11.0173 0.5 10.1829C0.5 9.32807 1.14705 8.61576 2.00981 8.61576C2.81371 8.61576 3.5 9.32807 3.5 10.1829C3.5 11.0173 2.81371 11.75 2.00981 11.75Z"
      fill={colorScheme.primaryColor}
    />
  </svg>
);

const IconContainer = ({ Icon }) => {
  const colorScheme = useContext(ColorSchemeContext);

  return (
    <Wrapper className="flex-center" colorScheme={colorScheme}>
      <Icon colorScheme={colorScheme} />
    </Wrapper>
  );
};

export default IconContainer;
