import React from 'react';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldBlock from '../../../../../components/FormFieldBlock';

function VehicleWeightField({ formik, editEnabled, isSwitchable = false, switchLabel = '' }) {
  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledVehicleWeight"
              name="enabledVehicleWeight"
              isOn={formik.values.enabledVehicleWeight}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledVehicleWeight}
        />
      )}

      {((formik.values.enabledVehicleWeight && isSwitchable) || !isSwitchable) && (
        <>
          <FormFieldBlock
            className="vehicleWeight"
            field={
              <>
                <label className="left-label">
                  {I18n.t('settings.pipelines.processors.form.from')}
                </label>
                <input
                  id="vehicleWeightGt"
                  name="vehicleWeightGt"
                  type="number"
                  className="small-input"
                  defaultValue={formik.values.vehicleWeightGt ?? ''}
                  onChange={formik.handleChange}
                  disabled={!editEnabled}
                />

                <label className="middle-label">
                  {I18n.t('settings.pipelines.processors.form.to')}
                </label>
                <input
                  id="vehicleWeightLt"
                  name="vehicleWeightLt"
                  type="number"
                  className="small-input"
                  defaultValue={formik.values.vehicleWeightLt ?? ''}
                  onChange={formik.handleChange}
                  disabled={!editEnabled}
                />

                <label className="right-label">
                  {I18n.t('settings.pipelines.processors.form.including_kg')}
                </label>

                <div className="error-label">{formik.errors.vehicleWeightGt}</div>
                <div className="error-label">{formik.errors.vehicleWeightLt}</div>
              </>
            }
            error={formik.errors.vehicleWeight}
          />
        </>
      )}
    </>
  );
}

export default VehicleWeightField;
