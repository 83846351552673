import React, { useEffect, useState } from 'react';
import { Loading, InfoIcon, FaSearch } from 'brickyard-ui';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import Api from '@/utils/Api';

dayjs.extend(customParseFormat);

const SSNSearch = ({ onChange, onError, ssn }) => {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    info && onChange(info);
  }, [info]);

  useEffect(() => {
    error && onError(error);
  }, [error]);

  const fetchInfo = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const { data } = await Api.get(`/services/bvbsn/${ssn}`);
        if (data?.err) {
          setError(data.msg);
          return;
        }

        const keyMap = {
          first_name: 'suspectFirstnames',
          prefix_last_name: 'suspectMiddlename',
          last_name: 'suspectLastname',
          gender_indication: 'suspectGender',
          birth_date: 'suspectBirthDate',
          birth_place: 'suspectBirthCity',
          country_of_birth: 'suspectBirthCountry',
          residence_street_name: 'suspectResidenceStreet',
          residence_house_number: 'suspectResidenceHousenumber',
          residence_house_number_addition: 'suspectResidenceHousenumberAddition',
          residence_postcode: 'suspectResidenceZipcode',
          residence_city: 'suspectResidenceCity',
          residence_country: 'suspectResidenceCountry'
        };
        const suspectIsResident = data.citizen_information?.residence_country === 'Nederland';

        const reformatDate = date => dayjs(date, 'DD-MM-YYYY').format('YYYY-MM-DD');

        const remappedInfo = Object.entries(keyMap).reduce(
          (newObj, [kebabKey, camelizedKey]) => ({
            ...newObj,
            [camelizedKey]: data.citizen_information[kebabKey] || ''
          }),
          { suspectIsResident }
        );

        const parsedInfo = {
          ...remappedInfo,
          suspectBirthDate: reformatDate(remappedInfo.suspectBirthDate)
        };

        setInfo(parsedInfo);

        setError(null);
      } catch (err) {
        setError(err?.response?.data?.msg);
      } finally {
        setLoading(false);
      }
    }
  };

  if (!ssn) return null;

  return (
    <i className="clickable" onClick={fetchInfo}>
      {loading ? (
        <Loading size="sm" />
      ) : (
        <InfoIcon
          text={I18n.t('tickets.form.sections.suspect.search_ssn')}
          size="sm"
          Icon={FaSearch}
        />
      )}
    </i>
  );
};

export default SSNSearch;
