import React from 'react';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function FuelTypeField({
  formik,
  editEnabled,
  isSwitchable = false,
  formLabel = '',
  switchLabel = ''
}) {
  const selectFuelType = fuel => {
    const newSelectedFuelTypes = [...formik.values.selectedFuelTypes, fuel];
    const newAvailableFuelTypes = formik.values.availableFuelTypes.filter(f => f.id !== fuel.id);

    formik.setValues({
      ...formik.values,
      selectedFuelTypes: newSelectedFuelTypes.sort(idSort),
      availableFuelTypes: newAvailableFuelTypes
    });
  };

  const deselectFuelType = fuel => {
    const newSelectedFuelTypes = formik.values.selectedFuelTypes.filter(c => c.id !== fuel.id);
    const newAvailableFuelTypes = [...formik.values.availableFuelTypes, fuel];

    formik.setValues({
      ...formik.values,
      selectedFuelTypes: newSelectedFuelTypes,
      availableFuelTypes: newAvailableFuelTypes.sort(idSort)
    });
  };

  const selectAllFuelTypes = () => {
    const newSelectedFuelTypes = [
      ...formik.values.selectedFuelTypes,
      ...formik.values.availableFuelTypes
    ];

    formik.setValues({
      ...formik.values,
      selectedFuelTypes: newSelectedFuelTypes.sort(idSort),
      availableFuelTypes: []
    });
  };

  const deselectAllFuelTypes = () => {
    const newAvailableFuelTypes = [
      ...formik.values.availableFuelTypes,
      ...formik.values.selectedFuelTypes
    ];

    formik.setValues({
      ...formik.values,
      selectedFuelTypes: [],
      availableFuelTypes: newAvailableFuelTypes.sort(idSort)
    });
  };

  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledFuelTypes"
              name="enabledFuelTypes"
              isOn={formik.values.enabledFuelTypes}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledFuelTypes}
        />
      )}

      {((formik.values.enabledFuelTypes && isSwitchable) || !isSwitchable) && (
        <FormFieldBlock
          label={formLabel}
          className="fuelTypes"
          field={
            <SideBySideSelector
              availableObjects={formik?.values?.availableFuelTypes}
              selectedObjects={formik?.values?.selectedFuelTypes}
              selectOne={selectFuelType}
              deselectOne={deselectFuelType}
              selectAll={selectAllFuelTypes}
              deselectAll={deselectAllFuelTypes}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.selectedFuelTypes}
        />
      )}
    </>
  );
}

export default FuelTypeField;
