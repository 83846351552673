import React from 'react';
import styled from 'styled-components';
import Label from './Label';

const Wrapper = styled(Label)`
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  input {
    display: none;
  }
  div {
    font-size: 17px;
    width: calc(2em - 1px);
    margin: 10px 0 0;
    position: relative;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0.5px solid #979797;
    border-radius: 20px;
  }
  .toggle-switch-inner {
    display: block;
    width: 6em;
    margin-left: -3em;
    transition: margin 0.15s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: calc(1em - 1px);
      padding: 0;
      line-height: 1em;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: '';
      padding-left: 10px;
      background-color: #ff6f00;
      color: #fff;
    }
  }
  .toggle-switch-disabled {
    cursor: not-allowed;
    .toggle-switch-inner {
      background-color: #ddd;
      &:before {
        background-color: #ddd;
      }
    }
  }
  .toggle-switch-inner:after {
    content: '';
    padding-right: 10px;
    background-color: #ebedf2;
    color: #fff;
    text-align: right;
  }
  .toggle-switch-switch {
    display: block;
    width: 13px;
    margin: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 14px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.15s ease-in 0s;
  }
  input:checked + .toggle-switch-div {
    &:not(.toggle-switch-disabled) {
      border: 0.5px solid #ff6f00;
    }
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
`;

const LabelToggle = ({
  name,
  label,
  className,
  isOn,
  disabled,
  onChange = () => {},
  isFilter = false
}) => {
  return (
    <Wrapper className={className}>
      {!isFilter && label}
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={name}
        id={name}
        checked={isOn}
        disabled={disabled}
        onChange={disabled ? null : onChange}
      />
      <div
        htmlFor={name}
        className={`toggle-switch-div ${disabled ? 'toggle-switch-disabled' : ''}`}
      >
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch" />
      </div>
      {isFilter && <label>{label}</label>}
    </Wrapper>
  );
};

export default LabelToggle;
