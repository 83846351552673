import React, { useEffect, useRef } from 'react';

function OutsideClickHandler({ children, onOutsideClick, disabled = false, ...props }) {
  const ref = useRef(null);

  useEffect(() => {
    !disabled ? addClickListener() : removeClickListener();

    return () => {
      removeClickListener();
    };
  }, [disabled]);

  const addClickListener = () => {
    document.addEventListener('click', handleClick, true);
  };

  const removeClickListener = () => {
    document.removeEventListener('click', handleClick, true);
  };

  const handleClick = event => {
    const isDescendantOfRoot = ref.current && ref.current.contains(event.target);

    if (!isDescendantOfRoot) {
      onOutsideClick(event);
    }
  };

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
}

export default OutsideClickHandler;
