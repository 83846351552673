import React from 'react';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  width: 100%;
  font-size: 12px;
  height: 68px;
  resize: none;
  border: 1px solid #cccccc;
`;

const Textarea = ({ name, value, onChange }) => {
  return <StyledTextarea name={name} onChange={onChange} defaultValue={value} />;
};

export default Textarea;
