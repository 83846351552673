import React from 'react';
import { Row } from 'brickyard-ui';

import UseCaseResultForm from './UseCaseResultForm';

const OffenseCard = ({ useCaseResult, mainUseCaseResultId, isEditable, setIsEditable, formik }) => {
  return (
    <div className="offense-card cop">
      <Row>
        <UseCaseResultForm
          canEdit={useCaseResult.id === mainUseCaseResultId}
          useCaseResult={useCaseResult}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          formik={formik}
          type={useCaseResult.observation.type}
        />
      </Row>
    </div>
  );
};

export default OffenseCard;
