import React, { useState } from 'react';
import { Modal, Button, Form as BYForm } from 'brickyard-ui';
import DeleteReasonsSelect from './DeleteReasonsSelect';

const DeleteReasonModal = ({ show, onHide, onSelect, name, value }) => {
  const [reasonId, setReasonId] = useState(value);
  const cancel = () => {
    onSelect('');
    onHide();
  };

  const save = () => {
    onSelect(reasonId);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('observations.observations.dialogs.reasons.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BYForm.Group>
          <BYForm.Label>{I18n.t('observations.observations.dialogs.reasons.title')}</BYForm.Label>
          <DeleteReasonsSelect
            name={name}
            onChange={evt => setReasonId(evt.target.value)}
            isValid={!reasonId && reasonId !== value}
            value={reasonId}
          />
        </BYForm.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="by-dark" onClick={cancel}>
          {I18n.t('actions.cancel')}
        </Button>

        <Button variant="by-primary" onClick={save} disabled={!reasonId}>
          {I18n.t('actions.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteReasonModal.defaultProps = {
  isValid: true,
  value: '',
  name: 'deleteReasonId'
};

export default DeleteReasonModal;
