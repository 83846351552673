import { useQuery } from '@apollo/client/react';
import { GET_PROJECTS } from '../tickets';

const useProjectOptions = () => {
  const { data, loading } = useQuery(GET_PROJECTS);

  const options = data ? data.projects : [];

  return [options, loading];
};

export default useProjectOptions;
