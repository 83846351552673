import React from 'react';
import { Polygon, Marker, MarkerClusterer } from '@react-google-maps/api';

import Map from '@shared/maps/Map';
import CameraOff from '@/assets/img/camera_off.svg';
import CameraOn from '@/assets/img/camera_on.svg';
import CameraIconDirection from '@/utils/CameraIconDirection';

const C01Map = ({ center, areas, onLoad, onAreaClick, onCamClick, parsePaths }) => {
  return (
    <Map center={center} onLoad={onLoad}>
      {areas.map(a => (
        <React.Fragment key={a.id}>
          <Polygon
            paths={parsePaths(a.mapParameters)}
            options={{
              fillColor: '#C0C0C0',
              fillOpacity: 0.8,
              strokeColor: 'black',
              strokeWeight: 3,
              clickable: true,
              draggable: false,
              editable: false,
              geodesic: false,
              zIndex: 1
            }}
            onClick={() => onAreaClick(a)}
          />
          <MarkerClusterer zoomOnClick minimumClusterSize={2} maxZoom={16} clusterClass="cluster">
            {clusterer =>
              a.cameras.map(cam => (
                <Marker
                  key={cam.id}
                  position={{ lat: cam.latitude, lng: cam.longitude }}
                  t
                  onClick={() => onCamClick(cam)}
                  clusterer={clusterer}
                  icon={
                    cam.active
                      ? cam.rotation || cam.rotation === 0
                        ? CameraIconDirection(cam.rotation)
                        : CameraOn
                      : CameraOff
                  }
                />
              ))
            }
          </MarkerClusterer>
        </React.Fragment>
      ))}
    </Map>
  );
};

export default C01Map;
