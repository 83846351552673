import React from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import styled from 'styled-components';

import DeleteIcon from '@/assets/img/close.svg';
import Label from './Label';
import ErrorWrapper from './ErrorWrapper';

import 'react-datepicker/dist/react-datepicker.css';
import { ShortDateFormat, ShortDateFormatNoTime } from '@/utils/DateFormats';

const InputWrapper = styled.div`
  align-items: center;
  width: calc(100% + ${props => (props.canClear ? 21 : 0)}px);

  button {
    margin: 0 5px;
    background: none;
    border: none;
    padding: 0;
  }
`;

const StyledLabel = styled(Label)`
  position: relative;

  input {
    width: 100%;
    height: 34px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 4px;

    &:active,
    &:focus {
      box-shadow: 0px 0px 4px #007bff;
      border: none;
      outline: none;
    }

    &.is-invalid {
      border-color: #dc3545;

      &:focus {
        box-shadow: 0px 0px 4px #dc3545;
      }
    }

    &.is-dirty {
      background: #fdfce5;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .error-message {
    bottom: -16px;
  }
`;

const LabelDatePicker = ({
  label,
  name,
  onChange,
  value,
  className,
  disabled,
  readOnly,
  error,
  showTimeInput,
  hasClear
}) => {
  const canClear = hasClear && value;

  const getDateFormat = () => {
    const format = showTimeInput ? ShortDateFormat : ShortDateFormatNoTime;

    return format.replace(/D/g, 'd').replace(/Y/g, 'y');
  };

  return (
    <StyledLabel className={className}>
      {label}
      <InputWrapper className="flex-between" canClear={canClear}>
        <DatePicker
          name={name}
          onChange={onChange}
          selected={value && new Date(value)}
          maxDate={new Date()}
          disabled={disabled}
          readOnly={readOnly}
          className={classNames('form-control', {
            'is-invalid': error,
            'is-dirty': value
          })}
          dateFormat={getDateFormat()}
          showTimeInput={showTimeInput}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          autoComplete="off"
        />
        {canClear && (
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              onChange(null);
            }}
          >
            <img src={DeleteIcon} />
          </button>
        )}
      </InputWrapper>
      {error && <ErrorWrapper className="error-message">{error}</ErrorWrapper>}
    </StyledLabel>
  );
};

export default LabelDatePicker;
