import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const BaseMenuItem = styled.div`
  display: flex;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;

  a {
    width: 100%;
    height: 100%;
  }

  p {
    color: #909393;
    margin: 7px auto 7px 40px;
    font-size: 18px;
  }

  :hover {
    background-color: #f36b2240;
  }
`;

const SelectedMenuItem = styled(BaseMenuItem)`
  background-color: #ffa777;

  p {
    color: #262626;
  }
`;

const MenuItem = ({ link, label, isSelected }) => {
  const ItemComponent = isSelected ? SelectedMenuItem : BaseMenuItem;
  const history = useHistory();

  return (
    <ItemComponent onClick={() => history.push(link)}>
      <p>{label}</p>
    </ItemComponent>
  );
};

export default MenuItem;
