import React from 'react';
import { PopupMenu } from 'brickyard-ui';

const ObservationRelationHeader = ({
  info,
  unrelateAll,
  relateAll,
  deleteAll,
  deleteUnrelated
}) => {
  const RelateAllItem = () => (
    <div onClick={closePopupAfter(relateAll)}>
      {I18n.t('observations.observations.details.actions.relate_all')}
    </div>
  );
  const UnrelateAllItem = () => (
    <div onClick={closePopupAfter(unrelateAll)}>
      {I18n.t('observations.observations.details.actions.unrelate_all')}
    </div>
  );
  const DeleteAllItem = () => (
    <div onClick={closePopupAfter(deleteAll)}>
      {I18n.t('observations.observations.details.actions.delete_all')}
    </div>
  );
  const DeleteUnrelatedItem = () => (
    <div onClick={closePopupAfter(deleteUnrelated)}>
      {I18n.t('observations.observations.details.actions.delete_unrelated')}
    </div>
  );

  const closePopupAfter = action => () => {
    action();
    document.body.click();
  };

  const actions = [
    { render: RelateAllItem },
    { render: UnrelateAllItem },
    { render: DeleteAllItem },
    { render: DeleteUnrelatedItem }
  ];

  return (
    <div className="observation-relation-header">
      {info}
      <div className="observation-relation-header-title">
        <PopupMenu placement="bottom-start" actions={actions} rootClose={true} />
        <h5>{I18n.t('observations.observations.details.vehicle_observations')}</h5>
      </div>
    </div>
  );
};

export default ObservationRelationHeader;
