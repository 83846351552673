import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { Alert } from 'brickyard-ui';
import { useFormik } from 'formik';
import styled from 'styled-components';
import FormControls from '../../../components/FormControls';
import FormField from '../../../components/FormField';
import { useSelector, useDispatch } from 'react-redux';
import withApolloProvider from '../../../../../../../utils/withApolloProvider';
import { useUseCasesSlice } from '../slice';
import {
  CREATE_PRINT_TEMPLATE_MUTATION,
  DELETE_PRINT_TEMPLATE_MUTATION,
  UPDATE_PRINT_TEMPLATE_MUTATION
} from '../mutations';
import _ from 'lodash';

const StyledUseCaseMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 90px);
    max-height: 720px;
  }

  .footer {
    display: flex;
    margin: auto;
    width: 100%;
  }
`;

function UseCaseMobile({ useCase }) {
  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';
  const [submitError, setSubmitError] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const editEnabledRef = useRef();
  editEnabledRef.current = editEnabled;

  const { actions } = useUseCasesSlice();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      ticket_template: _.find(useCase.printTemplates, { kind: 'ticket' })?.body,
      warning_template: _.find(useCase.printTemplates, { kind: 'warning' })?.body
    },
    enableReinitialize: true,
    onSubmit: async values => {
      ['ticket', 'warning'].forEach(async kind => {
        let template = _.find(useCase.printTemplates, { kind: kind });
        if (_.isEmpty(values[kind + '_template'])) {
          if (template && template.id !== null) {
            await deletePrintTemplate({ variables: { id: template.id } });
          }
        } else {
          await saveTemplate(template, values[kind + '_template'], kind);
        }
      });
    }
  });

  const saveTemplate = async (template, value, kind) => {
    let params = {
      name: template?.name ?? 'Mobile' + _.capitalize(kind),
      body: value,
      kind: kind,
      useCaseId: parseInt(useCase.id)
    };

    console.log(template);
    if (!template || template.id === null) {
      await createPrintTemplate({ variables: params });
    } else {
      await updatePrintTemplate({ variables: { ...params, id: template.id } });
    }
  };

  const [createPrintTemplate] = useMutation(CREATE_PRINT_TEMPLATE_MUTATION, {
    onError: errorUpdate => {
      setSubmitError(
        formik.dirty && errorUpdate.message ? errorUpdate.message.toString().split(':') : ''
      );
    },
    onCompleted: () => {
      setSubmitError(false);
      dispatch(actions.fetchUseCase(useCase.id));
    }
  });

  const [updatePrintTemplate] = useMutation(UPDATE_PRINT_TEMPLATE_MUTATION, {
    onError: errorUpdate => {
      setSubmitError(
        formik.dirty && errorUpdate.message ? errorUpdate.message.toString().split(':') : ''
      );
    },
    onCompleted: () => {
      setSubmitError(false);
      dispatch(actions.fetchUseCase(useCase.id));
    }
  });

  const [deletePrintTemplate] = useMutation(DELETE_PRINT_TEMPLATE_MUTATION, {
    onError: errorUpdate => {
      setSubmitError(
        formik.dirty && errorUpdate.message ? errorUpdate.message.toString().split(':') : ''
      );
    },
    onCompleted: () => {
      setSubmitError(false);
      dispatch(actions.fetchUseCase(useCase.id));
    }
  });

  const handleEdit = () => {
    setEditEnabled(true);
  };

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    setEditEnabled(false);
  };

  return (
    <StyledUseCaseMobile>
      <div className="body">
        {submitError &&
          submitError.map((error, index) => {
            return (
              <Alert variant="danger" key={index}>
                {error}
              </Alert>
            );
          })}
        <FormField
          label={I18n.t('settings.print_templates.label.ticket_template')}
          field={
            <textarea
              id="ticket_template"
              name="ticket_template"
              rows={5}
              disabled={!editEnabled}
              onChange={formik.handleChange}
              value={formik.values.ticket_template ?? ''}
            />
          }
          error={formik.errors.ticket_template}
        />
        <FormField
          label={I18n.t('settings.print_templates.label.warning_template')}
          field={
            <textarea
              id="warning_template"
              name="warning_template"
              disabled={!editEnabled}
              rows={5}
              onChange={formik.handleChange}
              value={formik.values.warning_template ?? ''}
            />
          }
          error={formik.errors.warning_template}
        />
        {hasWritePermission && (
          <FormControls
            editEnabled={editEnabled}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleCancel={handleCancel}
            isDeletable={false}
          />
        )}
      </div>
    </StyledUseCaseMobile>
  );
}

export default withApolloProvider(UseCaseMobile);
