import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
  width: 100%;
  font-size: 13px;
  color: #000000;
  align-items: flex-start;
  margin: 0 0 3px;

  .info-wrapper {
    .date {
      margin: 0 8px 0 0;
    }
  }

  a {
    color: #005dc1;

    &:hover {
      color: #005dc1;
    }
  }

  p {
    margin: 0;
    color: #000;
  }
`;

const MiscListItem = ({ date, author, text, url, helper }) => {
  return (
    <Wrapper>
      <div className="flex-between info-wrapper">
        <div>
          <span className="date">{date}</span>
          <span className="author">{author}</span>
        </div>
        {helper}
      </div>
      {url ? (
        <a rel="noopener noreferrer" target="_blank" href={url}>
          {text}
        </a>
      ) : (
        <p>{text}</p>
      )}
    </Wrapper>
  );
};

export default MiscListItem;
