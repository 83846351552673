import { string } from 'yup';

import { requiredMsg, isCameraSystem } from './CommonTicketSchema';

const customIsRequired = string().when('explainRightTranslator', {
  is: true,
  then: string()
    .ensure()
    .required(requiredMsg),
  otherwise: string().ensure()
});

const statementsValidations = {
  statementOfficer: string().when('type', {
    is: 'taxi',
    then: string()
      .ensure()
      .required(requiredMsg),
    otherwise: string().ensure()
  }),
  statementSuspect: string().when(['type', 'sourceSystem', 'personalInformationValidation'], {
    is: (type, sourceSystem, personalInformationValidation) =>
      (type === 'juridical' &&
        !isCameraSystem(sourceSystem) &&
        personalInformationValidation === 'required') ||
      type === 'taxi',
    then: string()
      .ensure()
      .required(requiredMsg),
    otherwise: string().ensure()
  }),
  cautie: string()
    .when(['type', 'sourceSystem'], {
      is: (type, sourceSystem) => !isCameraSystem(sourceSystem) || type === 'taxi',
      then: string()
        .ensure()
        .matches(/\d{0,}$/, I18n.t('activerecord.attributes.ticket.cautie_messages.missing')),
      otherwise: string().ensure()
    })
    .when('personalInformationValidation', {
      is: 'required',
      then: string()
        .ensure()
        .required(requiredMsg),
      otherwise: string().ensure()
    })
    .when(['type', 'suspectLastname', 'sourceSystem'], {
      is: (type, suspectLastname, sourceSystem) =>
        type === 'juridical' && suspectLastname && !isCameraSystem(sourceSystem),
      then: string()
        .ensure()
        .matches(/\d{1,}$/, requiredMsg),
      otherwise: string().ensure()
    }),
  languageOfInterrogation: customIsRequired,
  interpreterNumber: customIsRequired
};

export default statementsValidations;
