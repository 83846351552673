import React, { useEffect } from 'react';
import { Loading, InfoIcon, FaSearch } from 'brickyard-ui';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useApiSearch } from '@/hooks/useApiSearch';

dayjs.extend(customParseFormat);

const genderMap = {
  M: 'MALE',
  V: 'FEMALE'
};
const keyMap = {
  first_name: 'suspectFirstnames',
  prefix_last_name: 'suspectMiddlename',
  last_name: 'suspectLastname',
  gender_indication: 'suspectGender',
  birth_date: 'suspectBirthDate',
  birth_place: 'suspectBirthCity',
  country_of_birth: 'suspectBirthCountry',
  residence_street_name: 'suspectResidenceStreet',
  residence_house_number: 'suspectResidenceHousenumber',
  residence_house_number_addition: 'suspectResidenceHousenumberAddition',
  residence_postcode: 'suspectResidenceZipcode',
  residence_city: 'suspectResidenceCity',
  residence_country: 'suspectResidenceCountry'
};

const SSNSearch = ({ onChange, onError, ssn }) => {
  const { data, error, loading, fetchData } = useApiSearch(`/services/bvbsn/${ssn}`, parseData);

  function parseData(rawData) {
    const suspectIsResident = rawData.citizen_information?.residence_country === 'Nederland';
    const remappedInfo = Object.entries(keyMap).reduce(
      (newObj, [kebabKey, camelizedKey]) => ({
        ...newObj,
        [camelizedKey]: rawData.citizen_information[kebabKey] || ''
      }),
      { suspectIsResident }
    );
    return {
      ...remappedInfo,
      suspectGender: genderMap[remappedInfo.suspectGender],
      suspectBirthDate: dayjs(remappedInfo.suspectBirthDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
    };
  }

  useEffect(() => {
    data && onChange(data);
  }, [data]);

  useEffect(() => {
    error && onError(error);
  }, [error]);

  if (!ssn) return null;

  return (
    <i className="clickable" onClick={fetchData}>
      {loading ? (
        <Loading size="sm" />
      ) : (
        <InfoIcon
          text={I18n.t('tickets.form.sections.suspect.search_ssn')}
          size="sm"
          Icon={FaSearch}
        />
      )}
    </i>
  );
};

export default SSNSearch;
