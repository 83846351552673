import React from 'react';
import styled from 'styled-components';
import { BiFullscreen } from 'react-icons/bi';

const FullscreenButtonPage = styled.div`
  .full_screen {
    &:hover {
      cursor: pointer;
    }
  }
`;
const FullscreenButton = ({ onClick }) => {
  return (
    <FullscreenButtonPage>
      <BiFullscreen onClick={onClick} size={22} className="full_screen pt-2" />
    </FullscreenButtonPage>
  );
};

export default FullscreenButton;
