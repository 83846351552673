import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import 'dayjs/locale/nl';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/da';
import 'dayjs/locale/es';
import 'dayjs/locale/sv';

dayjs.extend(LocalizedFormat);

/**
 * This function helps standardise the date format on this project.
 * It formats using dayjs library with the "L" format and the locale that
 * is currently set on I18n object. For further informations about the format
 * check dayjs docs (Localized formats): https://day.js.org/docs/en/display/format
 * Please use it when the hook is not an option (e.g. on loops where you edit
 * the object before showing the information).
 *
 * Maybe the format should be, by default 'L LT', but this is being rolled back due
 * to back compatibility with the old UI.
 *
 * TODO: Define and set a proper localized default format
 *
 * @param {Date} date - Date to be parsed
 * @param {String} format - optional, check dayjs lib to see all available formats
 * @param {String} locale - optional, for this project choose one of: nl, en, fr, de, da, es or sv. By default it's the I18n locale
 * @returns {String} Date formatted according to the params.
 *
 */
export default (date, format = 'DD-MM-YYYY HH:mm', locale = I18n.locale) => {
  if (!date) return null;
  dayjs.locale(locale === 'en-AU' ? 'en' : locale);
  return dayjs(date).format(format);
};
