import React from 'react';
import styled from 'styled-components';
import { isNil } from 'ramda';
import DeleteIcon from '@/assets/img/close.svg';
import Select from '../../../../Select';
import Label from './Label';
import ErrorWrapper from './ErrorWrapper';

const StyledLabel = styled(Label)`
  position: relative;

  .error-message {
    bottom: -16px;
  }
`;

const InputWrapper = styled.div`
  align-items: center;
  width: calc(100% + ${props => (props.canClear ? 21 : 0)}px);

  button {
    margin: 0 5px;
    background: none;
    border: none;
    padding: 0;
  }

  &.is-invalid {
    border-color: #dc3545;

    &:focus {
      box-shadow: 0px 0px 4px #dc3545;
    }
  }
`;

const LabelSelect = ({
  label,
  name,
  onChange,
  value,
  placeholder,
  options,
  disabled,
  readOnly,
  readOnlyValue,
  error,
  hasClear
}) => {
  const InputComponent = (
    <Select
      disabled={disabled}
      name={name}
      onChange={onChange}
      value={value}
      options={options}
      placeholder={placeholder}
    />
  );

  if (readOnly) {
    return (
      <Label>
        {label}
        <span className="readonly-value">
          {readOnlyValue ?? (value && options.find(obj => obj.id === value.toString())?.name)}
        </span>
      </Label>
    );
  }

  const canClear = hasClear && value && !isNil(placeholder);

  return (
    <StyledLabel>
      {label}
      <>
        <InputWrapper className="flex-between" canClear={canClear}>
          {InputComponent}
          {canClear && (
            <button
              type="button"
              onClick={e => {
                e.preventDefault();
                onChange({ target: { name, value: placeholder } });
              }}
            >
              <img src={DeleteIcon} />
            </button>
          )}
        </InputWrapper>
        {error && <ErrorWrapper className="error-message">{error}</ErrorWrapper>}
      </>
    </StyledLabel>
  );
};

export default LabelSelect;
