import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button } from 'brickyard-ui';
import { useFormik } from 'formik';
import { Modal, Form } from 'react-bootstrap';
import { GET_EXEMPTION_TYPES } from '../queries/cop/exemptions/exemption_types';

const RequestExemptionButton = ({ onSubmit, disabled = false }) => {
  const [showModal, setShowModal] = useState(false);

  const { data: exemptionTypesData } = useQuery(GET_EXEMPTION_TYPES, {
    variables: { nonCopSettingsScope: true }
  });
  const getFormikData = () => {
    return exemptionTypesData && exemptionTypesData.exemptionTypes.length > 0
      ? {
          exemptionTypeId: exemptionTypesData?.exemptionTypes[0].id,
          comment: ''
        }
      : {
          exemptionTypeId: null,
          comment: ''
        };
  };

  const formik = useFormik({
    initialValues: getFormikData(),
    onSubmit: values => {
      onSubmit({
        ...values,
        type: 'exemption',
        toString: () => 'exemption'
      });
    },
    enableReinitialize: true
  });

  return (
    <>
      {disabled ? (
        <p className="disabled-exemption-label">
          {I18n.t('use_case.result.list.request_exemption_disabled')}
        </p>
      ) : (
        <Button onClick={() => setShowModal(true)} variant={'outline-by-secondary'} size="sm">
          {I18n.t('use_case.result.list.request_exemption')}
        </Button>
      )}

      {showModal && exemptionTypesData && (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="md">
          <Modal.Header closeButton>
            <Modal.Title>{I18n.t('use_case.result.exemption_form.title')}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              {exemptionTypesData && (
                <Form.Group>
                  <Form.Label>
                    {I18n.t('use_case.result.exemption_form.exemption_type_label')}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    id="exemptionTypeId"
                    name="exemptionTypeId"
                    value={formik.values.exemptionTypeId}
                    onChange={formik.handleChange}
                  >
                    {exemptionTypesData.exemptionTypes.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label>{I18n.t('use_case.result.exemption_form.comment_label')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  id="comment"
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              {I18n.t('use_case.result.exemption_form.cancel_button')}
            </Button>
            <Button variant="primary" onClick={formik.handleSubmit}>
              {I18n.t('use_case.result.exemption_form.submit_button')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default RequestExemptionButton;
