import React, { useState } from 'react';

import Api from '@/utils/Api';

import { Row, Col, FaChevronRight, Button } from 'brickyard-ui';

import CorrectionCard from './CorrectionCard';

const AreaOccupancyCard = ({ area, toggleCard, setToggleCard }) => {
  const [prisCorrection, setPrisCorrection] = useState(area.correction || 0);
  const [occupiedSpots, setOccupiedSpots] = useState(area.currentOccupancy);

  const sendOpenData = async reporterId => {
    try {
      await Api.patch(`/administration/counting/reporters/${reporterId}/upload_opendata`);
    } catch (err) {
      console.log(err);
    } finally {
      location.reload();
    }
  };

  const prisDisplayValue = (maxVehicles, occupiedSpots, prisCorrection) => {
    const val = maxVehicles - occupiedSpots - prisCorrection;
    if (val >= 0) {
      return val;
    }
    return 0;
  };

  return (
    <div key={area.id}>
      <Row className="occupancy-item" noGutters>
        <Col xs="8" lg="6" xl="4" className="info">
          <Row noGutters>
            <Col xs="8" className="header-container">
              <header>
                <h3>{area.name}</h3>
              </header>
            </Col>
            <Col xs="4" className="occupancy-labels">
              <main>
                <h5 className="text-success">
                  {I18n.t('counting_reporters.dashboard.pris_display')}
                </h5>
                <h4>{prisDisplayValue(area.maxVehicleNumber, occupiedSpots, prisCorrection)}</h4>
              </main>

              <footer>
                {I18n.t('counting_reporters.dashboard.capacity')} {area.maxVehicleNumber}
              </footer>
            </Col>
          </Row>
        </Col>
        <Col xs="1">
          <i
            className={toggleCard === area.id ? 'opened-occupancy' : ''}
            onClick={() => setToggleCard(toggleCard === area.id ? null : area.id)}
          >
            <FaChevronRight size="25" />
          </i>
        </Col>
        <Col xs="3" lg="5" className="occupancy-card-container">
          {toggleCard === area.id && (
            <CorrectionCard
              areaId={area.id}
              reporterId={area.reporterId}
              onCancel={() => setToggleCard(null)}
              maxVehicles={area.maxVehicleNumber}
              prisCorrection={prisCorrection}
              setPrisCorrection={setPrisCorrection}
              occupiedSpots={occupiedSpots}
              setOccupiedSpots={setOccupiedSpots}
              prisDisplayValue={prisDisplayValue}
            />
          )}
        </Col>
      </Row>
      {area.spdpv2 && (
        <div className="report-info-container">
          <Row>
            <Col className="title">{I18n.t('counting_reporters.dashboard.open_data_upload')}</Col>
          </Row>
          <Row>
            <Col>
              {I18n.t('counting_reporters.dashboard.last_upload_sent')}:{' '}
              {area.lastReportAt?.toLocaleString() || '-'}
              <span className="status-header">
                {I18n.t('counting_reporters.dashboard.status')}:
                {area.lastReportAt ? (
                  <span className={'status ' + area.lastReportStatus}>
                    {area.lastReportStatus.toUpperCase()}
                  </span>
                ) : (
                  '-'
                )}
              </span>
              <span className="error-message">{area.lastReportErrors}</span>
            </Col>
          </Row>
          <Row>
            <Col xs="8" lg="6" xl="4">
              <Button
                className="action-buttons float-right"
                variant="by-primary"
                size="sm"
                onClick={() => sendOpenData(area.reporterId)}
              >
                {I18n.t('counting_reporters.dashboard.send_now')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AreaOccupancyCard;
