import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMobileCamerasState, useMobileCamerasSlice } from './slice';
import { Alert, Button, Loading } from 'brickyard-ui';
import Table from '../../components/Table';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import CameraForm from './components/CameraForm';

const MobileCamerasPage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .left-panel {
    display: flex;
    flex-direction: column;
    width: calc(100% * 1 / 3);
    height: 100%;

    .left-panel-header {
      display: flex;
      max-height: 100px;

      h3 {
        margin: 15px;
        color: #777777;
        font-weight: 600;
      }

      button {
        margin: 15px;
        margin-left: auto;
      }
    }

    .left-panel-body {
      display: flex;
      margin: 30px;
      width: calc(100% - 60px);
      height: calc(100% - 20px);

      .loading-box {
        margin: auto;
      }
    }
  }

  .status-cell {
    display: flex;

    .status-label {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      display: inline-block;
      padding: 0px 4px;
      border: 1px solid #ffffff;
      border-radius: 2px;
    }

    .warn-label {
      background: rgba(247, 194, 68, 0.26);
      color: rgb(247, 194, 68);
    }

    .ok-label {
      background: rgba(0, 199, 44, 0.26);
      color: rgb(0, 199, 44);
    }
  }

  .alert {
    position: fixed;
    left: 55%;
    transform: translate(-50%, 0);
    width: 85%;
    margintop: 20px;
    textalign: center;
  }
`;

const MobileCameras = () => {
  const { actions } = useMobileCamerasSlice();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.fetchCameras());

    return () => {
      dispatch(actions.clearData());
    };
  }, [dispatch]);

  const state = useSelector(selectMobileCamerasState);

  const onCloseAlert = () => {
    dispatch(actions.setBackendValidationError(null));
  };

  const getStatusLabelClass = status => {
    let klass = 'status-label ';

    return (klass += status === 'enabled' ? 'ok-label' : 'warn-label');
  };

  const columns = [
    {
      key: 'name',
      header: I18n.t('settings.table_name_header'),
      sortable: true
    },
    {
      key: 'status',
      header: I18n.t('settings.table_status_header'),
      sortable: true,
      cell: row => (
        <div className="status-cell">
          <span className={getStatusLabelClass(row.status)}>{row.status.toUpperCase()}</span>
        </div>
      )
    }
  ];

  const onRowClick = id => {
    history.push({ pathname: `${match.url}/${id}`, search: location.search });
  };

  const onNewButtonClick = () => {
    history.push({ pathname: `${match.url}/new`, search: location.search });
  };

  const onFormClose = () => {
    history.push({ pathname: match.url, search: location.search });
    dispatch(actions.storeCamera(null));
    dispatch(actions.setBackendValidationError(null));
  };

  return (
    <MobileCamerasPage>
      <div className="left-panel">
        <div className="left-panel-header">
          <h3>{I18n.t('settings.mobile_cameras_label')}</h3>
          {state.permission === 'copSettingsWrite' && (
            <Button variant="outline-by-secondary" onClick={onNewButtonClick}>
              {I18n.t('settings.new_mobile_camera_button')}
            </Button>
          )}
        </div>
        <div className="left-panel-body">
          {state.loading && !state.cameras.length ? (
            <div className="loading-box">
              <Loading />
            </div>
          ) : (
            <Table
              columns={columns}
              items={state.cameras}
              onRowClick={onRowClick}
              selectedId={state.selectedCamera?.id}
            />
          )}
        </div>
      </div>

      <Switch>
        <Route path={`${match.path}/:id`}>
          <CameraForm
            camera={state.selectedCamera}
            onClose={onFormClose}
            isLoading={state.loading}
            backendValidationError={state.backendValidationError}
            permission={state.permission}
          />
        </Route>
      </Switch>

      {state.backendValidationError && (
        <Alert
          onClose={() => {
            onCloseAlert();
          }}
          variant="danger"
          dismissible
        >
          {state.backendValidationError}
        </Alert>
      )}
    </MobileCamerasPage>
  );
};

export default MobileCameras;
