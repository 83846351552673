import { useQuery } from '@apollo/client/react';
import { GET_ENF_CONFIGURATIONS } from '../tickets';

const useConfigOptions = () => {
  const { data, loading } = useQuery(GET_ENF_CONFIGURATIONS);

  const options = data ? data.enforcementConfigurations : [];

  return [options, loading];
};

export default useConfigOptions;
