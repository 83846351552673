import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  font-size: 12px;
  width: 100%;
  margin: 6px 0;

  label {
    font-size: 1em;
    margin: 0;
  }

  input {
    font-size: 1em;
    padding: 1px 2px;
    height: 34px;
  }

  .dropdown-menu {
    font-size: 12px;
    padding: 0;

    > span {
      padding: 0.25rem 2px;
    }
  }
`;
