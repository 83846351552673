import React from 'react';

import { Alert, Col, Row } from 'brickyard-ui';
import formatDate from '@/utils/formatDate';
import TestBoundary from '@/utils/TestBoundary';
import ResetUseCaseResult from '../../Cop/ResetUseCaseResult';

const UseCaseResultProcessItem = ({ useCaseResult, onReset, action }) => {
  const actionVariants = {
    delete: 'by-dark',
    warning: 'warning',
    ticket: 'danger'
  };

  return (
    <Alert variant={actionVariants[action]}>
      <Row>
        <Col xs="3">
          <span>{formatDate(useCaseResult.observedAt)}</span>
        </Col>

        <Col xs="3">
          <span>{useCaseResult.camera.name}</span>
        </Col>

        <Col xs="3">
          <span>{useCaseResult.vehicleLicensePlateNumber}</span>
        </Col>

        <Col xs="1">
          <span>{useCaseResult.countryCode}</span>
        </Col>

        <Col xs="1">
          <span>{(useCaseResult.accuracy * 100).toFixed(0)} %</span>
        </Col>

        <Col xs="1">
          <TestBoundary>
            <ResetUseCaseResult useCaseResultId={useCaseResult.id} onReset={onReset} />
          </TestBoundary>
        </Col>
      </Row>
    </Alert>
  );
};

export default UseCaseResultProcessItem;
