import { useState, useEffect } from 'react';

const useContinuousPagination = (minSize = 3) => {
  const [size, setSize] = useState(minSize);
  const [first, setFirst] = useState(minSize);
  const [last, setLast] = useState(null);
  const [after, setAfter] = useState(null);
  const [before, setBefore] = useState(null);

  useEffect(() => {
    setFirst((first && size) || null);
    setLast((last && size) || null);
  }, [size]);

  const start = () => {
    setBefore(null);
    setLast(null);

    setAfter(null);
    setFirst(size);
  };

  const next = endCursor => {
    setBefore(null);
    setLast(null);

    setAfter(endCursor);
    setFirst(size);
  };

  const previous = startCursor => {
    setAfter(null);
    setFirst(null);

    setBefore(startCursor);
    setLast(size);
  };

  const end = () => {
    setAfter(null);
    setFirst(null);

    setBefore(null);
    setLast(size);
  };

  return {
    size,
    setSize: size => setSize(Math.max(size, minSize)),
    first,
    last,
    after,
    before,
    start,
    next,
    previous,
    end
  };
};

export default useContinuousPagination;
