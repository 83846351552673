import React from 'react';
import styled from 'styled-components';
import Home from '@/assets/img/home_icon.svg';
import { useLocation } from 'react-router-dom';
import MenuItem from '../../Settings/components/MenuItem';

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  height: 100%;
  background-color: #ffffff;
  border-right: 1px solid #e5e5e5;

  a {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .home-link {
    display: flex;
    margin: 5px;
    border-radius: 5px;

    .home-link-text {
      display: flex;
      margin: auto auto auto 10px;

      p {
        color: #000000;
        margin: auto;
      }
    }
  }

  .home-link:hover {
    background-color: #f36b2240;
  }

  .home-link-spacer {
    height: 50px;
  }
`;

const Menu = () => {
  const location = useLocation();

  const menuItems = [
    { link: '/cop/account_settings/events', label: I18n.t('settings.events_label') }
  ];

  const isItemSelected = item => {
    return location.pathname.match(`${item.link}[/]{0,1}(\\w{1,}|\\d{1,}|$)`);
  };

  return (
    <StyledMenu>
      <div className="home-link">
        <a href="/">
          <img src={Home} alt="home" />
          <div className="home-link-text">
            <p>{I18n.t('settings.home_label')}</p>
          </div>
        </a>
      </div>

      <div className="home-link-spacer" />

      {menuItems.map(item => {
        return (
          <MenuItem
            key={item.link}
            link={item.link}
            label={item.label}
            isSelected={isItemSelected(item)}
          />
        );
      })}
    </StyledMenu>
  );
};

export default Menu;
