import { useQuery, useMutation, gql } from '@apollo/client';
import React from 'react';
import { Modal, Alert, Card, Button, Accordion, Loading } from 'brickyard-ui';

import ObservationProcessItem from './ObservationProcessItem';
import { GET_MIN_OBSERVATION } from '@/components/queries/observations';
import { RESET_OBSERVATIONS } from '@/components/mutations/observations';

export const PROCESS_VALIDATED = gql`
  mutation processValidated($areaId: ID!) {
    processValidated(areaId: $areaId) {
      id
    }
  }
`;

const ObservationProcessSummary = ({
  areaId,
  observations,
  onHide,
  onProcess,
  onReset,
  onResetAll,
  show
}) => {
  const sections = new Set(
    Object.values(observations)
      .map(meta => meta.action)
      .filter(Boolean)
  );

  const { loading, error, data } = useQuery(GET_MIN_OBSERVATION, {
    variables: { id: Object.keys(observations), areaId, first: Object.keys(observations).length }
  });

  const [processValidated, { loading: processingLoading, error: creatingErr }] = useMutation(
    PROCESS_VALIDATED
  );

  const [resetAll, { loading: resetAllLoading, error: resetAllErr }] = useMutation(
    RESET_OBSERVATIONS,
    {
      variables: {
        ids: Object.keys(observations)
      },
      onCompleted: onResetAll
    }
  );

  const handleProcess = async () => {
    await processValidated({
      variables: { areaId }
    });
    onProcess();
    onHide();
  };

  if (error || loading) return null;

  if (!observations || !Object.values(observations)) {
    onHide();
    return null;
  }

  return (
    <Modal show={show} onHide={onHide} size="xl" className="observation-process-summary">
      <Modal.Body>
        {' '}
        <Accordion defaultActiveKey="-1">
          {Array.from(sections).map((action, idx) => (
            <Card size="sm" key={action}>
              <Card.Header>
                <Accordion.Toggle eventKey={`${idx}`} as="span">
                  {I18n.t(
                    `observations.observations.details.${
                      action === 'delete' ? 'actions.delete' : `to_${action}`
                    }`
                  )}{' '}
                  ({Object.values(observations).filter(o => o.action === action).length})
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={`${idx}`}>
                <Card.Body>
                  {data &&
                    data.observations.nodes
                      .filter(o => (observations[o.id] && observations[o.id].action) === action)
                      .map(o => (
                        <ObservationProcessItem
                          observation={o}
                          onReset={onReset}
                          action={action}
                          key={o.id}
                        />
                      ))}

                  {resetAllErr && <Alert variant="danger">{resetAllErr.toString()}</Alert>}
                  {creatingErr && <Alert variant="danger">{creatingErr.toString()}</Alert>}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </Modal.Body>
      <Modal.Footer className="summary-footer">
        <Button variant="danger" onClick={resetAll} disabled={resetAllLoading || processingLoading}>
          {I18n.t('observations.observations.process.reset_all')}
        </Button>

        <Button variant="by-dark" onClick={onHide} disabled={resetAllLoading || processingLoading}>
          {I18n.t('actions.cancel')}
        </Button>

        <Button
          variant="by-primary"
          onClick={handleProcess}
          disabled={resetAllLoading || processingLoading}
        >
          {I18n.t('observations.observations.process.process')}
          {processingLoading && <Loading size="sm" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ObservationProcessSummary;
