import React, { useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { OBJECT_TYPES } from '../../../../../../../../utils/consts';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import { objectTypeCheckerSchema } from './validationSchema';
import FormFieldBlock from '../../../../components/FormFieldBlock';
import ObjectTypeField from './fields/ObjectTypeField';
import FormFieldSmall from '../../../../components/FormFieldSmall';
import LabelToggle from '../../../../../../Ticket/Cards/components/LabelToggle';

function ObjectTypeChecker({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();

  // object types
  const selectedObjectTypes = selectedProcessor?.config?.object_type
    ? OBJECT_TYPES?.filter(object =>
        selectedProcessor?.config?.object_type?.find(selectedObject => selectedObject == object.id)
      )
    : [];

  const availableObjectTypes = OBJECT_TYPES?.filter(
    object => !selectedObjectTypes?.find(selectedObject => selectedObject.id === object.id)
  );

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      selectedObjectTypes,
      availableObjectTypes,
      object_type_confidence: selectedProcessor?.config?.object_type_confidence ?? '',
      exempt_on_error: !!selectedProcessor?.config?.exempt_on_error
    },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, [
          'id',
          'name',
          'selectedObjectTypes',
          'object_type_confidence',
          'exempt_on_error'
        ])
      );
    },
    validationSchema: objectTypeCheckerSchema
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    let config = {
      object_type: values.selectedObjectTypes?.map(c => c.id),
      exempt_on_error: values.exempt_on_error
    };

    if (values.object_type_confidence) {
      config = {
        ...config,
        object_type_confidence: parseInt(values.object_type_confidence)
      };
    }

    const params = {
      id: values.id,
      name: values.name,
      config
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <div className={selectedProcessor?.className ?? ''}>
      <div className="content">
        <FormFieldBlock
          field={
            <>
              <div className="alert alert-primary mb-1" role="alert">
                {I18n.t('settings.pipelines.processors.messages.on_present_data_skip')}
              </div>
            </>
          }
        />

        <FormFieldBlock
          field={
            <>
              <FormFieldSmall
                field={
                  <LabelToggle
                    id="exempt_on_error"
                    name="exempt_on_error"
                    isOn={formik.values.exempt_on_error}
                    onChange={formik.handleChange}
                    disabled={!editEnabled}
                  />
                }
                error={formik.errors.exempt_on_error}
                label={
                  formik.values.exempt_on_error ? (
                    <div className="alert alert-success alert-switch" role="alert">
                      {I18n.t('settings.pipelines.processors.messages.on_missing_exempt')}
                    </div>
                  ) : (
                    <div className="alert alert-primary alert-switch" role="alert">
                      {I18n.t('settings.pipelines.processors.messages.on_missing_continue')}
                    </div>
                  )
                }
              />
            </>
          }
        />

        <FormFieldBlock
          field={
            <>
              <div className="alert alert-success" role="alert">
                {I18n.t('settings.pipelines.processors.form.object_type_checker.header_info_1')}
              </div>
              <div className="alert alert-success" role="alert">
                {I18n.t('settings.pipelines.processors.form.object_type_checker.header_info_2')}
              </div>
              <div className="alert alert-success" role="alert">
                {I18n.t('settings.pipelines.processors.form.object_type_checker.header_info_3')}
              </div>
            </>
          }
        />

        <ObjectTypeField
          formik={formik}
          formLabel={I18n.t('settings.pipelines.processors.form.object_type_checker.object_type')}
          editEnabled={editEnabled}
          isSwitchable={true}
        />

        <FormFieldBlock
          label={I18n.t(
            'settings.pipelines.processors.form.object_type_checker.object_type_confidence'
          )}
          className="object_type_confidence"
          field={
            <input
              type="number"
              id="object_type_confidence"
              name="object_type_confidence"
              value={formik.values.object_type_confidence ?? ''}
              disabled={!editEnabled}
              onChange={formik.handleChange}
            />
          }
          error={formik.errors.object_type_confidence}
        />
      </div>
    </div>
  );
}

export default ObjectTypeChecker;
