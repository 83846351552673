import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'brickyard-ui';

import useDebounce from '@/hooks/useDebounce';
import Api from '@/utils/Api';

const CitySearch = ({ onChange, value, name, disabled, readOnly, size, isInvalid, cityType }) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const debouncedSearch = useDebounce(search, 400);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  useEffect(() => {
    const fetchCities = async () => {
      let endpoint = `/cities/search.json?q=${debouncedSearch}`;
      switch (cityType) {
        case 'enforcement':
          endpoint = `/cities/enforcement_cities.json?q=${debouncedSearch}`;
          break;
        case 'municipalities':
          endpoint = `/municipalities/search.json?q=${debouncedSearch}`;
          break;
        default:
          // use initial endpoint value
          break;
      }
      const { data } = await Api.get(endpoint);
      setList(data.map(c => c.name));
      setLoading(false);
    };

    if (debouncedSearch && !disabled) {
      setLoading(true);
      fetchCities();
    }
  }, [debouncedSearch]);

  const selectCity = value => {
    setSearch(value);
    onChange({ target: { value, name } });
  };

  return (
    <AutoComplete
      inputProps={{
        value: search,
        autoComplete: 'off',
        onChange: evt => {
          if (!evt.target.value) {
            selectCity('');
          } else {
            setSearch(evt.target.value);
          }
        },
        disabled,
        readOnly,
        size,
        name,
        isInvalid
      }}
      onSelect={selectCity}
      loading={loading}
      list={list}
    />
  );
};

export default CitySearch;
