export const SHOW_LOGGED_OUT_MODAL = 'showLoggedOutModal';
export const TICKETS_FILTER_KEY = 'ticketsFilter';
export const TICKETS_PAGINATION_KEY = 'ticketsPagination';
export const OBSERVATION_OBSERVED_AT_ASC = 'OBSERVATION_OBSERVED_AT_ASC';
export const EXEMPTION_RESULT_VALUES = () => [
  { label: I18n.t(`settings.result.denied`).toUpperCase(), value: 'denied' },
  { label: I18n.t(`settings.result.approved`).toUpperCase(), value: 'approved' }
];
export const COP_PERM_WRITE = 'copSettingsWrite';

export const LABEL_BY_TYPE = {
  ticket: I18n.t('use_case.result.details.repeat_ticket'),
  warning: I18n.t('use_case.result.details.repeat_warning')
};

export const DEFAULT_IMAGE_FILTER_OPTIONS = [
  {
    name: 'Brightness',
    property: 'brightness',
    value: 100,
    range: {
      min: 0,
      max: 200
    },
    unit: '%'
  },
  {
    name: 'Contrast',
    property: 'contrast',
    value: 100,
    range: {
      min: 0,
      max: 200
    },
    unit: '%'
  }
];

export const AVAILABLE_PROCESSOR_CLASSES = [
  { className: 'BlacklistChecker' },
  { className: 'BlueZoneChecker' },
  { className: 'EmailNotifier' },
  { className: 'EnvironmentalRightChecker' },
  { className: 'EventChecker' },
  { className: 'ExemptionChecker' },
  { className: 'ExemptionRuleChecker' },
  { className: 'ExternalRightChecker' },
  { className: 'FollowUpDecorator' },
  { className: 'FooterDecorator' },
  { className: 'ImageDecorator' },
  { className: 'MessagePusherNotifier' },
  { className: 'ObjectTypeChecker' },
  { className: 'OffenseDecorator' },
  { className: 'P1ExternalBlacklistChecker' },
  { className: 'PermitChecker' },
  { className: 'RailwayFooterDecorator' },
  { className: 'RailwayViolationChecker' },
  { className: 'RoofDecorator' },
  { className: 'ShowSuspectDecorator' },
  { className: 'SuspectBlurringDecorator' },
  { className: 'VehicleInfoDecorator' },
  { className: 'VideoDecorator' },
  { className: 'WhitelistChecker' }
];

export const VEHICLE_DIRECTIONS = [
  {
    id: 'away',
    name: 'Away'
  },
  {
    id: 'towards',
    name: 'Towards'
  },
  {
    id: 'unknown',
    name: 'Unknown'
  }
];

export const VEHICLE_EMISSION_CODES = [
  {
    id: '0',
    name: 'Euro 0'
  },
  {
    id: '1',
    name: 'Euro 1'
  },
  {
    id: '2',
    name: 'Euro 2'
  },
  {
    id: '3',
    name: 'Euro 3'
  },
  {
    id: '4',
    name: 'Euro 4'
  },
  {
    id: '5',
    name: 'Euro 5'
  },
  {
    id: '6',
    name: 'Euro 6'
  },
  {
    id: 'EEV',
    name: 'EEV'
  },
  {
    id: 'R',
    name: 'R'
  },
  {
    id: 'Z',
    name: 'Z'
  }
];

export const VEHICLE_EUROPEAN_CATEGORIES = [
  {
    id: 'L',
    name: 'L'
  },
  {
    id: 'L1',
    name: 'L1'
  },
  {
    id: 'L2',
    name: 'L2'
  },
  {
    id: 'L3',
    name: 'L3'
  },
  {
    id: 'L4',
    name: 'L4'
  },
  {
    id: 'L5',
    name: 'L5'
  },
  {
    id: 'L6',
    name: 'L6'
  },
  {
    id: 'L7',
    name: 'L7'
  },
  {
    id: 'M',
    name: 'M'
  },
  {
    id: 'M1',
    name: 'M1'
  },
  {
    id: 'M2',
    name: 'M2'
  },
  {
    id: 'M3',
    name: 'M3'
  },
  {
    id: 'N',
    name: 'N'
  },
  {
    id: 'N1',
    name: 'N1'
  },
  {
    id: 'N2',
    name: 'N2'
  },
  {
    id: 'N3',
    name: 'N3'
  },
  {
    id: 'T',
    name: 'T'
  },
  {
    id: 'G',
    name: 'G'
  },
  {
    id: 'SA',
    name: 'SA'
  },
  {
    id: 'SB',
    name: 'SB'
  },
  {
    id: 'SC',
    name: 'SC'
  },
  {
    id: 'SD',
    name: 'SD'
  }
];

export const VEHICLE_FUEL_TYPES = [
  {
    id: 'Benzine',
    name: 'Benzine'
  },
  {
    id: 'Diesel',
    name: 'Diesel'
  },
  {
    id: 'Elektriciteit',
    name: 'Elektriciteit'
  },
  {
    id: 'LNG',
    name: 'LNG'
  },
  {
    id: 'LPG',
    name: 'LPG'
  },
  {
    id: 'CNG',
    name: 'CNG'
  },
  {
    id: 'Waterstof',
    name: 'Waterstof'
  },
  {
    id: 'Alchol',
    name: 'Alchol'
  }
];

export const OBJECT_TYPES = [
  {
    id: 'car',
    name: 'Car'
  },
  {
    id: 'truck',
    name: 'Truck'
  },
  {
    id: 'bus',
    name: 'Bus'
  },
  {
    id: 'bicycle',
    name: 'Bicycle'
  },
  {
    id: 'motorbike',
    name: 'Motorbike'
  },
  {
    id: 'trailer',
    name: 'Trailer'
  }
];

export const BARRIER_STATUS = ['open', 'closed', 'opening', 'closing'];

export const ALLOWED_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'bmp', 'png', 'tif', 'tiff'];
export const ALLOWED_VIDEO_EXTENSIONS = ['mp4', 'avi', 'mpeg', 'mov', 'wmv', 'webm', 'flv'];

export const DATETIME_USED_IN_QUERY_OPTIONS = [
  {
    id: 'observation_time',
    name: I18n.t(
      'settings.pipelines.processors.form.external_right_checker.datetime_used_in_query_options.observation_time'
    )
  },
  {
    id: 'checking_time',
    name: I18n.t(
      'settings.pipelines.processors.form.external_right_checker.datetime_used_in_query_options.checking_time'
    )
  },
  {
    id: 'minutes_after_observation',
    name: I18n.t(
      'settings.pipelines.processors.form.external_right_checker.datetime_used_in_query_options.minutes_after_observation'
    )
  }
];
export const MINUTES_AFTER_OBSERVATION = 'minutes_after_observation';
export const CHECKING_TIME = 'checking_time';
export const OBSERVATION_TIME = 'observation_time';

export const ANPR_XML = 'anpr_xml';
export const JSON_PROTOCOLS = ['anpr_json', 'railway_json', 'bop'];

export const VEHICLE_INFO_RELATED_FIELDS = [
  'enabledVehicleTypes',
  'enabledEmissionCodes',
  'enabledEuropeanCategories',
  'enabledFuelTypes',
  'is_taxi',
  'enabledVehicleBuildYear',
  'enabledVehicleWidth',
  'enabledVehicleWeight'
];
