import React, { useRef } from 'react';
import { Button } from 'brickyard-ui';
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from 'react-icons/ai';

function MultiRecipientsField({ disabled, formik }) {
  const emailRef = useRef([]);
  const addRecipient = () => {
    if (
      emailRef.current[formik.values.recipients.length - 1]?.value !== '' &&
      !formik.errors.recipients
    ) {
      formik.setValues({
        ...formik.values,
        recipients: [...formik.values.recipients, '']
      });
    }
  };

  const removeRecipient = () => {
    if (formik.values.recipients.length > 1) {
      formik.setValues({
        ...formik.values,
        recipients: formik.values.recipients.filter(
          r => r !== emailRef.current[formik.values.recipients.length - 1]?.value
        )
      });
    }
  };

  return formik.values.recipients.map((recipient, index) => (
    <div key={index} className="recipient">
      <input
        id={`recipients[${index}]`}
        name={`recipients[${index}]`}
        type="email"
        onChange={formik.handleChange}
        value={recipient}
        disabled={disabled}
        size="30"
        required
        ref={el => (emailRef.current[index] = el)}
        placeholder={'default@default.com'}
      />

      <Button variant="outline-primary" disabled={disabled}>
        <i>
          <AiOutlinePlusSquare size={25} onClick={() => addRecipient()} />
        </i>
      </Button>

      <Button variant="outline-dark" disabled={disabled} onClick={() => removeRecipient()}>
        <i>
          <AiOutlineMinusSquare size={25} />
        </i>
      </Button>
    </div>
  ));
}

export default MultiRecipientsField;
