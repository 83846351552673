import React, { useState } from 'react';
import MenuItem from '../../../components/MenuItem';
import UseCasePipelineDetails from './UseCasePipelineDetails';
import UseCasePipelineModal from './UseCasePipelineModal';
import { Button } from 'brickyard-ui';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../slice';

function UseCasePipelines({ useCase }) {
  const { actions } = useUseCasesSlice();
  const [showPipelineModal, setShowPipelineModal] = useState(false);
  const [selectedPipelineId, setSelectedPipelineId] = useState(null);

  const state = useSelector(selectUseCasesState);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const menuItems = [];

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';

  useCase?.pipelines?.map(item =>
    menuItems.push({
      key: item.id,
      label: item.name,
      link: `${match.url.replace(/\/$/, '')}/${item.id}`
    })
  );

  // pipeline actions
  const onNewPipeline = () => {
    setSelectedPipelineId(state?.selectedPipeline?.id);
    dispatch(actions.storePipeline(null));
    setShowPipelineModal(true);
  };

  const onEditPipeline = () => {
    setSelectedPipelineId(null);
    setShowPipelineModal(true);
  };

  return (
    <div className="cop-uc-pipelines">
      <div className="body">
        <div className="details-left-panel">
          {hasWritePermission && (
            <Button
              variant="outline-by-secondary"
              className="new-pipeline-btn"
              onClick={() => onNewPipeline()}
            >
              {I18n.t('settings.new_pipeline_button')}
            </Button>
          )}

          <div className="pipeline-menu">
            {menuItems.map(item => (
              <MenuItem
                key={item.key}
                link={item.link}
                label={item.label}
                isSelected={location.pathname.includes(`/pipelines/${item.key}`)}
              />
            ))}
            {showPipelineModal && (
              <UseCasePipelineModal
                useCase={useCase}
                showPipelineModal={showPipelineModal}
                setShowPipelineModal={setShowPipelineModal}
                selectedPipelineId={selectedPipelineId}
              />
            )}
          </div>
        </div>
        <Switch>
          <Route path={`${match.path}/:id`}>
            <UseCasePipelineDetails useCase={useCase} onEditPipeline={onEditPipeline} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default UseCasePipelines;
