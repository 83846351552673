import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import 'styles/scenes/cop_settings.scss';
import Menu from './components/Menu';
import Events from './containers/Events';

const AccountSettings = () => {
  const match = useRouteMatch();

  return (
    <div className="cop_settings">
      <Menu />

      <Switch>
        <Route path={`${match.path}/events`}>
          <Events />
        </Route>
      </Switch>
    </div>
  );
};

export default AccountSettings;
