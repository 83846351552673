import { object } from 'yup';

import offenseValidations from './offenseValidations';
import vehicleValidations from './vehicleValidations';
import locationValidations from './locationValidations';
import suspectValidations from './suspectValidations';
import statementsValidations from './statemensValidations';

const TicketSchema = object().shape({
  ...offenseValidations,
  ...vehicleValidations,
  ...locationValidations,
  ...suspectValidations,
  ...statementsValidations
});

export default TicketSchema;
