import gql from 'graphql-tag';

export const GET_VALIDATED_OBSERVATIONS_BY_USER = gql`
  query GetValidatedObservationByUser($userId: ID!, $areaId: ID!) {
    observations(
      search: {
        areaId: $areaId
        validatedByIdEq: $userId
        statusIn: ["validated", "marked_for_deletion"]
      }
      first: 100
    ) {
      nodes {
        id
        attachments {
          id
        }
        validatedType
      }
    }
  }
`;

export const GET_VALIDATED_FOR_DELETION_BY_USER = gql`
  query GetValidatedForDeletionByUser($userId: ID!, $areaId: ID!) {
    observations(search: { areaId: $areaId, validatedForDeletionByIdEq: $userId }, first: 100) {
      nodes {
        id
        validatedForDeletion
      }
    }
  }
`;

export const MINIMAL_OBSERVATION_FRAG = gql`
  fragment MinimalObservationFrag on Observation {
    id
    observedAt
    vehicleHeading
    accuracy
    vehicleLicensePlateNumber
    countryCode
    status
    delayed
    exempt
    offenseCategory
    validatedForDeletion
    camera {
      name
    }
    area {
      id
    }
    validatedBy {
      id
      name
    }
    validatedForDeletionBy {
      id
      name
    }
    assignedTo {
      id
      name
    }
    delayed
  }
`;
export const GET_DETAILED_OBSERVATION = gql`
  query GetDetailedObservation($id: [ID!], $areaId: ID!) {
    observations(
      search: { idIn: $id, areaId: $areaId, delayedFilter: true, exemptFilter: true }
      first: 1
    ) {
      nodes {
        ...MinimalObservationFrag
        vehicleType
        vehicleMake
        vehicleColor
        vehicleLatitude
        vehicleLongitude
        offenseCode
        offenseCategory
        offenseLocationStreet
        rdwAdmittance
        environmentalClass
        speed
        delayedRecheckScheduledTime
        recheckedAt
        ticketable
        scancarParkingAreaCode
        area {
          id
        }
        files {
          url
          title
          kind
        }
        panoramaFiles {
          url
        }
        offense {
          id
          text
        }
        previousWarnings {
          count
          lastDatetime
          sourceSystem
        }
        previousTickets {
          count
          lastDatetime
          sourceSystem
        }
        relatedObservations {
          ...MinimalObservationFrag
          vehicleType
          vehicleMake
          vehicleColor
          vehicleLatitude
          vehicleLongitude
          offenseCode
          offenseCategory
          offenseLocationStreet
          rdwAdmittance
          environmentalClass
          speed
          delayedRecheckScheduledTime
          recheckedAt
          ticketable
          files {
            url
            title
            kind
          }
          panoramaFiles {
            url
          }
          offense {
            id
            text
          }
          previousWarnings {
            count
            lastDatetime
            sourceSystem
          }
          previousTickets {
            count
            lastDatetime
            sourceSystem
          }
        }
      }
    }
  }
  ${MINIMAL_OBSERVATION_FRAG}
`;

export const GET_MIN_OBSERVATION = gql`
  query GetMinObservation($id: [ID!], $areaId: ID!, $first: Int = 1) {
    observations(search: { idIn: $id, areaId: $areaId }, first: $first) {
      nodes {
        ...MinimalObservationFrag
      }
    }
  }
  ${MINIMAL_OBSERVATION_FRAG}
`;

export const GET_OBSERVATIONS = gql`
  query GetObservations(
    $search: ObservationSearch!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    observations(search: $search, first: $first, last: $last, after: $after, before: $before) {
      edges {
        cursor
        node {
          ...MinimalObservationFrag
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
    meta {
      observationsCountries(search: $search)
    }
  }
  ${MINIMAL_OBSERVATION_FRAG}
`;

export const GET_OBSERVATIONS_FOR_MAP = gql`
  query GetObservationsForMap($search: ObservationSearch!, $first: Int) {
    observations(search: $search, first: $first) {
      edges {
        cursor
        node {
          id
          vehicleLatitude
          vehicleLongitude
        }
      }
    }
  }
`;

export const GET_PRP_RESPONSES = gql`
  query GetPrpResponses($observationId: [ID!], $areaId: ID!) {
    observations(search: { idIn: $observationId, areaId: $areaId, exemptFilter: true }, first: 1) {
      nodes {
        prpResponses {
          webService
          mobileQueryTimestamp
          portalQuerySentAt
          reply
          response
        }
      }
    }
  }
`;

export const GET_EXEMPTIONS = gql`
  query GetExemptions($id: [ID!], $areaId: ID!) {
    observations(search: { idIn: $id, areaId: $areaId, exemptFilter: true }, first: 1) {
      nodes {
        observationExemptions {
          id
          daysOfTheWeek
          endDate
          endTime
          startDate
          startTime
          exemptionType
          exemptionHolder
        }
      }
    }
  }
`;

export const GET_EXEMPTIONS_RULES = gql`
  query GetExemptionsRules($id: [ID!], $areaId: ID!) {
    observations(
      search: { areaId: $areaId, delayedFilter: true, exemptFilter: true, idIn: $id }
      first: 1
    ) {
      nodes {
        exemptionRulesMatches {
          id
          appliedData {
            name
            cameras
            vehicleTypes
            vehicleDirections
            offenseCode
            typesOfFuel
            emissionCodes
            europeanCategories
            countries
            vehicleBuildYear
            vehicleMaxWeight
            vehicleMaxWidth
          }
          exemptionRuleId
        }
      }
    }
  }
`;
