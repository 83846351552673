import React, { useMemo } from 'react';
import { Row, Col } from 'brickyard-ui';

import ExemptedBadge from './ExemptedBadge';

const TabbedPanelVehicle = ({ useCaseResult, type }) => {
  const readOnlyLabels = useMemo(() => {
    return [
      {
        i18nTitle: I18n.t('use_case.result.details.emission_code'),
        attr: 'vehicleEmissionCode',
        position: 0
      },
      {
        i18nTitle: I18n.t('use_case.result.details.vehicle_width'),
        attr: 'vehicleWidth',
        position: 1
      },
      {
        i18nTitle: I18n.t('use_case.result.details.vehicle_max_weight'),
        attr: 'vehicleMaxWeight',
        position: 2
      },
      {
        i18nTitle: I18n.t('use_case.result.details.rdw_admittance'),
        attr: 'rdwAdmittance',
        position: 3
      },
      {
        i18nTitle: I18n.t('use_case.result.details.fuel_type'),
        attr: 'vehicleFuelType',
        position: 4
      }
    ];
  }, [useCaseResult, type]);

  const renderField = field => {
    return (
      useCaseResult[field.attr] || I18n.t('observations.observations.details.no_offense_detail')
    );
  };

  return (
    <>
      {readOnlyLabels.map(field => (
        <Row key={field.attr}>
          <Col xs="4">{field.i18nTitle}</Col>
          <Col xs="8" className="font-weight-bold">
            {renderField(field)}
          </Col>
        </Row>
      ))}
      {useCaseResult.exempt && (
        <Row>
          <Col xs="12">
            <ExemptedBadge />
          </Col>
        </Row>
      )}
    </>
  );
};

export default TabbedPanelVehicle;
