import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Badge } from 'brickyard-ui';
import { ShortDateFormat } from '../../../../../../../utils/DateFormats';
import formatDate from '../../../../../../../utils/formatDate';
import Table from '../../../../Settings/components/Table';

const StyledExemptionRequestsTable = styled.div`
  display: flex;
  width: 60%;

  .table-card {
    display: flex;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;

    flex-direction: column;

    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 3px;

    .table-card-header {
      display: flex;
      height: 3%;
      width: calc(100% - 40px);
      color: #ff6f00;
      margin: 20px;
    }

    .table-card-body {
      display: flex;
      width: calc(100% - 40px);
      height: calc(97% - 80px);
      margin: 20px;
      flex-direction: column;

      h6 {
        margin-left: 20px;
      }

      .badges-cell {
        .badge {
          color: #ffffff;
          width: 100px;
          text-transform: uppercase;
          font-weight: 600;

          &.badge-by-primary {
            background: #0049c0;
            border-color: #0049c0;
          }

          &.badge-by-secondary {
            background: #884fc5;
            border-color: #884fc5;
          }
        }
      }
    }
  }
`;

const ExemptionRequestsTable = ({ exemptionRequests, onClick = () => {} }) => {
  const columns = [
    {
      key: 'requestedOn',
      header: I18n.t('exemption_requests.list.requested_on_table_head'),
      cell: row => <>{formatDate(row.requestedOn, ShortDateFormat)}</>,
      sortable: true
    },
    {
      key: 'licensePlate',
      header: I18n.t('exemption_requests.list.license_plate_table_head'),
      sortable: true
    },
    {
      key: 'requestedBy',
      header: I18n.t('exemption_requests.list.requested_by_table_head'),
      sortable: true
    },
    {
      key: 'useCase',
      header: I18n.t('exemption_requests.list.use_case_table_head'),
      sortable: true
    },
    { key: 'type', header: I18n.t('exemption_requests.list.type_table_head'), sortable: true },
    {
      key: 'scope',
      header: I18n.t('exemption_requests.list.scope_table_head'),
      cell: row => (
        <div className="badges-cell">
          {row.scope === true ? (
            <Badge variant="by-primary">{I18n.t('settings.table_global_label')}</Badge>
          ) : (
            <Badge variant="by-secondary">{I18n.t('settings.table_usecase_label')}</Badge>
          )}
        </div>
      ),
      sortable: true
    }
  ];
  const tableRows = exemptionRequests.map(exemptionRequest => {
    return {
      id: exemptionRequest.id,
      requestedOn: exemptionRequest.createdAt,
      licensePlate: exemptionRequest.useCaseResult.vehicleLicensePlateNumber,
      requestedBy: exemptionRequest.requestedBy.name,
      useCase: exemptionRequest.useCaseResult.useCase.name,
      type: exemptionRequest.exemptionType.name,
      scope: exemptionRequest.exemptionType.global
    };
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [paginatedItems, setPaginatedItems] = useState([]);

  const onRowClick = row => {
    setSelectedRow(row);
    onClick(row);
  };

  useEffect(() => {
    if (paginatedItems.length > 0) onRowClick(paginatedItems[0].id);
  }, [paginatedItems]);

  return (
    <StyledExemptionRequestsTable>
      <div className="table-card">
        <div className="table-card-header">
          <h5>{I18n.t('exemption_requests.list.header')}</h5>
        </div>
        <div className="table-card-body">
          <h6>
            {I18n.t('exemption_requests.list.table_header', { count: exemptionRequests.length })}
          </h6>
          <Table
            columns={columns}
            items={tableRows}
            onRowClick={onRowClick}
            selectedId={selectedRow}
            defaultSortBy="requestedOn"
            defaultSortOrder="desc"
            onPaginationChange={setPaginatedItems}
          />
        </div>
      </div>
    </StyledExemptionRequestsTable>
  );
};

export default ExemptionRequestsTable;
