/**
 * This function parses an object transforming empty/undefined values to null.
 *
 * @param {Object} obj Object to be parsed
 * @return {Object} Object parsed containing null values instead of empty values.
 */
export const emptyToNull = obj => {
  const newObj = Object.keys(obj).reduce((nullValuesObj, key) => {
    return {
      ...nullValuesObj,
      [key]: obj[key] === '' || obj[key] === undefined ? null : obj[key]
    };
  }, {});

  return newObj;
};

/**
 * This function parses an object transforming null values into undefined.
 *
 * @param {Object} obj Object to be parsed
 * @return {Object} Object parsed containing undefined values instead of null values.
 */
export const nullToUndefined = obj => {
  const newObj = Object.keys(obj).reduce((undefValuesObj, key) => {
    return { ...undefValuesObj, [key]: obj[key] === null ? undefined : obj[key] };
  }, {});

  return newObj;
};

export default {
  emptyToNull,
  nullToUndefined
};
