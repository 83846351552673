import React from 'react';
import styled from 'styled-components';
import { Form, Button, Badge, InputGroup } from 'react-bootstrap';
import withApolloProvider from '@/utils/withApolloProvider';
import UseCaseResultImages from '../../../../../../Cop/UseCaseResultImages';
import { useFormik } from 'formik';

const StyledExemptionRequestDetails = styled.div`
  display: flex;
  width: 40%;

  .details-card {
    display: flex;

    display: flex;
    width: calc(100% - 10px);
    height: calc(100% - 20px);
    margin: 10px;
    margin-left: 0px;

    flex-direction: column;

    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 3px;

    .image-section {
      display: flex;
      width: 705px;
      height: 442px;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
    }

    .form-section {
      display: flex;
      width: calc(100% - 20px);
      height: calc(100% - 472px);
      margin: 10px;

      form {
        display: flex;
        flex-direction: column;
        margin: 50px 100px;
        width: calc(100% - 200px);
        height: calc(100% - 100px);

        .form-buttons {
          display: flex;
          margin-top: 20px;

          button {
            margin: 0px 10px;
            width: calc(50% - 20px);
          }
        }

        .input-group-badge {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;

          &.global {
            background: #0049c0;
          }

          &.use-case {
            background: #884fc5;
          }
        }
      }
    }
  }
`;

const ExemptionRequestDetails = ({ exemptionRequest, exemptionTypes, onFormSubmit }) => {
  const formik = useFormik({
    initialValues: {
      id: exemptionRequest.id,
      comment: exemptionRequest.comment,
      exemptionTypeId: exemptionRequest.exemptionType.id,
      action: null
    },
    onSubmit: values => {
      onFormSubmit({ id: values.id, action: values.action });
    }
  });

  const submitApproval = () => {
    formik.setFieldValue('action', 'approve');
    formik.handleSubmit();
  };
  const submitDenial = () => {
    formik.setFieldValue('action', 'deny');
    formik.handleSubmit();
  };

  const getSelectedTypeScope = () => {
    const selectedType = exemptionTypes.find(type => type.id === formik.values.exemptionTypeId);
    return selectedType.global;
  };

  const getSelectedTypeScopeLabel = () => {
    const label = getSelectedTypeScope()
      ? I18n.t('settings.table_global_label')
      : I18n.t('settings.table_usecase_label');

    return label.toUpperCase();
  };

  return (
    <StyledExemptionRequestDetails>
      <div className="details-card">
        <div className="image-section">
          <UseCaseResultImages useCaseResult={exemptionRequest.useCaseResult} canEdit={false} />
        </div>
        <div className="form-section">
          <Form>
            <Form.Group>
              <Form.Label>{I18n.t('exemption_requests.details.comment_form_label')}</Form.Label>
              <Form.Control
                id="comment"
                name="comment"
                as="textarea"
                rows={3}
                disabled
                value={formik.values.comment}
                onChange={formik.handleChange}
                style={{ resize: 'none' }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{I18n.t('exemption_requests.details.type_form_label')}</Form.Label>
              <InputGroup>
                <Form.Control
                  id="exemptionTypeId"
                  name="exemptionTypeId"
                  as="select"
                  value={formik.values.exemptionTypeId}
                  onChange={formik.handleChange}
                >
                  {exemptionTypes.map(exemptionType => (
                    <option key={exemptionType.id} value={exemptionType.id}>
                      {exemptionType.name}
                    </option>
                  ))}
                </Form.Control>
                <InputGroup.Append>
                  <Badge
                    className={`input-group-badge ${
                      getSelectedTypeScope() ? 'global' : 'use-case'
                    }`}
                    variant="secondary"
                  >
                    {getSelectedTypeScopeLabel()}
                  </Badge>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <div className="form-buttons">
              <Button variant="success" onClick={submitApproval}>
                {I18n.t('exemption_requests.details.approve_button')}
              </Button>
              <Button variant="danger" onClick={submitDenial}>
                {I18n.t('exemption_requests.details.deny_button')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </StyledExemptionRequestDetails>
  );
};

export default withApolloProvider(ExemptionRequestDetails);
