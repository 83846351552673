import * as Yup from 'yup';

const validationSchema = Yup.object({
  criteria: Yup.boolean().test(
    'oneOfRequired',
    I18n.t('settings.pipelines.processors.form.offense_decorator.validations.criteria'),
    function() {
      return (
        this.parent.is_taxi ||
        this.parent.missing_vehicle_info ||
        this.parent.enabledScanCars ||
        this.parent.enabledFixedCameras ||
        this.parent.enabledCountries ||
        this.parent.enabledDirections ||
        this.parent.enabledVehicleTypes ||
        this.parent.enabledEmissionCodes ||
        this.parent.enabledEuropeanCategories ||
        this.parent.enabledFuelTypes ||
        this.parent.enabledVehicleBuildYear ||
        this.parent.enabledVehicleWidth ||
        this.parent.enabledVehicleWeight ||
        this.parent.scheduled_at_enabled
      );
    }
  ),
  selectedFixedCameras: Yup.array().when('enabledFixedCameras', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.fixed_camera_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.fixed_camera_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedMobileCameras: Yup.array().when('enabledScanCars', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.mobile_camera_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.mobile_camera_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedDirections: Yup.array().when('enabledDirections', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.directions_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.directions_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedCountries: Yup.array().when('enabledCountries', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.countries_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.countries_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedVehicleTypes: Yup.array().when('enabledVehicleTypes', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_types_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_types_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedEmissionCodes: Yup.array().when('enabledEmissionCodes', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.emission_codes_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.emission_codes_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedEuropeanCategories: Yup.array().when('enabledEuropeanCategories', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.european_categories_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.european_categories_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  selectedFuelTypes: Yup.array().when('enabledFuelTypes', {
    is: true,
    then: Yup.array()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.fuel_types_required'
        )
      )
      .required(
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.fuel_types_required'
        )
      ),
    otherwise: Yup.array().nullable()
  }),
  // vehicle year
  vehicleBuildYear: Yup.boolean().when('enabledVehicleBuildYear', {
    is: true,
    then: Yup.boolean().test(
      'oneOfRequired',
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.vehicle_build_year_required'
      ),
      function() {
        return this.parent.vehicleBuildYearGt || this.parent.vehicleBuildYearLt;
      }
    ),
    otherwise: Yup.boolean().nullable()
  }),
  vehicleBuildYearGt: Yup.number().when('enabledVehicleBuildYear', {
    is: true,
    then: Yup.number()
      .min(
        1900,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_build_year_gt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleBuildYearLt: Yup.number().when('enabledVehicleBuildYear', {
    is: true,
    then: Yup.number()
      .min(
        1900,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_build_year_lt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleWidth: Yup.boolean().when('enabledVehicleWidth', {
    is: true,
    then: Yup.boolean().test(
      'oneOfRequired',
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.vehicle_width_required'
      ),
      function() {
        return this.parent.vehicleWidthGt || this.parent.vehicleWidthLt;
      }
    ),
    otherwise: Yup.boolean().nullable()
  }),
  vehicleWidthGt: Yup.number().when('enabledVehicleWidth', {
    is: true,
    then: Yup.number()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_width_gt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleWidthLt: Yup.number().when('enabledVehicleWidth', {
    is: true,
    then: Yup.number()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_width_lt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleWeight: Yup.boolean().when('enabledVehicleWeight', {
    is: true,
    then: Yup.boolean().test(
      'oneOfRequired',
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.vehicle_weight_required'
      ),
      function() {
        return this.parent.vehicleWeightGt || this.parent.vehicleWeightLt;
      }
    ),
    otherwise: Yup.boolean().nullable()
  }),
  vehicleWeightGt: Yup.number().when('enabledVehicleWeight', {
    is: true,
    then: Yup.number()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_weight_gt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  vehicleWeightLt: Yup.number().when('enabledVehicleWeight', {
    is: true,
    then: Yup.number()
      .min(
        1,
        I18n.t(
          'settings.pipelines.processors.form.offense_decorator.validations.vehicle_weight_lt_min_value'
        )
      )
      .nullable(),
    otherwise: Yup.number().nullable()
  }),
  schedule_ical: Yup.string().when('scheduled_at_enabled', {
    is: true,
    then: Yup.string().required()
  }),
  name: Yup.string().required(
    I18n.t('settings.pipelines.processors.form.validations.name_required')
  )
});

export default validationSchema;
