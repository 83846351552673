import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UseCasePipelineProcessorCard from './UseCasePipelineProcessorCard';
import { Row } from 'brickyard-ui';
import { selectUseCasesState, useUseCasesSlice } from '../slice';
import { IoIosMenu } from 'react-icons/io';
import { FiArrowDown } from 'react-icons/fi';
import DraggableCard from './PipelineProcessorForms/fields/DraggableCard';
import DropContainer from './PipelineProcessorForms/fields/DropContainer';

const UseCasePipelineProcessors = () => {
  const { actions } = useUseCasesSlice();
  const { selectedUseCase, selectedPipeline, selectedProcessor } = useSelector(selectUseCasesState);
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';

  const pipelineProcessors = selectedPipeline?.pipelineProcessors || [];

  const updateStoredList = newOrder => {
    dispatch(
      actions.updateSeqnumPipelineProcessors({
        order: newOrder.map(Number),
        useCaseId: parseInt(selectedUseCase?.id)
      })
    );
  };

  const renderPipelineProcessors = () => {
    return (
      <Row className="droppable-container no-gutters" key={`row-${selectedPipeline?.id}`}>
        <DropContainer onSort={updateStoredList} key={`dc-processors-${selectedPipeline?.id}`}>
          {pipelineProcessors.map(pipelineProcessor => (
            <DraggableCard
              key={pipelineProcessor.id}
              draggableId={pipelineProcessor.id}
              isDragDisabled={!hasWritePermission}
            >
              <div
                className={`pipeline-processor-card${
                  selectedProcessor?.id === pipelineProcessor.id &&
                  location.pathname.includes(`/block/${pipelineProcessor.id}`)
                    ? ' selected'
                    : ''
                }`}
              >
                <em>
                  <IoIosMenu size={30} color={'grey'} className="io-ios-menu-icon" />
                </em>
                <UseCasePipelineProcessorCard pipelineProcessor={pipelineProcessor} />
                <i className="direction">
                  <FiArrowDown size={20} className="fi-arrow-down" />
                </i>
              </div>
            </DraggableCard>
          ))}
        </DropContainer>
      </Row>
    );
  };

  const emptyProcessorsMessage = () => {
    return (
      <div className="empty_message">
        <span>{I18n.t('settings.pipelines.processors.messages.no_pipeline_processors')}</span>
      </div>
    );
  };

  const renderProcesorsList = () => {
    if (pipelineProcessors.length > 0) {
      return renderPipelineProcessors();
    }
    return emptyProcessorsMessage();
  };

  return renderProcesorsList();
};

export default UseCasePipelineProcessors;
