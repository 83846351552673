import _ from 'lodash';

const findFileWithLocation = (observation, kind) => {
  return observation.files.find(
    file => file.kind === kind && _.size(file.licensePlateLocation) > 0
  );
};

const findFileWithoutLocation = (observation, kind) => {
  return observation.files.find(
    file =>
      file.kind === kind && (!file.licensePlateLocation || _.size(file.licensePlateLocation) == 0)
  );
};

export function getLicenseImage(observation) {
  if (observation && observation.files) {
    return (
      findFileWithLocation(observation, 'general') || findFileWithLocation(observation, 'overview')
    );
  } else {
    return null;
  }
}

export function getImageToAdjust(observation, licenseImageKind = null) {
  if (licenseImageKind === null) {
    licenseImageKind = getLicenseImage(observation)?.kind;
  }
  const otherImageKind = licenseImageKind === 'general' ? 'overview' : 'general';
  if (observation && observation.files) {
    return findFileWithoutLocation(observation, otherImageKind);
  } else {
    return null;
  }
}
