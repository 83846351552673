import React from 'react';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function EmissionCodeField({
  formik,
  editEnabled,
  isSwitchable = false,
  formLabel = '',
  switchLabel = ''
}) {
  const selectEmissionCode = code => {
    const newSelectedEmissionCodes = [...formik.values.selectedEmissionCodes, code];
    const newAvailableEmissionCodes = formik.values.availableEmissionCodes.filter(
      c => c.id !== code.id
    );

    formik.setValues({
      ...formik.values,
      selectedEmissionCodes: newSelectedEmissionCodes.sort(idSort),
      availableEmissionCodes: newAvailableEmissionCodes
    });
  };

  const deselectEmissionCode = code => {
    const newSelectedEmissionCodes = formik.values.selectedEmissionCodes.filter(
      c => c.id !== code.id
    );
    const newAvailableEmissionCodes = [...formik.values.availableEmissionCodes, code];

    formik.setValues({
      ...formik.values,
      selectedEmissionCodes: newSelectedEmissionCodes,
      availableEmissionCodes: newAvailableEmissionCodes.sort(idSort)
    });
  };

  const selectAllEmissionCodes = () => {
    const newSelectedEmissionCodes = [
      ...formik.values.selectedEmissionCodes,
      ...formik.values.availableEmissionCodes
    ];

    formik.setValues({
      ...formik.values,
      selectedEmissionCodes: newSelectedEmissionCodes.sort(idSort),
      availableEmissionCodes: []
    });
  };

  const deselectAllEmissionCodes = () => {
    const newAvailableEmissionCodes = [
      ...formik.values.availableEmissionCodes,
      ...formik.values.selectedEmissionCodes
    ];

    formik.setValues({
      ...formik.values,
      selectedEmissionCodes: [],
      availableEmissionCodes: newAvailableEmissionCodes.sort(idSort)
    });
  };

  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledEmissionCodes"
              name="enabledEmissionCodes"
              isOn={formik.values.enabledEmissionCodes}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledEmissionCodes}
        />
      )}

      {((formik.values.enabledEmissionCodes && isSwitchable) || !isSwitchable) && (
        <FormFieldBlock
          label={formLabel}
          className="emissionCodes"
          field={
            <SideBySideSelector
              availableObjects={formik?.values?.availableEmissionCodes}
              selectedObjects={formik?.values?.selectedEmissionCodes}
              selectOne={selectEmissionCode}
              deselectOne={deselectEmissionCode}
              selectAll={selectAllEmissionCodes}
              deselectAll={deselectAllEmissionCodes}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.selectedEmissionCodes}
        />
      )}
    </>
  );
}

export default EmissionCodeField;
