import React, { useEffect, useImperativeHandle } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CountryField from './fields/CountryField';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import FormFieldBlock from '../../../../components/FormFieldBlock';

function VehicleInfoDecorator({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor, countries } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();

  const selectedCountries = selectedProcessor?.config?.required_for_country_codes
    ? selectedProcessor?.config?.required_for_country_codes?.map(code => ({
        id: code,
        name: code
      }))
    : [{ id: 'NL', name: 'NL' }];
  const availableCountries = countries?.filter(
    country => !selectedCountries?.find(selectedCountry => selectedCountry.id === country.id)
  );

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      availableCountries,
      selectedCountries
    },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, ['id', 'name', 'selectedCountries'])
      );
    },
    validationSchema: Yup.object({
      selectedCountries: Yup.array()
        .required(I18n.t('settings.field_country_required'))
        .min(1, I18n.t('settings.field_country_required')),
      name: Yup.string().required(
        I18n.t('settings.pipelines.processors.form.validations.name_required')
      )
    })
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    const params = {
      id: values.id,
      name: values.name,
      config: {
        required_for_country_codes: values.selectedCountries?.map(country => country.id)
      }
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <div className={selectedProcessor?.className ?? ''}>
      <FormFieldBlock
        field={
          <>
            <div className="alert alert-primary mb-1" role="alert">
              {I18n.t('settings.pipelines.processors.messages.on_error_continue')}
            </div>
          </>
        }
      />

      <CountryField
        formik={formik}
        formLabel={I18n.t('settings.pipelines.processors.form.vehicle_info_decorator.countries')}
        editEnabled={editEnabled}
        isSwitchable={false}
      />
    </div>
  );
}

export default VehicleInfoDecorator;
