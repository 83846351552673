import React from 'react';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function OffenseCodeField({
  formik,
  editEnabled,
  isSwitchable = false,
  formLabel = '',
  switchLabel = ''
}) {
  const selectOffenseCode = oc => {
    const newSelectedOffenseCodes = [...formik.values.selectedOffenseCodes, oc];
    const newAvailableOffenseCodes = formik.values.availableOffenseCodes.filter(
      c => c.id !== oc.id
    );

    formik.setValues({
      ...formik.values,
      selectedOffenseCodes: newSelectedOffenseCodes.sort(idSort),
      availableOffenseCodes: newAvailableOffenseCodes
    });
  };

  const deselectOffenseCode = oc => {
    const newSelectedOffenseCodes = formik.values.selectedOffenseCodes.filter(c => c.id !== oc.id);
    const newAvailableOffenseCodes = [...formik.values.availableOffenseCodes, oc];

    formik.setValues({
      ...formik.values,
      selectedOffenseCodes: newSelectedOffenseCodes,
      availableOffenseCodes: newAvailableOffenseCodes.sort(idSort)
    });
  };

  const selectAllOffenseCodes = () => {
    const newSelectedOffenseCodes = [
      ...formik.values.selectedOffenseCodes,
      ...formik.values.availableOffenseCodes
    ];

    formik.setValues({
      ...formik.values,
      selectedOffenseCodes: newSelectedOffenseCodes.sort(idSort),
      availableOffenseCodes: []
    });
  };

  const deselectAllOffenseCodes = () => {
    const newAvailableOffenseCodes = [
      ...formik.values.availableOffenseCodes,
      ...formik.values.selectedOffenseCodes
    ];

    formik.setValues({
      ...formik.values,
      selectedOffenseCodes: [],
      availableOffenseCodes: newAvailableOffenseCodes.sort(idSort)
    });
  };

  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledOffenseCodes"
              name="enabledOffenseCodes"
              isOn={formik.values.enabledOffenseCodes}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledOffenseCodes}
        />
      )}

      {((formik.values.enabledOffenseCodes && isSwitchable) || !isSwitchable) && (
        <FormFieldBlock
          label={formLabel}
          className="OffenseCodes"
          field={
            <SideBySideSelector
              availableObjects={formik?.values?.availableOffenseCodes}
              selectedObjects={formik?.values?.selectedOffenseCodes}
              selectOne={selectOffenseCode}
              deselectOne={deselectOffenseCode}
              selectAll={selectAllOffenseCodes}
              deselectAll={deselectAllOffenseCodes}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.selectedOffenseCodes}
        />
      )}
    </>
  );
}

export default OffenseCodeField;
