import React, { useState } from 'react';
import TabbedPanelInfo from './TabbedPanelInfo';
import TabbedPanelVehicle from './TabbedPanelVehicle';
import TabbedPanelResponses from './TabbedPanelResponses';
import TabbedPanelRailwaySettings from './TabbedPanelRailwaySettings';
import TabbedPanelCameraOverviewImage from './TabbedPanelCameraOverviewImage';

import { Nav, Tab } from 'react-bootstrap';
import { BsListCheck } from 'react-icons/bs';
import { BiCar, BiInfoCircle } from 'react-icons/bi';
import { AiOutlineFileSearch } from 'react-icons/ai';
import Railway from '@/assets/img/railway.svg';
import OverviewImage from '@/assets/img/overview_image.svg';
import TabbedPanelModal from './TabbedPanelModal';
import { useSelector } from 'react-redux';

const TabbedPanel = ({ useCaseResult, useCaseName, type }) => {
  const DEFAULT_TAB = 'info';
  const TAB_INFO = 'info';
  const TAB_VEHICLE = 'vehicle';
  const TAB_RESPONSES = 'responses';
  const TAB_RAILWAY_SETTINGS = 'railway_settings';
  const TAB_CAMERA_OVERVIEW_IMAGE = 'camera_overview_image';
  const updateActiveTabbedPanel = val => {
    localStorage.setItem('activeTabbedPanel', val);
  };

  const getActiveTabbedPanel = () => {
    if (
      !useCaseResult.railwayViolationData &&
      localStorage.getItem('activeTabbedPanel') === TAB_RAILWAY_SETTINGS
    ) {
      return DEFAULT_TAB;
    }

    return localStorage.getItem('activeTabbedPanel') ?? DEFAULT_TAB;
  };

  const [showModal, setShowModal] = useState(false);

  const user = useSelector(state => state.user);
  const canSeePipelineLogs = !!user?.cop_show_pipeline_logs;

  return (
    <div className="tabbed-panel-card">
      <Tab.Container defaultActiveKey={getActiveTabbedPanel()}>
        <div className="full-height row no-gutters">
          <div className="tabbed-panel-content col-sm-11 pr-0 pb-0">
            <Tab.Content className="full-height">
              <Tab.Pane
                eventKey={TAB_INFO}
                className="full-height"
                key={`tabInfo${useCaseResult.id}`}
              >
                <TabbedPanelInfo
                  key={`tabbedPanelInfo${useCaseResult.id}`}
                  useCaseResult={useCaseResult}
                  type={type}
                />
              </Tab.Pane>

              <Tab.Pane
                eventKey={TAB_VEHICLE}
                className="full-height"
                key={`tabVehicle${useCaseResult.id}`}
              >
                <TabbedPanelVehicle
                  key={`tabbedPanelVehicle${useCaseResult.id}`}
                  useCaseResult={useCaseResult}
                  type={type}
                />
              </Tab.Pane>

              <Tab.Pane
                eventKey={TAB_RESPONSES}
                className="full-height overflow-list"
                key={`tabResponse${useCaseResult.id}`}
              >
                <TabbedPanelResponses
                  key={`tabbedPanelResponses${useCaseResult.id}`}
                  useCaseResult={useCaseResult}
                  type={type}
                />
              </Tab.Pane>

              {useCaseResult.observation.type == 'Cop::FixedCameraObservation' && (
                <Tab.Pane
                  eventKey={TAB_CAMERA_OVERVIEW_IMAGE}
                  className="full-height overflow-list"
                  key={`tabCameraOverviewImage${useCaseResult.id}`}
                >
                  <TabbedPanelCameraOverviewImage
                    key={`tabbedPanelCameraOverviewImage${useCaseResult.id}`}
                    useCaseResult={useCaseResult}
                  />
                </Tab.Pane>
              )}

              {useCaseResult.railwayViolationData && (
                <Tab.Pane
                  eventKey={TAB_RAILWAY_SETTINGS}
                  className="full-height overflow-list"
                  key={`tabRailwaySettings${useCaseResult.id}`}
                >
                  <TabbedPanelRailwaySettings
                    key={`tabbedPanelRailwaySettings${useCaseResult.id}`}
                    useCaseResult={useCaseResult}
                    type={type}
                  />
                </Tab.Pane>
              )}

              <TabbedPanelModal
                key={`tabbedPanelPipelineResultsModal${useCaseResult.id}`}
                useCaseId={useCaseResult.useCaseId}
                useCaseName={useCaseName}
                useCaseResultId={useCaseResult.id}
                showModal={showModal}
                setShowModal={setShowModal}
              />
            </Tab.Content>
          </div>

          <div className="tabbed-panel-nav col-sm-1">
            <Nav className="flex-column">
              <Nav.Link eventKey={TAB_INFO} onClick={() => updateActiveTabbedPanel(TAB_INFO)}>
                <BiInfoCircle size="24" />
              </Nav.Link>
              <Nav.Link eventKey={TAB_VEHICLE} onClick={() => updateActiveTabbedPanel(TAB_VEHICLE)}>
                <BiCar size="24" />
              </Nav.Link>
              <Nav.Link
                eventKey={TAB_RESPONSES}
                onClick={() => updateActiveTabbedPanel(TAB_RESPONSES)}
              >
                <BsListCheck size="24" />
              </Nav.Link>
              {useCaseResult.observation.type == 'Cop::FixedCameraObservation' && (
                <Nav.Link
                  eventKey={TAB_CAMERA_OVERVIEW_IMAGE}
                  onClick={() => updateActiveTabbedPanel(TAB_CAMERA_OVERVIEW_IMAGE)}
                >
                  <img src={OverviewImage} alt="overview image" />
                </Nav.Link>
              )}
              {useCaseResult.railwayViolationData && (
                <Nav.Link
                  eventKey={TAB_RAILWAY_SETTINGS}
                  onClick={() => updateActiveTabbedPanel(TAB_RAILWAY_SETTINGS)}
                >
                  <img src={Railway} alt="railway" />
                </Nav.Link>
              )}
              {canSeePipelineLogs && (
                <Nav.Link className="logs" onClick={() => setShowModal(true)}>
                  <AiOutlineFileSearch size="24" />
                </Nav.Link>
              )}
            </Nav>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
};

export default TabbedPanel;
