import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { isNil } from 'ramda';

const StyledSelect = styled.select`
  width: 100%;
  height: 34px;
  padding: 4px 0px 4px 6px;

  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;

  font-weight: 300;
  font-size: 14px;

  color: #4a4a49;

  &:disabled {
    background: transparent;
    cursor: not-allowed;
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
  }

  &.is-dirty {
    background: #fdfce5;
  }
`;

const Select = ({ name, onChange, value, placeholder, options = [], disabled, className = '' }) => {
  return (
    <StyledSelect
      className={classNames({ 'is-dirty': value }, className)}
      disabled={disabled}
      name={name}
      onChange={onChange}
      value={value || ''}
    >
      {!isNil(placeholder) && <option value="">{placeholder}</option>}
      {options.map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.name}
        </option>
      ))}
    </StyledSelect>
  );
};

export default Select;
