import * as Yup from 'yup';

const exemptionDetailSchema = Yup.object({
  notificationEmail: Yup.string().matches(
    '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
    I18n.t('settings.pipelines.processors.form.email_notifier.validations.email_invalid')
  )
});

export default exemptionDetailSchema;
