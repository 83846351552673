import React, { useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars

/*
 * This hook returns a debounced value depeding on the received value and the delay.
 * Whenever the useEffect is re-called, it will clear the timeout, so, while the value is
 * changing faster than the delay, it won't return a different value.
 * Use the returned value inside an useEffect or other side-effect "responsive"
 * method on the component using this hook (as on search of an useQuery hook).
 *
 * E.g:
 * const debounced = useDebounce(someValue, 300);
 * useEffect(async () => {
 *    const res = await Api.get(`/autocomplete?${q=debounced}`);
 *    setResults(res.data);
 * }, [debounced])
 *
 */
const useDebounce = (val, delay) => {
  const [debounced, setDebounced] = useState(val);

  useEffect(() => {
    const delayed = setTimeout(() => {
      setDebounced(val);
    }, delay);

    return () => {
      clearTimeout(delayed);
    };
  }, [val, delay]);

  return debounced;
};

export default useDebounce;
