import React from 'react';
import { Modal } from 'brickyard-ui';

import ScheduledOverrideForm from './ScheduledOverrideForm';

const ScheduledOverrideModal = ({ scheduledOverride, locations, show, onHide, onSave }) => {
  const onSaveModal = schedule => {
    onSave(schedule);
    onHide();
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} enforceFocus={false}>
      <Modal.Header closeButton>Scheduled Override</Modal.Header>
      <Modal.Body>
        {show && (
          <ScheduledOverrideForm
            locations={locations}
            scheduledOverride={scheduledOverride}
            onSave={onSaveModal}
            onCancel={onHide}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ScheduledOverrideModal;
