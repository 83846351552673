import React, { useState } from 'react';

import ZoomCarouselModal from '../ZoomCarouselModal';

const ObservationPanoramaImages = ({ images }) => {
  const [selected, setSelected] = useState(null);
  return (
    <>
      <div className="observation-panorama-container">
        <p>Panorama</p>
        <div className="observation-panorama-images">
          {images.map((image, index) => (
            <div
              className="observation-image"
              style={{ backgroundImage: `url(${image})` }}
              onClick={() => setSelected(index)}
              key={image}
            />
          ))}
        </div>
      </div>
      <ZoomCarouselModal
        images={images}
        selected={selected}
        show={selected !== null}
        onHide={() => setSelected(null)}
      />
    </>
  );
};

export default ObservationPanoramaImages;
