import { either, equals } from 'ramda';
import { boolean, string, number } from 'yup';

import { requiredMsg } from './CommonTicketSchema';

const locationValidations = {
  offenseLocationCity: string()
    .ensure()
    .required(requiredMsg),
  offenseLocationHousenumber: string().when('type', {
    is: 'taxi',
    then: string()
      .ensure()
      .required(requiredMsg),
    otherwise: string().ensure()
  }),
  offenseLocationRoadType: number().when('type', {
    is: either(equals('juridical'), equals('taxi')),
    then: number().required(requiredMsg),
    otherwise: number().nullable()
  }),
  offenseLocationStreet: string()
    .ensure()
    .required(requiredMsg),
  offenseLocationWithinCityLimits: boolean().when('type', {
    is: either(equals('juridical'), equals('taxi')),
    then: boolean().required(requiredMsg),
    otherwise: boolean().nullable()
  }),
  offenseParkingArea: string().when('type', {
    is: 'fiscal',
    then: string()
      .ensure()
      .required(requiredMsg),
    otherwise: string().ensure()
  })
};

export default locationValidations;
