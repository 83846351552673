import React from 'react';
import styled from 'styled-components';
import FormField from '../../../components/FormField';
import FormFieldBlock from '../../../components/FormFieldBlock';
import { Button } from 'brickyard-ui';

const StyledUseCaseImageField = styled.div`
  display: flex;
  flex-direction: column;
`;

function UseCaseImageField({ formik, editEnabled }) {
  return (
    <StyledUseCaseImageField>
      <FormField
        label={I18n.t('settings.field_fixed_camera_overview_image')}
        field={
          <input
            id="image"
            name="image"
            type="file"
            accept="image/*"
            disabled={!editEnabled}
            onChange={event => {
              formik.setFieldValue('imageUrl', URL.createObjectURL(event.currentTarget.files[0]));
              formik.setFieldValue('image', event.currentTarget.files[0]);
            }}
          />
        }
        error={formik.errors.image}
      />
      {formik.values.imageUrl && !formik.errors.image && (
        <FormFieldBlock
          field={
            <>
              <div>
                <img src={formik.values.imageUrl} height={200} />
              </div>
              <div>
                <Button
                  className="btn btn-danger mt-2"
                  onClick={async () => {
                    formik.setFieldValue('image', null);
                    formik.setFieldValue('imageUrl', null);
                  }}
                  disabled={!editEnabled}
                >
                  {I18n.t('settings.field_fixed_camera_overview_image_remove')}
                </Button>
              </div>
            </>
          }
        />
      )}
    </StyledUseCaseImageField>
  );
}

export default UseCaseImageField;
