import React from 'react';
import { InfoLabel, PopupMenu } from 'brickyard-ui';
import { useSelector } from 'react-redux';

const UseCaseResultRelationHeader = ({
  info,
  unrelateAll,
  relateAll,
  deleteAll,
  deleteUnrelated,
  useCaseName = false,
  canUseCaseResultBeEdited = false
}) => {
  const filters = useSelector(state => state.observations.filters);

  const RelateAllItem = () => (
    <div onClick={closePopupAfter(relateAll)}>
      {I18n.t('observations.observations.details.actions.relate_all')}
    </div>
  );
  const UnrelateAllItem = () => (
    <div onClick={closePopupAfter(unrelateAll)}>
      {I18n.t('observations.observations.details.actions.unrelate_all')}
    </div>
  );
  const DeleteAllItem = () => (
    <div onClick={closePopupAfter(deleteAll)}>
      {I18n.t('observations.observations.details.actions.delete_all')}
    </div>
  );
  const DeleteUnrelatedItem = () => (
    <div onClick={closePopupAfter(deleteUnrelated)}>
      {I18n.t('observations.observations.details.actions.delete_unrelated')}
    </div>
  );

  const closePopupAfter = action => () => {
    action();
    document.body.click();
  };

  const actions = [
    { render: RelateAllItem },
    { render: UnrelateAllItem },
    { render: DeleteAllItem },
    { render: DeleteUnrelatedItem }
  ];

  return (
    <div className="ucr-relation-header">
      <h5>{useCaseName}</h5>
      <InfoLabel title={I18n.t('scancar.observations.index.source')} text={info} />
      {!filters.validatedFilter && (
        <div className="ucr-relation-header-title">
          {canUseCaseResultBeEdited ? (
            <PopupMenu placement="bottom-start" actions={actions} rootClose={true} />
          ) : (
            <span style={{ width: '5px' }} />
          )}
          <h6 className={'normal'}>
            {I18n.t('observations.observations.details.vehicle_observations')}
          </h6>
        </div>
      )}
    </div>
  );
};

export default UseCaseResultRelationHeader;
