import React from 'react';
import { Card, Form as BYForm, Button, Spinner } from 'brickyard-ui';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import '../../../styles/scenes/login.scss';
import Api from '../../../utils/Api';

function Edit({ resetToken, locale }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiError, setApiError] = React.useState(null);
  const REDIRECT_TIMEOUT = 3000;

  React.useEffect(() => {
    I18n.locale = locale;
  }, []);

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, I18n.t('forms.password_reset.validation.password_min', { min: 8 }))
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\d]+$/,
          I18n.t('forms.password_reset.validation.password_format')
        )
        .required(I18n.t('forms.validation.required')),
      password_confirmation: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          I18n.t('forms.password_reset.validation.password_match')
        )
        .required(I18n.t('forms.validation.required'))
    }),
    onSubmit: values => submitRequest(values)
  });

  const submitRequest = async values => {
    setIsLoading(true);
    setApiError(null);

    try {
      const response = await Api.put(
        '/password',
        { user: { ...values, reset_password_token: resetToken } },
        { validateStatus: false }
      );

      if (response.data?.errors !== undefined) {
        if (response.data?.errors?.reset_password_token) {
          setApiError(I18n.t('forms.password_reset.validation.invalid_token'));
        }
        if (response.data?.errors?.password) {
          setApiError(I18n.t('forms.password_reset.validation.password_same'));
        }
      } else {
        setApiError(I18n.t('devise.passwords.updated_not_active'));

        setTimeout(function() {
          window.location.replace(response.request.responseURL);
        }, REDIRECT_TIMEOUT);
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  return (
    <Card className="login-card">
      <Card.Header>{I18n.t('forms.password_reset.title')}</Card.Header>
      <Card.Body>
        <form className="password-reset-form" onSubmit={formik.handleSubmit}>
          <BYForm.Group>
            <BYForm.Control
              placeholder={I18n.t('activerecord.attributes.user.password')}
              type="password"
              id="password"
              name="password"
              isValid={formik.touched.password && !formik.errors.password}
              onChange={formik.handleChange}
            />
            {formik.touched.password && formik.errors.password && (
              <BYForm.Control.Feedback type="invalid">
                {formik.errors.password}
              </BYForm.Control.Feedback>
            )}
          </BYForm.Group>

          <BYForm.Group>
            <BYForm.Control
              placeholder={I18n.t('activerecord.attributes.user.password_confirmation')}
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              isValid={formik.touched.password_confirmation && !formik.errors.password_confirmation}
              onChange={formik.handleChange}
            />
            {formik.touched.password_confirmation && formik.errors.password_confirmation && (
              <BYForm.Control.Feedback type="invalid">
                {formik.errors.password_confirmation}
              </BYForm.Control.Feedback>
            )}
          </BYForm.Group>

          {apiError && (
            <BYForm.Group>
              <BYForm.Control.Feedback type="invalid">{apiError}</BYForm.Control.Feedback>
            </BYForm.Group>
          )}

          <Button
            disabled={isLoading}
            variant="by-primary"
            type="submit"
            block
            id="password-reset-submit"
          >
            {isLoading ? <Spinner animation="border" /> : I18n.t('forms.password_reset.submit')}
          </Button>
        </form>
      </Card.Body>
      <Card.Footer>
        <a href="/login">{I18n.t('forms.password_reset.back')}</a>
      </Card.Footer>
    </Card>
  );
}

export default Edit;
