import * as Yup from 'yup';

const useCaseListsSchema = Yup.object({
  name: Yup.string().required(I18n.t('settings.field_name_required')),
  use_cases: Yup.array()
    .min(
      1,
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.fixed_camera_required'
      )
    )
    .required(
      I18n.t(
        'settings.pipelines.processors.form.offense_decorator.validations.fixed_camera_required'
      )
    )
});

export { useCaseListsSchema };
