import React from 'react';

const TabbedPanelCameraOverviewImage = ({ useCaseResult }) => {
  return (
    <>
      {useCaseResult.cameraOverviewImage && (
        <img src={useCaseResult.cameraOverviewImage} height={200} />
      )}
    </>
  );
};

export default TabbedPanelCameraOverviewImage;
