import { Polygon } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Map from '../../../../../../shared/maps/Map';

const StyledUseCaseListMap = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  margin: 30px;

  #google-map {
    min-width: 0px;
    border-radius: 4px;
    margin: 10px 10px 0px 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }
`;

function UseCaseListMap({ useCaseZones, onPolygonClick }) {
  const [map, setMap] = useState();

  const colorArray = ['#EB764B', '#EBC75E', '#6A9358', '#396557', '#2E3E57'];

  // Position map to show every zone
  useEffect(() => {
    if (map) {
      // Netherlands
      map.setZoom(8);
      map.setCenter({ lat: 52.1611973294726, lng: 5.621868260475858 });

      const bounds = new window.google.maps.LatLngBounds();

      useCaseZones.forEach(useCase => {
        useCase.zones?.forEach(zone => {
          zone.geom?.forEach(polygon => {
            polygon.forEach(ring => {
              ring.forEach(point => bounds.extend(point));
            });
          });
        });
      });

      !bounds.isEmpty() && map.fitBounds(bounds);
    }
  }, [map, useCaseZones]);

  return (
    <StyledUseCaseListMap>
      <Map onLoad={setMap} clickableIcons={false}>
        {useCaseZones.map((useCase, index) =>
          useCase.zones.map(zone =>
            zone.geom?.map(polygon => (
              <Polygon
                key={`${useCase.id}.${zone.id}`}
                paths={polygon}
                onClick={() => onPolygonClick(useCase, zone)}
                options={{
                  fillColor: colorArray[index % colorArray.length],
                  strokeColor: colorArray[index % colorArray.length]
                }}
              />
            ))
          )
        )}
      </Map>
    </StyledUseCaseListMap>
  );
}

export default UseCaseListMap;
