import React from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';

import PinGreen from '@/assets/img/pin_green.svg';
import PinRed from '@/assets/img/pin_red.svg';
import PinYellow from '@/assets/img/pin_yellow.svg';
import PinGrey from '@/assets/img/pin_grey.svg';
import { LongDateFormat } from '@/utils/DateFormats';
import formatDate from '@/utils/formatDate';

const InfoMarker = ({ device, clusterer }) => {
  const [showInfo, setShowInfo] = React.useState(false);

  const getStatusIcon = device => {
    if (device.device_status === 'logout') {
      return PinGrey;
    }

    const timesstamp = new Date(device.timestamp).getTime();
    const now = new Date().getTime();
    const diffInMinutes = ~~((now - timesstamp) / 1000 / 60);

    switch (diffInMinutes) {
      case diffInMinutes <= 2:
        return PinGreen;
      case diffInMinutes <= 5:
        return PinYellow;
      case diffInMinutes <= 30:
        return PinRed;
      default:
        return PinGrey;
    }
  };

  const getStatusLabel = device => {
    if (device.device_status === 'logout') {
      return I18n.t('tracking.status.inactive');
    }

    const timesstamp = new Date(device.timestamp).getTime();
    const now = new Date().getTime();
    const diffInMinutes = ~~((now - timesstamp) / 1000 / 60);

    switch (diffInMinutes) {
      case diffInMinutes <= 2:
        return I18n.t('tracking.status.active');
      case diffInMinutes <= 5:
        return I18n.t('tracking.status.delayed');
      case diffInMinutes <= 30:
        return I18n.t('tracking.status.unknown');
      default:
        return I18n.t('tracking.status.inactive');
    }
  };

  return (
    <Marker
      key={device.device_id}
      position={{
        lat: parseFloat(device.latitude),
        lng: parseFloat(device.longitude)
      }}
      clusterer={clusterer}
      icon={getStatusIcon(device)}
      onMouseOver={() => setShowInfo(true)}
      onMouseOut={() => setShowInfo(false)}
    >
      {showInfo && (
        <InfoWindow>
          <div>
            <p>
              {I18n.t('tracking.device_id_label')}: {device.device_id}
            </p>
            <p>
              {I18n.t('tracking.username_label')}: {device.username}
            </p>
            <p>
              {I18n.t('tracking.last_seen_label')}: {formatDate(device.timestamp, LongDateFormat)}
            </p>
            <p>
              {I18n.t('tracking.status_label')}: {getStatusLabel(device)}
            </p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default InfoMarker;
