import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { cond, equals, isNil } from 'ramda';
import * as Yup from 'yup';

import { Modal, Container } from 'brickyard-ui';

import useIsMount from '@/hooks/useIsMount';
import Button from '../Ticket/components/Button';
import Input from '../Ticket/Cards/components/LabelInput';
import Select from '../Ticket/Cards/components/LabelSelect';
import useProjectOptions from '@/components/queries/hooks/useProjectOptions';
import useSourceSystemOptions from '@/components/queries/hooks/useSourceSystemOptions';
import useConfigOptions from '@/components/queries/hooks/useConfigOptions';
import useVehicleTypeOptions from '@/components/queries/hooks/useVehicleTypeOptions';
import useCountryOptions from '@/components/queries/hooks/useCountryOptions';
import DatePicker from '../Ticket/Cards/components/LabelDatePicker';
import { replaceVehicleTypes } from './utils';
import LabelToggle from '../Ticket/Cards/components/LabelToggle';

const StyledRow = styled.div`
  flex: 1 1 0px;
  margin: 0 16px;

  h2 {
    font-weight: 600;
    font-size: 14px;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #c7c7c7;
  }
`;

const Row = ({ heading, children }) => {
  return (
    <StyledRow>
      <h2>{heading}</h2>
      {children}
    </StyledRow>
  );
};

export const initialFields = {
  idEq: '',
  projectCont: '',
  recordingNumberCont: '',
  offenseCodeCont: '',
  offenseTypeEq: '',
  userUsernameCont: '',
  partnerUsernameCont: '',
  offenseLocationStreetCont: '',
  offenseLocationCityCont: '',
  enforcementConfigurationIdEq: '',
  sourceSystemEq: '',
  offenseIsTowableEq: undefined,
  isWarningEq: undefined,
  vehicleLicensePlateNumberCont: '',
  vehicleTypeInsensitiveEqInsensitive: '',
  writtenAtGteq: null,
  writtenAtLteq: null,
  pvNumberEq: '',
  suspectLastnameCont: '',
  vehicleCountryInsensitiveEq: '',
  roofAreaIdEq: '',
  dismissalStateEq: '',
  transportIdPresent: ''
};

const offenseTypes = [
  {
    id: 'ASTERISK',
    name: I18n.t('tickets.asteriks')
  },
  {
    id: 'FISCAL',
    name: I18n.t('tickets.fiscal')
  },
  {
    id: 'MULDER',
    name: I18n.t('tickets.mulder')
  },
  {
    id: 'POLICE',
    name: I18n.t('tickets.police')
  },
  {
    id: 'TAXI',
    name: I18n.t('tickets.taxi')
  }
];

const convertBoolean = cond([
  [equals('yes'), () => true],
  [equals('no'), () => false],
  [equals(true), () => 'yes'],
  [equals(false), () => 'no'],
  [equals(''), () => null],
  [isNil, () => '']
]);

const convertDismissalState = cond([
  [equals('dismissed'), () => 'on'],
  [equals('on'), () => 'dismissed'],
  [isNil, () => ''],
  [equals(undefined), () => '']
]);

const convertTransportIdPresent = cond([
  [equals(true), () => 'on'],
  [equals('on'), () => true],
  [isNil, () => ''],
  [equals(undefined), () => '']
]);

const ActionsWrapper = styled.div`
  width: 100%;
  padding: 0 15px;

  button {
    margin: 0 16px;
  }

  .reset {
    color: #f36c21;
    border: none;
    font-weight: 400;
  }

  .submit {
    background: #007bff;
    border-color: #007bff;
    color: #fff;
  }
`;

const TicketFilters = ({ show, onHide, appliedFilters, onSubmit }) => {
  const isMount = useIsMount();
  const [projects] = useProjectOptions();
  const [sourceSystems] = useSourceSystemOptions();
  const [configs] = useConfigOptions();
  const [vehicleTypes] = useVehicleTypeOptions(true);
  const [countryCodes] = useCountryOptions();

  const formik = useFormik({
    initialValues: {
      ...initialFields,
      ...appliedFilters
    },
    validationSchema: Yup.object().shape({
      idEq: Yup.number()
        .typeError(I18n.t('tickets.search.invalid_case_number'))
        .integer(I18n.t('tickets.search.invalid_case_number'))
        .min(1, I18n.t('tickets.search.invalid_case_number'))
        .max(2147483647, I18n.t('tickets.search.invalid_case_number'))
    }),
    onSubmit
  });

  useEffect(() => {
    if (!isMount) {
      formik.setValues({ ...initialFields, ...appliedFilters });
    }
  }, [appliedFilters, show]);

  return (
    <Modal size="xl" show={show} onHide={onHide} dialogClassName="ticket-filters-modal">
      <Modal.Header closeButton>{I18n.t('tickets.index.tickets')}</Modal.Header>

      <Modal.Body>
        <Container fluid>
          <div className="flex-between">
            <Row heading={I18n.t('tickets.form.sections.general')}>
              <Input
                name="userUsernameCont"
                label={I18n.t('activerecord.attributes.ticket.user_username')}
                controlled
                hasClear
                {...formik.getFieldProps('userUsernameCont')}
              />
              <Input
                name="partnerUsernameCont"
                label={I18n.t('activerecord.attributes.ticket.partner_username')}
                controlled
                hasClear
                {...formik.getFieldProps('partnerUsernameCont')}
              />
              <Select
                name="enforcementConfigurationIdEq"
                label={I18n.t('activerecord.attributes.ticket.enforcement_configuration_id')}
                options={configs}
                placeholder=""
                hasClear
                {...formik.getFieldProps('enforcementConfigurationIdEq')}
              />
              <Select
                name="sourceSystemEq"
                label={I18n.t('activerecord.attributes.ticket.source_system')}
                options={sourceSystems}
                placeholder=""
                hasClear
                {...formik.getFieldProps('sourceSystemEq')}
              />
              <Select
                name="projectCont"
                label={I18n.t('activerecord.attributes.ticket.project')}
                options={projects}
                placeholder=""
                hasClear
                {...formik.getFieldProps('projectCont')}
              />
              <LabelToggle
                isFilter={true}
                label={I18n.t('tickets.search.transport_id_present')}
                name="transportIdPresent"
                className={'filter-align'}
                isOn={convertTransportIdPresent(formik.values.transportIdPresent)}
                onChange={({ target }) => {
                  let newVal =
                    target.value === 'on' && formik.values.transportIdPresent ? '' : 'on';
                  formik.setFieldValue('transportIdPresent', convertTransportIdPresent(newVal));
                }}
              />
            </Row>
            <Row heading={I18n.t('tickets.form.sections.ticket')}>
              <DatePicker
                label={I18n.t('tickets.search.written_at_gteq')}
                showTimeInput
                hasClear
                {...formik.getFieldProps('writtenAtGteq')}
                onChange={date => formik.setFieldValue('writtenAtGteq', date && date.toISOString())}
              />
              <DatePicker
                label={I18n.t('tickets.search.written_at_lteq')}
                showTimeInput
                hasClear
                {...formik.getFieldProps('writtenAtLteq')}
                onChange={date => formik.setFieldValue('writtenAtLteq', date && date.toISOString())}
              />
              <Input
                name="pvNumberEq"
                label={I18n.t('activerecord.attributes.ticket.pv_number')}
                controlled
                hasClear
                {...formik.getFieldProps('pvNumberEq')}
              />
              <Input
                name="recordingNumberCont"
                label={I18n.t('activerecord.attributes.ticket.recording_number')}
                controlled
                hasClear
                {...formik.getFieldProps('recordingNumberCont')}
              />
              <Input
                name="idEq"
                label={I18n.t('activerecord.attributes.ticket.id')}
                controlled
                hasClear
                error={formik.errors?.idEq}
                {...formik.getFieldProps('idEq')}
              />
              <LabelToggle
                isFilter={true}
                label={I18n.t('activerecord.attributes.ticket.dismissed')}
                name="dismissalStateEq"
                className={'filter-align'}
                isOn={convertDismissalState(formik.values.dismissalStateEq)}
                onChange={({ target }) => {
                  let newVal = target.value === 'on' && formik.values.dismissalStateEq ? '' : 'on';
                  formik.setFieldValue('dismissalStateEq', convertDismissalState(newVal));
                }}
              />
            </Row>
            <Row heading={I18n.t('tickets.form.sections.offense.offense')}>
              <Select
                name="offenseTypeEq"
                label={I18n.t('activerecord.attributes.ticket.offense_type')}
                options={offenseTypes}
                placeholder=""
                hasClear
                {...formik.getFieldProps('offenseTypeEq')}
              />
              <Input
                name="offenseCodeCont"
                label={I18n.t('activerecord.attributes.ticket.offense_code')}
                controlled
                hasClear
                {...formik.getFieldProps('offenseCodeCont')}
              />
              <Select
                name="isWarningEq"
                label={I18n.t('activerecord.attributes.ticket.is_warning')}
                placeholder=""
                options={[
                  {
                    name: I18n.t('tickets.yes'),
                    id: 'yes'
                  },
                  {
                    name: I18n.t('tickets.no'),
                    id: 'no'
                  }
                ]}
                onChange={({ target }) =>
                  formik.setFieldValue('isWarningEq', convertBoolean(target.value))
                }
                value={convertBoolean(formik.values.isWarningEq)}
                hasClear
              />
            </Row>
            <Row heading={I18n.t('tickets.form.sections.vehicle')}>
              <Select
                name="vehicleTypeInsensitiveEqInsensitive"
                label={I18n.t('activerecord.attributes.ticket.vehicle_type')}
                options={vehicleTypes.map(type => ({
                  id: replaceVehicleTypes(type.name),
                  name: type.name
                }))}
                placeholder=""
                hasClear
                {...formik.getFieldProps('vehicleTypeInsensitiveEqInsensitive')}
              />
              <Input
                name="vehicleLicensePlateNumberCont"
                label={I18n.t('activerecord.attributes.ticket.vehicle_license_plate_number')}
                controlled
                hasClear
                {...formik.getFieldProps('vehicleLicensePlateNumberCont')}
              />
              <Select
                name="vehicleCountryEq"
                label={I18n.t('activerecord.attributes.ticket.country.code')}
                options={countryCodes.map(countryCode => ({
                  id: countryCode.code,
                  name: countryCode.code
                }))}
                placeholder=""
                hasClear
                {...formik.getFieldProps('vehicleCountryEq')}
              />
              <Select
                name="offenseIsTowableEq"
                label={I18n.t('activerecord.attributes.ticket.offense_is_towable')}
                placeholder=""
                options={[
                  {
                    name: I18n.t('tickets.yes'),
                    id: 'yes'
                  },
                  {
                    name: I18n.t('tickets.no'),
                    id: 'no'
                  }
                ]}
                onChange={({ target }) =>
                  formik.setFieldValue('offenseIsTowableEq', convertBoolean(target.value))
                }
                value={convertBoolean(formik.values.offenseIsTowableEq)}
                hasClear
              />
            </Row>
            <Row heading={I18n.t('tickets.form.sections.location')}>
              <Input
                name="offenseLocationCityCont"
                label={I18n.t('activerecord.attributes.ticket.offense_location_city')}
                controlled
                hasClear
                {...formik.getFieldProps('offenseLocationCityCont')}
              />
              <Input
                name="offenseLocationStreetCont"
                label={I18n.t('activerecord.attributes.ticket.offense_location_street')}
                controlled
                hasClear
                {...formik.getFieldProps('offenseLocationStreetCont')}
              />

              <Input
                name="roofAreaIdEq"
                label={I18n.t('activerecord.attributes.ticket.roof_zone_id')}
                controlled
                hasClear
                {...formik.getFieldProps('roofAreaIdEq')}
              />
            </Row>
            <Row heading={I18n.t('tickets.form.sections.suspect_section_title')}>
              <Input
                name="suspectLastnameCont"
                label={I18n.t('activerecord.attributes.ticket.suspect_lastname')}
                controlled
                hasClear
                {...formik.getFieldProps('suspectLastnameCont')}
              />
            </Row>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <ActionsWrapper className="flex-between">
          <Button className="reset" onClick={() => formik.setValues(initialFields)}>
            {I18n.t('tickets.search.reset')}
          </Button>
          <div>
            <Button onClick={onHide}>{I18n.t('actions.cancel')}</Button>

            <Button className="submit" type="submit" onClick={formik.handleSubmit}>
              {I18n.t('actions.search')}
            </Button>
          </div>
        </ActionsWrapper>
      </Modal.Footer>
    </Modal>
  );
};

export default TicketFilters;
