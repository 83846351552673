import React from 'react';

export const Mock = () => null;
/**
 * This component put some boundaries on components with child components when testing them.
 * Check: https://hackernoon.com/testing-the-finite-react-components-d08ca74eb8c1
 * Related to: https://github.com/facebook/react/issues/15275
 *
 * The validations for presence of child is due to unredered components that may return undefined.
 *
 * @param {React.Children} children - Children that will be conditionally rendered or not
 * @returns {React.Children} children or mock span if it's running on test environment.
 **/
const TestBoundary = ({ children }) =>
  process.env.NODE_ENV === 'test'
    ? React.Children.map(children, child => {
        const props = child ? child.props : {};
        const mockName = child ? child.type.displayName : 'MockedUnknown';
        return <Mock {...{ ...props, mockName }} />;
      })
    : children || null;

export default TestBoundary;
