import React from 'react';
import styled from 'styled-components';
import Tag from './components/Tag';
import { InfoIcon } from 'brickyard-ui';

const StyledTag = styled(Tag)`
  margin: 0 4px;
  padding-top: 2px;
  height: 22px;
`;

const Tags = ({ ticket }) => {
  const dismissalReason =
    ticket.dismissalReason && ticket.dismissalReason !== ''
      ? I18n.t('tickets.show.dismissal.reason', { reason: ticket.dismissalReason })
      : I18n.t('tickets.show.dismissal.no_reason');

  return (
    <>
      {ticket.state === 'complete' && (
        <StyledTag className="tag" color="0,199,44">
          {I18n.t('activerecord.state_machines.ticket.state.states.complete').toUpperCase()}
        </StyledTag>
      )}

      {ticket.blockState === 'blocked' && (
        <StyledTag className="tag" color="203,68,74">
          {I18n.t('activerecord.state_machines.ticket.block_state.states.blocked').toUpperCase()}
        </StyledTag>
      )}
      {ticket.dismissalState === 'dismissed' && (
        <StyledTag className="tag" color="0,0,0" style={{ display: 'flex', flexDirection: 'row' }}>
          {I18n.t(
            'activerecord.state_machines.ticket.dismissal_state.states.dismissed'
          ).toUpperCase()}
          <div style={{ marginLeft: '5px' }}>
            <InfoIcon text={dismissalReason} variant="by-dark" />
          </div>
        </StyledTag>
      )}
      {ticket.exportState === 'exported' && (
        <StyledTag className="tag" color="0,24,239">
          {I18n.t('activerecord.state_machines.ticket.export_state.states.exported').toUpperCase()}
        </StyledTag>
      )}
      {ticket.paymentState === 'paid' && (
        <StyledTag className="tag" color="171,171,0" secondaryColor="255,255,0">
          {I18n.t('activerecord.state_machines.ticket.payment_state.states.paid').toUpperCase()}
        </StyledTag>
      )}
    </>
  );
};

export default Tags;
