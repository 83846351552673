import { useMutation, gql } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MessagePopup } from 'brickyard-ui';
import MiscListItem from './MiscListItem';

const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleteTicketComment(id: $id) {
      id
    }
  }
`;

const StyledDeleteButton = styled.button`
  line-height: 19px;
  font-size: 10px;
  color: #f36c21;
  border: none;
  background: none;
`;

const Remark = ({ id, date, author, text, afterDelete, formik }) => {
  const [confirmationVisible, setConfirmationVisibility] = useState(false);
  const [deleteComment, { loading }] = useMutation(DELETE_COMMENT, {
    variables: { id },
    onCompleted: afterDelete
  });

  const okBtn = {
    label: I18n.t('actions.confirm'),
    onClick: () => {
      if (!loading) {
        deleteComment();
      }
    }
  };
  const cancelBtn = {
    label: I18n.t('actions.cancel'),
    onClick: () => {
      if (!loading) {
        setConfirmationVisibility(false);
      }
    }
  };
  return (
    <div>
      <MiscListItem
        date={date}
        author={author}
        text={text}
        helper={
          !formik.status.readOnly && (
            <StyledDeleteButton onClick={() => setConfirmationVisibility(true)}>
              delete
            </StyledDeleteButton>
          )
        }
      />

      <MessagePopup
        type="confirmation"
        size="md"
        title={I18n.t('tickets.full_ticket.titles.comments')}
        message={I18n.t('comments.messages.confirm_delete')}
        show={confirmationVisible}
        onHide={() => setConfirmationVisibility(false)}
        okBtn={okBtn}
        cancelBtn={cancelBtn}
      />
    </div>
  );
};

export default Remark;
