import _ from 'lodash';

export const getProcessorName = className => {
  let string = _.startCase(className);
  let lastIndex = string.lastIndexOf(' ');

  return string.substring(0, lastIndex);
};

export const getProcessorFullName = className => {
  return _.startCase(className);
};

export const getProcessorType = className => {
  return _.startCase(className)
    .split(' ')
    .pop();
};
