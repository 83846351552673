import React from 'react';
import dayjs from 'dayjs';
import Home from '@/assets/img/home_icon.svg';
import Tickets from '@/assets/img/tickets_icon.svg';
import Information from '@/assets/img/information_icon.svg';
import Suspects from '@/assets/img/suspects_icon.svg';
import Exemptions_requests from '@/assets/img/exemption_requests_icon.svg';
import PowerBI_reports from '@/assets/img/powerbi_icon.svg';
import { TICKETS_FILTER_KEY } from '../../utils/consts';
import 'styles/scenes/left_menu.scss';
import { useSelector } from 'react-redux';

const ticketsFilter = JSON.parse(localStorage.getItem(TICKETS_FILTER_KEY));
const sortBy = ticketsFilter?.s ?? 'WRITTEN_AT_DESC';
const writtenAt =
  ticketsFilter?.writtenAtGteq ??
  dayjs()
    .subtract(3, 'month')
    .toISOString();

const LeftMenu = ({ showCopOptions = false, showTicketOptions = false }) => {
  const {
    exemption_requests_visible: copExemptionRequestsVisible,
    status_visible: statusVisible
  } = useSelector(state => state.user);

  return (
    <nav id="left-menu">
      <section>
        <a href="/">
          <img src={Home} alt="home" />
          {I18n.t('actionbar.home')}
        </a>
        {showCopOptions && (
          <>
            <a href={`/cop/use_cases`}>
              <img src={Suspects} alt="suspects" />
              {I18n.t('suspect_list.index')}
            </a>
            {copExemptionRequestsVisible && (
              <a href="/cop/exemption_requests">
                <img src={Exemptions_requests} alt="exemptions requests" />
                {I18n.t('exemption_requests.index')}
              </a>
            )}
            {statusVisible && (
              <a href="/cop/camera_status">
                <img src={PowerBI_reports} alt="PowerBi reports" />
                {I18n.t('powerbi_reports.index')}
              </a>
            )}
          </>
        )}
        {showTicketOptions && (
          <>
            <a href={`/tickets?s=${sortBy}&writtenAtGteq=${writtenAt}`}>
              <img src={Tickets} alt="tickets" />
              {I18n.t('tickets.index.tickets')}
            </a>
            <a href="/information">
              <img src={Information} alt="information" />
              {I18n.t('actionbar.information')}
            </a>
          </>
        )}
      </section>
    </nav>
  );
};

export default LeftMenu;
