import React, { useEffect } from 'react';
import { Loading, InfoIcon, FaSearch } from 'brickyard-ui';

import { useApiSearch } from '@/hooks/useApiSearch';

const keyMap = {
  country: 'vehicleCountry',
  maker: 'vehicleMake',
  color: 'vehicleColor',
  type: 'vehicleType'
};

const LicensePlateSearch = ({ onChange, onError, country, licensePlate }) => {
  const { data, error, loading, fetchData } = useApiSearch(
    `/services/vehicle/${licensePlate?.toUpperCase()},${country?.toUpperCase()}`,
    parseData
  );

  function parseData(rawData) {
    return Object.keys(keyMap).reduce((newObj, key) => {
      if (rawData?.vehicle_information && rawData.vehicle_information[key]) {
        newObj[keyMap[key]] = rawData.vehicle_information[key];
      } else {
        newObj[keyMap[key]] = '';
      }
      return newObj;
    }, {});
  }

  useEffect(() => {
    let hasData = false;

    if (!data) {
      return;
    }

    for (const [key] of Object.entries(data)) {
      if (data[key]) {
        hasData = true;
        break;
      }
    }

    hasData ? onChange(data) : onError(true);
  }, [data]);

  useEffect(() => {
    error && onError(error);
  }, [error]);

  if (!country || !licensePlate) return null;

  return (
    <i className="clickable" onClick={fetchData}>
      {loading ? (
        <Loading size="sm" />
      ) : (
        <InfoIcon
          text={I18n.t('tickets.form.sections.search_vehicle_info')}
          size="sm"
          Icon={FaSearch}
        />
      )}
    </i>
  );
};

export default LicensePlateSearch;
