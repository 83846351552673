import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Loading } from 'brickyard-ui';
import { selectUseCasesState, useUseCasesSlice } from './slice';
import { useSelector } from 'react-redux';
import Table from '../../components/Table';
import UseCaseListMap from './components/UseCaseListMap';
import UseCaseDashboard from './components/UseCaseDashboard';

const UseCasesPage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .left-panel {
    display: flex;
    flex-direction: column;
    width: calc(100% * 1 / 3);
    height: 100%;

    .left-panel-header {
      display: flex;
      max-height: 100px;
      flex-direction: row;

      h3 {
        margin: 15px;
        color: #777777;
        font-weight: 600;
      }

      button {
        margin: 15px;
        margin-left: auto;
      }
    }

    .left-panel-body {
      display: flex;
      margin: 30px;

      width: calc(100% - 60px);
      height: calc(100% - 20px);

      .loading-box {
        margin: auto;
      }
    }
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    width: calc(100% * 2 / 3 - 60px);
  }
`;

function UseCases() {
  const { actions } = useUseCasesSlice();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const state = useSelector(selectUseCasesState);

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === 'copSettingsWrite';

  useEffect(() => {
    dispatch(actions.fetchUseCases());
    dispatch(actions.fetchUseCaseZones());

    return () => {
      dispatch(actions.clearData());
    };
  }, [dispatch, actions]);

  const columns = [
    {
      key: 'name',
      header: I18n.t('settings.table_name_header'),
      sortable: true
    }
  ];

  const onRowClick = id => {
    dispatch(actions.clearSelectedUseCase());
    history.push({ pathname: `${match.url}/${id}/details`, search: location.search });
  };

  const onNewButtonClick = () => {
    dispatch(actions.clearSelectedUseCase());
    history.push({ pathname: `${match.url}/new/details`, search: location.search });
  };

  const onDashboardClose = () => {
    history.push({ pathname: `${match.url}`, search: location.search });
  };

  return (
    <UseCasesPage>
      <Switch>
        <Route path={`${match.path}/:id`}>
          <UseCaseDashboard onClose={onDashboardClose} />
        </Route>
        <Route path={`${match.path}`}>
          <div className="left-panel">
            <div className="left-panel-header">
              <h3>{I18n.t('settings.use_cases_label')}</h3>
              {hasWritePermission && (
                <Button variant="outline-by-secondary" onClick={onNewButtonClick}>
                  {I18n.t('settings.new_use_case_button')}
                </Button>
              )}
            </div>
            <div className="left-panel-body">
              {state.loading && !state.useCases.length ? (
                <div className="loading-box">
                  <Loading />
                </div>
              ) : (
                <Table
                  columns={columns}
                  items={state.useCases}
                  onRowClick={onRowClick}
                  selectedId={state.selectedUseCase?.id}
                />
              )}
            </div>
          </div>
          <div className="right-panel">
            <UseCaseListMap
              useCaseZones={state.useCaseZones}
              onPolygonClick={(useCase, _zone) => onRowClick(useCase.id)}
            />
          </div>
        </Route>
      </Switch>
    </UseCasesPage>
  );
}

export default UseCases;
