import React from 'react';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import SideBySideSelector from '../../../../../components/SideBySideSelector';
import { idSort } from './utils';

function EuropeanCategoryField({
  formik,
  editEnabled,
  isSwitchable = false,
  formLabel = '',
  switchLabel = ''
}) {
  const selectEuropeanCategory = code => {
    const newSelectedEuropeanCategories = [...formik.values.selectedEuropeanCategories, code];
    const newAvailableEuropeanCategories = formik.values.availableEuropeanCategories.filter(
      c => c.id !== code.id
    );

    formik.setValues({
      ...formik.values,
      selectedEuropeanCategories: newSelectedEuropeanCategories.sort(idSort),
      availableEuropeanCategories: newAvailableEuropeanCategories
    });
  };

  const deselectEuropeanCategory = code => {
    const newSelectedEuropeanCategories = formik.values.selectedEuropeanCategories.filter(
      c => c.id !== code.id
    );
    const newAvailableEuropeanCategories = [...formik.values.availableEuropeanCategories, code];

    formik.setValues({
      ...formik.values,
      selectedEuropeanCategories: newSelectedEuropeanCategories,
      availableEuropeanCategories: newAvailableEuropeanCategories.sort(idSort)
    });
  };

  const selectAllEuropeanCategories = () => {
    const newSelectedEuropeanCategories = [
      ...formik.values.selectedEuropeanCategories,
      ...formik.values.availableEuropeanCategories
    ];

    formik.setValues({
      ...formik.values,
      selectedEuropeanCategories: newSelectedEuropeanCategories.sort(idSort),
      availableEuropeanCategories: []
    });
  };

  const deselectAllEuropeanCategories = () => {
    const newAvailableEuropeanCategories = [
      ...formik.values.availableEuropeanCategories,
      ...formik.values.selectedEuropeanCategories
    ];

    formik.setValues({
      ...formik.values,
      selectedEuropeanCategories: [],
      availableEuropeanCategories: newAvailableEuropeanCategories.sort(idSort)
    });
  };

  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledEuropeanCategories"
              name="enabledEuropeanCategories"
              isOn={formik.values.enabledEuropeanCategories}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledEuropeanCategories}
        />
      )}

      {((formik.values.enabledEuropeanCategories && isSwitchable) || !isSwitchable) && (
        <FormFieldBlock
          label={formLabel}
          className="europeanCategories"
          field={
            <SideBySideSelector
              availableObjects={formik?.values?.availableEuropeanCategories}
              selectedObjects={formik?.values?.selectedEuropeanCategories}
              selectOne={selectEuropeanCategory}
              deselectOne={deselectEuropeanCategory}
              selectAll={selectAllEuropeanCategories}
              deselectAll={deselectAllEuropeanCategories}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.selectedEuropeanCategories}
        />
      )}
    </>
  );
}

export default EuropeanCategoryField;
