import React from 'react';
import { Card, Form as BYForm, Button, Spinner } from 'brickyard-ui';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import '../../../styles/scenes/login.scss';
import Api from '../../../utils/Api';

function New({ locale }) {
  React.useEffect(() => {
    I18n.locale = locale;
  }, []);

  const [isLoading, setIsLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required(I18n.t('forms.validation.required'))
    }),
    onSubmit: values => submitRequest(values)
  });

  const submitRequest = async values => {
    setIsLoading(true);

    try {
      await Api.post('/password', { user: { ...values } }, { validateStatus: false });
    } catch (e) {
      console.error(e);
    }

    window.location = '/login';
  };

  return (
    <Card className="login-card">
      <Card.Header>{I18n.t('forms.forgot_password.title')}</Card.Header>
      <Card.Body>
        <p>{I18n.t('devise.passwords.send_paranoid_instructions')}</p>

        <form className="password-reset-request-form" onSubmit={formik.handleSubmit}>
          <BYForm.Group>
            <BYForm.Control
              placeholder={I18n.t('activerecord.attributes.user.email')}
              isValid={formik.touched.email && !formik.errors.email}
              id="email"
              name="email"
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email && (
              <BYForm.Control.Feedback type="invalid">
                {formik.errors.email}
              </BYForm.Control.Feedback>
            )}
          </BYForm.Group>

          <Button
            disabled={isLoading}
            variant="by-primary"
            type="submit"
            block
            id="password-reset-request-submit"
          >
            {isLoading ? <Spinner animation="border" /> : I18n.t('forms.forgot_password.submit')}
          </Button>
        </form>
      </Card.Body>
      <Card.Footer>
        <a href="/login">{I18n.t('forms.forgot_password.back')}</a>
      </Card.Footer>
    </Card>
  );
}

export default New;
