export const iCalToJSON = iCalString => {
  if (!iCalString) return {};

  const lines = iCalString.split('\n');
  const json = {};

  lines.forEach(line => {
    const lineSplit = line.split(':');
    const key = lineSplit[0];
    const value = lineSplit[1];

    if (key === 'RRULE') {
      json[key] = {};
      const ruleSplit = value.split(';');
      ruleSplit.forEach(rule => {
        const ruleSplit = rule.split('=');
        const ruleKey = ruleSplit[0];
        const ruleValue = ruleSplit[1];
        json[key][ruleKey] = ruleValue;
      });
    } else {
      json[key] = value.split(';')[1];
    }
  });

  return json;
};

export const parseICalJSON = json => {
  let startDate = '';
  let startTime = '';
  if (json.DTSTART) {
    startTime = json.DTSTART.split('T')[1].slice(0, 4);
    startTime = startTime.slice(0, 2) + ':' + startTime.slice(2, 4);

    startDate = json.DTSTART?.split('T')[0];
    startDate = startDate.slice(0, 4) + '-' + startDate.slice(4, 6) + '-' + startDate.slice(6, 8);
  }

  const endDate = startDate;
  let endTime = '';
  if (json.DTEND) {
    endTime = json.DTEND?.split('T')[1].slice(0, 4);
    endTime = endTime.slice(0, 2) + ':' + endTime.slice(2, 4);
  }

  let untilDate = '';
  if (json.RRULE?.UNTIL) {
    untilDate = json.RRULE?.UNTIL.split('T')[0];
    untilDate = untilDate.slice(0, 4) + '-' + untilDate.slice(4, 6) + '-' + untilDate.slice(6, 8);
  }

  let recurrence = (json.RRULE?.BYDAY ?? '').split(',').filter(Boolean);

  return {
    startTime,
    endTime,
    startDate,
    endDate,
    untilDate,
    untilTime: '235959Z',
    frequency: 'WEEKLY',
    recurrence
  };
};

export const JSONToICal = values => {
  const startDate = values.startDate.replace(/-/g, '');
  const startTime = `${values.startTime.replace(/:/g, '')}00`.substring(0, 6);

  const endDate = values.startDate.replace(/-/g, '');
  const endTime = `${values.endTime.replace(/:/g, '')}59`.substring(0, 6);

  const recurrence = values.recurrence.join(',');
  const untilDate = values.untilDate?.replace(/-/g, '');

  let dtStart = `DTSTART:TZID=CET;${startDate}T${startTime}`;
  let dtEnd = `DTEND:TZID=CET;${endDate}T${endTime}`;
  let rrule = `RRULE:FREQ=${values.frequency};BYDAY=${recurrence}`;
  let until = untilDate ? `;UNTIL=${untilDate}T${values.untilTime}` : '';

  return `${dtStart}\n${dtEnd}\n${rrule}${until}`;
};
