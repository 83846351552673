import React, { useState } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';

import Api from '../../utils/Api';
import TestBoundary from '@/utils/TestBoundary';
import ActivityCheckerModal from '../shared/ActivityChecker/ActivityCheckerModal';

import { SHOW_LOGGED_OUT_MODAL } from '../../utils/consts';

const ActivityChecker = ({
  activity_checker_prompt_timeout,
  activity_checker_logout_timeout,
  cross_tab = true
}) => {
  const PROMPT_TIMEOUT_VALUE = activity_checker_prompt_timeout || 15;
  const LOGOUT_TIMEOUT_VALUE = activity_checker_logout_timeout || 1;

  const [showActivityCheckerModal, setShowActivityCheckerModal] = useState(false);

  const processLogout = async () => {
    await Api.delete('/logout');
  };

  const onPrompt = () => {
    setShowActivityCheckerModal(true);
  };

  const onIdle = () => {
    setShowActivityCheckerModal(false);
    processLogout().then(() => {
      localStorage.setItem(SHOW_LOGGED_OUT_MODAL, true);
      location.reload();
    });
  };

  return (
    <>
      <IdleTimerProvider
        crossTab={cross_tab}
        syncTimers={true}
        name="ActivityChecker"
        promptTimeout={1000 * 60 * LOGOUT_TIMEOUT_VALUE}
        timeout={1000 * 60 * PROMPT_TIMEOUT_VALUE}
        onPrompt={onPrompt}
        onIdle={onIdle}
        stopOnIdle={true}
      >
        {showActivityCheckerModal && (
          <TestBoundary>
            <ActivityCheckerModal
              show={showActivityCheckerModal}
              onHide={() => setShowActivityCheckerModal(false)}
              onLogout={onIdle}
            />
          </TestBoundary>
        )}
      </IdleTimerProvider>
    </>
  );
};

export default ActivityChecker;
