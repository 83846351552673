import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Badge, Button, InfoLabel, Loading, Modal } from 'brickyard-ui';

import { GET_EXEMPTIONS } from '@/components/queries/observations';

import formatDate from '@/utils/formatDate';
import { LongDateFormatNoTime } from '@/utils/DateFormats';

import '@/styles/shared/observations.scss';

const I18N_PREFIX = 'observations.observations.exemption_dialog';

const ExemptedBadge = ({ observationId, areaId }) => {
  const [showModal, setShowModal] = useState(false);
  const { loading, data, error } = useQuery(GET_EXEMPTIONS, {
    variables: { id: [observationId], areaId }
  });

  if (loading) return <Loading size="sm" />;
  if (error) return <Badge variant="danger">{error.toString()}</Badge>;
  if (!data.observations.nodes.length || !data.observations.nodes[0].observationExemptions.length)
    return (
      <Badge variant="by-secondary">
        {I18n.t('observations.observations.details.exempted_badge')}
      </Badge>
    );

  const { observationExemptions } = data.observations.nodes[0];

  return (
    <>
      <Badge onClick={() => setShowModal(true)} variant="by-secondary" className="clickable">
        {I18n.t('observations.observations.details.exempted_badge')} ({observationExemptions.length}
        )
      </Badge>

      <Modal
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        size="lg"
        className="observation-exempted-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('observations.observations.exemption_dialog.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="exempted-modal-body">
          {observationExemptions.map(observationExemption => (
            <div className="exemption-item" key={observationExemption.id}>
              <h5>
                {observationExemption.exemptionType === 'blocktime'
                  ? I18n.t(
                      'observations.observations.exemption_dialog.intro_exemption_blocktime_dialog'
                    )
                  : I18n.t(
                      'observations.observations.exemption_dialog.intro_exemption_permit_dialog'
                    )}
              </h5>
              <InfoLabel
                title={I18n.t(`${I18N_PREFIX}.exemption_type_label`)}
                text={I18n.t(
                  `observations.observations.exemption_dialog.${observationExemption.exemptionType}`
                )}
              />
              {observationExemption.exemptionType !== 'blocktime' && (
                <>
                  <InfoLabel
                    title={I18n.t(`${I18N_PREFIX}.exemption_holder_label`)}
                    text={observationExemption.exemptionHolder}
                  />
                  {observationExemption.startDate && (
                    <InfoLabel
                      title={I18n.t(`${I18N_PREFIX}.date_box_label`)}
                      text={`${formatDate(
                        observationExemption.startDate,
                        LongDateFormatNoTime
                      )} - ${formatDate(observationExemption.endDate, LongDateFormatNoTime)}`}
                    />
                  )}
                </>
              )}
              <InfoLabel
                title={I18n.t(`${I18N_PREFIX}.days_of_the_week_label`)}
                text={observationExemption.daysOfTheWeek
                  .split(' ')
                  .map(day => I18n.t(`${I18N_PREFIX}.${day.toLowerCase()}`))
                  .join(', ')}
              />
              {observationExemption.startTime && (
                <InfoLabel
                  title={I18n.t(`${I18N_PREFIX}.time_box_label`)}
                  text={`${observationExemption.startTime}-${observationExemption.endTime}`}
                />
              )}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="by-dark" onClick={() => setShowModal(false)}>
            {I18n.t('actions.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExemptedBadge;
