import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Form as BYForm, Loading, Modal, Card } from 'brickyard-ui';

import useDebounce from '@/hooks/useDebounce';

import { GET_CLARIFICATIONS } from '@/components/queries/tickets';

import '@/styles/shared/tickets.scss';

const OffenseClarificationsSearch = ({ show, onHide, offenseCode, onSelect }) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 400);

  const { data, loading } = useQuery(GET_CLARIFICATIONS, {
    variables: {
      search: { offenseCode, text: debouncedSearch },
      limit: !!debouncedSearch && !!offenseCode ? 10 : 0,
      offset: 0
    },
    skip: !offenseCode
  });

  useEffect(() => {
    setSearch('');
  }, [show]);

  const selectClose = c => {
    onSelect(c);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" id="offense-clarification-search">
      <Modal.Header closeButton>
        {I18n.t('activerecord.attributes.ticket.offense_clarification')}
      </Modal.Header>

      <Modal.Body>
        <BYForm.Group>
          <BYForm.Label>{I18n.t('actions.search')}</BYForm.Label>

          <BYForm.Control value={search} onChange={evt => setSearch(evt.target.value)} />
        </BYForm.Group>

        <section className="search-result-card-list">
          {loading && <Loading size="sm" />}
          {data?.clarifications &&
            data.clarifications.map(c => (
              <Card key={c.id} className="result-card" onClick={() => selectClose(c)}>
                <Card.Body>{c.text}</Card.Body>
              </Card>
            ))}
          {!loading && !!search && !data?.clarifications?.length && (
            <p>{I18n.t('messages.general.no_results')}</p>
          )}
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default OffenseClarificationsSearch;
