import { useCallback, useReducer } from 'react';
import Api from '@/utils/Api';

export const useApiSearch = (url, onDataParse = data => data) => {
  const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), {
    data: null,
    error: null,
    loading: false
  });

  const fetchData = useCallback(async () => {
    if (state.loading) {
      return;
    }
    setState({ loading: true });
    try {
      const { data: rawData } = await Api.get(url);
      if (rawData?.err) {
        setState({ error: rawData.msg, loading: false });
        return;
      }
      setState({ data: onDataParse(rawData), error: null, loading: false });
    } catch (err) {
      setState({ error: err?.message ?? err?.response?.data?.msg, loading: false });
    }
  }, [url, onDataParse]);

  return { ...state, fetchData };
};
