import { gql } from '@apollo/client';

export const GET_EXEMPTIONS = gql`
  query GetExemptions($exemptionSearch: ExemptionSearch, $requestSearch: ExemptionRequestSearch) {
    exemptionsList(search: $exemptionSearch) {
      id
      licensePlate
      result
      copExemptionType {
        id
        global
      }
      copUseCase {
        id
      }
    }
    exemptionRequests(search: $requestSearch) {
      id
    }
  }
`;
