import React from 'react';
import styled from 'styled-components';
import CitySearch from './../../../TicketsTable/search/CitySearch';
import Label from './Label';
import AutoCompleteWrapper from './AutoCompleteWrapper';
import ErrorWrapper from './ErrorWrapper';

const Wrapper = styled.div`
  position: relative;

  .error-message {
    bottom: -10px;
  }
`;

const CitySearchInput = ({ label, fieldProps, disabled, readOnly, error, cityType }) => {
  return (
    <Wrapper>
      <AutoCompleteWrapper>
        <Label>
          {label}
          {readOnly && <span className="readonly-value">{fieldProps.value}</span>}
        </Label>
        {!readOnly && (
          <>
            <CitySearch disabled={disabled} isInvalid={error} cityType={cityType} {...fieldProps} />
            {error && <ErrorWrapper className="error-message">{error}</ErrorWrapper>}
          </>
        )}
      </AutoCompleteWrapper>
    </Wrapper>
  );
};

export default CitySearchInput;
