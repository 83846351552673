import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  margin: 6px 0;

  .note {
    display: inline-block;
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }

  label {
    font-size: 12px;
    font-weight: 400;

    &.is-invalid {
      color: #dc3545;
    }
  }

  input {
    display: none;

    &:checked + div {
      &:not(.is-disabled) {
        border: 0.5px solid #f36c21;
      }
      > div {
        width: 12px;
        height: 12px;
      }
    }
  }

  .radio {
    width: 33.33%;
  }

  .error-message {
    width: max-content;
    font-size: 10px;
    position: absolute;
    bottom: -16px;
    left: 0;
    color: #dc3545;
  }
`;

const OuterCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0.5px solid #979797;
  box-sizing: border-box;
  margin: 0 12px 0 0;

  &.is-invalid {
    border-color: #dc3545;
  }

  &.is-disabled {
    cursor: not-allowed;
    > div {
      background-color: #ddd;
    }
  }
`;

const InnerCircle = styled.div`
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #f36c21;
  transition: all 0.1s linear;
`;

const LabelRadio = ({ label, name, onChange, defaultValue, disabled, error, options = [] }) => {
  return (
    <Wrapper>
      <span className="note">{label}</span>
      <div className="flex-between">
        {options.map(
          (option, index) =>
            !option.isHidden && (
              <div key={`${name}_${option.value}_${index}`} className="radio">
                <label className={error ? 'flex-start is-invalid' : 'flex-start'}>
                  <input
                    name={name}
                    type="radio"
                    disabled={disabled}
                    value={option.value}
                    defaultChecked={defaultValue === option.value}
                    onChange={onChange}
                  />
                  <OuterCircle
                    className={`${error ? 'is-invalid' : ''} ${disabled ? 'is-disabled' : ''}`}
                  >
                    <InnerCircle />
                  </OuterCircle>
                  {option.label}
                </label>
              </div>
            )
        )}
      </div>
      {error && <span className="error-message">{error}</span>}
    </Wrapper>
  );
};

export default LabelRadio;
