import { useQuery, gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'brickyard-ui';
import ImageComparison from './ImageComparasion';
import withApolloProvider from '../../../../../../../utils/withApolloProvider';
import { getImageToAdjust, getLicenseImage } from './utils';

function CameraBlurringOffset({ show, onHide, formik, camera }) {
  const GET_OBSERVATIONS = gql`
    query GetObservations($search: CopObservationSearch, $limit: Int) {
      copObservations(search: $search, limit: $limit) {
        id
        observedAt
        files {
          id
          url
          kind
          licensePlateLocation
        }
      }
    }
  `;

  const { error, data } = useQuery(GET_OBSERVATIONS, {
    variables: { search: { copCameraId: camera?.id }, limit: 20 }
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const observations = data?.copObservations.filter(observation => {
    const licenseImage = getLicenseImage(observation);
    return !!licenseImage && !!getImageToAdjust(observation, licenseImage.kind);
  });
  const [offset, setOffset] = useState(null);

  const previousObservation = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  };

  const nextObservation = () => {
    if (observations && currentIndex < observations.length - 1) {
      setCurrentIndex(prev => prev + 1);
    }
  };

  const nextDisabled = () => {
    return !observations || currentIndex >= observations.length - 1 ? 'disabled' : '';
  };

  const previousDisabled = () => {
    return currentIndex <= 0 ? 'disabled' : '';
  };

  useEffect(() => {
    formik.setFieldValue('license_plate_offset', offset);
  }, [offset]);

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" className="blurring-offset-modal">
        <Modal.Header closeButton>
          <div className="container">
            <div className="row">
              <div className="col">
                <Modal.Title>{I18n.t('settings.fixed_cameras_blurring_offset_label')}</Modal.Title>
              </div>
              <div className="col d-flex justify-content-center">
                <Button
                  variant="outline-by-secondary"
                  className="m-2 w-50"
                  disabled={previousDisabled()}
                  onClick={previousObservation}
                >
                  {I18n.t('settings.blurring_offset.previous_button')}
                </Button>
                <Button
                  variant="outline-by-secondary"
                  className="m-2 w-50"
                  disabled={nextDisabled()}
                  onClick={nextObservation}
                >
                  {I18n.t('settings.blurring_offset.next_button')}
                </Button>
              </div>
              <div className="col"></div>
            </div>
          </div>
        </Modal.Header>
        {error && (
          <Modal.Body>
            <p className="p-5">Error: {error.message}</p>
          </Modal.Body>
        )}

        {observations && observations.length === 0 && (
          <p className="p-5">{I18n.t('settings.blurring_offset.no_results')}</p>
        )}
        {observations && observations.length > 0 && (
          <Modal.Body>
            <ImageComparison
              observation={observations[currentIndex]}
              offset={formik.values.license_plate_offset || camera.license_plate_offset}
              setOffset={setOffset}
            ></ImageComparison>
          </Modal.Body>
        )}
        <Modal.Footer className="summary-footer">
          <Button onClick={onHide}>{I18n.t('settings.form_close_button')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default withApolloProvider(CameraBlurringOffset);
