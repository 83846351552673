import React from 'react';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldBlock from '../../../../../components/FormFieldBlock';

function VehicleWidthField({ formik, editEnabled, isSwitchable = false, switchLabel = '' }) {
  return (
    <>
      {isSwitchable && (
        <FormFieldSmall
          label={switchLabel}
          field={
            <LabelToggle
              id="enabledVehicleWidth"
              name="enabledVehicleWidth"
              isOn={formik.values.enabledVehicleWidth}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.enabledVehicleWidth}
        />
      )}

      {((formik.values.enabledVehicleWidth && isSwitchable) || !isSwitchable) && (
        <>
          <FormFieldBlock
            className="vehicleWidth"
            field={
              <>
                <label className="left-label">
                  {I18n.t('settings.pipelines.processors.form.from')}
                </label>
                <input
                  id="vehicleWidthGt"
                  name="vehicleWidthGt"
                  type="number"
                  className="small-input"
                  defaultValue={formik.values.vehicleWidthGt ?? ''}
                  onChange={formik.handleChange}
                  disabled={!editEnabled}
                />

                <label className="middle-label">
                  {I18n.t('settings.pipelines.processors.form.to')}
                </label>
                <input
                  id="vehicleWidthLt"
                  name="vehicleWidthLt"
                  type="number"
                  className="small-input"
                  defaultValue={formik.values.vehicleWidthLt ?? ''}
                  onChange={formik.handleChange}
                  disabled={!editEnabled}
                />

                <label className="right-label">
                  {I18n.t('settings.pipelines.processors.form.including_cm')}
                </label>

                <div className="error-label">{formik.errors.vehicleWidthGt}</div>
                <div className="error-label">{formik.errors.vehicleWidthLt}</div>
              </>
            }
            error={formik.errors.vehicleWidth}
          />
        </>
      )}
    </>
  );
}

export default VehicleWidthField;
