import React, { useState } from 'react';
import Slider from 'react-input-slider';

import { Card, Col, Row, Form as BYForm, Button, FaEdit } from 'brickyard-ui';

import Api from '@/utils/Api';

const CorrectionCard = ({
  areaId,
  reporterId,
  onCancel,
  maxVehicles,
  prisCorrection,
  setPrisCorrection,
  occupiedSpots,
  setOccupiedSpots,
  prisDisplayValue
}) => {
  const [freeSpots, setFreeSpots] = useState(maxVehicles - occupiedSpots);
  const [enableCorrectionEdit, setEnabledCorrectionEdit] = useState(false);

  const updateArea = async () => {
    try {
      const data = {
        area_id: areaId,
        counting_reporter: { correction: prisCorrection },
        counting_area: { current_occupancy: occupiedSpots }
      };
      await Api.patch(`/administration/counting/reporters/${reporterId}`, data);
      // This is necessary to reuse the current controller
      location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const updateOccupancies = occupancies => {
    if (occupancies.freeSpots) {
      let freeSpots = parseInt(occupancies.freeSpots) || 0;
      if (freeSpots < 0) {
        freeSpots = 0;
      }
      if (freeSpots > maxVehicles) {
        freeSpots = maxVehicles;
      }
      setFreeSpots(freeSpots);
      setOccupiedSpots(maxVehicles - freeSpots);
    } else if (occupancies.occupiedSpots) {
      let occupiedSpots = parseInt(occupancies.occupiedSpots) || 0;
      if (occupiedSpots < 0) {
        occupiedSpots = 0;
      }
      if (occupiedSpots > maxVehicles) {
        occupiedSpots = maxVehicles;
      }
      setOccupiedSpots(occupiedSpots);
      setFreeSpots(maxVehicles - occupiedSpots);
    }
  };

  const updatePrisCorrection = val => {
    let corr = parseInt(val) || 0;
    if (corr < 0) {
      corr = 0;
    }
    if (corr > maxVehicles) {
      corr = maxVehicles;
    }
    setPrisCorrection(corr);
  };

  return (
    <Card className="correction-card">
      <Card.Body>
        <div className="capacity-header">
          <small>{I18n.t('counting_reporters.dashboard.capacity')}</small>
          <h5>{maxVehicles}</h5>
        </div>

        <Row>
          <Col xs="6">
            <BYForm.Label>
              {I18n.t('counting_reporters.dashboard.actual_available_spots')}
            </BYForm.Label>
          </Col>
          <Col xs="6">
            <BYForm.Label className="d-flex justify-content-end">
              {I18n.t('counting_reporters.dashboard.actual_occupancy')}
            </BYForm.Label>
          </Col>
        </Row>

        <Row>
          <Col xs="4">
            <BYForm.Control
              name="actualAvailableSpots"
              value={freeSpots}
              onChange={evt => updateOccupancies({ freeSpots: evt.target.value })}
              type="number"
              min="0"
              max={maxVehicles}
            />
          </Col>
          <Col xs={{ span: 4, offset: 4 }}>
            <BYForm.Control
              name="actualOccupancy"
              value={occupiedSpots}
              onChange={evt => updateOccupancies({ occupiedSpots: evt.target.value })}
              type="number"
              min="0"
              max={maxVehicles}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <Slider
              className="occupancy-slider"
              styles={{ track: { backgroundColor: 'red' }, active: { backgroundColor: 'green' } }}
              xmin={-1} // normally it would be 0, but then you cannot pull the slider below 1 (don't know why)
              xmax={maxVehicles}
              xstep={1}
              x={freeSpots}
              onChange={({ x }) => updateOccupancies({ freeSpots: x })}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="8">
            <BYForm.Label>{I18n.t('counting_reporters.dashboard.pris_correction')}</BYForm.Label>
            <FaEdit className="edit-correction" onClick={() => setEnabledCorrectionEdit(true)} />
          </Col>

          <Col>
            <BYForm.Control
              name="correction"
              value={prisCorrection}
              onChange={evt => updatePrisCorrection(evt.target.value)}
              onBlur={() => setEnabledCorrectionEdit(false)}
              type="number"
              min="0"
              max={maxVehicles}
              disabled={!enableCorrectionEdit}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="8">
            <BYForm.Label>{I18n.t('counting_reporters.dashboard.pris_display')}</BYForm.Label>
          </Col>

          <Col>
            <BYForm.Control
              type="number"
              name="display"
              value={prisDisplayValue(maxVehicles, occupiedSpots, prisCorrection)}
              onChange={() => {}}
              readOnly
              disabled
            />
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer className="correction-card-footer">
        <Button variant="by-dark" size="sm" onClick={onCancel}>
          {I18n.t('actions.cancel')}
        </Button>

        <Button variant="by-primary" size="sm" onClick={updateArea}>
          {I18n.t('counting_reporters.dashboard.calibrate')}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default CorrectionCard;
