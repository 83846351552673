import { boolean, string, mixed } from 'yup';
// import { toLower } from 'ramda';

import { requiredMsg, isCameraSystem } from './CommonTicketSchema';
// import dutchSSNIsValid from './dutchSSNValidator';

const shouldRequire = (type, sourceSystem, personalInformationValidation) => {
  switch (type) {
    case 'fiscal':
      return false;
    case 'juridical':
      return !isCameraSystem(sourceSystem) && personalInformationValidation === 'required';
    case 'taxi':
      return personalInformationValidation === 'required';
    case 'recording':
      return true; // template action.person_fields affects this
    default:
      return false;
  }
};

const customIsRequired = string().when(['type', 'sourceSystem', 'personalInformationValidation'], {
  is: shouldRequire,
  then: string()
    .ensure()
    .required(requiredMsg),
  otherwise: string().ensure()
});

const suspectZvwovpFieldValidation = string().when(
  ['type', 'sourceSystem', 'personalInformationValidation', 'suspectZvwovp'],
  {
    is: (type, sourceSystem, personalInformationValidation, suspectZvwovp) =>
      shouldRequire(type, sourceSystem, personalInformationValidation) && suspectZvwovp !== true,
    then: string()
      .ensure()
      .required(requiredMsg),
    otherwise: string().ensure()
  }
);

// const nlList = ['nl', 'netherlands', 'nederland'];

const suspectValidations = {
  suspectIdentificationType: customIsRequired,
  suspectIdentificationCode: string().when('type', {
    is: 'taxi',
    then: string()
      .ensure()
      .required(requiredMsg),
    otherwise: string().ensure()
  }),
  suspectSsn: string().when('type', {
    is: 'recording',
    then: string()
      .ensure()
      .required(requiredMsg),
    otherwise: string().ensure()
  }),
  // TODO: add this back after I make sure how it should work and if business needs it
  // .when('suspectResidenceCountry', {
  //   is: country => (country ? nlList.includes(toLower(country)) : false),
  //   then: string()
  //     .ensure()
  //     .test('is-valid-ssn', invalidMsg, dutchSSNIsValid)
  // }),
  suspectFirstnames: customIsRequired,
  suspectLastname: customIsRequired,
  suspectGender: customIsRequired,

  suspectBirthDate: mixed().when(['type', 'sourceSystem', 'personalInformationValidation'], {
    is: shouldRequire,
    then: mixed().required(requiredMsg)
  }),
  suspectBirthCountry: customIsRequired,
  suspectBirthCity: customIsRequired,
  suspectIsResident: boolean().when(['type', 'sourceSystem'], {
    is: shouldRequire,
    then: boolean().required(requiredMsg),
    otherwise: boolean().nullable()
  }),

  suspectResidenceCountry: suspectZvwovpFieldValidation,
  suspectResidenceZipcode: suspectZvwovpFieldValidation,
  suspectResidenceCity: suspectZvwovpFieldValidation,
  suspectResidenceStreet: suspectZvwovpFieldValidation,
  suspectResidenceHousenumber: suspectZvwovpFieldValidation
};

export default suspectValidations;
