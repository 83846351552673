import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';

import { Col, Loading, Row, Button as BYButton } from 'brickyard-ui';
import { omit } from 'ramda';

import { GET_SOURCE_SYSTEMS, GET_ENF_CONFIGURATIONS } from '@/components/queries/tickets';
import useToggle from '@/hooks/useToggle';

import { i18nMap } from './SearchTicketDialog';
import TestBoundary from '@/utils/TestBoundary';

import '@/styles/shared/tickets.scss';
import Input from '../Ticket/Cards/components/LabelInput';
import DatePicker from '../Ticket/Cards/components/LabelDatePicker';
import Button from '../Ticket/components/Button';
import FilterTags from './FilterTags';
import TicketFilters, { initialFields } from './TicketFilters';
import { getCleanFilters } from './utils';
import HistorySearch from './HistorySearch';
import formatDate from '@/utils/formatDate';
import { ShortDateFormat } from '@/utils/DateFormats';

export const i18nAllSearchFields = {
  ...i18nMap,
  observationIdEq: I18n.t('tickets.search.observation_id_eq'),
  useCaseResultIdEq: I18n.t('tickets.search.observation_id_eq'),
  temporaryFilters: I18n.t('tickets.search.temporary_filters'),
  vehicleLicensePlateNumberCont: I18n.t(
    'activerecord.attributes.ticket.vehicle_license_plate_number'
  ),
  suspectLastnameCont: I18n.t('activerecord.attributes.ticket.suspect_lastname'),
  pvNumberEq: I18n.t('activerecord.attributes.ticket.pv_number'),
  writtenAtLteq: I18n.t('tickets.search.written_at_lteq_placeholder'),
  writtenAtGteq: I18n.t('tickets.search.written_at_gteq_placeholder'),
  writtenAt: I18n.t('tickets.search.written_at_placeholder'),
  vehicleCountryEq: I18n.t('activerecord.attributes.ticket.country.code'),
  roofAreaIdEq: I18n.t('activerecord.attributes.ticket.roof_zone_id'),
  dismissalStateEq: I18n.t('activerecord.attributes.ticket.dismissed'),
  transportIdPresent: I18n.t('tickets.search.transport_id_present')
};

export const temporaryFiltersKeys = [
  'observationIdEq',
  'isWarningEq',
  'offenseCodeCont',
  'vehicleLicensePlateNumberInsensitiveEqInsensitive',
  'useCaseResultIdEq',
  'temporaryFilters'
];

const StyledButton = styled(Button)`
  font-size: 14px;
  color: #fd6e1d;
  border: none;
`;

const TicketsSearch = ({ onSearch, onHistorySearch, value: appliedFilters, historySearch }) => {
  const [advancedModalIsOpen, toggleAdvancedModal] = useToggle(false);
  const [historyModalIsOpen, toggleHistoryModal] = useToggle(false);

  const offenseTypes = {
    ASTERISK: I18n.t('tickets.asteriks'),
    FISCAL: I18n.t('tickets.fiscal'),
    MULDER: I18n.t('tickets.mulder'),
    POLICE: I18n.t('tickets.police'),
    TAXI: I18n.t('tickets.taxi')
  };
  const { data: sourceData, loading: loadingSource } = useQuery(GET_SOURCE_SYSTEMS);
  const { data: configData, loading: loadingConfig } = useQuery(GET_ENF_CONFIGURATIONS);

  const initialValues = {
    vehicleLicensePlateNumberCont: '',
    writtenAtLteq: null,
    writtenAtGteq: null,
    vehicleTypeInsensitiveEqInsensitive: '',
    pvNumberEq: ''
  };
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...appliedFilters
    }
  });

  // Updates the values on formik after onChange is emitted
  useEffect(() => {
    formik.setValues({ ...formik.values, ...appliedFilters });
  }, [appliedFilters]);

  // Get user friendly field values when label and values differ
  const getValueLabel = (key, value) => {
    switch (key) {
      case 'observationIdEq':
        return 'true';
      case 'useCaseResultIdEq':
        return 'true';
      case 'temporaryFilters':
        return 'true';
      case 'offenseTypeEq':
        return offenseTypes[value];
      case 'sourceSystemEq':
        return sourceData?.licensedSourceSystems?.find(sys => sys.value === value)?.name || value;
      case 'enforcementConfigurationIdEq':
        return configData?.enforcementConfigurations?.find(cfg => cfg.id === value)?.name || value;
      case 'writtenAtGteq':
      case 'writtenAtLteq':
      case 'writtenAt':
        return formatDate(value, ShortDateFormat);
      default:
        return value || formik.values[key];
    }
  };

  if (loadingSource || loadingConfig) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const removeTag = key => {
    const filters = omit([key], appliedFilters);
    formik.setFieldValue(key, initialFields[key]);
    onSearch(filters);
  };

  const handleDialogSubmit = filters => {
    const cleanFilters = getCleanFilters(filters);
    onSearch(cleanFilters);
    formik.setValues(filters);
    toggleAdvancedModal();
  };

  const handleHistorySubmit = filters => {
    resetSearch();
    const cleanFilters = getCleanFilters(omit(['searchMode'], filters));
    onHistorySearch(cleanFilters);
    toggleHistoryModal();
  };

  const resetSearch = () => {
    onSearch({});
    formik.setValues(initialValues);
  };

  return (
    <section id="tickets-search">
      <TestBoundary>
        <TicketFilters
          show={advancedModalIsOpen}
          appliedFilters={appliedFilters}
          onHide={toggleAdvancedModal}
          onSubmit={handleDialogSubmit}
        />
        <HistorySearch
          show={historyModalIsOpen}
          onHide={toggleHistoryModal}
          onSubmit={handleHistorySubmit}
        />
      </TestBoundary>
      <Row>
        <Col xs="3" lg="1">
          <Input
            label={I18n.t('activerecord.attributes.ticket.vehicle_license_plate_number')}
            controlled
            {...formik.getFieldProps('vehicleLicensePlateNumberCont')}
          />
        </Col>
        <Col xs="3" lg="1">
          <Input
            label={I18n.t('activerecord.attributes.ticket.pv_number')}
            controlled
            {...formik.getFieldProps('pvNumberEq')}
          />
        </Col>

        <Col xs="3" lg="3" xl="2">
          <DatePicker
            label={I18n.t('tickets.search.written_at_gteq') + ' ' + I18n.t('time.js.formats.date')}
            {...formik.getFieldProps('writtenAtGteq')}
            onChange={date => formik.setFieldValue('writtenAtGteq', date && date.toISOString())}
            showTimeInput
          />
        </Col>

        <Col xs="3" lg="3" xl="2">
          <DatePicker
            label={I18n.t('tickets.search.written_at_lteq') + ' ' + I18n.t('time.js.formats.date')}
            {...formik.getFieldProps('writtenAtLteq')}
            onChange={date => formik.setFieldValue('writtenAtLteq', date && date.toISOString())}
            showTimeInput
          />
        </Col>

        <Col xs="4" lg="2" xl="3" className="search-btn">
          <StyledButton onClick={toggleAdvancedModal}>
            {I18n.t('observations.observations.search.advanced_filters')}
          </StyledButton>
          <StyledButton onClick={toggleHistoryModal}>
            {I18n.t('observations.observations.search.history_search')}
          </StyledButton>
        </Col>

        <Col xs="4" lg="3" xl="1" className="search-btn text-right p-0">
          <BYButton variant="light-by-primary" onClick={resetSearch}>
            {I18n.t('tickets.search.reset')}
          </BYButton>
        </Col>

        <Col xs="3" lg="2" xl="1" className="search-btn">
          <Button variant="by-secondary" onClick={() => onSearch(getCleanFilters(formik.values))}>
            {I18n.t('actions.search')}
          </Button>
        </Col>
      </Row>
      <FilterTags
        filters={historySearch || appliedFilters}
        removeTag={removeTag}
        getLabel={getValueLabel}
        i18nAllSearchFields={i18nAllSearchFields}
        isHistorySearch={!!historySearch}
      />
    </section>
  );
};

export default TicketsSearch;
