import React, { useEffect, useRef, useState } from 'react';
import Close from '@/assets/img/close_icon.svg';
import styled from 'styled-components';
import FormField from '../../../components/FormField';
import FormControls from '../../../components/FormControls';
import OutsideClickHandler from '../../../../../../OutsideClickHandler';
import InputConfirmationModal from '../../../components/InputConfirmationModal';
import locationSchema from '../locationValidationSchema';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Loading, Alert } from 'brickyard-ui';
import { COP_PERM_WRITE } from '../../../../../../../utils/consts';
import { useLocationsSlice } from '../locationsSlice';
import { useDispatch, useSelector } from 'react-redux';

const LocationFormPanel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .loading-box {
    margin: auto;
  }

  .click-handler-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .form-header {
      display: flex;
      width: calc(100% - 80px);
      margin: 10px;
      height: 50px;

      h5 {
        display: flex;
        color: #4a4a49;
        font-weight: 600;
        margin: auto auto auto 10px;

        .static-title {
          font-weight: 300;
          margin-right: 7px;
        }
      }

      .close-button {
        margin: auto 10px auto auto;
        cursor: pointer;
      }
    }
  }
`;

const LocationForm = ({ location, onClose, isLoading, backendValidationError, _permission }) => {
  const { actions } = useLocationsSlice();
  const dispatch = useDispatch();
  const params = useParams();

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === COP_PERM_WRITE;

  const [editEnabled, setEditEnabled] = useState(false);
  const editEnabledRef = useRef();
  editEnabledRef.current = editEnabled;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [outsideClickEnabled, setOutsideClickEnabled] = useState(false);

  useEffect(() => {
    setOutsideClickEnabled(!showDeleteConfirmation);
  }, [showDeleteConfirmation]);

  const formik = useFormik({
    initialValues: location ?? {
      name: ''
    },
    enableReinitialize: true,
    onSubmit: values => {
      const propsToRemove = ['id', 'updated_at', 'created_at'];

      const filteredValues = Object.keys(values)
        .filter(key => !propsToRemove.includes(key))
        .reduce((obj, key) => {
          return { ...obj, [key]: values[key] };
        }, {});

      if (params.id === 'new') {
        dispatch(
          actions.createLocation({
            props: filteredValues,
            onSuccess: () => {
              setEditEnabled(false);
              onClose();
            }
          })
        );
      } else {
        dispatch(
          actions.updateLocation({
            id: parseInt(params.id),
            props: filteredValues,
            onSuccess: () => {
              setEditEnabled(false);
              onClose();
            }
          })
        );
      }
    },
    validationSchema: locationSchema
  });

  useEffect(() => {
    if (params.id === 'new') {
      dispatch(actions.storeLocation(null));
      setEditEnabled(true);
    } else {
      dispatch(actions.fetchLocation(parseInt(params.id)));
      setEditEnabled(false);
    }
  }, [params.id]);

  const handleEdit = () => {
    setEditEnabled(true);
  };

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    params.id === 'new' ? onClose() : setEditEnabled(false);
  };

  const handleDelete = () => {
    dispatch(actions.deleteLocation(parseInt(params.id)));
    onClose();
  };

  const handleOutsideClick = event => {
    if (editEnabledRef.current) {
      setEditEnabled(false);
      if (confirm(I18n.t('settings.form_close_confirm'))) {
        onClose();
      } else {
        setEditEnabled(true);
        event.stopPropagation();
      }
    } else {
      onClose();
    }
  };

  return (
    <div className="details-panel">
      <LocationFormPanel>
        {isLoading ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <OutsideClickHandler
            className="click-handler-wrapper"
            onOutsideClick={handleOutsideClick}
            disabled={!outsideClickEnabled}
          >
            <div className="form-header">
              <h5>{location?.name}</h5>
              <div className="close-button" onClick={onClose}>
                <img src={Close} alt="close" />
              </div>
            </div>

            {backendValidationError && <Alert variant="danger">{backendValidationError}</Alert>}

            <FormField
              label={I18n.t('settings.field_name_label')}
              field={
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name ?? ''}
                  disabled={!editEnabled}
                />
              }
              error={formik.touched.name && formik.errors.name}
            />

            {hasWritePermission && (
              <FormControls
                editEnabled={editEnabled}
                handleEdit={handleEdit}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleDelete={() => setShowDeleteConfirmation(true)}
                isDeletable={params.id !== 'new'}
              />
            )}
          </OutsideClickHandler>
        )}
      </LocationFormPanel>

      {showDeleteConfirmation && (
        <InputConfirmationModal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          message={I18n.t('settings.form_location_delete_confirm', { word: 'DELETE' })}
          confirmMessage={'DELETE'}
          cancelBtn={{
            label: I18n.t('settings.form_cancel_button'),
            onClick: () => setShowDeleteConfirmation(false)
          }}
          okBtn={{
            label: I18n.t('settings.form_ok_button'),
            onClick: handleDelete
          }}
        />
      )}
    </div>
  );
};

export default LocationForm;
