import React from 'react';
import styled from 'styled-components';
import PowerBIPage from '../../../../PowerBIPage';

const CameraStatusPage = styled.div`
  width: 100%;
  height: 99%;
`;

const CameraStatus = () => {
  return (
    <CameraStatusPage>
      <PowerBIPage pageName="camera_status" showNavigation={false} />
    </CameraStatusPage>
  );
};

export default CameraStatus;
