import { useQuery, useMutation, gql } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Form as BYForm, Modal, MessagePopup } from 'brickyard-ui';

import usePermission from '@/hooks/usePermission';

import Button from '../../components/Button';

export const DISMISS_TICKET = gql`
  mutation DismissTicket($id: ID!, $dismissalReason: String!) {
    ticketDismiss(id: $id, dismissalReason: $dismissalReason) {
      id
      dismissalState
    }
  }
`;

export const UNDISMISS_TICKET = gql`
  mutation UndismissTicket($id: ID!) {
    ticketUndismiss(id: $id) {
      id
      dismissalState
    }
  }
`;

export const GET_REASONS = gql`
  query GetReason {
    reasons {
      id
      description
      type
    }
  }
`;

const ToggleDismiss = ({ ticket }) => {
  const [dismissalReason, setDismissalReason] = useState('');
  const [showReason, setShowReason] = useState(false);
  const canDismiss = usePermission('dismiss');
  const canUndismiss = usePermission('undismiss');
  const { id, dismissalState } = ticket;
  const isDismissed = dismissalState === 'dismissed';

  const { data: reasonData } = useQuery(GET_REASONS, { skip: isDismissed });

  const [dismissTicket, { loading: dismissLoading, error: dismissErr }] = useMutation(
    DISMISS_TICKET,
    {
      variables: { id, dismissalReason },
      onCompleted: () => {
        setDismissalReason('');
        setShowReason(false);
      }
    }
  );
  const [undismissTicket, { loading: undismissLoading, error: undismissErr }] = useMutation(
    UNDISMISS_TICKET,
    {
      variables: { id }
    }
  );
  const [err, setErr] = useState('');

  useEffect(() => {
    if (dismissErr || undismissErr) {
      setErr(dismissErr?.message || undismissErr?.message);
    }
  }, [dismissErr, undismissErr]);

  const toggle = () => (isDismissed ? undismissTicket() : setShowReason(true));

  if ((!canUndismiss && isDismissed) || (!canDismiss && !isDismissed)) {
    return null;
  }

  return (
    <div>
      <Button onClick={toggle} disabled={dismissLoading || undismissLoading}>
        {isDismissed ? I18n.t('tickets.actions.undismiss') : I18n.t('tickets.actions.dismiss')}
      </Button>

      <MessagePopup
        type="error"
        show={err}
        onHide={() => setErr('')}
        okBtn={{ label: I18n.t('actions.close'), onClick: () => setErr('') }}
        message={err}
      />

      <Modal show={showReason} onHide={() => setShowReason(false)}>
        <Modal.Header>
          <Modal.Title>{I18n.t('tickets.dialogs.dismissal.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <BYForm.Group>
            <BYForm.Label>{I18n.t('tickets.dialogs.dismissal.description')}</BYForm.Label>
            <BYForm.Control
              name="dismissalReason"
              value={dismissalReason}
              onChange={evt => setDismissalReason(evt.target.value)}
              as="select"
            >
              <option value="" />

              {reasonData?.reasons
                ?.filter(r => r.type === 'dismissal')
                .map(r => (
                  <option value={r.description} key={r.id}>
                    {r.description}
                  </option>
                ))}
            </BYForm.Control>
          </BYForm.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="by-dark"
            className="float-right ml-2"
            onClick={() => setShowReason(false)}
          >
            {I18n.t('actions.cancel')}
          </Button>

          <Button
            variant="by-primary"
            className="float-right"
            onClick={dismissTicket}
            disabled={!dismissalReason}
          >
            {I18n.t('actions.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ToggleDismiss;
