import React from 'react';
import styled from 'styled-components';
import CountrySearch from './../../../TicketsTable/search/CountrySearch';
import Label from './Label';
import AutoCompleteWrapper from './AutoCompleteWrapper';
import ErrorWrapper from './ErrorWrapper';

const Wrapper = styled.div`
  position: relative;

  .error-message {
    bottom: -10px;
  }
`;

const CountrySearchInput = ({
  disabled,
  readOnly,
  attr = 'name',
  label,
  fieldProps,
  error,
  suspectBirthDate = null
}) => {
  return (
    <Wrapper>
      <AutoCompleteWrapper>
        <Label>
          {label}
          {readOnly && <span className="readonly-value">{fieldProps.value}</span>}
        </Label>
        {!readOnly && (
          <>
            <CountrySearch
              attr={attr}
              disabled={disabled}
              isInvalid={error}
              {...fieldProps}
              suspectBirthDate={suspectBirthDate?.value}
            />
            {error && <ErrorWrapper className="error-message">{error}</ErrorWrapper>}
          </>
        )}
      </AutoCompleteWrapper>
    </Wrapper>
  );
};

export default CountrySearchInput;
