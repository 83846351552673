import React from 'react';
import styled from 'styled-components';
import { Marker } from '@react-google-maps/api';
import { Modal } from 'brickyard-ui';
import Map from '@shared/maps/Map';

const MapContainer = styled.div`
  height: 80vh;
  max-height: 400px;
  overflow: hidden;

  > div {
    height: 100%;
  }
`;

const MapModal = ({ isVisible, toggleMap, street, houseNumber, city, center }) => {
  return (
    <Modal size="lg" show={isVisible} onHide={toggleMap} centered>
      <Modal.Header closeButton>
        {I18n.t('tickets.form.sections.offense.location')}: {street} {houseNumber}, {city}
      </Modal.Header>
      <Modal.Body>
        <MapContainer>
          <Map center={center} zoomLevel={18}>
            <Marker position={center} t />
          </Map>
        </MapContainer>
      </Modal.Body>
    </Modal>
  );
};

export default MapModal;
