import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ColorSchemeContext } from './Card';

const StyledTab = styled.li`
  display: inline-block;
  font-size: 1em;
  flex-basis: 0;
  flex-grow: 1;
  list-style: none;
  padding: 0.5em 0;
  border: 1px solid ${props => props.colorScheme.primaryColor};
  border-right: none;
  color: ${props => props.colorScheme.primaryColor};
  cursor: pointer;

  &.is-active {
    background: ${props => props.colorScheme.primaryColor};
    color: #fff;
  }

  &.disabled {
    color: rgba(73, 73, 73, 0.8);
    cursor: not-allowed;

    &.is-active {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  &:last-of-type {
    border-right: 1px solid ${props => props.colorScheme.primaryColor};
  }
`;

const Tab = ({ label, isActive, className = '', onClick, disabled }) => {
  const colorScheme = useContext(ColorSchemeContext);
  const handleClick = () => {
    onClick(label);
  };

  const activeStateClass = isActive ? 'is-active ' : '';
  const fullClassName = `${activeStateClass}${className}${disabled ? 'disabled' : ''}`.trim();
  return (
    <StyledTab
      colorScheme={colorScheme}
      className={fullClassName}
      onClick={!disabled ? handleClick : () => {}}
      disabled={disabled}
    >
      {label}
    </StyledTab>
  );
};

const Container = styled.div`
  font-size: 13px;

  ol {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-align: center;
  }
`;

const Tabs = ({ children, disabled }) => {
  const tabs = children.filter(item => item);
  const [active, setActive] = useState(tabs[0].props.label);

  return (
    <Container className="tabs">
      <ol className="tab-list">
        {tabs.map(tab => (
          <Tab
            isActive={tab.props.label === active}
            key={tab.props.label}
            label={tab.props.label}
            onClick={setActive}
            disabled={disabled || tab.props.disabled}
          />
        ))}
      </ol>
      <div className="tab-content">
        {tabs.map(content => (content.props.label === active ? content.props.children : null))}
      </div>
    </Container>
  );
};

export default Tabs;
