import React from 'react';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import styled from 'styled-components';

const StyledMakesExemptField = styled.div`
  display: flex;
  flex-direction: row;

  .colored-box-label {
    height: 30px;
    width: 550px;
    display: flex;

    p {
      margin: auto;
    }
  }

  .toggle-switch {
    margin-left: -32px;
    margin-right: 35px;
  }

  .if-match {
    border: 2px solid #40d561;
    background-color: #bdf1c8;
  }
  .unless-match {
    border: 2px solid #d4666b;
    background-color: #f2cfd0;
  }
`;

function MakesExemptField({ formik, editEnabled }) {
  return (
    <StyledMakesExemptField>
      <div className="toggle-switch">
        <FormFieldSmall
          field={
            <LabelToggle
              id="makes_exempt_if_matches"
              name="makes_exempt_if_matches"
              isOn={formik.values.makes_exempt_if_matches ?? false}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.makes_exempt_if_matches}
        />
      </div>
      <div
        className={`colored-box-label ${
          formik.values.makes_exempt_if_matches ? 'if-match' : 'unless-match'
        }`}
      >
        <p>
          {formik.values.makes_exempt_if_matches
            ? I18n.t('settings.pipelines.processors.exemption_rule_checker.makes_exempt_if_label')
            : I18n.t(
                'settings.pipelines.processors.exemption_rule_checker.makes_exempt_unless_label'
              )}
        </p>
      </div>
    </StyledMakesExemptField>
  );
}

export default MakesExemptField;
