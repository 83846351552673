import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Loading, Alert } from 'brickyard-ui';
import Table from '../../components/Table';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCaseListsState, useUseCaseListsSlice } from './slice';
import UseCaseListForm from './components/UseCaseListForm';

const UseCaseListsPage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .left-panel {
    display: flex;
    flex-direction: column;
    width: calc(100% * 1 / 3);
    height: 100%;

    .left-panel-header {
      display: flex;
      max-height: 100px;

      h3 {
        margin: 15px;
        color: #777777;
        font-weight: 600;
      }

      button {
        margin: 15px;
        margin-left: auto;
      }
    }

    .left-panel-body {
      display: flex;
      margin: 30px;
      width: calc(100% - 60px);
      height: calc(100% - 20px);

      .loading-box {
        margin: auto;
      }
    }
  }

  .alert {
    position: fixed;
    left: 55%;
    transform: translate(-50%, 0);
    width: 85%;
  }
`;

function UseCaseLists() {
  const { actions } = useUseCaseListsSlice();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.fetchUseCaseLists());

    return () => {
      dispatch(actions.clearData());
    };
  }, [dispatch]);

  const state = useSelector(selectUseCaseListsState);

  const onRowClick = id => {
    history.push({ pathname: `${match.url}/${id}`, search: location.search });
  };

  const onNewButtonClick = () => {
    history.push({ pathname: `${match.url}/new`, search: location.search });
  };

  const onCloseAlert = () => {
    dispatch(actions.setBackendValidationError(null));
  };

  const onFormClose = () => {
    history.push({ pathname: match.url, search: location.search });
    dispatch(actions.storeUseCaseList(null));
    dispatch(actions.setBackendValidationError(null));
  };

  const columns = [
    {
      key: 'name',
      header: I18n.t('settings.table_name_header'),
      sortable: true
    }
  ];

  return (
    <UseCaseListsPage>
      <div className="left-panel">
        <div className="left-panel-header">
          <h3>{I18n.t('settings.use_case_lists_label')}</h3>
          {state.permission === 'copSettingsWrite' && (
            <Button variant="outline-by-secondary" onClick={onNewButtonClick}>
              {I18n.t('settings.new_use_case_list_button')}
            </Button>
          )}
        </div>
        <div className="left-panel-body">
          {state.loading && !state.useCaseLists.length ? (
            <div className="loading-box">
              <Loading />
            </div>
          ) : (
            <Table
              columns={columns}
              items={state.useCaseLists}
              onRowClick={onRowClick}
              selectedId={state.selectedUseCaseList?.id}
            />
          )}
        </div>
      </div>

      <Switch>
        <Route path={`${match.path}/:id`}>
          <UseCaseListForm
            useCaseList={state.selectedUseCaseList}
            onClose={onFormClose}
            isLoading={state.loading}
            backendValidationError={state.backendValidationError}
          />
        </Route>
      </Switch>

      {state.backendValidationError && (
        <Alert onClose={onCloseAlert} variant="danger" dismissible>
          {state.backendValidationError}
        </Alert>
      )}
    </UseCaseListsPage>
  );
}

export default UseCaseLists;
