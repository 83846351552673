import { useQuery } from '@apollo/client';
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  GET_VALIDATED_FOR_DELETION_BY_USER,
  GET_VALIDATED_OBSERVATIONS_BY_USER
} from '@/components/queries/observations';
import { loadObservationMetadata, cleanMetadata } from '@/actions/observationActions';

/**
 * This component loads the metada from the backend and parse it to
 * a format that the store expects. It's implemented as component
 * to not break the declarative aproach that react-apollo provides,
 * otherwise the query would be called imperatively.
 *
 */
const ObservationsMetadataSetter = ({ dispatch, user, areaId }) => {
  const { loading, data, error, refetch } = useQuery(GET_VALIDATED_OBSERVATIONS_BY_USER, {
    variables: { userId: user.id, areaId }
  });

  const {
    loading: deletionsLoading,
    data: deletionData,
    error: deletionErr,
    refetch: deletionRefetch
  } = useQuery(GET_VALIDATED_FOR_DELETION_BY_USER, { variables: { userId: user.id, areaId } });

  useEffect(() => {
    refetch();
    deletionRefetch();
  }, [areaId]);

  useEffect(() => {
    if (data?.observations?.nodes && deletionData?.observations?.nodes) {
      dispatch(cleanMetadata());
      [...data.observations.nodes, ...deletionData.observations.nodes].forEach(obs => {
        if (obs.validatedForDeletion || obs.validatedType) {
          dispatch(
            loadObservationMetadata({
              [obs.id]: {
                action: obs.validatedForDeletion ? 'delete' : obs.validatedType.toLowerCase(),
                relations: new Set(obs.attachments)
              }
            })
          );
        }
      });
    }
  }, [data, loading, error, deletionsLoading, deletionData, deletionErr]);

  return null;
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(ObservationsMetadataSetter);
