import { Polygon } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Map from '../../../../../../shared/maps/Map';

const StyledUseCaseDetailsMap = styled.div`
  display: flex;
  width: 400px;
  height: 400px;
  margin: 0px auto;

  #google-map {
    min-width: 0px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    margin: 10px 10px 0px 10px;
    width: calc(100% - 20px);
    padding-top: calc(100% - 20px);
  }
`;

function UseCaseDetailsMap({ useCase }) {
  const [map, setMap] = useState();
  const [mapCenter, setMapCenter] = useState({ lat: 52.1611973294726, lng: 5.621868260475858 });

  // Position map to show every zone
  useEffect(() => {
    if (map && useCase) {
      // Netherlands
      setMapCenter({ lat: 52.1611973294726, lng: 5.621868260475858 });

      const bounds = new window.google.maps.LatLngBounds();

      useCase.zones.forEach(zone => {
        zone.geom?.forEach(polygon => {
          polygon.forEach(ring => {
            ring.forEach(point => bounds.extend(point));
          });
        });
      });
      if (!bounds.isEmpty()) {
        setMapCenter(bounds.getCenter());
        map.fitBounds(bounds);
      }
    }
  }, [map, useCase]);

  return (
    <StyledUseCaseDetailsMap>
      <Map onLoad={setMap} clickableIcons={false} center={mapCenter}>
        {useCase?.zones &&
          useCase.zones.map(zone =>
            zone.geom?.map(polygon => (
              <Polygon
                key={`${useCase.id}.${zone.id}`}
                paths={polygon}
                options={{
                  clickable: false
                }}
              />
            ))
          )}
      </Map>
    </StyledUseCaseDetailsMap>
  );
}

export default UseCaseDetailsMap;
